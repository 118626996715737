import React, { useEffect, useState, useRef } from 'react';
import { getDatabase, ref, onValue, update, get, set} from 'firebase/database';
import { db } from '../Firebase';
import SideBar from './AdminSideBar';
import './Attendance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortUp, faSortDown ,faSort  } from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle, faExclamationCircle, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import AttendanceModal from './AttendanceModal';
import * as XLSX from 'xlsx';
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { saveAs } from 'file-saver';


const DisplayData = ({ selectedTrainingDayData }) => {
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [userDataArray, setUserDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingTrainingDates, setExistingTrainingDates] = useState([]);
  const [trainingData, setTrainingData] = useState([]); // Separate state for training data
  const [schoolYears, setSchoolYears] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [isOpenAbsenteeModal, setIsOpenAbsenteeModal] = useState(false);
  const [isOpenCustomAbsenteeModal, setIsOpenCustomAbsenteeModal] = useState(false);
  const [allRecords, setAllRecords] = useState([]); // Assuming you have records fetched somewhere
  const [sortedStudentNumbers, setSortedStudentNumbers] = useState([]); // Assuming you have sorted student numbers
  const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

  useEffect(() => {
    const dbRef = ref(db, 'users');
    const callback = (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const userList = Object.values(userData);
        setUserDataArray(userList);
      } else {
        console.log('No user data found in the database.');
      }
    };

    const unsubscribe = onValue(dbRef, callback);

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
        // Reference the appropriate location in the database using the student number
        const userRef = ref(db, `Officer/${designation}/${studentNumber}`);

        // Get the user data from the database
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
            const userData = userSnapshot.val();

            // Update the user's status to "Offline"
            await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Offline');

            toast.success('Logout successful!', { position: 'top-center' });

            // Clear local storage
            localStorage.clear();

            // Redirect to the login page using navigate
            navigate("/Admin/AdminOfficerLogin");
        } else {
            // User does not exist
            toast.error('User does not exist.', { position: 'top-center' });
        }
    } catch (error) {
        console.error('Error logging out:', error);
        toast.error('Failed to log out. Please try again later.', { position: 'top-center' });
    }
};


  const fetchAttendanceData = async () => {
    setLoading(true);
  
    try {
      // Fetch user data
      const userRef = ref(db, 'users');
      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userList = Object.values(userData);
          setUsers(userList); // Update user state
        } else {
          console.log('No user data found in the database.');
        }
      });
  
      // Fetch enrolled students data if semester and school year are selected
      if (selectedSemester !== '' && selectedSchoolYear !== '') {
        const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        onValue(userSemesterRef, (snapshot) => {
          if (snapshot.exists()) {
            const enrolledStudentsData = transformEnrolledData(snapshot.val());
            setEnrolledStudents(enrolledStudentsData);
            fetchUserUIDs(enrolledStudentsData);
          } else {
            setEnrolledStudents([]);
            setStudentUIDs({});
          }
        });
  
        // Fetch training dates data
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates`;
        const trainingDatesRef = ref(db, sessionPath);
        onValue(trainingDatesRef, (snapshot) => {
          if (snapshot.exists()) {
            const trainingDatesData = snapshot.val();
            setExistingTrainingDates(trainingDatesData);
  
            // Set initial training date
            const initialTrainingDate = Object.keys(trainingDatesData)[0];
            setSelectedTrainingDate(initialTrainingDate);
          } else {
            setExistingTrainingDates([]);
          }
        });
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error.message);
      // Handle the error appropriately, e.g., display a notification to the user
    } finally {
      setLoading(false);
    }
  };
  
  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber
    }));
  };
  useEffect(() => {
    fetchAttendanceData();
  }, [selectedSemester, selectedSchoolYear]);

  useEffect(() => {
    async function fetchSchoolYears() {
      try {
        const schoolYearsRef = ref(db, 'SchoolYear');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      } 
    }

    fetchSchoolYears();
  }, []);

  const [selectedTrainingDate, setSelectedTrainingDate] = useState(null);

  const markAllStudentsAbsent = async () => {
    try {
      if (!selectedTrainingDate) {
        console.error('No training date selected');
        return;
      }
    
      const confirmMarkingAbsent = window.confirm(`Are you sure you want to mark all students as absent for ${selectedTrainingDate}?`);
    
      if (!confirmMarkingAbsent) {
        return;
      }
      // Fetch existing attendance data for the selected training date
      const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
      const existingAttendanceRef = ref(db, sessionPath);
      const existingAttendanceSnapshot = await get(existingAttendanceRef);
    
      if (existingAttendanceSnapshot.exists()) {
        // Iterate over userDataArray and create a data structure for each student with 'absent' appearance only if no existing record
        const absentStudentData = {};
        enrolledStudents.forEach((user) => {
          const { studentNumber } = user;
          const hasExistingRecord = existingAttendanceSnapshot.hasChild(studentNumber);
          if (!hasExistingRecord) {
            // Modify the appearance key based on the existing data
            const existingData = existingAttendanceSnapshot.child(studentNumber).val();
            const appearanceKey = existingData && existingData['Time In'] ? 'Time Out/appearance' : 'Time In/appearance';
            absentStudentData[`${studentNumber}/${appearanceKey}`] = 'Absent';
          }
        });
    
        // Store the absent student data in the database using the update function
        const absentStudentsRef = ref(db, sessionPath);
        await update(absentStudentsRef, absentStudentData);
    
        console.log(`All students marked as absent for ${selectedTrainingDate}`);
    
        // Optional: After marking all students as absent, you may want to update the UI or reset some state
        setSelectedTrainingDate(null);
        fetchAttendanceData(); // Fetch updated data after marking all students as absent
      } else {
        console.log('No existing attendance data found for the selected training date.');
      }
    } catch (error) {
      console.error('Error marking all students absent:', error.message);
      // Display an error message to the user if needed
    }
    };

const [changesPending, setChangesPending] = useState(false);

  const handleTrainingDateChange = (newTrainingDate) => {
    setSelectedTrainingDate(newTrainingDate);
  };

  const [filterPrefix, setFilterPrefix] = useState(''); // State for the student number prefix filter
  const [filterCourse, setFilterCourse] = useState(''); // State for the course filter
  const [filterGender, setFilterGender] = useState(''); // State for the gender filter
  const [filterStatus, setFilterStatus] = useState(''); // State for the status filter
  const [searchText, setSearchText] = useState('');
  const [selectedFilterTrainingDate, setSelectedFilterTrainingDate] = useState('');
  const [displayMode, setDisplayMode] = useState('appearance'); // Add this line

  const handleFilterStatusChange = (newFilterStatus) => {
    setFilterStatus(newFilterStatus);
  };
  const studentNumberPrefixes = enrolledStudents.map((user) => user.studentNumber?.slice(0, 4) || '');

  // Create a set of unique prefixes
  const uniquePrefixes = [...new Set(studentNumberPrefixes)];

  const handleFilterPrefixChange = (newFilterPrefix) => {
    setFilterPrefix(newFilterPrefix);
  };
  const handleFilterCourseChange = (newFilterCourse) => {
    setFilterCourse(newFilterCourse);
  };
  const handleFilterGenderChange = (newFilterGender) => {
    setFilterGender(newFilterGender);
  };
  const handleFilterTrainingDateChange = (value) => {
    setSelectedFilterTrainingDate(value);
  };
  
  const [excuseStatus, setExcuseStatus] = useState({});
  const [showExcuseDropdowns, setShowExcuseDropdowns] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const menuContentRef = useRef(null);


  const handleTrainingDateClick = (trainingDate) => {
    setSelectedColumn(trainingDate);
  };
  const handleExcuseChange = (studentNumber, trainingDate, value) => {
    setExcuseStatus((prevExcuseStatus) => ({
      ...prevExcuseStatus,
      [`${studentNumber}-${trainingDate}`]: value,
    }));

    // Set changes pending state to true
    setChangesPending(true);
  };

  const handleSubmit = async (studentNumber, trainingDate) => {
    try {
      const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${trainingDate}`;
      // Create an object to store updates
      const updates = {};
      // Iterate through excuseStatus to find students with "Excused" status
      const key = `${studentNumber}-${trainingDate}`;
      const value = excuseStatus[key];
      const appearanceKey = value === 'Excused' ? 'Time In/appearance' : 'Time Out/appearance';
      const path = `${sessionPath}/${studentNumber}/${appearanceKey}`;
      updates[path] = value === 'Excused' ? 'Excused' : 'Absent';

      // Use the update function to save the changes to the database
      const dbRef = ref(db);
      await update(dbRef, updates);

      // Reset state and fetch updated data
      setExcuseStatus({});
      setChangesPending(false);
      fetchAttendanceData(); // Fetch updated data after submitting changes
    } catch (error) {
      console.error('Error submitting changes:', error.message);
      // Display an error message to the user if needed
    }
  };
 
  const countAttendanceData = (trainingDates, enrolledStudents , allRecords) => {
    const counts = {};
  
    Object.keys(trainingDates).forEach((trainingDate) => {
      const count = {
        Absent: 0,
        Late: 0,
        Excused: 0,
        Present: 0,
      };
  
      enrolledStudents.forEach((userData) => {
        const recordsForStudent = allRecords.filter((r) => r.studentNumber === userData.studentNumber);
        const record = recordsForStudent.find((r) => r.trainingDate === trainingDate);
  
        if (record) {
          const statusValue = record['Time In']?.appearance || record['Time Out']?.appearance || '-';
          count[statusValue]++;
        }
      });
  
      counts[trainingDate] = count;
    });
  
    return counts;
  }
  
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const [selectedStudentRecords, setSelectedStudentRecords] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const openModal = (userData, recordsForStudent, displayedRowNumber) => {
    setSelectedStudentData(userData);
    setSelectedStudentRecords(recordsForStudent);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setSelectedStudentData(null);
    setSelectedStudentRecords(null);
    setIsModalOpen(false);
    setShowSummaryModal(false);
    setShowButtons(false);
  };

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map(student => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach(studentNumber => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid]; // Storing user details by UID
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };

  // Fetch student UIDs when userDataArray changes
  useEffect(() => {
    if (enrolledStudents.length > 0) {
      fetchUserUIDs(enrolledStudents);
    }
  }, [enrolledStudents]);
  const [absenteeList, setAbsenteeList] = useState([]);

  const [userType, setUserType] = useState("");
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedEmail, setStoredEmail] = useState('');
  const [storedDesignation, setStoredDesignation] = useState('');
  const [designation, setDesignation] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  useEffect(() => {
      // Set user type based on credentials
      const userEmail = localStorage.getItem('userEmail');
      if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
          setUserType("admin");
      }
  }, []);

  useEffect(() => {
      const userType = localStorage.getItem("userType");
      setUserType(userType);

      const storedStudentNumber = localStorage.getItem('studentNumber');
      setStoredStudentNumber(storedStudentNumber);


      const storedEmail = localStorage.getItem('email');
      setStoredEmail(storedEmail);

      const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
      setStoredDesignation(storedDesignation); // Set the designation in the component state
  }, []);

  useEffect(() => {
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');
      const storedEmail = localStorage.getItem('email');
      if (storedDesignation && storedStudentNumber) {
          setDesignation(storedDesignation);
          setStudentNumber(storedStudentNumber);
          setEmail(storedEmail);
      }
  }, []);
  // Effect to fetch user data when component mounts or when studentNumber or designation changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromDb = await getUserData(designation, studentNumber);
        setUserData(userDataFromDb);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchData();
  }, [designation, studentNumber]);
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState(null);



  const getUserData = async (designation, studentNumber) => {
    try {
      // Construct the reference path
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
      
      // Retrieve the data
      const snapshot = await get(userRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Extract the user data
        const userData = snapshot.val();
        // Return the relevant data such as Lastname and Firstname
        return {
          Course: userData.Course,
          Lastname: userData.Lastname || '',
          Firstname: userData.Firstname || '',
          Middlename: userData.Middlename || '',
          // Add more properties as needed
        };
      } else {
        // Data does not exist
        console.log("No data available for the given student number.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user data:", error.message);
      throw error;
    }
  };


  const [userImageUrl, setUserImageUrl] = useState(null);
  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
      setUserImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching user image:', error);
      setUserImageUrl(null);
    }
  };

  useEffect(() => {
    // Fetch user image when email changes
    if (email) {
      fetchUserImage(email);
    }
  }, [email]);
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const handleLogoutClick = () => {
    setIsModalOpen1(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen1(false);
    // Add any additional logic you want to execute when the modal is closed
  };


const [showSummaryModal, setShowSummaryModal] = useState(false);
const [selectedStudentNumber, setSelectedStudentNumber] = useState('');
const [selectedStudentFirstName, setSelectedStudentFirstName] = useState('');

// Function to handle the click event on the "Show Summary" button
const [gradeStatus, setGradeStatus] = useState(''); // State to hold the grade status
const [searchQueries, setSearchQueries] = useState({});

const handleSearchPerSemester = (event, year, semester) => {
  const { value } = event.target;
  setSearchQueries(prevState => ({
    ...prevState,
    [`${year}-${semester}`]: value
  }));
};

const handleResetSearch = () => {
  setSearchQueries({});
};
  const renderAttendanceData = (trainingDates, handleExcuseChange, handleSubmit) => {
    // Move the declaration of allRecords to the beginning
    const allRecords = Object.entries(trainingDates || {}).flatMap(([trainingDate, attendanceRecords]) => {
      return Object.entries(attendanceRecords || {}).map(([studentNumber, record]) => ({
        studentNumber,
        trainingDate,
        ...record,
      }));
    });


const filterAbsenteeList = () => {
  const filteredList = sortedStudentNumbers.filter(studentNumber => {
      const recordsForStudent = allRecords.filter(r => r.studentNumber === studentNumber);
      const absenceCount = recordsForStudent.reduce((count, record) => {
          if (
              record['Time In']?.appearance.toLowerCase() === 'absent' ||
              record['Time Out']?.appearance.toLowerCase() === 'absent'
          ) {
              return count + 1;
          }
          return count;
      }, 0);
      return absenceCount === 2;
  });
  setAbsenteeList(filteredList);
  setIsOpenAbsenteeModal(true);
  setIsOpenCustomAbsenteeModal(false);
};

const storeAbsenteeDataWarning = async (absenteeList, selectedSchoolYear, selectedSemester) => {
  try {
      const droplistRef = ref(db, `WarningDroplist/${selectedSchoolYear}/${selectedSemester}`);
      await set(droplistRef, absenteeList);
      console.log('Absentee data stored in the WarningDroplist successfully.');
  } catch (error) {
      console.error('Error storing absentee data in the WarningDroplist:', error.message);
  }
};

const filterAbsenteeListWithThreshold = () => {
  const filteredList = sortedStudentNumbers.filter(studentNumber => {
      const recordsForStudent = allRecords.filter(r => r.studentNumber === studentNumber);
      const absenceCount = recordsForStudent.reduce((count, record) => {
          if (
              record['Time In']?.appearance.toLowerCase() === 'absent' ||
              record['Time Out']?.appearance.toLowerCase() === 'absent'
          ) {
              return count + 1;
          }
          return count;
      }, 0);
      return absenceCount >= 3;
  });

  setAbsenteeList(filteredList);
  setIsOpenCustomAbsenteeModal(true);
  setIsOpenAbsenteeModal(false);
};

const countAbsentees = () => {
  return absenteeList.length;
};
const storeAbsenteeDataDroplist = async (absenteeList, selectedSchoolYear, selectedSemester) => {
  try {
      const droplistRef = ref(db, `Droplist/${selectedSchoolYear}/${selectedSemester}`);
      await set(droplistRef, absenteeList);
      console.log('Absentee data stored in the Droplist successfully.');
  } catch (error) {
      console.error('Error storing absentee data in the Droplist:', error.message);
  }
};
const AbsenteeModal = ({ isOpen, closeModal, selectedSchoolYear, selectedSemester, absenteeList }) => {
  useEffect(() => {
    if (isOpen && absenteeList.length > 0) {
      storeAbsenteeDataWarning(absenteeList, selectedSchoolYear, selectedSemester);
    }
  }, [isOpen, absenteeList, selectedSchoolYear, selectedSemester]);

  return (
    isOpen && (
      <div className="modal232">
      <div className="modal-content232">
        <span className="close1" onClick={closeModal}>&times;</span>
        <div class="droplisted-container">
  <div class="button-wrapper">
    <button class="warningDroplist" onClick={filterAbsenteeList}>
      Warning Droplist
    </button>
  </div>
  <div class="separator"></div>
  <div class="button-wrapper">
    <button class="droplist" onClick={filterAbsenteeListWithThreshold}>
      Droplist
    </button>
  </div>
</div>
         
<div class="absences-info">
  <h2>Warning Droplist:</h2>
    <p>School Year: <span class="highlight">{selectedSchoolYear}</span></p>
    <p>Semester: <span class="highlight">{selectedSemester}</span></p>
    <p>Total Absentees: <span class="highlight">{countAbsentees()}</span></p>
</div>

        <table className="table-attendance-count2">
  <thead className='attendance-thead2'>
            <tr>
              <th>No.</th>
              <th>Student Number</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Course</th>
              <th>Gender</th>
            </tr>
          </thead>
          <tbody>
            {absenteeList.map((studentNumber, index) => {
              const userData = userDataArray.find((user) => user.studentNumber === studentNumber);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{studentNumber}</td>
                  <td>{userData ? userData.Lastname || '-' : '-'}</td>
                  <td>{userData ? userData.Firstname || '-' : '-'}</td>
                  <td>{userData ? userData.Middlename || '-' : '-'}</td>
                  <td>{userData ? userData.Course || '-' : '-'}</td>
                  <td>{userData ? userData.Gender || '-' : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
    )
  );
};


const CustomAbsenteeModal = ({ isOpen, closeModal, selectedSchoolYear, selectedSemester, absenteeList }) => {
  useEffect(() => {
    if (isOpen && absenteeList.length > 0) {
      storeAbsenteeDataDroplist(absenteeList, selectedSchoolYear, selectedSemester);
    }
  }, [isOpen, absenteeList, selectedSchoolYear, selectedSemester]);

  return (
    isOpen && (
      <div className="modal232">
        
        <div className="modal-content232">
          
          <span className="close1" onClick={closeModal}>&times;</span>
          <div class="droplisted-container">
  <div class="button-wrapper">
    <button class="warningDroplist" onClick={filterAbsenteeList}>
      Droplist
    </button>
  </div>
  <div class="separator"></div>
  <div class="button-wrapper">
    <button class="droplist" onClick={filterAbsenteeListWithThreshold}>
      Droplist
    </button>
  </div>
</div>
         
<div class="absences-info">
  <h2>Students with 3 or more absences:</h2>
    <p>School Year: <span class="highlight">{selectedSchoolYear}</span></p>
    <p>Semester: <span class="highlight">{selectedSemester}</span></p>
    <p>Total Absentees: <span class="highlight">{countAbsentees()}</span></p>
</div>


<div class="table-container121">
  <table class="table-attendance-count2">
    <thead class='attendance-thead2'>
      <tr>
        <th>No.</th>
        <th>Student Number</th>
        <th>Last Name</th>
        <th>First Name</th>
        <th>Middle Name</th>
        <th>Course</th>
        <th>Gender</th>
      </tr>
    </thead>
    <tbody>
      {absenteeList.map((studentNumber, index) => {
        const userData = userDataArray.find((user) => user.studentNumber === studentNumber);
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{studentNumber}</td>
            <td>{userData ? userData.Lastname || '-' : '-'}</td>
            <td>{userData ? userData.Firstname || '-' : '-'}</td>
            <td>{userData ? userData.Middlename || '-' : '-'}</td>
            <td>{userData ? userData.Course || '-' : '-'}</td>
            <td>{userData ? userData.Gender || '-' : '-'}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>

        </div>
      </div>
    )
  );
};


    // Now you can use allRecords in the countAttendanceData function
    const counts = countAttendanceData(trainingDates, enrolledStudents, allRecords);
  
    const filteredTrainingDates = selectedFilterTrainingDate
      ? { [selectedFilterTrainingDate]: trainingDates[selectedFilterTrainingDate] }
      : trainingDates;
  
    // Get a list of all unique student numbers from userDataArray
    const allStudentNumbers = userDataArray.map((user) => user.studentNumber);
    const studentNumbers = [...new Set(allStudentNumbers)];
  
    
    const downloadAttendanceDataAsExcel = () => {
      const headerCells = document.querySelectorAll('.table-attendance thead th');
      const rows = document.querySelectorAll('.table-attendance tbody tr');
  
      const data = [];
  
      // Add headers to data, excluding the first row and another four headers
      const headerData = Array.from(headerCells).map(th => th.textContent.trim());
      const headerRow = headerData.slice(11).map(header => {
          // Extracting the training day number from the header text
          const trainingNumberMatch = header.match(/Training (\d+)/);
          if (trainingNumberMatch) {
              return `Training ${trainingNumberMatch[1]}`; // Extracted training day number
          } else {
              return header;
          }
      });
      data.push(headerRow);
  
      // Add rows to data, excluding rows with time format data
      rows.forEach((row) => {
          const cells = row.querySelectorAll('td');
          const rowData = Array.from(cells).map(cell => cell.textContent.trim());
  
          // Check if any cell contains time format data
          const containsTimeFormatData = rowData.some(cellData => /\d{1,2}:\d{1,2}:\d{1,2}/.test(cellData));
  
          // If the row does not contain time format data, add it to the data array
          if (!containsTimeFormatData) {
              // If any cell contains "AbsentAbsentExcused", consider it as "Absent"
              const updatedRowData = rowData.map(cellData => {
                  if (cellData === 'AbsentAbsentExcused') {
                      return 'Absent';
                  }
                  return cellData;
              });
              data.push(updatedRowData);
          }
      });
  
      // Create workbook and worksheet
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Attendance Data');
  
      // Generate Excel file
      XLSX.writeFile(wb, 'attendance_data.xlsx');
  };
  
  
    

    let displayedRowNumber = 0; // Counter for displayed rows
  
    const getLatestPresenceStatusForStudent = (trainingDates, studentNumber) => {
      const recordsForStudent = allRecords.filter((r) => r.studentNumber === studentNumber);
      const sortedTrainingDates = Object.keys(trainingDates).sort((a, b) => new Date(b) - new Date(a));
  
      for (const trainingDate of sortedTrainingDates) {
        const record = recordsForStudent.find((r) => r.trainingDate === trainingDate);
        if (record) {
          return record['Time In']?.appearance || record['Time Out']?.appearance || '-';
        }
      }
      return '-';
    };
    const sortedStudentNumbers = studentNumbers.sort((a, b) => {
      const presenceStatusA = getLatestPresenceStatusForStudent(trainingDates, a);
      const presenceStatusB = getLatestPresenceStatusForStudent(trainingDates, b);
  
      if (presenceStatusA === 'Late' && presenceStatusB !== 'Late') {
          return -1;
      } else if (presenceStatusA !== 'Late' && presenceStatusB === 'Late') {
          return 1;
      } else if (presenceStatusA === 'Present' && presenceStatusB !== 'Present') {
          return -1;
      } else if (presenceStatusA !== 'Present' && presenceStatusB === 'Present') {
          return 1;
      } else {
          // If presence statuses are the same, sort by last name and student number
          const userDataA = userDataArray.find(user => user.studentNumber === a);
          const userDataB = userDataArray.find(user => user.studentNumber === b);
          const lastNameA = userDataA ? userDataA.Lastname || '' : '';
          const lastNameB = userDataB ? userDataB.Lastname || '' : '';
          if (lastNameA === lastNameB) {
              return a.localeCompare(b);
          }
          return lastNameA.localeCompare(lastNameB);
      }
  });
  
  
  const handleSummaryClick = (studentNumber) => {
    setSelectedStudentNumber(studentNumber);
    setShowSummaryModal(true);
    const selectedStudent = userDataArray.find((user) => user.studentNumber === studentNumber);
    if (selectedStudent) {
      setSelectedStudentFirstName(selectedStudent.Firstname || ''); // Pass the first name of the selected student
    } else {
      setSelectedStudentFirstName('');
    }
  };
  
  const recordsForStudent = allRecords.filter((r) => r.studentNumber === selectedStudentNumber);
  const selectedStudent = userDataArray.find((user) => user.studentNumber === selectedStudentNumber);
  const selectedStudentFirstName = selectedStudent ? (selectedStudent.Firstname || '') : '';
  const selectedStudentLastName = selectedStudent ? (selectedStudent.Lastname || '') : '';
  const selectedStudentMiddleName = selectedStudent ? (selectedStudent.Middlename || '') : '';
  
  // Format the name as "Lastname, Firstname Middlename"
  const formattedName = `${selectedStudentLastName}, ${selectedStudentFirstName} ${selectedStudentMiddleName}`;
  
const handleSort = (column) => {
  if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
      setSortBy(column);
      setSortOrder('asc');
  }
};

// Function to render the sort icon based on column and sort order
const renderSortIcon = (column) => {
  if (sortBy === column) {
      return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  }
  return <FontAwesomeIcon icon={faSort} />; // Always render the sort icon
};

const saveAsExcel = () => {
  // Initialize an empty array to hold the table data
  const tableData = [];

  // Iterate through the table rows
  const rows = document.querySelectorAll('.table-attendance-count2 tbody tr');
  rows.forEach(row => {
    const rowData = {};

    // Extract status and counts from each row
    const cells = row.querySelectorAll('td');
    rowData.Status = cells[0].textContent.trim(); // Extract status from the first cell

    // Extract counts for each training date
    cells.forEach((cell, index) => {
      if (index !== 0) { // Skip the first cell (Status)
        const trainingDate = sortedTrainingDates[index - 1];
        const count = cell.textContent.trim();
        rowData[trainingDate] = count;
      }
    });

    // Push the row data to the table data array
    tableData.push(rowData);
  });

  // Convert table data to Excel format
  const worksheet = XLSX.utils.json_to_sheet(tableData);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance Counts');

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, 'attendance_counts.xlsx');
};


const getTrainingDayNumber = (trainingDate) => {
  const match = trainingDate.match(/\d+/);
  return match ? parseInt(match[0]) : 0;
};



const calculateStatusCount = (status, trainingDate) => {
  return allRecords.filter((record) => {
    return record.trainingDate === trainingDate && (record['Time In']?.appearance === status || record['Time Out']?.appearance === status);
  }).length;
};
const sortedTrainingDates = Object.keys(trainingDates)
  .sort((a, b) => {
    const dateA = new Date(a.split(':')[1].trim());
    const dateB = new Date(b.split(':')[1].trim());
    return dateA - dateB;
  });
  

  const handleDroplistButtonClick = () => {
    setShowButtons(!showButtons);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };



    return (
      <div className='Attendance'>

<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

        <div className='attendance-container'>
        <AttendanceModal
  isOpen={isModalOpen}
  closeModal={closeModal}
  userData={selectedStudentData}
  recordsForStudent={selectedStudentRecords}
  displayedRowNumber={displayedRowNumber}
/>
 <div className="header-container1">

{isModalOpen1 && (
<div className="CustomCard1">
  <div className="CustomCard">
    <div className="card-content">
      <p className="card-heading">Log Out</p>
      <p className="card-description">Are you sure you want to Log out?</p>
    </div>
    <div className="card-button-wrapper">
      <button className="card-button secondary" onClick={handleCloseModal}>No</button>
      <button className="card-button primary" onClick={handleLogout} >Yes</button>
    </div>
    <button className="exit-button" onClick={handleCloseModal}>
      <svg height="20px" viewBox="0 0 384 512">
        <path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        ></path>
      </svg>
    </button>
  </div>
</div>
)}
  <h1 className="Header-container-attendance">ATTENDANCE</h1>  
  
{!designation   && (
    <>
     
    </>
  )}
     
     { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
<div className="user-details">
<div className="user-details1">
{userData && (
<p className="user-name">
{userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
</p>
)}
</div>
<div className="user-image-container1" onClick={handleModalOpen}>
{userImageUrl ? (
<img className="user-image1" src={userImageUrl} alt="User" />
) : (

<span className="visually-hidden">Loading...</span>

)}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

</div>
{showModal && (
<div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
<div className="modal-content" onClick={handleModalContentClick}>
<span className="close" onClick={handleModalClose}>&times;</span>
<div className="pendingHeader1">Profile</div>
<div className="user-image-container12">
 {userImageUrl ? (
   <img className="user-image12" src={userImageUrl} alt="User" />
 ) : (
   <Spinner animation="border" role="status" variant="success">
     <span className="visually-hidden">Loading...</span>
   </Spinner>
 )}
 

</div>
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
<div className="info-box">
<p className="Personnel">Personnel Officer ({designation})</p>
{userData && (
<>
<div className="custom-table"> 
<table className="custom-table">
<tbody>
<tr>
  <td><label htmlFor="studentNumber">Student Number:</label></td>
  <td><p>{studentNumber}</p></td>
</tr>
<tr>
<td><label>Name:</label></td>
  <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
</tr>
<tr>
  <td><label htmlFor="email">Email:</label></td>
  <td><p>{email}</p></td>
</tr>
<tr>
  <td><label>Course:</label></td>
  <td><p>{userData.Course}</p></td>
</tr>

</tbody>
</table></div>

</>
)}

</div>
<div className="button-container12">
<button className="CustomBtn" onClick={handleLogoutClick}>
  <div className="sign12">
    <svg viewBox="0 0 512 512">
      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
    </svg>
  </div>
  <div className="text12">Logout</div>
</button>
</div>


</div>

</div>

)}

</div>
)}

</div>  

<div className="attendance-table-container">
<div className="pendingHeader">ATTENDANCE LIST</div>


<div className="reminder">
      <FontAwesomeIcon icon={faExclamationCircle} className="reminder-icon" />
      <span>Reminder: Select<span className="school-year">School Year</span> and<span className="semester">Semester</span> to show the data</span>
    </div>
    
<div class="admin-attendance-container">
    <select
      id="schoolYear"
      className="custom-dropdown1"
      onChange={(e) => setSelectedSchoolYear(e.target.value)}
      value={selectedSchoolYear}
    >
      <option value="" hidden>Select School Year</option>
      {schoolYears.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
    <select
      id="semester"
      className="custom-dropdown1"
      onChange={(e) => setSelectedSemester(e.target.value)}
      value={selectedSemester}
    >
      <option value="" disabled hidden>
        Select Semester
      </option>
      <option value="First Semester">First Semester</option>
      <option value="Second Semester">Second Semester</option>
    </select>
   
</div>
<div className="table-container-attendance">
<div class="update-attendance1">Attendance Summary</div>
<table className="table-attendance-count2">
  <thead className='attendance-thead2'>
    <tr>
      <th>Status</th>
      {sortedTrainingDates.map((trainingDate, index) => (
        <th key={index}>{trainingDate.split(':')[0]}</th>
      ))}
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Present</td>
      {sortedTrainingDates.map((trainingDate, index) => {
        const presentCount = calculateStatusCount('Present', trainingDate);
        return <td key={index}>{presentCount}</td>;
      })}
    </tr>
    <tr>
      <td>Absent</td>
      {sortedTrainingDates.map((trainingDate, index) => {
        const absentCount = calculateStatusCount('Absent', trainingDate);
        return <td key={index}>{absentCount}</td>;
      })}
    </tr>
    <tr>
      <td>Late</td>
      {sortedTrainingDates.map((trainingDate, index) => {
        const lateCount = calculateStatusCount('Late', trainingDate);
        return <td key={index}>{lateCount}</td>;
      })}
    </tr>
    <tr>
      <td>Excused</td>
      {sortedTrainingDates.map((trainingDate, index) => {
        const excusedCount = calculateStatusCount('Excused', trainingDate);
        return <td key={index}>{excusedCount}</td>;
      })}
    </tr>
  </tbody>
</table>
<button class="download-button11" onClick={saveAsExcel}> 
<span class="button-tet">Download Summary</span></button>

</div>


{!designation   && (
            <>
             <div class="Button-attendance1">


             <div class="Button-attendance123">
  <button class="attendance-button23" onClick={() => openModal(selectedStudentData, selectedStudentRecords, displayedRowNumber)}>
    <span class="button-text23">Open Attendance (Time)</span>
  </button>
</div>


            <AbsenteeModal
                isOpen={isOpenAbsenteeModal}
                closeModal={() => setIsOpenAbsenteeModal(false)}
                selectedSchoolYear={selectedSchoolYear}
                selectedSemester={selectedSemester}
                absenteeList={absenteeList}
            />

            <CustomAbsenteeModal
                isOpen={isOpenCustomAbsenteeModal}
                closeModal={() => setIsOpenCustomAbsenteeModal(false)}
                selectedSchoolYear={selectedSchoolYear}
                selectedSemester={selectedSemester}
                absenteeList={absenteeList}
            />
            </div>
            </>
          )}
              {designation   === 'EXO'  && (
            <>
               <div class="Button-attendance1">
         <div class="Button-attendance">
<div class="update-attendance">Attendance Time</div>
<div class="Button-attendance123">
  <button class="attendance-button23" onClick={() => openModal(selectedStudentData, selectedStudentRecords, displayedRowNumber)}>
    Open Attendance (Time)
  </button>
</div>
</div>
<div class="Button-attendance">
<div class="update-attendance">Update Droplist</div>

<div class="Button-attendance1">
  <button class="absentee-button" onClick={filterAbsenteeList}>Warning Droplist</button>
  <button class="absentee-button" onClick={filterAbsenteeListWithThreshold}>Droplist</button>
</div>


</div>
</div>
            </>
          )}
              {designation   === 'S1'  && (
            <>
              <div class="Button-attendance1">
            <div class="Button-attendance">
<div class="update-attendance">Attendance Time</div>
<div class="Button-attendance123">
  <button class="attendance-button23" onClick={() => openModal(selectedStudentData, selectedStudentRecords, displayedRowNumber)}>
    Open Attendance (Time)
  </button>
</div>
</div>
<div class="Button-attendance">
<div class="update-attendance">Download Table Data</div>
<div class="Button-attendance1">
  <button class="download-button1" onClick={downloadAttendanceDataAsExcel}>
  <span class="button-text">Download XLXS</span>
  </button>
 
</div>
</div>
              <div class="Button-attendance">
<div class="update-attendance">Update Droplist</div>

<div class="Button-attendance1">
  <button class="absentee-button" onClick={filterAbsenteeList}>Warning Droplist</button>
  <button class="absentee-button" onClick={filterAbsenteeListWithThreshold}>Droplist</button>
</div>


</div>


            <AbsenteeModal
                isOpen={isOpenAbsenteeModal}
                closeModal={() => setIsOpenAbsenteeModal(false)}
                selectedSchoolYear={selectedSchoolYear}
                selectedSemester={selectedSemester}
                absenteeList={absenteeList}
            />

            <CustomAbsenteeModal
                isOpen={isOpenCustomAbsenteeModal}
                closeModal={() => setIsOpenCustomAbsenteeModal(false)}
                selectedSchoolYear={selectedSchoolYear}
                selectedSemester={selectedSemester}
                absenteeList={absenteeList}
            />
            </div>
            </>
          )}
              {designation   === 'S2'  && (
            <>
               <div className="Button-attendance1">
             <div className="Button-attendance">
<div class="update-attendance">Update Attendance</div>
<div >Select Training Day </div>

<div className="">
  <select
    id="trainingDate"
    className="custom-dropdown11"
    onChange={(e) => handleTrainingDateChange(e.target.value)}
    value={selectedTrainingDate}
  >
    <option value="" disabled>Select Training Day</option>
    {existingTrainingDates &&
      Object.keys(existingTrainingDates).map((date) => (
        <option key={date} value={date}>
          {date}
        </option>
      ))}
  </select>
  </div>
  <div className="admin-dropdown-attendance-absent-excuse2">
  <button onClick={markAllStudentsAbsent} className="primary-button">
  Mark All Students Absent
</button>
</div>

</div>

<div class="Button-attendance">
<div class="update-attendance">Attedance Excused</div>

<div class="Button-attendance1">
  <button class="excuse-dropdown-button" onClick={() => setShowExcuseDropdowns((prev) => !prev)}>
    {showExcuseDropdowns ? 'Hide' : 'Show'} Excuse Dropdowns
  </button>
</div>
</div>
</div>
            </>
          )}

    <div className="table-container-attendance">
 
 <div class="update-attendance1">Attendance Table</div>



{loading && <p>Loading data...</p>}

   <div class="admin-attendance-container2">
   <select
  id="filterTrainingDate"
  className="custom-dropdown1"
  onChange={(e) => handleFilterTrainingDateChange(e.target.value)}
  value={selectedFilterTrainingDate}
>
  <option value="">All Training Dates</option>
  {Object.keys(existingTrainingDates).map((date) => (
    <option key={date} value={date}>
      {date}
    </option>
  ))}
</select>
   <select
     id="filterPrefix"
     className="custom-dropdown1"
     onChange={(e) => handleFilterPrefixChange(e.target.value)}
     value={filterPrefix}
   >
      <option value="" disabled hidden>
       Student Number
     </option>
     {uniquePrefixes.map((prefix) => (
       <option key={prefix} value={prefix}>
         {prefix}
       </option>
     ))}
   </select>

   <select
  id="filterCourse"
  className="custom-dropdown1"
  onChange={(e) => handleFilterCourseChange(e.target.value)}
  value={filterCourse}
>
  <option value="" disabled hidden>
    Course
  </option>
  <option value="">All</option>
  {/* Filter courses based on displayed data */}
  {[...new Set(
    sortedStudentNumbers
      .map((studentNumber) => {
        const userData = userDataArray.find((user) => user.studentNumber === studentNumber);
        return userData ? userData.Course : null;
      })
      .filter((course) => course !== null)
  )].map((course) => (
    <option key={course} value={course}>
      {course}
    </option>
  ))}
</select>


   <select
     id="filterGender"
     className="custom-dropdown1"
     onChange={(e) => handleFilterGenderChange(e.target.value)}
     value={filterGender}
   >
     <option value="" disabled hidden>
       Gender
     </option>
     <option value="">All</option>
     <option value="Female">Female</option>
     <option value="Male">Male</option>
   </select>

   <select
     id="filterStatus"
     className="custom-dropdown1"
     onChange={(e) => handleFilterStatusChange(e.target.value)}
     value={filterStatus}
   >
     <option value="" disabled hidden>
       Status
     </option>
     <option value="">All</option>
     <option value="Present">Present</option>
     <option value="Absent">Absent</option>
     <option value="Late">Late</option>
     <option value="Excused">Excused</option>
   </select>
 
 <form className="form-search">
      <label htmlFor="search" className="label-search">
   <input
      className="input-search"
      type="text1"
      required

      placeholder="Enter text to search"
      id="search"
     value={searchText}
     onChange={(e) => setSearchText(e.target.value)}
   />
 <div className="fancy-bg-search"></div>
        <div className="search-search">
          <svg viewBox="0 0 24 24" aria-hidden="true" className="icon-search r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
        </div>
        <button className="close-btn-search" type="reset" onClick={handleResetSearch}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
   </label>
    </form>

</div>  




<div class="Button-attendance2">
  <div className="legend-container">
    <div className="legend-header">LEGEND</div>
    <div className="legend-items">
        <div className="legend-item">
            <div className="legend-color red"></div>
            <p className="legend-text">Droplist</p>
            
        </div>
        <div className="legend-item">
            <div className="legend-color yellow"></div>
            <p className="legend-text">Warning Droplist</p>
        </div>
    </div>
</div>  
<button className="download-button1" onClick={downloadAttendanceDataAsExcel} title="Download Report">
  <span class="button-text">Download Report</span>
</button>
<button className="droplist-button11" onClick={filterAbsenteeList}>
        <span class="button-text1">Show Droplisted</span>
      </button>
      {showButtons && (
  <div className="modal12121">
    <span className="close1" onClick={closeModal}>&times;</span>
      <div className="modal-content1213">
      
        <button className="absentee-button1" onClick={filterAbsenteeList}>
          Warning Droplist
        </button>
        <button className="absentee-button" onClick={filterAbsenteeListWithThreshold}>
          Droplist
        </button>
      </div>
  </div>
)}


</div>
<div class="table-container-horizontal-scroll">
          <table className="table-attendance">
              <thead className='attendance-thead'>
                  <tr>
                      <th style={{ whiteSpace: 'nowrap' }}>No.</th>
                      <th style={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => handleSort('Student Number')}>
      Student Number {renderSortIcon('Student Number')}
  </th>
  <th style={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => handleSort('Last Name')}>
      Last Name {renderSortIcon('Last Name')}
  </th>

                      <th style={{ whiteSpace: 'nowrap' }}>First Name</th>
                      <th style={{ whiteSpace: 'nowrap' }}>Middle Name</th>
                      <th style={{ whiteSpace: 'nowrap' }}>Course</th>
                      <th style={{ whiteSpace: 'nowrap' }}>Gender</th>
                      {Object.keys(filteredTrainingDates).map((trainingDate) => ({
        original: trainingDate,
        numericPart: parseInt(trainingDate.match(/\d+/)[0]),
      })).sort((a, b) => a.numericPart - b.numericPart).map((trainingDate) => (
        <th key={trainingDate.original} className="training-date-header" onClick={() => handleTrainingDateClick(trainingDate.original)}>
          <div className="training-date12">
            <span>{trainingDate.original.split(': ')[0]}</span>
            <div className="menu">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="menu-content" ref={menuContentRef}>
                <button
                  onClick={() => markAllStudentsAbsent(trainingDate.original)}
                  className="mark-absent-button"
                >
                  Mark All Students Absent
                </button>
                <button
                  onClick={() => setShowExcuseDropdowns((prev) => !prev)}
                  className="show-excuse-dropdown-button"
                >
                  {showExcuseDropdowns ? 'Hide' : 'Show'} Excuse Dropdowns
                </button>
              </div>
            </div>
          </div>
        </th>
      ))}
  {Object.keys(filteredTrainingDates).length >= 1 && (
    <th className="summary-header">Summary</th>
  )}


                  </tr>
              </thead>
              <tbody>
              {sortedStudentNumbers
      .sort((a, b) => {
          const userDataA = userDataArray.find(user => user.studentNumber === a);
          const userDataB = userDataArray.find(user => user.studentNumber === b);
          
          if (sortBy === 'Last Name') {
              return sortOrder === 'asc'
                  ? (userDataA.Lastname || '').localeCompare(userDataB.Lastname || '')
                  : (userDataB.Lastname || '').localeCompare(userDataA.Lastname || '');
          } else if (sortBy === 'Student Number') {
              return sortOrder === 'asc'
                  ? a.localeCompare(b)
                  : b.localeCompare(a);
          } else {
              return 0;
          }
      })
      .map((studentNumber, index) => {
                      const uid = studentUIDs[studentNumber];

                      if (!uid) {
                          return null;
                      }

                      const userData = userDataArray.find((user) => user.studentNumber === studentNumber);
                      const recordsForStudent = allRecords.filter((r) => r.studentNumber === studentNumber);

                      const absenceCount = recordsForStudent.reduce((count, record) => {
                          if (record['Time In']?.appearance.toLowerCase() === 'absent' ||
                              record['Time Out']?.appearance.toLowerCase() === 'absent') {
                              return count + 1;
                          }
                          return count;
                      }, 0);

                      const highlightClass =
                          absenceCount >= 3 ? 'highlight-red' :
                          absenceCount === 2 ? 'highlight-warning' :
                          '';

                          const shouldDisplayRow =
                          (!filterGender || (userData && userData.Gender === filterGender)) &&
                          (!filterPrefix || studentNumber.startsWith(filterPrefix)) &&
                          (!filterCourse || (userData && userData.Course === filterCourse)) &&
                          (!filterStatus ||
                              recordsForStudent.some(
                                  (record) =>
                                      record['Time In']?.appearance === filterStatus ||
                                      record['Time Out']?.appearance === filterStatus
                              )) &&
                          (!selectedFilterTrainingDate ||
                              recordsForStudent.some(
                                  (record) =>
                                      record.trainingDate === selectedFilterTrainingDate &&
                                      (filterStatus === '' ||
                                          record['Time In']?.appearance === filterStatus ||
                                          record['Time Out']?.appearance === filterStatus)
                              )) &&
                          (searchText === '' ||
                              Object.values(userData).some(
                                  (value) =>
                                      value &&
                                      value.toString().toLowerCase().includes(searchText.toLowerCase())
                              ) ||
                              recordsForStudent.some(
                                  (record) =>
                                      Object.values(record)
                                          .filter((value) => value && typeof value !== 'object')
                                          .some((value) => value.toLowerCase().includes(searchText.toLowerCase()))
                              ));
                      
                      



                      if (!shouldDisplayRow) {
                          return null;
                      }

                      displayedRowNumber++;

                      return (
                          <tr key={index}>
                              <td>{displayedRowNumber}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{studentNumber}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{userData ? userData.Lastname || '-' : '-'}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{userData ? userData.Firstname || '-' : '-'}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{userData ? userData.Middlename || '-' : '-'}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{userData ? userData.Course || '-' : '-'}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{userData ? userData.Gender || '-' : '-'}</td>
                              {Object.keys(filteredTrainingDates)
                                  .map((trainingDate) => ({
                                      original: trainingDate,
                                      numericPart: parseInt(trainingDate.match(/\d+/)[0]) // Extract numeric part
                                  }))
                                  .sort((a, b) => a.numericPart - b.numericPart) // Sort by numeric part
                                  .map((trainingDate) => {
                                      const record = recordsForStudent.find((r) => r.trainingDate === trainingDate.original);

                                      const isSelectedTrainingDate =
                                          selectedFilterTrainingDate === '' ||
                                          selectedFilterTrainingDate === trainingDate.original;

                                      if (isSelectedTrainingDate) {
                                          const statusClass = record
                                              ? record['Time In']?.appearance || record['Time Out']?.appearance || '-'
                                              : '-';
                                          const statusValue = record
                                              ? record['Time In']?.appearance || record['Time Out']?.appearance || '-'
                                              : '-';

                                          const isSelectedStatus = filterStatus === '' || statusClass.toLowerCase() === filterStatus.toLowerCase();

                                          if (isSelectedStatus) {
                                              return (
                                                  <td
                                                      key={`${trainingDate.original}-${studentNumber}`}
                                                      className={`status-${statusClass.toLowerCase()} ${highlightClass}`}
                                                  >
                                                      {statusValue}
                                                      {statusClass.toLowerCase() === 'absent' && (
                                                          <select
                                                              style={{ display: showExcuseDropdowns ? 'block' : 'none' }}
                                                              value={excuseStatus[`${studentNumber}-${trainingDate.original}`] || 'Absent'}
                                                              onChange={(e) =>
                                                                  handleExcuseChange(
                                                                      studentNumber,
                                                                      trainingDate.original,
                                                                      e.target.value
                                                                  )
                                                              }
                                                          >
                                                              <option value="Absent">Absent</option>
                                                              <option value="Excused">Excused</option>
                                                          </select>
                                                      )}
                                                      {showExcuseDropdowns && (
                                                          <button
                                                              onClick={() =>
                                                                  handleSubmit(studentNumber, trainingDate.original)
                                                              }
                                                              disabled={!changesPending}
                                                          >
                                                              Submit
                                                          </button>
                                                      )}
                                                  </td>
                                              );
                                          } else {
                                              return (
                                                  <td key={`${trainingDate.original}-${studentNumber}`}>
                                                      -
                                                  </td>
                                              );
                                          }
                                      } else {
                                          return (
                                              <td key={`${trainingDate.original}-${studentNumber}`}>
                                                  -
                                              </td>
                                          );
                                      }
                                  })}
                              {Object.keys(filteredTrainingDates).length >= 1 && (
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                  <button className="n" onClick={() => handleSummaryClick(studentNumber)}>
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                  </button>
                              </td>
                              
                          
                              )}
                          </tr>
                      );
                  })}
              </tbody>
          </table>
          </div>
    </div>

    {showSummaryModal && (
    <div className={`modal121 ${showSummaryModal ? 'modal-visible' : ''}`}>
        <div className="modal-content121">
            <span className="close1" onClick={closeModal}>&times;</span>
            <h2>Summary of Attendance  </h2> {/* Display the formatted name here */}
            <h2>({selectedStudentNumber})</h2>
            <p> {formattedName}</p>
            <p>School Year: {selectedSchoolYear}</p>
            <p>Semester: {selectedSemester}</p>
            <table>
                <thead>
                    <tr>
                        <th>Training Date</th>
                        <th>Attendance</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(filteredTrainingDates)
                        .map((trainingDate) => ({
                            original: trainingDate,
                            numericPart: parseInt(trainingDate.match(/\d+/)[0]) // Extract numeric part
                        }))
                        .sort((a, b) => a.numericPart - b.numericPart) // Sort by numeric part
                        .map((trainingDateObj, index) => {
                            const trainingDate = trainingDateObj.original;
                            const record = recordsForStudent.find((r) => r.trainingDate === trainingDate);
                            const appearance = record ? 
                                record['Time In']?.appearance || record['Time Out']?.appearance || '-' :
                                '-';
                            
                            return (
                                <tr key={index}>
                                    <td>{trainingDate}</td>
                                    <td>{appearance}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    </div>
)}


        </div>
      </div>
    </div>
  );
};
return renderAttendanceData(
  existingTrainingDates,
  handleExcuseChange,
  handleSubmit
);
};

export default DisplayData;