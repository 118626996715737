import React, { useState } from "react";
import { auth } from "../Firebase"; // Import Firebase auth
import { updatePassword } from "firebase/auth";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const user = auth.currentUser;
      await updatePassword(user, newPassword);
      setSuccessMessage("Password successfully changed.");
      setErrorMessage("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setErrorMessage("Password change failed. Please try again.");
      setSuccessMessage("");
      console.error("Error:", error.message);
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {successMessage && <p className="success">{successMessage}</p>}
      <form>
        <div>
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="button" onClick={handlePasswordChange}>
          Change Password
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
