    import React, { useState, useEffect } from "react";
    import { ref, get, onValue  } from "firebase/database";
    import { db } from "../Firebase";
    import SideBar from "./AdminSideBar";
    import Modal from 'react-modal';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faInfoCircle, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
    import UserInfoModal from "./UserInfoModal";
    import "./AdminUserlist.css"
    import { faDownload, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
    import AdminModalTable from "./AdminModalTable";
    import EditProfileModal from "./EditProfileModal";
    
    function AdminUserList() {
      const [userData, setUserData] = useState([]);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [selectedUserData, setSelectedUserData] = useState(null);
      const columnsToDisplay = ['No.', 'UID', 'studentNumber', 'Lastname', 'Firstname', 'Middlename', 'Course', 'Gender', 'email', 'Info', 'Edit'];

      const [selectAll, setSelectAll] = useState(false);
      const [courseOptions, setCourseOptions] = useState([]);
      const [schoolNumberOptions, setSchoolNumberOptions] = useState([]);
      const [courseFilter, setCourseFilter] = useState("");
      const [genderFilter, setGenderFilter] = useState("");
      const [schoolNumberFilter, setSchoolNumberFilter] = useState("");
        const [selectedSemester, setSelectedSemester] = useState('');
    const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
    const [userDataArray, setUserDataArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [existingTrainingDates, setExistingTrainingDates] = useState([]);
    const [trainingData, setTrainingData] = useState([]); // Separate state for training data
    const [schoolYears, setSchoolYears] = useState([]);
    const [studentUIDs, setStudentUIDs] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [enrolledStudents, setEnrolledStudents] = useState([]);
    const [users, setUsers] = useState([]);

    
      const [isDataModalOpen, setIsDataModalOpen] = useState(false);

      // Open and close functions for AdminModalTable
      const openDataModal = () => {
        setIsDataModalOpen(true);
      };

      const closeDataModal = () => {
        setIsDataModalOpen(false);
      };
      const openModal = (userData) => {
        setSelectedUserData(userData);
        setIsModalOpen(true);
      };
      
      const closeModal = () => {
        setSelectedUserData(null);
        setIsModalOpen(false);

      };
    


      useEffect(() => {
        const fetchEnrolledStudents = async () => {
          if (selectedSchoolYear && selectedSemester) {
            try {
              const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
              const userSemesterSnapshot = await get(userSemesterRef);
              if (userSemesterSnapshot.exists()) {
                const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
                setEnrolledStudents(enrolledStudentsData);
                fetchUserUIDs(enrolledStudentsData);
              } else {
                setEnrolledStudents([]);
                setStudentUIDs({});
              }
            } catch (error) {
              console.error('Error fetching enrolled students:', error.message);
            }
          }
        };
    
        fetchEnrolledStudents();
      }, [selectedSchoolYear, selectedSemester]);
    
      const transformEnrolledData = (data) => {
        if (!data) return [];
        return Object.entries(data).map(([id, student]) => ({
          id: id,
          year: selectedSchoolYear,
          semester: selectedSemester,
          studentNumber: student.studentNumber,
          Lastname: student.Lastname
        }));
      };
    
      useEffect(() => {
        const fetchSchoolYears = async () => {
          try {
            const schoolYearsRef = ref(db, 'enrolled');
            const schoolYearsSnapshot = await get(schoolYearsRef);
            if (schoolYearsSnapshot.exists()) {
              const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
              setSchoolYears(schoolYearsList);
            } else {
              setSchoolYears([]);
            }
          } catch (error) {
            console.error('Error fetching school years:', error.message);
          }
        };
    
        fetchSchoolYears();
      }, []);
    
      const fetchUserUIDs = async (students) => {
        const studentNumbers = students.map(student => student.studentNumber);
        const usersRef = ref(db, 'users');
        const userSnapshot = await get(usersRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          const studentUIDs = {};
          const userDetails = {};
          studentNumbers.forEach(studentNumber => {
            for (const uid in userData) {
              if (userData[uid].studentNumber === studentNumber) {
                studentUIDs[studentNumber] = uid;
                userDetails[uid] = userData[uid]; // Storing user details by UID
                break;
              }
            }
          });
          setStudentUIDs(studentUIDs);
          setUserDetails(userDetails);
        } else {
          console.log('No user data found in the database.');
        }
      };
    

      const [sortOrder, setSortOrder] = useState({
        lastName: 'asc',
        course: 'asc',
        gender: 'asc',
        studentNumber: 'asc',
      });
      const getUIDFromStudentNumber = (studentNumber) => {
        return studentUIDs[studentNumber] || 'UID not found';
      };
      const getUserDetailsByUID = (uid) => {
        return userDetails[uid] || null;
      };
      
      const sortData = (column) => {
        setSortOrder((prevSortOrder) => ({
          ...prevSortOrder,
          [column]: prevSortOrder[column] === 'asc' ? 'desc' : 'asc',
        }));
      
        setUserData((prevUserData) =>
          [...prevUserData].sort((a, b) => {
            const order = sortOrder[column] === 'asc' ? 1 : -1;
      
            if (column === 'lastName') {
              return order * a[column].localeCompare(b[column], undefined, { sensitivity: 'base' });
            } else {
              return order * a[column].localeCompare(b[column], undefined, { sensitivity: 'base' });
            }
          })
        );
      };
      const renderTable = () => {
        if (loading) {
          return <p>Loading...</p>;
        }
      
        if (enrolledStudents.length === 0) {
          return <p>No data to display</p>;
        }
      
        const filteredData = enrolledStudents.filter((row) =>
          Object.values(row).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      
        return (
          <table className="admin-table">
            <thead>
              <tr>
                {columnsToDisplay.map((header, index) => (
                  <th
                    key={header}
                    onClick={
                      shouldSortColumn(header) ? () => sortData(header) : null
                    }
                  >
                    {header}{' '}
                    {shouldSortColumn(header) && sortOrder[header] ? (
                      sortOrder[header] === 'asc' ? (
                        '▲'
                      ) : (
                        '▼'
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
{filteredData.map((row, rowIndex) => (
  <tr key={rowIndex}>
    {/* Add the 'No.' column */}
    <td>{rowIndex + 1}</td>
    {columnsToDisplay.slice(1, -2).map((column) => (
      <td key={column}>
{column === 'UID'
  ? getUIDFromStudentNumber(row.studentNumber) // Get UID based on student number
  : (column === 'email' || column === 'Course' || column === 'Gender' || column === 'Lastname' || column === 'Firstname' || column === 'Middlename')
    ? // Retrieve user details by UID and access required fields
      getUserDetailsByUID(getUIDFromStudentNumber(row.studentNumber)) ? getUserDetailsByUID(getUIDFromStudentNumber(row.studentNumber))[column] : '-'
    : column === 'No.'
      ? rowIndex + 1
      : column === 'Info'
        ? <button className="info" onClick={() => openModal(row)}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </button>
        : column === 'Edit'
          ? <button className="edit-profile-button" onClick={() => openEditModal(row)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          : row[column] ? capitalizeSecondName(row[column]) : '-'}
      </td>
    ))}
    {/* Buttons */}
    <td>
      <button className="info" onClick={() => openModal(row)}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </td>
    <td>
      <button
        className="edit-profile-button"
        onClick={() => openEditModal(row)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </td>
  </tr>
))}


            </tbody>
          </table>
        );
      };
            
    const capitalizeSecondName = (name) => {
      if (!name) {
        return ''; // Return an empty string if the name is undefined or null
      }

      const names = name.split(' ');
      if (names.length > 1) {
        return names.map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
      } else {
        return name; // Return the original name if it doesn't contain multiple parts
      }
    };

    const shouldSortColumn = (column) => {
      return column !== 'No.' && column !== 'Info';
    };

      useEffect(() => {
        // Generate unique course options from the data
        const uniqueCourses = [...new Set(users.map((user) => user.Course))];
        setCourseOptions(uniqueCourses.filter(Boolean)); // Remove empty or undefined values

        // Generate unique school number options from the data
        const uniqueSchoolNumbers = [...new Set(users.map((user) => String(user.studentNumber).slice(0, 4)))];

        setSchoolNumberOptions(uniqueSchoolNumbers.filter(Boolean)); // Remove empty or undefined values
      }, [users]);

    const handleCourseFilterChange = (e) => {
        setCourseFilter(e.target.value);
      };

      const handleGenderFilterChange = (e) => {
        setGenderFilter(e.target.value);
      };

      const handleSchoolNumberFilterChange = (e) => {
        setSchoolNumberFilter(e.target.value);
      };

      const renderFilters = () => (
        
        <div className="filters">
          <div>
    
          <div class="Filter-container">
      <div class="Filter-box">
        {/* Remove the external label */}
        <select class="Filter-select" id="courseFilter" value={courseFilter} onChange={handleCourseFilterChange}>
          {/* Use "Filter by Course" as a placeholder */}
          <option value="" disabled hidden>
            Course
          </option>
          {/* Add an "All" option */}
          <option value="">All</option>
          {/* Rest of the options */}
          {courseOptions.map((course) => (
            <option key={course} value={course}>
              {course}
            </option>
          ))}
        </select>
      </div>


      <div class="Filter-box">
        {/* Gender filter */}
        <select class="Filter-select" id="genderFilter" value={genderFilter} onChange={handleGenderFilterChange}>
          <option value="" disabled hidden>
            Gender
          </option>
          <option value="">All</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>

      <div class="Filter-box">
        {/* School Number filter */}
        <select class="Filter-select" id="schoolNumberFilter" value={schoolNumberFilter} onChange={handleSchoolNumberFilterChange}>
          <option value="" disabled hidden>
            School Number
          </option>
          <option value="">All</option>
          {schoolNumberOptions.map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
      </div>
      </div>
      </div>
        </div>
      );

      const [searchQuery, setSearchQuery] = useState('');


      const handleSearch = (e) => {
        setSearchQuery(e.target.value);
      };
      const [isEditModalOpen, setIsEditModalOpen] = useState(false);
      const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);

      const openEditModal = (user) => {
        setSelectedUserForEdit(user);
        setIsEditModalOpen(true);
      };

      const closeEditModal = () => {
        setSelectedUserForEdit(null);
        setIsEditModalOpen(false);
      };

      return (
        <div className="admin-container1">
          <SideBar />
          <div className="admin-container">
            <header className="admin-header1">
            <div className="Student-list-header">
          <h1>Student List</h1>  
          </div>        

          <div className="search-container-box">

          <div class="admin-dropdown-container">
            {renderFilters()}

            <div class="admin-dropdown-userlist">
      {/* Semester dropdown */}
      <select
        id="semester"
        class="user-list-label1"
        onChange={(e) => setSelectedSemester(e.target.value)}
        value={selectedSemester}
      >
        <option value="" disabled hidden>
          Select Semester
        </option>
        <option value="First Semester">First Semester</option>
        <option value="Second Semester">Second Semester</option>
      </select>
    </div>

    <div class="admin-dropdown-userlist">
    <select
        id="schoolYear"
        class="user-list-label1"
        onChange={(e) => setSelectedSchoolYear(e.target.value)}
        value={selectedSchoolYear}
      >
        <option value="" hidden>Select School Year</option>
        {schoolYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>

        </div>
        <div className="search-userlist">
              <div className="search-input-userlist">
                <input
                  type="text"
                  id="courseFilter1"
                  placeholder="Search by student, name, email, or course"
              value={searchQuery}
              onChange={handleSearch}
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon-userlist" />
            </div>
          </div>
          </div>

        
              <AdminModalTable
            isOpen={isDataModalOpen}
            onRequestClose={closeDataModal}
            // Add the functions as props
            openDataModal={openDataModal}
            closeDataModal={closeDataModal}
          />
              <div className="admin-table">{renderTable()}</div>
            </header>

            <EditProfileModal
            isOpen={isEditModalOpen}
            onRequestClose={closeEditModal}
            user={selectedUserForEdit}
          />
        </div>
          <UserInfoModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            user={selectedUserData}
          />

          
        </div>
        
      );
    }

    export default AdminUserList;