import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CadetHomepage from "./Cadet/CadetHomepage";
import Status from "./Status";
import SOP from "./Officer/SOP";
import AboutUs from "./Officer/AboutUs";
import ContactUs from "./Officer/ContactUs";
import Admin from "./Admin/AdminCreateAccount";
import Login from "./Cadet/CadetLogin";
import ForgotPassword from "./Cadet/ForgotPassword";
import Cadet from "./Cadet/Cadet";
import QRCodeScanner from "./Admin/QRScanner";
import ResetPassword from "./Cadet/ResetPassword";
import DisplayData from "./Admin/Attendance";
import LoginAdmin from "./Admin/LoginAdmin";
import CadetUpdate from "./Cadet/CadetUpdate";
import Home from "./LANDINGPAGES/ImageCarousel";
import SideBar from "./Admin/AdminSideBar";
import Dashboard from "./Admin/Dashboard";
import SideBarCadet from "./Cadet/CadetSidebar";
import QRCodeDownloader from "./Cadet/QrCode";
import Overview from "./Officer/Overview";
import AdminSchedule from "./Admin/AdminSchedule";
import AdminUserList from "./Admin/AdminUserlist";
import UserInfoModal from "./Admin/UserInfoModal";
import ColumnSelectorModal from "./Admin/ColumnSelectorModal";
import AdminModalTable from "./Admin/AdminModalTable";
import EditProfileModal from "./Admin/EditProfileModal";
import AttendanceModal from "./Admin/AttendanceModal";
import CadetAttendance from "./Cadet/CadetAttendance";
import AttendanceDisplay from "./Admin/AttendanceSummary";
import PieChart from "./Admin/AdminPieChart";
import DownloadCSV from "./Admin/DownloadCSV";
import AnnouncementForm from "./Admin/AdminAnnouncement";
import Announcement from "./Officer/Announcement";
import CadetAttend from "./Cadet/CadetAttend";
import SignupForm from "./Cadet/CadetSignUp";
import AdminVerifyUser from "./Admin/AdminVerfied";
import CadetEnrollment from "./Cadet/CadetEnrollment";
import PendingEnrollments from "./Admin/AdminPendingEnrollees";
import FileUploader from "./Admin/AdminAddSubject";
import DisplayFile from "./Cadet/CadetDisplayFile";
import EnrolledStudents from "./Admin/AdminEnrolled";
import Sample from "./LANDINGPAGES/Samplee";
import ManualReadings from "./LANDINGPAGES/ManualReadings";
import Authority from "./Admin/AdminAuthority";
import QRScannerLogin from "./Admin/QRScannerLogin";
import QRScannerSideBar from "./Admin/QRScannerSideBar";
import QRCodeScanner2 from "./Admin/QRScanner2.0";
import AdminOfficerAuthorization from "./Admin/AdminOfficerAuthorization";
import AdminSignUpOfficer from "./Admin/AdminSignUpOfficer";
import AdminOfficerLogin from "./Admin/AdminOfficerLogin";
import SideBarMonitor from "./Admin/AdminSideBarMonitor";
import SideBarReport from "./Admin/AdminSideBarReport";
import SideBarApproval from "./Admin/AdminSideBarUserApproval";
import CadetProfile from "./Cadet/CadetProfile";
import AdminEditProfile from "./Admin/AdminEditProfle";
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Cadet/QrCode" element={<QRCodeDownloader />} />
          <Route path="/Cadet/CadetSidebar" element={<SideBarCadet/>}/>
          <Route path="/Status" element={<Status />} />
          <Route path="/Admin/Dashboard" element={<Dashboard />} />
          <Route path="/Admin/AdminSideBar" element={<SideBar />} />
          <Route path="/Admin/QRScanner" element={<QRCodeScanner />} />
          <Route path="/Admin/LoginAdmin" element={<LoginAdmin />} />
          <Route path="/Officer/SOP" element={<SOP />} />
          <Route path="/Officer/AboutUs" element={<AboutUs />} />
          <Route path="/Officer/Overview" element={<Overview />} />
          <Route path="/Officer/ContactUs" element={<ContactUs />} />
          <Route path="/Admin/AdminCreateAccount" element={<Admin />} />
          <Route path="/Cadet/CadetHomepage" element={<CadetHomepage />} />
          <Route path="/Cadet/CadetLogin" element={<Login />} />
          <Route path="/Cadet/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/Cadet/Cadet" element={<Cadet />} />
          <Route path="/Cadet/ResetPassword" element={<ResetPassword />} />
          <Route path="/Admin/Attendance" element={<DisplayData />} />
          <Route path="/Cadet/CadetUpdate" element={<CadetUpdate />} />
          <Route path="/Admin/AdminSchedule" element={<AdminSchedule />} />
          <Route path="/Admin/AdminUserlist" element={<AdminUserList />} />
          <Route path="/Admin/UserInfoModal" element={<UserInfoModal />} />
          <Route path="/Admin/AdminSchedule" element={<AdminSchedule />} />
          <Route path="/Admin/AdminModalTable" element={<AdminModalTable />} />
          <Route path="/Admin/EditProfileModal " element={<EditProfileModal />} />
          <Route path="/Admin/AttendanceModal " element={<AttendanceModal />} />
          <Route path="/Cadet/CadetAttendance " element={<CadetAttendance />} />
          <Route path="/Cadet/AttendanceSummary " element={<AttendanceDisplay />} />
          <Route path="/Admin/AdminPieChart " element={<PieChart />} />
          <Route path="/Admin/DownloadCSV " element={<DownloadCSV />} />
          <Route path="/Admin/AdminAnnouncement" element={<AnnouncementForm/> }/>
          <Route path="/Cadet/AttendanceCount " element={<getStudentNumberCount  />} />
          <Route path="/Admin/ColumnSelectorModal" element={<ColumnSelectorModal />} />
          <Route path="/Officer/Announcement" element={<Announcement/>} />
          <Route path="/Cadet/CadetAttend" element={<CadetAttend/>} />
          <Route path="/Cadet/CadetSignUp" element={<SignupForm/>} />
          <Route path="/Cadet/AdminEnrollment" element={<CadetEnrollment/>}/>
          <Route path="/Admin/AdminVerified" element={<AdminVerifyUser/>} />
          <Route path="/Admin/AdminPendingEnrollees" element={<PendingEnrollments/>} />
          <Route path="/Admin/AdminAddSubject" element={<FileUploader/>} />
          <Route path="/Cadet/CadetDisplayFile" element={<DisplayFile />} />
          <Route path="/Admin/AdminEnrolled" element={<EnrolledStudents />} />
          <Route path="/LANDINGPAGES/Samplee" element={<Sample/>} />
          <Route path="/LANDINGPAGES/ManualReadings" element={<ManualReadings/>} />
          <Route path="/Admin/AdminAuthority" element={<Authority />} />
          <Route path="/Admin/QRScannerLogin" element={<QRScannerLogin />} />
          <Route path="/Admin/QRScannerSideBar" element={<QRScannerSideBar />} />
          <Route path="/Admin/QRScanner2.0" element={<QRCodeScanner2 />} />
          <Route path="/Admin/AdminOfficerAuthorization" element={<AdminOfficerAuthorization/>} />
          <Route path="/Admin/AdminSignUpOfficer" element={<AdminSignUpOfficer />} />
          <Route path="/Admin/AdminOfficerLogin" element={<AdminOfficerLogin />} />
          <Route path="/Admin/AdminSideBarMonitor" element={<SideBarMonitor />} />
          <Route path="/Admin/AdminSideBarReport" element={<SideBarReport/>} />
          <Route path="/Admin/AdminSideBarUserApproval" element={<SideBarApproval/>} />
          <Route path="/Cadet/CadetProfile" element={<CadetProfile/>}/>
          <Route path="/Admin/AdminEditProfile" element={<AdminEditProfile/>}/>
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;