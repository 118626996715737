import React, { useState, useEffect } from 'react';
import { db, storage } from '../Firebase'; // Import the Firebase database and storage objects
import { ref, onValue, remove, set, get } from 'firebase/database'; // Import the necessary Firebase database functions
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage'; // Import the necessary Firebase storage functions
import SideBar from "./AdminSideBar";
import './AdminPendingEnrollees.css'
import Modal from 'react-modal'; // Import the modal component
import Modal1 from './Modal1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortUp, faSortDown ,faSort  } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "react-bootstrap/Spinner";
Modal.setAppElement('#root'); // Set the app root element for the modal

function PendingEnrollments() {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationApprovalModalIsOpen, setConfirmationApprovalModalIsOpen] = useState(false); // New state variable
  const [pendingEnrollments, setPendingEnrollments] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({}); 
  const [modalIsOpen, setModalIsOpen] = useState(false); 
  const [selectedFileURL, setSelectedFileURL] = useState('');
  const [selectedEnrollmentToDelete, setSelectedEnrollmentToDelete] = useState(null);


  const openModal = (fileURL) => {
    setSelectedFileURL(fileURL);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedFileURL('');
    setModalIsOpen(false);
  };

  useEffect(() => {
    // Fetch pending enrollments when the component mounts
    const fetchPendingEnrollments = () => {
      const pendingEnrollmentsRef = ref(db, 'pendingEnrollees');
      onValue(pendingEnrollmentsRef, (snapshot) => {
        const data = snapshot.val();
        const enrollmentRequests = transformData(data);
        setPendingEnrollments(enrollmentRequests);
        fetchUserUIDs(enrollmentRequests);
      });
    };

    fetchPendingEnrollments();

    return () => {
    };
  }, []);

  const transformData = (data) => {
    if (!data) return [];
    const transformedData = [];
    for (const [year, semesters] of Object.entries(data)) {
      for (const [semester, enrollments] of Object.entries(semesters)) {
        transformedData.push({
          year: year,
          semester: semester,
          enrollments: Object.entries(enrollments).map(([id, enrollment]) => ({
            id: id,
            ...enrollment
          }))
        });
      }
    }
    return transformedData;
  };

  const handleAction = (id, action, year, semester, studentNumber) => {
    if (action === 'approve') {
      // Open the confirmation approval modal
      setConfirmationApprovalModalIsOpen(true);
      // Set selected enrollment to delete for the approval process
      setSelectedEnrollmentToDelete({ id, year, semester, studentNumber });
    } else if (action === 'decline') {
      // Remove the enrollment request from the database after declining
      remove(ref(db, `pendingEnrollees/${year}/${semester}/${id}`))
        .then(() => {
          console.log(`Enrollment request ${action}d successfully.`);
          // Refresh the pendingEnrollments state to reflect the changes
          setPendingEnrollments(prevState => {
            const updatedEnrollments = prevState.map(entry => {
              if (entry.year === year && entry.semester === semester) {
                return {
                  ...entry,
                  enrollments: entry.enrollments.filter(enrollment => enrollment.id !== id)
                };
              }
              return entry;
            });
            return updatedEnrollments.filter(entry => entry.enrollments.length > 0);
          });
        })
        .catch((error) => {
          console.error(`Error ${action}ing enrollment request:`, error);
        });
    }
  };
  
  const fetchUserUIDs = (enrollmentRequests) => {
    const studentNumbers = enrollmentRequests.flatMap(enrollment => enrollment.enrollments.map(student => student.studentNumber));
    const usersRef = ref(db, 'users');
    get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const studentUIDs = {};
        const userDetails = {};
        studentNumbers.forEach(studentNumber => {
          for (const uid in userData) {
            if (userData[uid].studentNumber === studentNumber) {
              studentUIDs[studentNumber] = uid;
              userDetails[uid] = userData[uid];
              break;
            }
          }
        });
        setStudentUIDs(studentUIDs);
        setUserDetails(userDetails);
      } else {
        console.log('No user data found in the database.');
      }
    }).catch(error => {
      console.error('Error fetching user data:', error);
    });
  };

  
  const [userDetails, setUserDetails] = useState({});
  const [userType, setUserType] = useState(""); // State to store user type
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedSelectedOption, setStoredSelectedOption] = useState("");
  const [storedSelectedSchoolYear, setStoredSelectedSchoolYear] = useState('');
  const [storedSelectedSemester, setStoredSelectedSemester] = useState('');
  const [storedEmail, setStoredEmail] = useState('');

  useEffect(() => {
    // Set user type based on credentials
    const userEmail = localStorage.getItem('userEmail'); // Assuming you store the user's email in localStorage
    if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
      setUserType("admin");
    } 
  }, []);

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    setUserType(userType);

    const storedStudentNumber = localStorage.getItem('studentNumber');
    setStoredStudentNumber(storedStudentNumber);

    const storedSelectedOption = localStorage.getItem('selectedOption');
    setStoredSelectedOption(storedSelectedOption);

    const storedSelectedSchoolYear = localStorage.getItem('selectedSchoolYear');
    setStoredSelectedSchoolYear(storedSelectedSchoolYear);

    const storedSelectedSemester = localStorage.getItem('selectedSemester');
    setStoredSelectedSemester(storedSelectedSemester);

    const storedEmail = localStorage.getItem('email'); // Retrieve the email from local storage
    setStoredEmail(storedEmail); // Set the email in the component state

  }, []);
  const [fileURLs, setFileURLs] = useState({}); // Dictionary to store file URLs for each student

  const showFileAndOpenModal = async (studentNumber, year, semester) => {
    try {
      await fetchUserData(studentNumber); // Fetch user data before opening modal
      const fileName = `${studentNumber}-${year}-${semester}`;
      const fileRef = storageRef(storage, `pdf_files/${fileName}`);
      const url = await getDownloadURL(fileRef);
    
      setFileURLs(prevFileURLs => ({
        ...prevFileURLs,
        [fileName]: url
      }));
  
      // Open the modal with the selected file URL
      openModal(url);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };
  const [selectedCourses, setSelectedCourses] = useState({});
  const [userData, setUserData] = useState(null);

  const fetchUserData = async (studentNumber) => {
    try {
      const userRef = ref(db, `users/${studentUIDs[studentNumber]}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        setUserData(snapshot.val()); // Set user data in state
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const handleCourseFilterChangePerSemester = (event, year, semester) => {
    const { value } = event.target;
    console.log(`Selected course for ${year}-${semester}: ${value}`);
    setSelectedCourses(prevState => ({
      ...prevState,
      [`${year}-${semester}`]: value
    }));
  };
  const filterEnrollmentsByCoursePerSemester = (enrollments, year, semester) => {
    const selectedCourse = selectedCourses[`${year}-${semester}`] || '';
    if (selectedCourse === '') {
      return enrollments;
    } else {
      return enrollments.filter(enrollment =>
        userDetails[studentUIDs[enrollment.studentNumber]] &&
        userDetails[studentUIDs[enrollment.studentNumber]].Course === selectedCourse
      );
    }
  };
// Inside your component
const [selectAllPerSemester, setSelectAllPerSemester] = useState({});

// Handler function to toggle select all checkboxes per semester
const toggleSelectAllPerSemester = (year, semester) => {
  setSelectAllPerSemester(prevState => {
    const newSelectAll = { ...prevState };
    const key = `${year}-${semester}`;
    newSelectAll[key] = !newSelectAll[key];

    // Update selected checkboxes for the current semester
    const selectedCheckboxesCopy = { ...selectedCheckboxes };
    filterEnrollmentsByCoursePerSemester(pendingEnrollments.find(enrollment => enrollment.year === year && enrollment.semester === semester)?.enrollments || [], year, semester).forEach(enrollment => {
      selectedCheckboxesCopy[enrollment.id] = newSelectAll[key];
    });

    setSelectedCheckboxes(selectedCheckboxesCopy);

    return newSelectAll;
  });
};

const areAllCheckboxesChecked = (year, semester) => {
  const selectedEnrollments = pendingEnrollments.find(enrollment => enrollment.year === year && enrollment.semester === semester)?.enrollments || [];
  return selectedEnrollments.every(enrollment => selectedCheckboxes[enrollment.id]);
};

const [showConfirmationModal, setShowConfirmationModal] = useState(false);
const [confirmationDeleteModalIsOpen, setConfirmationDeleteModalIsOpen] = useState(false);

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
    setSelectedEnrollmentToDelete(null);
  };
  const openDeleteConfirmationModal = (year, semester, id) => {
    // Fetch additional details if needed based on id
    // For now, we only need id to delete the enrollment
    setSelectedEnrollmentToDelete({ id, year, semester });
    setConfirmationDeleteModalIsOpen(true);
  };

  const [declineReason, setDeclineReason] = useState('');

  const handleDeleteConfirmation = () => {
    if (selectedEnrollmentToDelete) {
      const { id, year, semester } = selectedEnrollmentToDelete;
  
      // Check if declineReason is empty
      if (!declineReason.trim()) {
        alert('Please provide a reason for declining the enrollment.');
        return;
      }
  
      // Fetch the student number before removing from pendingEnrollees database
      const enrollmentRef = ref(db, `pendingEnrollees/${year}/${semester}/${id}`);
      get(enrollmentRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const { studentNumber } = snapshot.val();
  
            // Remove the student number from the pendingEnrollees database
            remove(enrollmentRef)
              .then(() => {
                console.log('Student number deleted successfully.');
                // Move the declined enrollment to DeclineEnrollees database
                const declineRef = ref(db, `DeclineEnrollees/${year}/${semester}`);
                set(declineRef, { [id]: { studentNumber, declineReason } }) // Save decline reason
                  .then(() => {
                    console.log('Student enrollment declined and moved to DeclineEnrollees database.');
                    // Show success toast or message if needed
                  })
                  .catch((error) => {
                    console.error('Error moving declined enrollment to DeclineEnrollees:', error);
                    // Show error toast or message if needed
                  });
              })
              .catch((error) => {
                console.error('Error deleting student number:', error);
                // Show error toast or message if needed
              })
              .finally(() => {
                // Close the delete confirmation modal
                setConfirmationDeleteModalIsOpen(false);
                // Reset the selected enrollment to delete
                setSelectedEnrollmentToDelete(null);
                // Reset declineReason
                setDeclineReason('');
              });
          } else {
            console.error('Snapshot does not exist.');
            // Handle error if snapshot doesn't exist
          }
        })
        .catch((error) => {
          console.error('Error fetching enrollment data:', error);
          // Show error toast or message if needed
        });
    }
  };
  
  
// Function to handle approval confirmation
const handleApproveConfirmation = () => {
  if (selectedEnrollmentToDelete) {
    const { id, year, semester, studentNumber } = selectedEnrollmentToDelete;

    // Check if the student number exists in DeclineEnrollees database
    const declineRef = ref(db, `DeclineEnrollees/${year}/${semester}`);
    get(declineRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const declineData = snapshot.val();
          // Check if the studentNumber exists in declineData
          if (Object.values(declineData).some((data) => data.studentNumber === studentNumber)) {
            // Delete the studentNumber data from DeclineEnrollees database
            const declineKey = Object.keys(declineData).find((key) => declineData[key].studentNumber === studentNumber);
            const studentRefToDelete = ref(db, `DeclineEnrollees/${year}/${semester}/${declineKey}`);
            remove(studentRefToDelete)
              .then(() => {
                console.log(`Student number ${studentNumber} data deleted from DeclineEnrollees database.`);
              })
              .catch((error) => {
                console.error('Error deleting student number data from DeclineEnrollees:', error);
                // Show error toast or message if needed
              });
          }
        }
        
        // Move the enrollment from pending to enrolled
        const enrolledRef = ref(db, `enrolled/${year}/${semester}/${id}`);
        set(enrolledRef, { studentNumber })
          .then(() => {
            console.log(`Student ${studentNumber} enrolled successfully.`);
            // Show success toast
            toast.success(`Student ${studentNumber} enrolled successfully.`);
            // Remove the enrollment request from the database after approval
            remove(ref(db, `pendingEnrollees/${year}/${semester}/${id}`))
              .then(() => {
                console.log('Enrollment request removed from pending.');
                // Show success toast
                toast.success('Enrollment request removed from pending.');
                // Refresh the pendingEnrollments state to reflect the changes
                setPendingEnrollments((prevState) => {
                  const updatedEnrollments = prevState.map((entry) => {
                    if (entry.year === year && entry.semester === semester) {
                      return {
                        ...entry,
                        enrollments: entry.enrollments.filter((enrollment) => enrollment.id !== id),
                      };
                    }
                    return entry;
                  });
                  return updatedEnrollments.filter((entry) => entry.enrollments.length > 0);
                });
              })
              .catch((error) => {
                console.error('Error removing enrollment request from pending:', error);
                // Show error toast
                toast.error('Error removing enrollment request from pending');
              });
          })
          .catch((error) => {
            console.error('Error enrolling student:', error);
            // Show error toast
            toast.error('Error enrolling student');
          });
      })
      .catch((error) => {
        console.error('Error fetching decline data:', error);
        // Show error toast or message if needed
      });
  }
  setConfirmationApprovalModalIsOpen(false); // Close the approval confirmation modal
};




const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

const toggleCheckbox = (enrollmentId) => {
  setSelectedCheckboxes((prevSelected) => ({
    ...prevSelected,
    [enrollmentId]: !prevSelected[enrollmentId],
  }));
};
const [searchQueries, setSearchQueries] = useState({});

const handleSearchPerSemester = (event, year, semester) => {
  const { value } = event.target;
  setSearchQueries(prevState => ({
    ...prevState,
    [`${year}-${semester}`]: value
  }));
};
const filterEnrollments = (enrollments, year, semester) => {
  const searchQuery = searchQueries[`${year}-${semester}`] || '';
  const selectedCourse = selectedCourses[`${year}-${semester}`] || '';
  const selectedGender = selectedGenders[`${year}-${semester}`] || '';

  let filteredEnrollments = enrollments.filter(enrollment => {
    const studentDetails = userDetails[studentUIDs[enrollment.studentNumber]];
    const studentNumber = enrollment.studentNumber.toString().toLowerCase();
    const email = (studentDetails && studentDetails.email) ? studentDetails.email.toLowerCase() : '';
    const lastName = (studentDetails && studentDetails.Lastname) ? studentDetails.Lastname.toLowerCase() : '';
    const firstName = (studentDetails && studentDetails.Firstname) ? studentDetails.Firstname.toLowerCase() : '';
    const middleName = (studentDetails && studentDetails.Middlename) ? studentDetails.Middlename.toLowerCase() : '';
    const course = (studentDetails && studentDetails.Course) ? studentDetails.Course.toLowerCase() : '';
    const gender = (studentDetails && studentDetails.Gender) ? studentDetails.Gender.toLowerCase() : '';
    const fullName = `${firstName} ${lastName} ${middleName}`;

    const searchMatch = (
      studentNumber.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase()) ||
      lastName.includes(searchQuery.toLowerCase()) ||
      firstName.includes(searchQuery.toLowerCase()) ||
      middleName.includes(searchQuery.toLowerCase()) ||
      course.includes(searchQuery.toLowerCase()) ||
      gender.includes(searchQuery.toLowerCase()) ||
      fullName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const courseMatch = selectedCourse === '' || course === selectedCourse.toLowerCase();
    const genderMatch = selectedGender === '' || gender === selectedGender.toLowerCase();

    return searchMatch && courseMatch && genderMatch;
  });

  // Apply sorting
  if (sortOrder.column) {
    filteredEnrollments.sort((a, b) => {
      let valueA, valueB;
  
      if (sortOrder.column === 'email' || sortOrder.column === 'Lastname' || sortOrder.column === 'Firstname' || sortOrder.column === 'Course') {
        // Access email, Lastname, and Firstname from userDetails using studentNumber
        const userDetailsA = userDetails[studentUIDs[a.studentNumber]];
        const userDetailsB = userDetails[studentUIDs[b.studentNumber]];
        
        valueA = userDetailsA ? userDetailsA[sortOrder.column] || '' : '';
        valueB = userDetailsB ? userDetailsB[sortOrder.column] || '' : '';
      } else {
        // For other columns, directly access from enrollment object
        valueA = a[sortOrder.column] || '';
        valueB = b[sortOrder.column] || '';
      }
  
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return sortOrder.order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else {
        return sortOrder.order === 'asc' ? valueA - valueB : valueB - valueA;
      }
    });
  }

  return filteredEnrollments;
};


const [sortOrder, setSortOrder] = useState({ column: null, order: 'asc' });
const handleSort = (column) => {
  setSortOrder(prevState => ({
    column,
    order: prevState.column === column && prevState.order === 'asc' ? 'desc' : 'asc'
  }));
};
const renderSortIcon = (column) => {
  if (sortOrder.column === column) {
    return sortOrder.order === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  }
  return <FontAwesomeIcon icon={faSort} />;
};


const handleResetSearch = () => {
  setSearchQueries({});
};
const handleApproveSelectedEnrollments = (year, semester) => {
  const selectedEnrollmentIds = Object.keys(selectedCheckboxes).filter(
    id => selectedCheckboxes[id]
  );

  if (selectedEnrollmentIds.length === 0) {
    toast.error('Please select enrollments to approve.');
    return;
  }

  const confirmation = window.confirm('Are you sure you want to approve selected enrollments?');

  if (confirmation) {
    selectedEnrollmentIds.forEach(id => {
      // Perform the approve action for each selected enrollment
      const enrolledRef = ref(db, `enrolled/${year}/${semester}/${id}`);
      // Remove the pending enrollment
      remove(ref(db, `pendingEnrollees/${year}/${semester}/${id}`));
    });

    // Reset the selected checkboxes
    setSelectedCheckboxes({});

    toast.success('Enrollments approved successfully!');
  } else {
    toast.info('Action cancelled.');
  }
};

const handleDeleteSelectedEnrollments = (year, semester) => {
  const selectedEnrollmentIds = Object.keys(selectedCheckboxes).filter(
    id => selectedCheckboxes[id]
  );

  if (selectedEnrollmentIds.length === 0) {
    toast.error('Please select enrollments to delete.');
    return;
  }

  const confirmation = window.confirm('Are you sure you want to delete selected enrollments?');

  if (confirmation) {
    selectedEnrollmentIds.forEach(id => {
      // Remove the pending enrollment
      remove(ref(db, `pendingEnrollees/${year}/${semester}/${id}`));
    });

    // Reset the selected checkboxes
    setSelectedCheckboxes({});

    toast.success('Enrollments deleted successfully!');
  } else {
    toast.info('Action cancelled.');
  }
};
const [storedDesignation, setStoredDesignation] = useState('');
const [designation, setDesignation] = useState('');
const [studentNumber, setStudentNumber] = useState('');
useEffect(() => {
    // Set user type based on credentials
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
        setUserType("admin");
    }
}, []);

useEffect(() => {
    const userType = localStorage.getItem("userType");
    setUserType(userType);

    const storedStudentNumber = localStorage.getItem('studentNumber');
    setStoredStudentNumber(storedStudentNumber);


    const storedEmail = localStorage.getItem('email');
    setStoredEmail(storedEmail);

    const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
    setStoredDesignation(storedDesignation); // Set the designation in the component state
}, []);

useEffect(() => {
    const storedDesignation = localStorage.getItem('designation');
    const storedStudentNumber = localStorage.getItem('studentNumber');
    const storedEmail = localStorage.getItem('email');
    if (storedDesignation && storedStudentNumber) {
        setDesignation(storedDesignation);
        setStudentNumber(storedStudentNumber);
        setEmail(storedEmail);
    }
}, []);
// Effect to fetch user data when component mounts or when studentNumber or designation changes
useEffect(() => {
  const fetchData = async () => {
    try {
      const userDataFromDb = await getUserData(designation, studentNumber);
      setUserData(userDataFromDb);
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  fetchData();
}, [designation, studentNumber]);
const [email, setEmail] = useState('');

const getUserData = async (designation, studentNumber) => {
  try {
    // Construct the reference path
    const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
    
    // Retrieve the data
    const snapshot = await get(userRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Extract the user data
      const userData = snapshot.val();
      // Return the relevant data such as Lastname and Firstname
      return {
        Course: userData.Course,
        Lastname: userData.Lastname || '',
        Firstname: userData.Firstname || '',
        Middlename: userData.Middlename || '',
        // Add more properties as needed
      };
    } else {
      // Data does not exist
      console.log("No data available for the given student number.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving user data:", error.message);
    throw error;
  }
};


const [userImageUrl, setUserImageUrl] = useState(null);
const fetchUserImage = async (email) => {
  try {
    const storage = getStorage();
    const imageRef = storageRef(storage, `user_images/${email}`);
    const imageUrl = await getDownloadURL(imageRef);
    setUserImageUrl(imageUrl);
  } catch (error) {
    console.error('Error fetching user image:', error);
    setUserImageUrl(null);
  }
};

useEffect(() => {
  // Fetch user image when email changes
  if (email) {
    fetchUserImage(email);
  }
}, [email]);
const [showModal, setShowModal] = useState(false);
const [sortIcon, setSortIcon] = useState(faSortDown);

const handleModalOpen = () => {
  setShowModal(prevState => !prevState); // Toggle modal state
  setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
};

const handleModalClose = () => {
  setShowModal(false);
  setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
};

const handleModalContentClick = (e) => {
  e.stopPropagation(); // Prevent event bubbling
};
const handleLogoutClick = () => {
  setIsModalOpen1(true);
  // Add any additional logic you want to execute when logout is clicked
};

const handleCloseModal = () => {
  setIsModalOpen1(false);
  // Add any additional logic you want to execute when the modal is closed
};

const [isModalOpen1, setIsModalOpen1] = useState(false);
const [selectedGenders, setSelectedGenders] = useState({});

// Define function to handle gender filter change per semester
const handleGenderFilterChangePerSemester = (event, year, semester) => {
  const { value } = event.target;
  setSelectedGenders(prevSelectedGenders => ({
    ...prevSelectedGenders,
    [`${year}-${semester}`]: value
  }));
};


  return (
    <div className="Admin-PE">
        
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

      <div className='attendance-container'>
      <ToastContainer className="toast-container" />
  
      <div className="header-container1">
   

{isModalOpen1 && (
<div className="CustomCard1">
<div className="CustomCard">
  <div className="card-content">
    <p className="card-heading">Log Out</p>
    <p className="card-description">Are you sure you want to Log out?</p>
  </div>
  <div className="card-button-wrapper">
    <button className="card-button secondary" onClick={handleCloseModal}>No</button>
    <button className="card-button primary">Yes</button>
  </div>
  <button className="exit-button" onClick={handleCloseModal}>
    <svg height="20px" viewBox="0 0 384 512">
      <path
        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
      ></path>
    </svg>
  </button>
</div>
</div>
)}
<h1 className="Header-container-attendance">PENDING ENROLLEES</h1>  

{!designation   && (
  <>
   
  </>
)}
   
   { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
<div className="user-details">
<div className="user-details1">
{userData && (
<p className="user-name">
{userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
</p>
)}
</div>
<div className="user-image-container1" onClick={handleModalOpen}>
{userImageUrl ? (
<img className="user-image1" src={userImageUrl} alt="User" />
) : (

<span className="visually-hidden">Loading...</span>

)}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

</div>
{showModal && (
<div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
<div className="modal-content" onClick={handleModalContentClick}>
<span className="close" onClick={handleModalClose}>&times;</span>
<div className="pendingHeader1">Profile</div>
<div className="user-image-container12">
{userImageUrl ? (
 <img className="user-image12" src={userImageUrl} alt="User" />
) : (
 <Spinner animation="border" role="status" variant="success">
   <span className="visually-hidden">Loading...</span>
 </Spinner>
)}


</div>
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
<div className="info-box">
<p className="Personnel">Personnel Officer ({designation})</p>
{userData && (
<>
<div className="custom-table"> 
<table className="custom-table">
<tbody>
<tr>
<td><label htmlFor="studentNumber">Student Number:</label></td>
<td><p>{studentNumber}</p></td>
</tr>
<tr>
<td><label>Name:</label></td>
<td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
</tr>
<tr>
<td><label htmlFor="email">Email:</label></td>
<td><p>{email}</p></td>
</tr>
<tr>
<td><label>Course:</label></td>
<td><p>{userData.Course}</p></td>
</tr>

</tbody>
</table></div>

</>
)}

</div>
<div className="button-container12">
<button className="CustomBtn" onClick={handleLogoutClick}>
<div className="sign12">
  <svg viewBox="0 0 512 512">
    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
  </svg>
</div>
<div className="text12">Logout</div>
</button>
</div>


</div>

</div>

)}

</div>
)}

</div>
        <Modal1
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        selectedFileURL={selectedFileURL}
        userData={userData}
      />
<div className="enrollement-yearsem1"></div>
{pendingEnrollments.map(({ year, semester, enrollments }) => (
  <div className="enrollement-yearsem" key={`${year}-${semester}`}>
        <div key={`${year}-${semester}`}>
            <h1 className="pendingHeader">School Year - {year} ({semester})</h1>
            <div className="Admin-PE-action-buttons">
            <button className="noselect00" onClick={() => handleApproveSelectedEnrollments(year, semester)}>
  <span className="text00">Approve</span>
  <span className="icon00">
    <div className="sign12">  </div>
  </span>
</button>

<button className="noselect0" onClick={() => handleDeleteSelectedEnrollments(year, semester)}>
  <span className="text0">Delete</span>
  <span className="icon0">
    <div className="sign">
      <svg
        viewBox="0 0 16 16"
        className="bi bi-trash3-fill"
        fill="currentColor"
        height="18"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
        ></path>
      </svg>
    </div>
  </span>
</button>


            <select
  className="custom-dropdown"
  value={selectedCourses[`${year}-${semester}`] || ''}
  onChange={(event) => handleCourseFilterChangePerSemester(event, year, semester)}
>
        <option value="">All Courses</option>
        {/* Extracting existing courses from enrollments */}
        {enrollments.flatMap(enrollment =>
            userDetails[studentUIDs[enrollment.studentNumber]] ?
                userDetails[studentUIDs[enrollment.studentNumber]].Course : null
        ).filter((course, index, self) => course && self.indexOf(course) === index).map((course, index) =>
            <option key={index} value={course}>{course}</option>
        )}
    </select>
    <select
                className="custom-dropdown1"
                value={selectedGenders[`${year}-${semester}`] || ''}
                onChange={(event) => handleGenderFilterChangePerSemester(event, year, semester)}
              >
                <option value="">All </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
    <form className="form-search">
      <label htmlFor="search" className="label-search">
        <input
          className="input-search"
          type="text1"
          required
          placeholder="Search"
          id="search"
          value={searchQueries[`${year}-${semester}`] || ''}
          onChange={(event) => handleSearchPerSemester(event, year, semester)}
        />
        <div className="fancy-bg-search"></div>
        <div className="search-search">
          <svg viewBox="0 0 24 24" aria-hidden="true" className="icon-search r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
        </div>
        <button className="close-btn-search" type="reset" onClick={handleResetSearch}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
      </label>
    </form>
</div>   

<div className="verified-container">
<p className="total-enrollees">Total Number of Enrollees: {filterEnrollments(enrollments, year, semester).length}</p>
<table className="table-container-verified">
  <thead>
    <tr>
      <th>
        <input
          type="checkbox"
          checked={areAllCheckboxesChecked(year, semester)}
          onChange={() => toggleSelectAllPerSemester(year, semester)}
        />
      </th>
      <th>#</th>
      <th onClick={() => handleSort('studentNumber')}>
        Student Number {renderSortIcon('studentNumber')}
      </th>
      <th onClick={() => handleSort('email')}>
        Email {renderSortIcon('email')}
      </th>
      <th onClick={() => handleSort('Lastname')}>
        Last Name {renderSortIcon('Lastname')}
      </th>
      <th onClick={() => handleSort('Firstname')}>
        First Name {renderSortIcon('Firstname')}
      </th>
      <th>Middle Name  </th>
      <th  onClick={() => handleSort('Course')}>Course {renderSortIcon('Course')}</th>
      <th >
        Gender 
      </th>
      <th>Actions</th>
      <th>File</th>
    </tr>
  </thead>
      <tbody>
    {filterEnrollments(enrollments, year, semester).map((enrollment, index) => (
                <tr key={enrollment.id}>
                    <td>
                        <input
                            type="checkbox"
                            checked={selectedCheckboxes[enrollment.id] || false}
                            onChange={() => toggleCheckbox(enrollment.id)}
                        />
                    </td>
                    <td>{index + 1}</td> {/* Numbering column */}
                    <td>{enrollment.studentNumber}</td>
                            {studentUIDs[enrollment.studentNumber] && userDetails[studentUIDs[enrollment.studentNumber]] ? (
                                <>

                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].email}</td>
                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].Lastname}</td>
                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].Firstname}</td>
                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].Middlename}</td>
                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].Course}</td>
                                    <td>{userDetails[studentUIDs[enrollment.studentNumber]].Gender}</td>
                                </>
                            ) : (
                                <td colSpan="6">User details not available</td>
                            )}
                            <td className="action-btn">
                                <button onClick={() => handleAction(enrollment.id, 'approve', year, semester, enrollment.studentNumber)} className="Btn1">
                                    <div className="sign1">
                                    </div>
                                    <div className="text2">Approve</div>
                                </button>
                           <button onClick={() => openDeleteConfirmationModal(year, semester, enrollment.id)} className="Btn">
    <div className="sign">
        <svg
            viewBox="0 0 16 16"
            className="bi bi-trash3-fill"
            fill="currentColor"
            height="18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
            ></path>
        </svg>
    </div>
    <div className="text1">Delete</div>
</button>

                            </td>
                            <td>
                                {/* Button to show the file and open the modal */}
                                <div className="Documents-btn" onClick={() => showFileAndOpenModal(enrollment.studentNumber, year, semester)}> <span className="folderContainer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 146 113"
                                        height="113"
                                        width="146"
                                        className="fileBack"
                                    >
                                        <path
                                            fill="url(#paint0_linear_117_4)"
                                            d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
                                        ></path>
                                        <defs>
                                            <linearGradient
                                                gradientUnits="userSpaceOnUse"
                                                y2="95.4804"
                                                x2="72.93"
                                                y1="0"
                                                x1="0"
                                                id="paint0_linear_117_4"
                                            >
                                                <stop stopColor="#8F88C2"></stop>
                                                <stop stopColor="#5C52A2" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 88 99"
                                        height="99"
                                        width="88"
                                        className="filePage"
                                    >
                                        <rect fill="url(#paint0_linear_117_6)" height="99" width="88"></rect>
                                        <defs>
                                            <linearGradient
                                                gradientUnits="userSpaceOnUse"
                                                y2="160.5"
                                                x2="81"
                                                y1="0"
                                                x1="0"
                                                id="paint0_linear_117_6"
                                            >
                                                <stop stopColor="white"></stop>
                                                <stop stopColor="#686868" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 160 79"
                                        height="79"
                                        width="160"
                                        className="fileFront"
                                    >
                                        <path
                                            fill="url(#paint0_linear_117_5)"
                                            d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
                                        ></path>
                                        <defs>
                                            <linearGradient
                                                gradientUnits="userSpaceOnUse"
                                                y2="82.8317"
                                                x2="66.9106"
                                                y1="8.71323"
                                                x1="38.7619"
                                                id="paint0_linear_117_5"
                                            >
                                                <stop stopColor="#C3BBFF"></stop>
                                                <stop stopColor="#51469A" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </span><p className="text" >View File</p></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>

    </div>
))}
{confirmationApprovalModalIsOpen && (
          <div className="confirmation-modal">
            <div className="card">
              <div className="card-content">
                <p className="card-heading">Approve Enrollment?</p>
                <p className="card-description">Are you sure you want to approve enrollment for student number {selectedEnrollmentToDelete?.studentNumber}?</p>
              </div>
              <div className="card-button-wrapper">
                <button className="card-button secondary" onClick={() => setConfirmationApprovalModalIsOpen(false)}>Cancel</button>
                <button className="card-button primary" onClick={handleApproveConfirmation}>Approve</button>
              </div>
              <button className="exit-button" onClick={() => setConfirmationApprovalModalIsOpen(false)}>
                <svg height="20px" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                </svg>
              </button>
            </div>
          </div>
        )}
       {confirmationDeleteModalIsOpen && (
  <div className="confirmation-modal">
    <div className="card">
      <div className="card-content">
        <p className="card-heading">Delete Enrollment?</p>
        <p className="card-description">Are you sure you want to delete the enrollment?</p>
        <textarea
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
          placeholder="Reason for decline..."
        />
      </div>
      <div className="card-button-wrapper">
        <button className="card-button secondary" onClick={() => setConfirmationDeleteModalIsOpen(false)}>Cancel</button>
        <button className="card-button primary" onClick={handleDeleteConfirmation}>Delete</button>
      </div>
      <button className="exit-button" onClick={() => setConfirmationApprovalModalIsOpen(false)}>
                <svg height="20px" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                </svg>
              </button>
    </div>
  </div>
)}
      </div>
    </div>
  );
}

export default PendingEnrollments;
