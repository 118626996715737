import React, { useEffect, useRef, useState } from "react";
import { BrowserQRCodeReader, NotFoundException } from "@zxing/library";
import { Link } from "react-router-dom";
import { getDatabase, ref, get, set} from "firebase/database";
import {getStorage,ref as storageRef,getDownloadURL,} from "firebase/storage";
import { app } from "../Firebase"; // Import your Firebase app object
import "./QRScanner.css"; 
import { db } from "../Firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref as dbRef, onValue } from 'firebase/database';
import QRScannerSideBar  from "./QRScannerSideBar";
function QRCodeScanner({ onScan, selectedAction }) {
  const [countResults, setCountResults] = useState(0);
  const [scannedCodes, setScannedCodes] = useState(new Set());
  const [emailStatus, setEmailStatus] = useState(null);
  const [scannedEmail, setScannedEmail] = useState(null);
  const [userData, setUserData] = useState(null);
  const [scanning, setScanning] = useState(true);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [scannedEmails, setScannedEmails] = useState([]);
  const [sidebarStudentNumber, setSidebarStudentNumber] = useState('');
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );
  const qrVideoRef = useRef(null);
  const resultDivRef = useRef(null);
  const scannedListRef = useRef(null);
  let codeReader = null;
  let resetInterval = null;
  const [isPresent, setIsPresent] = useState(false);
  const [morningScanRecorded, setMorningScanRecorded] = useState(false);
  const [userImageUrl, setUserImageUrl] = useState("");
  const [uniqueAttendees, setUniqueAttendees] = useState(0);
  const [scannedUsers, setScannedUsers] = useState(new Set());

  const isValidEmail = (text) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(text);
  };

  const determineAttendanceStatus = (currentTime) => {
    const presentStartTime = new Date(currentTime);
    const presentEndTime = new Date(currentTime);
  
    presentStartTime.setHours(15, 30, 0);   // 4:00:00 PM
    presentEndTime.setHours(18, 0, 0);    // 7:30:00 PM
  
    const earlyTimeStart = new Date(currentTime);
    const earlyTimeEnd = new Date(currentTime);
    const lateTimeStart = new Date(currentTime);
    const lateTimeEnd = new Date(currentTime);
  
    earlyTimeStart.setHours(4, 30, 0);      // 4:30:00 AM
    earlyTimeEnd.setHours(7, 5, 59);         // 7:05:59 AM
    lateTimeStart.setHours(7, 6, 0);         // 7:06:00 AM
    lateTimeEnd.setHours(8, 59, 59);       // 11:59:59 AM
  
    if (
      (currentTime >= presentStartTime && currentTime <= presentEndTime) ||
      (currentTime >= earlyTimeStart && currentTime <= earlyTimeEnd)
    ) {
      return "Present";
    } else if (currentTime >= lateTimeStart && currentTime <= lateTimeEnd) {
      return "Late";
    } else {
      return "Absent";
    }
  };
  
  const isTrainingDateEnabled = (trainingDate) => {
    if (!trainingDate) {
      console.error("Invalid training date.");
      return false;
    }
    const currentDate = new Date();
    const selectedTrainingDate = new Date(trainingDate);
    return (
      currentDate.getFullYear() === selectedTrainingDate.getFullYear() &&
      currentDate.getMonth() === selectedTrainingDate.getMonth() &&
      currentDate.getDate() === selectedTrainingDate.getDate()
    );
  };
  
  const determineAttendanceType = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
  
    const startTimeForTimeOut = (15, 30 , 0);
  
    if (hours >= startTimeForTimeOut || (hours === startTimeForTimeOut && minutes >= 30)) {
      return "Time Out";
    } else {
      return "Time In";
    }
  };
  const isTrainingDaySet = () => {
    return selectedTrainingDay !== null;
  };

  const isScanningAllowed = () => {
    const currentTime = new Date();
    const disableMorningStart = 9;
    const disableMorningEnd = 15;
    const disableEveningStart = 18;
    const disableEveningEnd = 4;

    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const isMorningDisabled =
      currentHour === disableMorningStart && currentMinutes >= 0 ||
      currentHour > disableMorningStart && currentHour < disableMorningEnd;

    const isEveningDisabled =
      currentHour >= disableEveningStart ||
      currentHour < disableEveningEnd;

    return !isMorningDisabled && !isEveningDisabled;
  };

  const [totalUsers, setTotalUsers] = useState(0);
  const fetchTotalUsers = async () => {
    try {
      const db = getDatabase(app);
      const usersRef = ref(db, "users");
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const usersCount = Object.keys(usersData).length;
        setTotalUsers(usersCount);
      } else {
        setTotalUsers(0);
      }
    } catch (error) {
      console.error("Error fetching total users:", error);
    }
  };

  useEffect(() => {
    fetchTotalUsers();
  }, []);
  
  const [uniqueScannedEmails, setUniqueScannedEmails] = useState(new Set());


  useEffect(() => {
    codeReader = new BrowserQRCodeReader();
      if (!isTrainingDaySet()) {
        resultDivRef.current.textContent = "Training day not set. Scanning disabled.";
        setScanning(false);
        return;
      }
    const initializeScanner = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
          audio: false,
        });
        qrVideoRef.current.srcObject = stream;
        startScanning();
      } catch (error) {
        console.error(error);
        alert("Unable to access camera devices.");
        setScanning(false);
      }
    };
    const startScanning = () => {
      try {
        if (!isScanningAllowed()) {
          resultDivRef.current.textContent = "Scanning disabled";
          return;
        }
        codeReader.decodeFromVideoDevice(null, qrVideoRef.current, async (result) => {
          if (result) {
            const codeText = result.getText();
  
            if (!scannedCodes.has(codeText)) {
              const newCount = countResults + 1;
              setCountResults(newCount);
              const listItem = document.createElement("li");
              listItem.textContent = `${newCount}: ${codeText}`;
            
              if (scannedListRef.current) {
                scannedListRef.current.appendChild(listItem);
              } else {
                console.error("scannedListRef.current is null");
              }
            
              setScannedCodes((prevCodes) => new Set(prevCodes.add(codeText)));
              resultDivRef.current.textContent = "Scanning...";
              clearInterval(resetInterval);
              resetInterval = setInterval(startScanning, 2000);
            
              const studentNumber = codeText.split('/')[2];
            
              updateStudentNumberCount(studentNumber);
              setCurrentTime(new Date().toLocaleTimeString());
            
              updateUniqueAttendees(codeText);
            
              if (isValidEmail(codeText)) {
                setEmailStatus("email");
              
                if (!uniqueScannedEmails.has(codeText)) {
                  setUniqueScannedEmails((prevEmails) => new Set(prevEmails.add(codeText)));
                  setScannedEmail(codeText);
              
                  resultDivRef.current.textContent = `Scanning... ${codeText}`;
                  
                  if (selectedAction) {
                    onScan(codeText, selectedAction);
                    notifySuccess(codeText); // Notify success here
                  }
                } else {
                  console.log("Email already scanned");
                }
              } else {
                setEmailStatus(null);
              }
            
              const attendanceStatus = determineAttendanceStatus(new Date());
              setIsPresent(attendanceStatus);
            
              if (determineAttendanceType() === "Time In" && attendanceStatus === "Present") {
                setMorningScanRecorded(true);
            
                const currentDate = new Date(); // Define currentDate here
                const formattedDate = `${currentDate.getFullYear()}-${(
                  currentDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
            
                let userAttendanceRef = null;
            
                // Ensure the reference is not null before making the query
                if (userAttendanceRef) {
                  const attendanceSnapshot = await get(userAttendanceRef);
                
                  if (!attendanceSnapshot.exists()) {
                    const lateTimeStart = new Date(currentDate);
                    lateTimeStart.setHours(7, 6, 0); // 7:06:00 AM
                    const lateTimeEnd = new Date(currentDate);
                    lateTimeEnd.setHours(11, 59, 59); // 11:59:59 AM
                    const currentTime = new Date().toLocaleTimeString([], { hour12: false });
            
                    const isLate = currentTime >= lateTimeStart && currentTime <= lateTimeEnd ? "Late" : "Present";
            
                    const newAttendanceRecord = {
                      appearance: isLate,
                      time: currentTime,
                    };
            
                    await set(userAttendanceRef, newAttendanceRecord);
                    console.log("Time Out attendance record saved successfully");
                  } else {
                    console.log("Time Out attendance record already exists");
                  }
                }
              } else {
                resultDivRef.current.textContent = "Already recorded";
              }
              await new Promise(resolve => setTimeout(resolve, 1000));
              startScanning();
            }
          }
        });
      } catch (error) {
        if (error instanceof NotFoundException) {
          resultDivRef.current.textContent = "Scanning...";
        } else {
          console.error(error);
          resultDivRef.current.textContent = "An error occurred while scanning";
          clearInterval(resetInterval);
          resetInterval = setInterval(startScanning, 1000);
        }
      }
    };
    initializeScanner();
    return () => {
      codeReader.reset();
      clearInterval(resetInterval);
    };
  }, [scannedCodes, countResults, isTrainingDaySet, isTrainingDateEnabled]);

  const fetchUserData = async (email) => {
    const db = getDatabase(app);
    const dbRef = ref(db, "users");
    try {
      if (!isValidEmail(email)) {
        console.error("Invalid email format.");
        return;
      }
      const snapshot = await get(dbRef);
  
      if (!snapshot.exists()) {
        console.error("Database 'users' reference does not exist.");
        return;
      }
      const userData = snapshot.val();
      const user = Object.values(userData).find((user) => user.email === email);
      if (!user) {
        console.error(`User with email ${email} not found in the database.`);
        // Show error toast notification
        toast.error(`User with email ${email} not found in the database.`);
        return;
      }
      setUserData(user);
  
      const attendanceType = determineAttendanceType();
  
      const selectedTrainingDayData = getSelectedTrainingDayData();
  
      if (!selectedTrainingDayData) {
        console.error("Invalid selected training day or data not found.");
        return;
      }
  
      const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDayData.day} : ${selectedTrainingDayData.date}/${user.studentNumber}/${attendanceType}`;
      const userAttendanceRef = ref(db, sessionPath);
  
      const attendanceSnapshot = await get(userAttendanceRef);
  
      if (!attendanceSnapshot.exists()) {
        const currentTime = new Date();
        
        const attendanceStatus = determineAttendanceStatus(currentTime);
  
        const newAttendanceRecord = {
          appearance: attendanceStatus,
          time: currentTime.toLocaleTimeString(),
        };
  
        await set(userAttendanceRef, newAttendanceRecord);
        console.log("Attendance record saved successfully");
      } else {
        console.log("Attendance record already exists");
      }
  
      const imageRef = storageRef(getStorage(), `user_images/${user.email}`);
  
      try {
        const userImageUrl = await getDownloadURL(imageRef);
        setUserImageUrl(userImageUrl);
      } catch (error) {
        console.error("Error fetching user image URL:", error);
      }
    } catch (error) {
      console.error("Error fetching user data and storing attendance:", error);
      // Handle other errors here
    } finally {
      setLoadingUserData(false);
    }
  };
  
  const notifySuccess = async (email) => {
    try {
      const db = getDatabase(app);
      const usersRef = ref(db, "users");
  
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const user = Object.values(userData).find((user) => user.email === email);
  
        if (user) {
          // Email exists in the database, notify success
          toast.success(`Email ${email} is successfully recorded!`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            zIndex: 1, // Apply z-index of 1 to the toast container
          });
        } else {
          // Email does not exist in the database, notify error
          toast.error(`Email ${email} does not exist in the database!`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            zIndex: 1, // Apply z-index of 1 to the toast container
          });
        }
      } else {
        console.error("Database 'users' reference does not exist.");
      }
    } catch (error) {
      console.error("Error notifying success:", error);
    }
  };

  useEffect(() => {
    if (emailStatus === "email" && scannedEmail) {
      setScannedEmails((prevEmails) => [...prevEmails, scannedEmail]);

      fetchUserData(scannedEmail);
      notifySuccess(scannedEmail); // Call the notifySuccess function when email is successfully recorded
    }
  }, [emailStatus, scannedEmail]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };

  const updateUniqueAttendees = (codeText) => {
    setScannedUsers((prevUsers) => {
      if (!prevUsers.has(codeText)) {
        setUniqueAttendees((prevCount) => prevCount + 1);
        return new Set(prevUsers.add(codeText));
      }
      return prevUsers;
    });
  };
  
  const [studentNumberCounts, setStudentNumberCounts] = useState({});

  // Function to update the count of a scanned student number
  const updateStudentNumberCount = (studentNumber) => {
    setStudentNumberCounts((prevCounts) => {
      const updatedCounts = { ...prevCounts };
      updatedCounts[studentNumber] = (updatedCounts[studentNumber] || 0) + 1;
      const attendanceType = determineAttendanceType();
      // Update the session path
      const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDay}/${studentNumber}/${attendanceType}`;
      console.log("Updated Session Path:", sessionPath);
  
      return updatedCounts;
    });
  };
  
  const [cameraOn, setCameraOn] = useState(true); // State to manage camera activation

  const toggleCamera = () => {
    setCameraOn((prevState) => !prevState); // Toggle camera state
  };
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [existingTrainingDates, setExistingTrainingDates] = useState(Array.from({ length: 15 }, () => ''));
  const [updatedTrainingDates, setUpdatedTrainingDates] = useState({});
  const [selectedTrainingDay, setSelectedTrainingDay] = useState(null);
  
  const [schoolYears, setSchoolYears] = useState([]);

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(db, 'enrolled');
        const schoolYearsSnapshot = await get(schoolYearsRef);
        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);

  async function fetchExistingData(selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates) {
    try {
      const db = getDatabase(app);
  
      const trainingDatesRef = ref(
        db,
        `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
      );
  
      const snapshot = await get(trainingDatesRef);
  
      if (snapshot.exists()) {
        const trainingDatesData = snapshot.val();
        console.log('Existing Training Dates:', trainingDatesData);
        setExistingTrainingDates(trainingDatesData || Array.from({ length: 15 }, () => ''));
      } else {
        console.log('No existing training dates found.');
        setExistingTrainingDates(Array.from({ length: 15 }, () => ''));
      }
    } catch (error) {
      console.error('Error fetching existing data:', error.message);
    }
  }
  
  useEffect(() => {
    if (selectedSemester && selectedSchoolYear) {
      fetchExistingData(selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates);
      setUpdatedTrainingDates({});
    }
  }, [selectedSemester, selectedSchoolYear]);


  useEffect(() => {
    if (existingTrainingDates) {
      const trainingDays = Object.keys(existingTrainingDates).map((day) => ({
        day,
        date: existingTrainingDates[day],
      }));
      setExistingTrainingDays(trainingDays);
    }
  }, [existingTrainingDates]);

  const [existingTrainingDays, setExistingTrainingDays] = useState([]);

  const getSelectedTrainingDayData = () => {
    if (!selectedTrainingDay || !existingTrainingDays[selectedTrainingDay - 1]) {
      console.error("Invalid selected training day or data not found.");
      return null;
    }
    return existingTrainingDays[selectedTrainingDay - 1];
  };
  const selectedTrainingDayData = getSelectedTrainingDayData();
console.log("Selected Training Day Data:", selectedTrainingDayData);

const [isMinimized, setIsMinimized] = useState(false);
  const toggleMinimize = () => setIsMinimized(!isMinimized);

  const [uniqueStudentCount, setUniqueStudentCount] = useState(0);
  const countStudentNumberFromDatabase = (selectedTrainingDate) => {
    try {
      const db = getDatabase(app);
      const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
      const sessionRef = dbRef(db, sessionPath);

      // Listen for changes in the session data
      onValue(sessionRef, (snapshot) => {
        if (snapshot.exists()) {
          const sessionData = snapshot.val();
          const studentNumbers = Object.keys(sessionData);
          const uniqueStudentCount = studentNumbers.length;
          setUniqueStudentCount(uniqueStudentCount); // Set the count in the state
          console.log(`Number of unique students on ${selectedTrainingDate}: ${uniqueStudentCount}`);
        } else {
          console.log(`No data found for ${selectedTrainingDate}`);
        }
      });
    } catch (error) {
      console.error("Error counting student numbers:", error);
    }
  };
  useEffect(() => {
    if (selectedTrainingDay) {
      const selectedTrainingDateData = existingTrainingDays[selectedTrainingDay - 1];
      const selectedTrainingDate = `${selectedTrainingDateData.day} : ${selectedTrainingDateData.date}`;
      countStudentNumberFromDatabase(selectedTrainingDate);
    }
  }, [selectedTrainingDay, existingTrainingDays]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserImageUrl = () => {
      setTimeout(() => {
        // Once the image URL is fetched, set it and stop loading
        setUserImageUrl('your-user-image-url.jpg');
        setLoading(false);
      }, 2000); // Simulate a 2-second delay
    };

    fetchUserImageUrl();
  }, []); // Only run once on component mount

  const [trainingDayKey, setTrainingDayKey] = useState('');

  // Retrieving the student number from local storage on component mount
  useEffect(() => {
    
    const storedSchoolYear = localStorage.getItem('selectedSchoolYear');
    if (storedSchoolYear) {
      setSelectedSchoolYear(storedSchoolYear);
    }
  
    const storedSemester = localStorage.getItem('selectedSemester');
    if (storedSemester) {
      setSelectedSemester(storedSemester);
    }
  
    const storedTrainingDay = localStorage.getItem('selectedTrainingDay');
    if (storedTrainingDay) {
      setSelectedTrainingDay(JSON.parse(storedTrainingDay));
    }

    const storedTrainingDayKey = localStorage.getItem('trainingDayKey');
    if (storedTrainingDayKey) {
      setTrainingDayKey(storedTrainingDayKey);
    }
  }, []);
  useEffect(() => {
    const storedStudentNumber = localStorage.getItem('studentNumber');
    if (storedStudentNumber) {
      setSidebarStudentNumber(storedStudentNumber);
    }
  }, []);
return (
  <div className="QrScanner-Container">
     <QRScannerSideBar studentNumber={sidebarStudentNumber} />
    <div className="scanner-continer-full">
    <div className="Header-list-Scanner">
        <div className="home-back-scanner"><Link to="/Admin/LoginAdmin" className="back-button">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Back</span>
          </Link>
          </div>
          <h1>QR Scanner</h1>  
    </div>
    <div className="qr-scanner-container">

      <div className="email-and-attendance-container">
      <div className="scan-training">
        <div> 
  {!isMinimized ? (
    <div className="select-date-training1">
<div className="header-select-date-training1">
  <h2>
    Set-up 
    <span className="minimize-button-container">
      <button className="minimize-button-change" onClick={toggleMinimize}>
        {isMinimized ? 'Maximize' : <FontAwesomeIcon icon={faAngleDown} />}
      </button>
    </span>
  </h2>
</div>
      <div className="admin-dropdown13">
        <label htmlFor="semester">Select Semester: </label>
        <select
       id="semester"
       className="user-list-label1"
       onChange={(e) => setSelectedSemester(e.target.value)}
       value={selectedSemester}
     >
       <option value="" disabled selected>Select Semester</option>
       <option value="First Semester">First Semester</option>
       <option value="Second Semester">Second Semester</option>
     </select>
      </div>
      <div className="admin-dropdown13">
        <label htmlFor="schoolYear">Select School Year: </label>
        <select
      id="schoolYear"
      className="user-list-label1"
      onChange={(e) => setSelectedSchoolYear(e.target.value)}
      value={selectedSchoolYear}
    >
      <option value="" disabled selected>Select School Year</option>
      {schoolYears.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
      </div>
      <div className="admin-dropdown13">
        <label htmlFor="trainingDay">Select Training Day: </label>
        <select
          id="trainingDay"
          className="user-list-label1"
          onChange={(e) => setSelectedTrainingDay(parseInt(e.target.value))}
          value={selectedTrainingDay || ''}
        >
          <option value="">Select Training Day</option>
          {existingTrainingDays.map((item, index) => (
            <option
              key={index}
              value={index + 1}
              disabled={!isTrainingDateEnabled(item.date)}
            >
              {`Training Day ${item.day}: ${item.date}`}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : (
<button className="toggle-button-max" onClick={toggleMinimize}>
  {isMinimized ? 'Maximize' : 'Minimize'}
</button>
  )}
     <div id="scanned-list-container1">
        {/* Display the count of unique students for the selected training date */}
        {selectedTrainingDay && (
          <p>
             <h3>Attendees: {uniqueStudentCount} | {totalUsers}</h3>
          </p>
        )}
<div className="scanned-emails-container">
  <h5>Scanned Emails:</h5>
  <ul>
    {scannedEmails.map((email, index) => (
      <li key={index}>{index + 1}. {email}</li>
    ))}
  </ul>
</div>

        <div id="result" ref={resultDivRef}></div>
      </div>
  </div>
<ToastContainer />

      <div className="scanner-frame1">
        <div className="QR-Header">
          <h3>QR Scanner</h3>
        </div>
        <video
          ref={qrVideoRef}
          id="qr-video"
          autoPlay
          playsInline
        ></video>
        <div className="camera-toggle">
      <button className="button-camera" onClick={toggleCamera}>
        {cameraOn ? "Turn Off Camera" : "Turn On Camera"}
      </button>
    </div>
      </div>
      <div className="email-container1">
<div>
<h3 className="user-data-header">User Data:</h3>
  <div className="user-image-container-qr-scanner">
        {loading ? (
          <p className="loading-message">Loading user image...</p>
        ) : (
          <img className="user-image" src={userImageUrl} alt="User" />
        )}
      </div>



  <table className="user-data-table">
    <tbody>
      {loadingUserData ? (
        <tr>
          <td colSpan="2" className="loading-message">Loading user data...</td>
        </tr>
      ) : userData ? (
        <>
          <tr>
          <td>
              <div class="user-info-container-scanner">
                <p>Student ID: {userData.studentNumber}</p>
                <p>
                  Fullname: {userData.Lastname}, {userData.Firstname} {userData.Middlename}
                </p>
                <p>Course: {userData.Course}</p>
              </div>
            </td>
          </tr>
        </>
      ) : (
        <tr className="no-user-data">
          <td colSpan="2">No user data available</td>
        </tr>
      )}
    </tbody>
  </table>

<div>
  <h3 className="attendance-header">Attendance:</h3>
  <table className="attendance-table">
    <tbody>
      {isPresent ? (
        <>
          <tr>
            <th>Date:</th>
            <td>{formatDate(new Date())}</td>
          </tr>
          <tr>
            <th>Scanned at:</th>
            <td>{currentTime}</td>
          </tr>
        </>
      ) : (
        <tr className="waiting-for-qr">
          <td colSpan="2">Waiting for QR scan...</td>
        </tr>
      )}
    </tbody>
  </table>
</div>
</div>
      </div>
      </div>
      </div>
   
    </div>
</div>
  </div>
);
}
export default QRCodeScanner;