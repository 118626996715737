import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import './PresentTotal.css';

const PresentTotal = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [presentCount, setPresentCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          let totalPresentCount = 0;

          // Iterate through each student entry and count present appearances
          Object.values(attendanceData).forEach(studentData => {
            if (studentData['Time In']?.appearance === 'Present' || studentData['Time Out']?.appearance === 'Present') {
              totalPresentCount++;
            }
          });

          // Update state with the total present count
          setPresentCount(totalPresentCount);
        } else {
          // If attendance data doesn't exist, set present count to 0
          setPresentCount(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate]);

  return (
    <div className="present-total-display">
      <h2>Total Present: </h2>
      <div className='present-count-ttl'>
        {presentCount}
      </div>
    </div>
  );
};

export default PresentTotal;
