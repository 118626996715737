import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Pie } from 'react-chartjs-2';
import './StudentsPerCourse.css'; // Update the CSS file name

function StudentsPerCourse({ selectedSchoolYear, selectedSemester }) {
  const [courseData, setCourseData] = useState({});
  const db = getDatabase();

  useEffect(() => {
    const fetchUserCourses = async () => {
      try {
        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const snapshot = await get(enrolledRef);

        if (snapshot.exists()) {
          const enrolledData = snapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);

          const usersRef = ref(db, 'users');
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const courseCounts = {};

            // Count the number of users per course
            Object.values(users).forEach(user => {
              if (studentNumbers.includes(user.studentNumber)) {
                const { Course } = user;
                if (Course in courseCounts) {
                  courseCounts[Course]++;
                } else {
                  courseCounts[Course] = 1;
                }
              }
            });

            setCourseData(courseCounts);
          } else {
            setCourseData({});
          }
        } else {
          setCourseData({});
        }
      } catch (error) {
        console.error('Error fetching user courses:', error.message);
      }
    };

    fetchUserCourses();
  }, [db, selectedSchoolYear, selectedSemester]);

  const prepareChartData = () => {
    return {
      labels: Object.keys(courseData),
      datasets: [
        {
          label: 'Students Per Course',
          data: Object.values(courseData),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
          ],
        },
      ],
    };
  };

  return (
    <div>  
      <div className="pie-chart-container-program">
      <h2>Program</h2>
        <Pie data={prepareChartData()} />
      </div>
    </div>
  );
}

export default StudentsPerCourse;
