// Login.js

import React, { useState , useEffect} from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db} from '../Firebase';
import { useNavigate } from 'react-router-dom';
import {Button, Form, Col, Row, InputGroup } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref, get, set } from 'firebase/database';
import '../Cadet/ForgotPassword.css'; // Import the CSS file
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal"; // Import the Modal component
import ForgotPassword from '../Cadet/ForgotPassword';
import './AdminOfficerLogin.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [designation, setDesignation] = useState('');
    const [studentNumber, setStudentNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
   
    const toggleForgotPasswordModal = () => {
      setShowForgotPasswordModal(!showForgotPasswordModal);
    };  const [userData, setUserData] = useState(null);
    const handleLogin = async () => {
      try {
        setLoading(true);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // Fetch user data from the database based on the provided email
        const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
        const snapshot = await get(userRef);
    
        if (snapshot.exists()) {
          const userData = snapshot.val();
          // Check if the fetched email matches the provided email
          if (userData.email === email) {
            // Update the user's status to "Online"
            await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Online');
    
            // Save designation, student number, and email to local storage
            localStorage.setItem('designation', designation);
            localStorage.setItem('studentNumber', studentNumber);
            localStorage.setItem('email', email);
    
            toast.success('Login successful');
            navigate('/Admin/Dashboard'); // Redirect to dashboard after successful login
          } else {
            toast.error('Email does not match with the provided credentials.');
          }
        } else {
          toast.error('User data not found.');
        }
      } catch (error) {
        toast.error('Invalid credentials. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      // Retrieve designation, student number, and email from local storage
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');
      const storedEmail = localStorage.getItem('email');
    
      // Check if all required data is available in local storage
      if (storedDesignation && storedStudentNumber && storedEmail) {
        // Set the state with retrieved values
        setDesignation(storedDesignation);
        setStudentNumber(storedStudentNumber);
        setEmail(storedEmail);
      }
    }, []);
    
    const getUserData = async (designation, studentNumber) => {
      try {
        // Construct the reference path
        const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
        
        // Retrieve the data
        const snapshot = await get(userRef);
    
        // Check if data exists
        if (snapshot.exists()) {
          // Extract the user data
          const userData = snapshot.val();
          // Return the relevant data such as Lastname and Firstname
          return {
            Lastname: userData.Lastname || '',
            Firstname: userData.Firstname || '',
            Middlename: userData.Middlename || '',
            // Add more properties as needed
          };
        } else {
          // Data does not exist
          console.log("No data available for the given student number.");
          return null;
        }
      } catch (error) {
        console.error("Error retrieving user data:", error.message);
        throw error;
      }
    };
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="officer-login-container">
        <div className="login-form-container-off">

        <Form>
      <div className="officer-login-form">
      <Link to="/">
              <Button
                variant="link"
                size="lg"
                className="button-homepage"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="xl"
                  style={{ color: "#000000" }}
                />
              </Button>
            </Link>
        <Form.Group className="mb-3" controlId="formDesignation">
          <Form.Label>Designation</Form.Label>
          <Form.Select
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            style={{ border: "1px solid black" }} // Add custom border style
          >
            <option value="">Select Designation</option>
            <option value="EXO">EXO</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
          </Form.Select>
        </Form.Group>
      
        <Form.Group className="mb-3" controlId="formStudentNumber">
          <Form.Label>Student Number</Form.Label>
          <Form.Control
             type="text"
             placeholder="Enter your student number"
             value={studentNumber}
             onChange={(e) => setStudentNumber(e.target.value)}
          />
        </Form.Group>
      
<Form.Group as={Row} className="mb-1" controlId="formPlaintextEmail">
  <Form.Label column sm="3">Email</Form.Label>
  <Col sm="15">
    <Form.Control
      id="email-input"
      type="email"
      placeholder="Enter your email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  </Col>
</Form.Group>

<Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
  <Form.Label column sm="3">Password</Form.Label>
  <Col sm="15">
    <InputGroup>
      <Form.Control
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{
          border: "1px solid #343a40",
          borderRadius: "1",
          height: "40px",
          width: "200px",
          fontSize: "18px",
        }}
      />
      <Button
        variant="outline-dark"
        onClick={togglePasswordVisibility}
        aria-label={showPassword ? "Hide Password" : "Show Password"}
        className="input-group-btn-small1"
      >
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
        />
      </Button>
    </InputGroup>
  </Col>
</Form.Group>

      </div>
    </Form>

          {/* Login Button */}
          <div className="login-container-button-cd">
            <button
              variant="success"
              size="lg"
              type="button"
              className="button-login-cd"
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-2"
                    style={{ marginRight: '8px' }}
                  />
                  <span className="spinner-text">Logging in...</span>
                </>
              ) : (
                'Login'
              )}
            </button>
          </div>
          <div className="cadet-login">
      <p>
        <a href="#" className="forgotpass" onClick={() => setShowForgotPasswordModal(true)}>
          Forgot Password
        </a>
      </p>

      
    </div>
     
          <ToastContainer />
        
        </div>
         {showForgotPasswordModal && (
        <div className="forgot-modal">
          <div className="custom-modal-content">
            <div className="custom-modal-header">
              <h5 className="modal-title">Forgot Password</h5>
              <button  onClick={() => setShowForgotPasswordModal(false)}>
                <span>&times;</span>
              </button>
            </div>
            <div className="custom-modal-body">
              <ForgotPassword />
            </div>
            <div className="custom-modal-footer">
              <button type="button" className="btn btn-primary" onClick={toggleForgotPasswordModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      </div>
    );
  }
  
export default Login;