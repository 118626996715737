import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set, remove, onValue } from "firebase/database";
import SideBar from "./AdminSideBar";
import "./AdminOfficerAuthorization.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'; // Import Link from React Router
import 'react-toastify/dist/ReactToastify.css';
import { auth, db } from '../Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Form, Row, Col, InputGroup, } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash, faXmark, faCheck  } from "@fortawesome/free-solid-svg-icons";
import { getStorage,ref as storageRef,uploadString,uploadBytes,getDownloadURL,} from "firebase/storage";

function AdminOfficerAuthorization() {
const [storedStudentNumber, setStoredStudentNumber] = useState("");
const [storedSelectedOption, setStoredSelectedOption] = useState("");
const [storedSelectedSchoolYear, setStoredSelectedSchoolYear] = useState('');
const [storedSelectedSemester, setStoredSelectedSemester] = useState('');
const [storedEmail, setStoredEmail] = useState('');
const [selectedOfficer, setSelectedOfficer] = useState(null);
const [showModal, setShowModal] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const [selectedDesignation, setSelectedDesignation] = useState(null);
const [showModalS1, setShowModalS1] = useState(false);
const [showModalEXO, setShowModalEXO] = useState(false);
const [showModalS2, setShowModalS2] = useState(false);

const toggleShowConfirmPassword = () => {
  setShowConfirmPassword(prevState => !prevState);
};

const toggleShowPassword = () => {
  setShowPassword(!showPassword);
};

const [selectedOfficerData, setSelectedOfficerData] = useState(null); // State to store selected officer data
const [selectedOfficerDesignation, setSelectedOfficerDesignation] = useState(""); // State to store selected officer designation

useEffect(() => {
  // Fetch and set officer data
  const fetchOfficerData = async () => {
    try {
      const officerRef = ref(db, 'Officer');
      const officerSnapshot = await get(officerRef);
      if (officerSnapshot.exists()) {
        setOfficerData(officerSnapshot.val());
      }
    } catch (error) {
      console.error('Error fetching officer data:', error.message);
    }
  };

  fetchOfficerData();
}, []);

const handleOpenModal = (officer, designation) => {
  setSelectedOfficerData(officer);
  setSelectedOfficerDesignation(designation);
};
const handleCloseModal1 = () => {
  setIsModalOpen1(false);
}
const handleCloseModal = () => {
  setSelectedOfficerData(null);
  setSelectedOfficerDesignation("");
  
};
const [showDescriptionModal, setShowDescriptionModal] = useState(false);

useEffect(() => {
const storedStudentNumber = localStorage.getItem('studentNumber');
setStoredStudentNumber(storedStudentNumber);

const storedSelectedOption = localStorage.getItem('selectedOption');
setStoredSelectedOption(storedSelectedOption);

const storedSelectedSchoolYear = localStorage.getItem('selectedSchoolYear');
setStoredSelectedSchoolYear(storedSelectedSchoolYear);

const storedSelectedSemester = localStorage.getItem('selectedSemester');
setStoredSelectedSemester(storedSelectedSemester);

}, []);
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const [studentNumber, setStudentNumber] = useState('');
const [Lastname, setLastname] = useState('');
const [Firstname, setFirstname] = useState('');
const [Middlename, setMiddlename] = useState('');
const [Course, setCourse] = useState('');
const [Gender, setGender] = useState('');
const [designation, setDesignation] = useState(''); // New state for designation
const [errorMessage, setErrorMessage] = useState('');
const [showPassword, setShowPassword] = useState(false);
const [loading, setLoading] = useState(false);
const navigate = useNavigate();
const [passwordStrength, setPasswordStrength] = useState('');


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const handleSignup = async () => {
  try {
    setLoading(true);

    // Check if the designation already exists in the database
    const designationRef = ref(db, `Officer/${designation}`);
    const designationSnapshot = await get(designationRef);
    if (designationSnapshot.exists()) {
      toast.error('Designation already exists in the database.');
      return; // Exit the function if the designation exists
    }

    // Check if the student number is already used by any other designation
    const studentNumberUsed = await checkStudentNumberUsed(studentNumber);
    if (studentNumberUsed) {
      toast.error('Student number is already used by another designation.');
      return; // Exit the function if the student number is already used
    }

    // Check if the email already exists in the database
    const emailRef = ref(db, 'Officer');
    const emailSnapshot = await get(emailRef);
    emailSnapshot.forEach((designationSnapshot) => {
      designationSnapshot.forEach((studentSnapshot) => {
        if (studentSnapshot.child('email').val() === email) {
          toast.error('Email already exists in the database.');
          return; // Exit the function if the email exists
        }
      });
    });

    const storage = getStorage();
    const imageRef = storageRef(storage, `user_images/${email}`);
    let imageURL = ""; // Initialize imageURL variable

    // Check if there is a selected image
    if (selectedImage) {
      // Upload the selected image
      const blob = await fetch(selectedImage).then((r) => r.blob());
      await uploadBytes(imageRef, blob);

      // Get the download URL of the uploaded image
      imageURL = await getDownloadURL(imageRef);
    }

    // Create user with email and password
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    setErrorMessage('');

    // Capitalize Firstname, Middlename, and Lastname
    const capitalizedFirstname = capitalizeFirstLetter(Firstname);
    const capitalizedMiddlename = capitalizeFirstLetter(Middlename);
    const capitalizedLastname = capitalizeFirstLetter(Lastname);

    // Prepare user data
    const userData = {
      email: email,
      Lastname: capitalizedLastname,
      Firstname: capitalizedFirstname,
      Middlename: capitalizedMiddlename,
      Course: Course,
      Gender: Gender,
      imageURL: imageURL // Add the imageURL to userData
    };

    // Store user data under the Officer node with the appropriate designation
    await set(ref(db, `Officer/${designation}/${studentNumber}`), userData);

    setErrorMessage('Signup request sent for approval.');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setStudentNumber('');
    setLastname('');
    setFirstname('');
    setMiddlename('');
    setCourse('');
    setGender('');
    setDesignation('');
    setSelectedImage(null); // Reset selected image after successful signup
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      toast.error('Email is already in use. Please use a different email.');
    } else {
      toast.error('Signup failed. Please try again.');
    }
  } finally {
    setLoading(false);
  }
};



// Function to check if the student number is already used by any other designation
const checkStudentNumberUsed = async (studentNumber) => {
  const designationRef = ref(db, 'Officer');
  const designationSnapshot = await get(designationRef);
  let studentNumberUsed = false;

  designationSnapshot.forEach((designation) => {
    designation.forEach((studentSnapshot) => {
      if (studentSnapshot.key !== designation && studentSnapshot.key === studentNumber) {
        studentNumberUsed = true;
        return true; // Exit the loop if the student number is found
      }
    });
  });

  return studentNumberUsed;
};

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

const handleSignupClick = () => {
  if (!email || !password || !confirmPassword || !studentNumber || !Lastname || !Firstname || !Middlename || !Course || !Gender || !designation) {
    toast.error('Please enter all fields.');
  } else if (password !== confirmPassword) {
    toast.error('Passwords do not match.');
  } else if (!isValidEmail(email)) {
    toast.error('Please enter a valid email address.');
  } else {
    if (passwordStrength === 'Weak') {
      toast.error('Password is weak. Please ensure it meets the strength requirements.');
    } else if (passwordStrength === 'Medium') {
      toast.warn('Password is medium. Consider making it stronger.');
    } else {
      handleSignup();
    }
  }
};


const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
const [officerData, setOfficerData] = useState(null); // State to store officer data

useEffect(() => {
  // Fetch and set officer data
  const fetchOfficerData = async () => {
    try {
      const officerRef = ref(db, 'Officer');
      const officerSnapshot = await get(officerRef);
      if (officerSnapshot.exists()) {
        setOfficerData(officerSnapshot.val());
      }
    } catch (error) {
      console.error('Error fetching officer data:', error.message);
    }
  };

  fetchOfficerData();
}, []);
const handleDelete = async (designation, studentNumber) => {
  try {
    const user = auth.currentUser;
    if (user) {
      // Remove the officer data from the database
      await remove(ref(db, `Officer/${designation}/${studentNumber}`));

      // Delete the user from authentication
      await user.delete();

      // Display success message
      toast.success('Officer data and authentication deleted successfully.');
    } else {
      toast.error('User not found.');
    }
  } catch (error) {
    console.error('Error deleting officer data and authentication:', error.message);
    toast.error('Failed to delete officer data and authentication.');
  }
};


const checkPasswordStrength = (password) => {
  // Password strength criteria: minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
  const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
  
  if (strongRegex.test(password)) {
    return 'Strong';
  } else if (mediumRegex.test(password)) {
    return 'Medium';
  } else {
    return 'Weak';
  }
};

const defaultImageUrl = 'https://example.com/default-image.jpg'; // Replace with your default image URL
const [selectedImage, setSelectedImage] = useState("/Admin/Default_pfp.svg.png");

const handleImageUpload = async (e) => {
  const file = e.target.files[0];
  if (file) {
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        setSelectedImage(event.target.result);

        const storage = getStorage();
        const imageRef = storageRef(storage, `user_images/${email}`);
        const blob = await fetch(event.target.result).then((r) => r.blob());
        await uploadBytes(imageRef, blob);

        const imageURL = await getDownloadURL(imageRef);
        setUploadedImageURL(imageURL);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  } else {
    // If no file is selected, set the default image
    setSelectedImage("/Admin/Default_pfp.svg.png");
  }
};


  const [userImageUrl, setUserImageUrl] = useState(() => {
    // Try to retrieve the user image URL from local storage
    const storedUserImageUrl = localStorage.getItem("userImageUrl");
    return storedUserImageUrl || ""; // Initialize with an empty string if not found
  });

  const [uploadedImageURL, setUploadedImageURL] = useState(null);
  const handleLogoutClick = () => {
    setIsModalOpen1(true);
    // Add any additional logic you want to execute when logout is clicked
  };
  

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  return (
      <div className="Admin-authority">
   <SideBar
    selectedOption={storedSelectedOption}
    studentNumber={storedStudentNumber}
    selectedSchoolYear={storedSelectedSchoolYear}
    selectedSemester={storedSelectedSemester}
    email={storedEmail} // Add the email prop here
/>
      <div className="Admin-authority-containerre3">
         
        <h1  className="Admin-authority-heading">Authorized Personel</h1>
  
        <Link to="/Admin/AdminAuthority" className="admin-officer-auth-link">
  <button class="animated-button">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="arr-2"><path d="M48 48h88c13.3 0 24-10.7 24-24s-10.7-24-24-24H32C14.3 0 0 14.3 0 32V136c0 13.3 10.7 24 24 24s24-10.7 24-24V48zM175.8 224a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-26.5 32C119.9 256 96 279.9 96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3zm368 80c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6h56.1zm-89.4 0c-8.6-24.3-29.9-42.6-55.9-47c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24zM464 224a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-80-32a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM504 48h88v88c0 13.3 10.7 24 24 24s24-10.7 24-24V32c0-17.7-14.3-32-32-32H504c-13.3 0-24 10.7-24 24s10.7 24 24 24zM48 464V376c0-13.3-10.7-24-24-24s-24 10.7-24 24V480c0 17.7 14.3 32 32 32H136c13.3 0 24-10.7 24-24s-10.7-24-24-24H48zm456 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H608c17.7 0 32-14.3 32-32V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v88H504z"/></svg>
    <span class="text">Scanner</span>
    <span class="circle"></span>
    <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
      <path
        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
      ></path>
    </svg>
  </button>
</Link>


      <div className="officer-login-container-su">
      <div className="login-form-container-su">
      <h2>Create Account</h2>

      <div className="image-upload-container">
      <Col sm="15">
        <div className="d-flex justify-content-center">
        
          <Form.Control
            type="file"
            id="ImageUpload"
            accept="image/*"
            onChange={handleImageUpload}
          />
            <img src={selectedImage} alt="Selected Image" style={{ maxWidth: "100%" }} />
        </div>
        
      </Col>
    </div>


  <Form>
    <Form.Group as={Row} className="mb-1" controlId="formDesignation">
      <Form.Label column sm="3">
        Designation
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          style={{
            border: "1px solid #343a40",
          }}
        >
          <option value="" disabled hidden>Select Designation</option>
          <option value="EXO">EXO</option>
          <option value="S1">S1</option>
          <option value="S2">S2</option>
        </Form.Control>
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-1" controlId="formPlaintextEmail">
        <Form.Label column sm="3">
          Email
        </Form.Label>
        <Col sm="9">
          <Form.Control
            id="email-input"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-describedby="passwordHelpBlock"
            style={{
              border: "1px solid #343a40",
            }}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="3">
          Password
        </Form.Label>
        <Col sm="9">
          <InputGroup>
            <Form.Control
              id="password-input"
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              style={{
                border: "1px solid #343a40",
                borderRadius: "1",
                height: "40px",
                width: "200px",
                fontSize: "18px",
              }}
            />
          <Button
            variant="outline-dark"
            onClick={toggleShowPassword}
            aria-label={showPassword ? 'Hide Password' : 'Show Password'}
            className="show-eye-button"
            style={{
              border: "1px solid #343a40",
              marginTop: "5px",
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
          </Button>
        </InputGroup>
              {/* Conditional rendering for password strength indicator and requirements */}
      {password && (
        <>
          <div className="password-strength-indicator">
            <span className="password-strength-label">Strength:</span>
            {passwordStrength === 'Strong' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon strong" />}
            {passwordStrength === 'Medium' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon medium" />}
            {passwordStrength === 'Weak' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon weak" />}
            <span className={`password-strength-text ${passwordStrength.toLowerCase()}`}>{passwordStrength}</span>
          </div>
          <div className="password-requirements">
            <p>Password must contain:</p>
            <ul>
              <li style={{ color: password.length >= 8 ? 'green' : 'red' }}>At least 8 characters</li>
              <li style={{ color: /[A-Z]/.test(password) ? 'green' : 'red' }}>At least one uppercase letter</li>
              <li style={{ color: /[a-z]/.test(password) ? 'green' : 'red' }}>At least one lowercase letter</li>
              <li style={{ color: /\d/.test(password) ? 'green' : 'red' }}>At least one number</li>
              <li style={{ color: /[!@#$%^&*]/.test(password) ? 'green' : 'red' }}>At least one special character (!@#$%^&*)</li>
            </ul>
          </div>
        </>
      )}
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="password-input">
      <Form.Label column sm="3">
        Confirm Password
      </Form.Label>
      <Col sm="9">
      <InputGroup>
        <Form.Control
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{
                border: "1px solid #343a40",
                height: "40px",
                width: "200px",
                fontSize: "18px",
              }}
        />
              <Button 
              onClick={toggleShowConfirmPassword}
              variant="outline-dark"
              aria-label={showConfirmPassword ? "Hide Password" : "Show Password"}
              className="show-eye-button"
              style={{
                border: "1px solid #343a40",
                marginTop: "5px",
              }}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </Button>
              </InputGroup>
              {confirmPassword && password !== confirmPassword && (
      <p style={{ color: "red" }}>Passwords do not match <FontAwesomeIcon icon={faXmark} style={{ color: "red" }}/></p>
    )}
    {confirmPassword && password == confirmPassword && (
      <p style={{ color: "green" }}>Password Match <FontAwesomeIcon icon={faCheck} style={{ color: "green" }}></FontAwesomeIcon></p>
    )}
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-1" controlId="formStudentNumber">
      <Form.Label column sm="3">
        Student Number
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          value={studentNumber}
          onChange={(e) => setStudentNumber(e.target.value)}
          placeholder="e.x. 2020-12345-LQ-0"
          style={{
            border: "1px solid #343a40",
          }}
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formName">
      <Form.Label column sm="3">
        Lastname
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          value={Lastname}
          onChange={(e) => setLastname(e.target.value)}
          placeholder="Dalisay"
          style={{
            border: "1px solid #343a40",
          }}
        />
      </Col>
      <Form.Label column sm="3">
        Firstname
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          value={Firstname}
          onChange={(e) => setFirstname(e.target.value)}
          placeholder="Ricardo"
          style={{
            border: "1px solid #343a40",
          }}
        />
      </Col>
      <Form.Label column sm="3">
        Middlename
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="text"
          value={Middlename}
          onChange={(e) => setMiddlename(e.target.value)}
                          placeholder="Tanggol"
          style={{
            border: "1px solid #343a40",
          }}
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formCourseGender">
      <Form.Label column sm="3">
        Course
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          value={Course}
          onChange={(e) => setCourse(e.target.value)}
          style={{
            border: "1px solid #343a40",
          }}
        >
          <option value="" disabled hidden>Select Course</option>
          <option value="BEED">BEED</option>
          <option value="BPA">BPA</option>
          <option value="BPA-FA">BPA-FA</option>
          <option value="BPA-FM">BPA-FM</option>
          <option value="BSA">BSA</option>
          <option value="BSAM">BSAM</option>
          <option value="BSBA-FM">BSBA-FM</option>
          <option value="BSBIO">BSBIO</option>
          <option value="BSBA-MM">BSBA-MM</option>
          <option value="BSCE">BSCE</option>
          <option value="BSED-MT">BSED-MT</option>
          <option value="BSEE">BSEE</option>
          <option value="BSHM">BSHM</option>
          <option value="BSIT">BSIT</option>
          <option value="BSND">BSND</option>
          <option value="BSOA">BSOA</option>
          <option value="DCPET">DCPET</option>
          <option value="DCVET">DCVET</option>
          <option value="DEET">DEET</option>
          <option value="DIT">DIT</option>
          <option value="DOMT-LOM">DOMT-LOM</option>
          <option value="DOMT-MOM">DOMT-MOM</option>
          <option value="BSARCHI">BSARCHI</option>
          {/* Add other options here */}
        </Form.Control>
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formCourseGender">
      <Form.Label column sm="3">
        Gender
      </Form.Label>
      <Col sm="9">
        <Form.Control
          as="select"
          value={Gender}
          onChange={(e) => setGender(e.target.value)}
          style={{
            border: "1px solid #343a40",
          }}
        >
          <option value="" disabled hidden>Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </Form.Control>
      </Col>
    </Form.Group>

    <div className="login-container-button-cd">
      <button
      variant="success"
      size="lg"
      type="button"
      className="button-login-cd"
        onClick={handleSignupClick}
        disabled={loading}
      >
        {loading ? (
          <>
            <Spinner
              animation="border"
              size="sm"
              className="me-2"
              style={{ marginRight: '8px' }}
            />
            <span className="spinner-text">Submitting...</span>
          </>
        ) : (
          <p>Submit</p>
        )}
      </button>
    </div>
    <ToastContainer />
  </Form>
</div>
<div className='officer-designation'>
  {officerData && (
    <div className="officer-data-container">
      <h2 className="section-title">Designated Officer</h2>
      <div className="table-container">
        <table className="officer-table">
          <tbody>
            <tr>
              <td>EXO</td>
              <td>
                {officerData['EXO'] ? (
                  Object.keys(officerData['EXO']).map((studentNumber) => (
                  <button 
                    className="officer-view-btn" 
                    key={studentNumber} 
                    onClick={() => {
                      handleOpenModal(officerData['EXO'][studentNumber], 'EXO');
                      setShowModalEXO(true);
                      setShowModalS1(false); 
                      setShowModalS2(false);// Set showModalS1 to false when clicking the 'View' button for EXO // Set showModalEXO to true when the button is clicked
                    }}
                  >
                    View
                  </button>
                  ))
                ) : (
                  <span>Designate an officer</span>
                )}

                {showModalEXO && (
  <div className="custom-modal-schedfe3">
    <div className="custom-modal-content-sched2e">
      <div className="custom-modal-header-sched2e">
        <span className="custom-close2" onClick={handleCloseModal}></span>
        <h2 className='officer-info1'> EXO (Executive Officer)</h2>
      </div>
      <div className="custom-modal-body-sched1f">
        <p>
        The Executive Officer's role in AROTC is multifaceted, involving both administrative and operational responsibilities. This position is instrumental in ensuring the overall success and functionality of the cadet unit, contributing to the development of effective and future Army leaders.        </p>
      </div>
      <div className="custom-modal-footer">
        {/* Add footer content here if needed */}
      </div>
    </div>
  </div>
)}
              </td>
            </tr>
            <tr>
            <td>S1</td>
            <td>
            {officerData['S1'] ? (
  Object.keys(officerData['S1']).map((studentNumber) => (
      <button 
        className="officer-view-btn" 
        key={studentNumber} 
        onClick={() => {
          handleOpenModal(officerData['S1'][studentNumber], 'S1');
          setShowModalS1(true);
          setShowModalEXO(false);
          setShowModalS2(false);// Set showModalS1 to true when the button is clicked
        }}
      >
        View
      </button>
  ))
) : (
  <span>Designate an officer</span>
)}
{showModalS1 && (
  <div className="custom-modal-schedfe3">
    <div className="custom-modal-content-sched1">
      <div className="custom-modal-header-sched1">
        <span className="custom-close1" onClick={handleCloseModal}></span>
        <h2 className='officer-info1'> S1 (Personnel Officer)</h2>
      </div>
      <div className="custom-modal-body-sched1">
        <p>
           Responsible for administrative duties of BN such manifests and transportation rosters, etc. Plans, organizes, and supervises the execution of battalion awards ceremonies, change of command ceremonies, commissioning ceremonies and social events
        </p>
      </div>
      <div className="custom-modal-footer">
        {/* Add footer content here if needed */}
      </div>
    </div>
  </div>
)}
    </td>
            </tr>
            <tr>
              <td>S2</td>
              <td>
                {officerData['S2'] ? (
                  Object.keys(officerData['S2']).map((studentNumber) => (
                <button 
                  className="officer-view-btn" 
                  key={studentNumber} 
                  onClick={() => {
                    handleOpenModal(officerData['S2'][studentNumber]);
                    setShowModalS2(true);
                    setShowModalS1(false);
                    setShowModalEXO(false);// Set showModalS2 to true when clicking the 'View' button for S2
                  }}
                >
                  View
                </button>

                  ))
                ) : (
                  <span>Designate an officer</span>
                )}
                {showModalS2 && (
  <div className="custom-modal-schedfe3">
    <div className="custom-modal-content-sched3">
      <div className="custom-modal-header-sched3">
        <span className="custom-close1" onClick={handleCloseModal}></span>
        <h2 className='officer-info1'> S2 (Intelligence Officer)</h2>
      </div>
      <div className="custom-modal-body-sched3">
        <p>
 Supervises battalion physical security. Provides terrain analysis, light and weather data, and updates on events that could impact training. Serves on event staff for planning and conducting battalion training events.        </p>
      </div>
      <div className="custom-modal-footer">
        {/* Add footer content here if needed */}
      </div>
    </div>
  </div>
)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )}
{selectedOfficerData && (
  <div className={`custom-modal-sched ${selectedOfficerData ? 'show' : ''}`}>
    <div className="custom-modal-content-schede1">
      <div className="custom-modal-header-sched">
        <span className="custom-close" onClick={handleCloseModal}></span>
        <h2 className='officer-info'>Officer's Information</h2>
      </div>
      {Object.keys(officerData).map((designation) => (
        Object.keys(officerData[designation]).map((studentNumber) => {
          const officer = officerData[designation][studentNumber];
          if (officer.email === selectedOfficerData.email && officer.Lastname === selectedOfficerData.Lastname && officer.Firstname === selectedOfficerData.Firstname && officer.Middlename === selectedOfficerData.Middlename && officer.Course === selectedOfficerData.Course && officer.Gender === selectedOfficerData.Gender && officer.status === selectedOfficerData.status) {
            return (
              <div key={studentNumber}>
                <p>Email: {selectedOfficerData.email}</p>
                <p>Student Number: {studentNumber}</p>
                <p>Lastname: {selectedOfficerData.Lastname}</p>
                <p>Firstname: {selectedOfficerData.Firstname}</p>
                <p>Middlename: {selectedOfficerData.Middlename}</p>
                <p>Course: {selectedOfficerData.Course}</p>
                <p>Gender: {selectedOfficerData.Gender}</p>
                <p>Status: {selectedOfficerData.status}</p>
                <button onClick={handleLogoutClick}>Delete</button>
              </div>
            );
          }
          return null;
        })
      ))}
    </div>
  </div>
)}
{isModalOpen1 && (
<div className="CustomCard1">
  <div className="CustomCard">
    <div className="card-content">
      <p className="card-heading">Delete</p>
      <p className="card-description">Are you sure you want to Delete?</p>
    </div>
    <div className="card-button-wrapper">
      <button className="card-button secondary" onClick={handleCloseModal1}>No</button>
      <button className="card-button primary" onClick={() => handleDelete(selectedOfficerDesignation, studentNumber)}>Yes</button>
    </div>
    <button className="exit-button" onClick={handleCloseModal1}>
      <svg height="20px" viewBox="0 0 384 512">
        <path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        ></path>
      </svg>
    </button>
  </div>
</div>
)}
</div>
    </div>
</div>
       </div>
  
   );
  }

export default AdminOfficerAuthorization;