import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function PieChart({ successfulCount, existingCount, unsuccessfulCount }) {
  const chartRef = useRef(null);
  let chartInstance = null;

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance) {
        chartInstance.destroy(); // Destroy the previous chart instance
      }
      
      const ctx = chartRef.current.getContext('2d');
      chartInstance = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Successful', 'Existing', 'Unsuccessful'],
          datasets: [{
            label: 'Emails',
            data: [successfulCount, existingCount, unsuccessfulCount],
            backgroundColor: [
              'rgba(0, 255, 0, 0.6)', // Green for successful
              '#4a94df',                // Blue #4a94df for existing
              'rgba(255, 0, 0, 0.6)',   // Red for unsuccessful
            ],
            borderColor: [
              'rgba(0, 255, 0, 1)',     // Green for successful
              '#4a94df',                 // Blue #4a94df for existing
              'rgba(255, 0, 0, 1)',     // Red for unsuccessful
            ],
            borderWidth: 1,
          }],
        },
        options: {
          responsive: false,
          plugins: {
            legend: {
              position: 'top',
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Clean up the chart instance when the component unmounts
      }
    };
  }, [successfulCount, existingCount, unsuccessfulCount]);

  return <canvas ref={chartRef} width={370} height={370} />;
}

export default PieChart;
