import React, { useEffect, useState } from 'react';
import { db } from '../Firebase';
import { ref, get, set } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import './QRScannerSideBar.css';

function QRScannerSideBar({ studentNumber  }) {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedTrainingDay, setSelectedTrainingDay] = useState([]);
  const [trainingDayKey, setTrainingDayKey] = useState('');
  const [minimized, setMinimized] = useState(false);
  const [icon, setIcon] = useState(faAngleLeft);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          for (const key in usersData) {
            if (usersData[key].studentNumber === studentNumber) {
              setUserData(usersData[key]); // Set the user data corresponding to the student number
              break;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, [studentNumber]);
  
  const handleLogout = async () => {
    // Show confirmation dialog before logging out
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      try {
        const logoutRef = ref(
          db,
          `Scanner/${selectedSchoolYear}/${selectedSemester}/TrainingDates/${trainingDayKey}/${studentNumber}`
        );
        await set(logoutRef, { status: "Log-Out" });
        console.log('Logout status updated successfully');

        // Clear local storage
        localStorage.removeItem('studentNumber');
        localStorage.removeItem('selectedSchoolYear');
        localStorage.removeItem('selectedSemester');
        localStorage.removeItem('selectedTrainingDay');
        localStorage.removeItem('trainingDayKey');

        // Navigate to login page
        navigate('/Admin/QRScannerLogin');
      } catch (error) {
        console.error('Error updating logout status:', error.message);
      }
    }
  };

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  const [sidebarStudentNumber, setSidebarStudentNumber] = useState('');
  // Retrieving the student number from local storage on component mount
  useEffect(() => {
    const storedStudentNumber = localStorage.getItem('studentNumber');
    if (storedStudentNumber) {
      setSidebarStudentNumber(storedStudentNumber);
    }
  }, []);
  useEffect(() => {
    const storedStudentNumber = localStorage.getItem('studentNumber');
    if (storedStudentNumber) {
      // Set the student number from local storage
      setSidebarStudentNumber(storedStudentNumber);
    }
    
    const storedSchoolYear = localStorage.getItem('selectedSchoolYear');
    if (storedSchoolYear) {
      setSelectedSchoolYear(storedSchoolYear);
    }
  
    const storedSemester = localStorage.getItem('selectedSemester');
    if (storedSemester) {
      setSelectedSemester(storedSemester);
    }
  
    const storedTrainingDay = localStorage.getItem('selectedTrainingDay');
    if (storedTrainingDay) {
      setSelectedTrainingDay(JSON.parse(storedTrainingDay));
    }

    const storedTrainingDayKey = localStorage.getItem('trainingDayKey');
    if (storedTrainingDayKey) {
      setTrainingDayKey(storedTrainingDayKey);
    }
  }, []);
  
  const handleLogoClick = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  return (
    <div className={`sidebarAdmin12 ${minimized ? "minimized" : ""}`}>
      <div
        className={`logo-image ${minimized ? "minimized" : ""}`}
        onClick={handleLogoClick}
      ></div>
      <hr className="line-separator" />
      {!minimized && (
        <>
        <div className='scanner-data'>
          <p>Student Number: {studentNumber}</p>
          <p>School Year: {selectedSchoolYear}</p>
          <p>Semester: {selectedSemester}</p>
          <p>Training Day Key: {trainingDayKey}</p> {/* Displaying the trainingDayKey */}

          {userData && (
            <div>
              <p>Email: {userData.email}</p>
              <p>Name : {userData.Lastname}, {userData.Firstname} {userData.Middlename} </p>
              <p>Course : {userData.Course}</p>
            </div>
          )}
          </div>
        </>
      )}
      <div className="minimize-btn-container-scan">
      <button className="minimize-btn-admin-scan" onClick={toggleMinimized}>
      <FontAwesomeIcon icon={icon} />
      </button>
      </div>
      <button onClick={handleLogout}>
      <FontAwesomeIcon icon={faSignOutAlt} />
    <span>Logout</span>
        </button>
    </div>
  );
}

export default QRScannerSideBar;
