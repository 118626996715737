import React, { useState, useEffect, useRef } from 'react';
import { db } from '../Firebase'; // Import the Firebase database object
import { getDatabase, ref, get, set } from 'firebase/database'; // Import the necessary Firebase database function
import { app } from '../Firebase';
import { BrowserMultiFormatReader } from '@zxing/library';
import { ref as dbRef, onValue } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import "./QRScanner2.0.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify css
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {  faHome } from "@fortawesome/free-solid-svg-icons";
import QRScannerSideBar from './QRScannerSideBar';
import { Modal, Button } from 'react-bootstrap';


function QRCodeScanner2() {
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSchoolNumber, setSelectedSchoolNumber] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTrainingDay, setSelectedTrainingDay] = useState(null);
  const [existingTrainingDates, setExistingTrainingDates] = useState(Array.from({ length: 15 }, () => ''));
  const [updatedTrainingDates, setUpdatedTrainingDates] = useState({});
  const [scannerEnabled, setScannerEnabled] = useState(false);
  const [scannedData, setScannedData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); // Define errorMessage state variable
  const [isMinimized, setIsMinimized] = useState(false);
  const [sidebarStudentNumber, setSidebarStudentNumber] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowModal(window.innerWidth <= 830);
    };

    window.addEventListener('resize', handleResize);

    // Check on initial render
    handleResize();

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMinimize = () => {
    setIsMinimized(prevState => !prevState);
  };

  
const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const twelveHourFormat = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedTime = `${twelveHourFormat}:${minutes}:${seconds} ${amOrPm}`;
  return formattedTime;
};

  
  useEffect(() => {
    if (scannedData) {
      checkEnrollment(scannedData.studentNumber);
    }
  }, [scannedData, enrolledStudents]);

  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      if (selectedSchoolYear && selectedSemester) {
        try {
          const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
          const userSemesterSnapshot = await get(userSemesterRef);
          if (userSemesterSnapshot.exists()) {
            const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
            setEnrolledStudents(enrolledStudentsData);
            fetchUserUIDs(enrolledStudentsData);
          } else {
            setEnrolledStudents([]);
            setStudentUIDs({});
          }
        } catch (error) {
          console.error('Error fetching enrolled students:', error.message);
        }
      }
    };

    fetchEnrolledStudents();
  }, [selectedSchoolYear, selectedSemester]);

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(db, 'enrolled');
        const schoolYearsSnapshot = await get(schoolYearsRef);
        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);

  useEffect(() => {
    let filtered = enrolledStudents.filter(
      (student) =>
        student.studentNumber.includes(searchQuery) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Firstname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Lastname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.studentNumber || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Middlename || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Course || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedSchoolNumber) {
      filtered = filtered.filter((student) => student.studentNumber.includes(selectedSchoolNumber));
    }
    if (selectedCourse) {
      filtered = filtered.filter((student) => userDetails[studentUIDs[student.studentNumber]].Course.includes(selectedCourse));
    }
    if (selectedGender) {
      filtered = filtered.filter((student) => userDetails[studentUIDs[student.studentNumber]].Gender.includes(selectedGender));
    }

    setFilteredStudents(filtered);
  }, [searchQuery, enrolledStudents, userDetails, studentUIDs, selectedSchoolNumber, selectedCourse, selectedGender]);

  const isTrainingDateEnabled = (trainingDate) => {
    if (!trainingDate) {
      return false;
    }
    const currentDate = new Date();
    const selectedTrainingDate = new Date(trainingDate);
    return (
      currentDate.getFullYear() === selectedTrainingDate.getFullYear() &&
      currentDate.getMonth() === selectedTrainingDate.getMonth() &&
      currentDate.getDate() === selectedTrainingDate.getDate()
    );
  };



  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber,
    }));
  };

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map((student) => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach((studentNumber) => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid];
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };
  useEffect(() => {
    if (scannerEnabled) {
      startScanner();
    }
  }, [scannerEnabled]);

  
  const startScanner = async () => {
    try {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();

        // Check if the current time is 9:01:00 AM
        if (currentHour === 9 && currentMinute === 1) {
            console.log("Scanning not allowed at 9:01:00 AM");
            return;
        }

        const codeReader = new BrowserMultiFormatReader();
        const videoInputDevices = await codeReader.getVideoInputDevices();

        if (videoInputDevices && videoInputDevices.length > 0) {
            // Find the rear camera if available
            const rearCamera = videoInputDevices.find(device => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear'));

            // Select the rear camera if available, else use the first available device
            const deviceId = rearCamera ? rearCamera.deviceId : videoInputDevices[0].deviceId;

            while (scannerEnabled) { // Continuously scan while scanner is enabled
                try {
                    const result = await codeReader.decodeFromInputVideoDevice(deviceId, 'video');

                    // Handle the QR code result
                    handleQrCodeResult(result.text);
                } catch (err) {
                    console.error('Error while decoding QR code:', err);
                }
            }
        } else {
            console.error('No video input devices found.');
        }
    } catch (error) {
        console.error('Error initializing QR code scanner:', error);
    }
};

const preventScanningAtNine = () => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const currentSecond = currentDate.getSeconds();

  if (currentHour === 9 && currentMinute === 1 && currentSecond === 0) {
      console.log("Scanning not allowed at 9:01:00 AM");
      return false;
  }

  return true;
};

// Modify your function invocation to integrate the prevention of scanning at 9:01:00 AM
const startScannerWithRestriction = async () => {
  if (!preventScanningAtNine()) {
      return;
  }

  await startScanner();
};

  const saveQRCodeDataToDatabase = async (qrCodeData) => {
    try {
        const db = getDatabase(app);

        // Extract relevant data from the QR code
        const dataLines = qrCodeData.split('\n');
        const studentNumberLine = dataLines[0];
        const schoolYearLine = dataLines[1];
        const semesterLine = dataLines[2];

        // Define regular expressions to extract data
        const studentNumberRegex = /Student Number: (\d{4}-\d{5}-\w{2}-\d)/;
        const schoolYearRegex = /School Year: (\d{4}-\d{4})/;
        const semesterRegex = /Semester: (First Semester|Second Semester)/;

        // Extract data using regular expressions
        const studentNumberMatch = studentNumberLine.match(studentNumberRegex);
        const schoolYearMatch = schoolYearLine.match(schoolYearRegex);
        const semesterMatch = semesterLine.match(semesterRegex);

        if (studentNumberMatch && schoolYearMatch && semesterMatch) {
            const studentNumber = studentNumberMatch[1];
            const schoolYear = schoolYearMatch[1];
            const semester = semesterMatch[1];

            // Define the database path for checking if student number exists
            const studentCheckPath = `Session/${schoolYear}/${semester}/Trainingdates/${selectedTrainingDayData.day} : ${selectedTrainingDayData.date}/${studentNumber}`;

            // Get a reference to the database location for the student number check
            const studentCheckRef = ref(db, studentCheckPath);

            // Check if the student number already exists in the database
            const studentCheckSnapshot = await get(studentCheckRef);
            if (studentCheckSnapshot.exists()) {
                // Display toast indicating the student number is already recorded
                toast.error(`Student Number ${studentNumber} is already recorded. Data will not be saved in the database session.`);
                return; // Exit function without saving data
            }

            // Define the database path for storing QR code data
            const qrCodePath = `Session/${schoolYear}/${semester}/Trainingdates/${selectedTrainingDayData.day} : ${selectedTrainingDayData.date}/${studentNumber}`;

            // Get a reference to the database location
            const qrCodeRef = ref(db, qrCodePath);

            // Construct the data object based on Time-In or Time-Out event
            const getEventData = () => {
                if (getRemarks().startsWith("Time-In")) {
                    return {
                        "Time In": {
                            appearance: getRemarks().endsWith("Late") ? "Late" : "Present",
                            time: getCurrentTime(),
                        }
                    };
                } else if (getRemarks().startsWith("Time-Out")) {
                    return {
                        "Time Out": {
                            appearance: "Present",
                            time: getCurrentTime(),
                        }
                    };
                }
            };

            // Get the event data based on Time-In or Time-Out
            const eventData = getEventData();

            // Save the event data to the database
            await set(qrCodeRef, eventData);

            console.log('QR code data saved to database successfully.');
        } else {
            console.error('Invalid QR code format.');
        }
    } catch (error) {
        console.error('Error saving QR code data to database:', error.message);
    }
};


  const [scannedDataArray, setScannedDataArray] = useState([]);
  const handleQrCodeResult = async (qrCodeData) => {
    const dataLines = qrCodeData.split('\n');
    const studentNumberLine = dataLines[0];
    const schoolYearLine = dataLines[1];
    const semesterLine = dataLines[2];

    const studentNumberRegex = /Student Number: (\d{4}-\d{5}-\w{2}-\d)/;
    const schoolYearRegex = /School Year: (\d{4}-\d{4})/;
    const semesterRegex = /Semester: (First Semester|Second Semester)/;

    const studentNumberMatch = studentNumberLine.match(studentNumberRegex);
    const schoolYearMatch = schoolYearLine.match(schoolYearRegex);
    const semesterMatch = semesterLine.match(semesterRegex);

    if (studentNumberMatch && schoolYearMatch && semesterMatch) {
        const scannedStudentNumber = studentNumberMatch[1];
        const scannedSchoolYear = schoolYearMatch[1];
        const scannedSemester = semesterMatch[1];

        const newScannedData = {
            studentNumber: scannedStudentNumber,
            schoolYear: scannedSchoolYear,
            semester: scannedSemester,
        };

        setScannedDataArray(prevScans => [...prevScans, newScannedData]);

        // Check if the scanned data is valid before proceeding
        if (isValidScannedData(newScannedData)) {
            // Define the database path for checking if student number exists
            const studentCheckPath = `Session/${scannedSchoolYear}/${scannedSemester}/Trainingdates/${selectedTrainingDayData.day} : ${selectedTrainingDayData.date}/${scannedStudentNumber}`;

            // Get a reference to the database location for the student number check
            const studentCheckRef = ref(getDatabase(app), studentCheckPath);

            // Check if the student number already exists in the database
            const studentCheckSnapshot = await get(studentCheckRef);

            if (studentCheckSnapshot.exists()) {
                // Student number already recorded, show error toast
                toast.error(`Student Number ${scannedStudentNumber} is already recorded. Data will not be saved in the database session.`);
                return;
            }

            // Save the QR code data to the database
            saveQRCodeDataToDatabase(qrCodeData);
            // Show success toast
            toast.success(`Student Number: ${scannedStudentNumber}\n School Year: ${scannedSchoolYear}\n Semester: ${scannedSemester}`);
        } else {
            toast.error(`Student Number: ${scannedStudentNumber}\n School Year: ${scannedSchoolYear}\n Semester: ${scannedSemester} is invalid`);
        }

        displayStudentDetails(scannedStudentNumber);
    } else {
        toast.error('Invalid QR code format.');
    }
};

  
  const isValidScannedData = (scannedData) => {
    // Check if scanned data matches selected school year and semester
    const isSchoolYearValid = scannedData.schoolYear === selectedSchoolYear;
    const isSemesterValid = scannedData.semester === selectedSemester;

    // Check if the student is enrolled
    const isStudentEnrolled = enrolledStudents.some((student) => student.studentNumber === scannedData.studentNumber);

    return isSchoolYearValid && isSemesterValid && isStudentEnrolled;
  };

  const turnOffScanner = () => {
    const turnOffTime = new Date();
    turnOffTime.setHours(9);
    turnOffTime.setMinutes(1);
    turnOffTime.setSeconds(0);
  
    // Get the time remaining until 9:01:00
    const timeUntilTurnOff = turnOffTime - Date.now();
  
    // Check if the turn off time is in the future
    if (timeUntilTurnOff > 0) {
      setTimeout(() => {
        setScannerEnabled(false); // Turn off the scanner after the specified time
      }, timeUntilTurnOff);
    } else {
      setScannerEnabled(false); // Turn off the scanner immediately if it's already past 9:01:00
    }
  };
  
const turnOnScanner = () => {
    const storedSemester = localStorage.getItem('selectedSemester');
    const storedSchoolYear = localStorage.getItem('selectedSchoolYear');

    let errorMessage = '';

    if (!storedSemester) {
        errorMessage += 'Please select semester.\n';
    }

    if (!storedSchoolYear) {
        errorMessage += 'Please select school year.\n';
    }

    if (!filteredTrainingDays.length) {
        errorMessage += 'No available training days.';
    } else if (!selectedTrainingDay) {
        errorMessage += 'Please select training day.';
    }

    if (errorMessage) {
        toast.error(errorMessage);
        return;
    }

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentSecond = currentTime.getSeconds();

    // Check if the current time is 9:01:00 AM
    if (currentHour === 9 && currentMinute === 1 && currentSecond === 0) {
        toast.error(`Scanner is not available at 9:01:00 AM.`);
        return;
    }

    // Check other time constraints
    if (
        (currentHour === 9 && currentMinute >= 1) ||
        (currentHour > 9 && currentHour < 15) ||
        (currentHour === 15 && currentMinute <= 30) ||
        (currentHour === 19 && currentMinute >= 1) ||
        (currentHour >= 20 && currentHour < 4) ||
        (currentHour === 4 && currentMinute <= 30)
    ) {
        const formattedHour = currentHour < 10 ? `0${currentHour}` : currentHour;
        const formattedMinute = currentMinute < 10 ? `0${currentMinute}` : currentMinute;
        const formattedSecond = currentSecond < 10 ? `0${currentSecond}` : currentSecond;

        toast.error(`Scanner is not available at ${formattedHour}:${formattedMinute}:${formattedSecond}.`);
        turnOffScanner();
        return;
    }

    setScannerEnabled(true);
};

  

  async function fetchExistingData(selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates) {
    try {
      const db = getDatabase(app);
  
      const trainingDatesRef = ref(
        db,
        `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
      );
  
      const snapshot = await get(trainingDatesRef);
  
      if (snapshot.exists()) {
        const trainingDatesData = snapshot.val();
        console.log('Existing Training Dates:', trainingDatesData);
        setExistingTrainingDates(trainingDatesData || Array.from({ length: 15 }, () => ''));
      } else {
        console.log('No existing training dates found.');
        setExistingTrainingDates(Array.from({ length: 15 }, () => ''));
      }
    } catch (error) {
      console.error('Error fetching existing data:', error.message);
    }
  }
  
  useEffect(() => {
    if (selectedSemester && selectedSchoolYear) {
      fetchExistingData(selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates);
      setUpdatedTrainingDates({});
    }
  }, [selectedSemester, selectedSchoolYear]);

  useEffect(() => {
    if (existingTrainingDates) {
      const trainingDays = Object.keys(existingTrainingDates).map((day) => ({
        day,
        date: existingTrainingDates[day],
      }));
      setExistingTrainingDays(trainingDays);
    }
  }, [existingTrainingDates]);
  

  const [existingTrainingDays, setExistingTrainingDays] = useState([]);

  const getSelectedTrainingDayData = () => {
    if (!selectedTrainingDay || !existingTrainingDays[selectedTrainingDay - 1]) {
      return null;
    }
    return existingTrainingDays[selectedTrainingDay - 1];
  };

  const selectedTrainingDayData = getSelectedTrainingDayData();
 
  const [uniqueStudentCount, setUniqueStudentCount] = useState(0);
  const countUniqueStudentNumbersFromDatabase = (selectedTrainingDate) => {
    try {
        const db = getDatabase(app);
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const sessionRef = dbRef(db, sessionPath);

        // Listen for changes in the session data
        onValue(sessionRef, (snapshot) => {
            if (snapshot.exists()) {
                const sessionData = snapshot.val();
                const studentNumbers = Object.keys(sessionData);
                const uniqueStudentCount = new Set(studentNumbers).size;
                setUniqueStudentCount(uniqueStudentCount); // Set the count in the state
                console.log(`Number of unique students on ${selectedTrainingDate}: ${uniqueStudentCount}`);
            } else {
                console.log(`No data found for ${selectedTrainingDate}`);
            }
        });
    } catch (error) {
        console.error("Error counting student numbers:", error);
    }
};
useEffect(() => {
  if (selectedTrainingDay) {
      const selectedTrainingDateData = existingTrainingDays[selectedTrainingDay - 1];
      const selectedTrainingDate = `${selectedTrainingDateData.day} : ${selectedTrainingDateData.date}`;
      countUniqueStudentNumbersFromDatabase(selectedTrainingDate);
  }
}, [selectedTrainingDay, existingTrainingDays]);

  const displayStudentDetails = (studentNumber) => {
    // Check if userDetails is defined before accessing its properties
    if (userDetails && userDetails[studentUIDs[studentNumber]]) {
        const userDetailsData = userDetails[studentUIDs[studentNumber]];
      
        // Extract relevant student details
        const firstName = userDetailsData.Firstname || 'N/A';
        const middleName = userDetailsData.Middlename || 'N/A';
        const lastName = userDetailsData.Lastname || 'N/A';
    
        // Display the student details
        console.log('First Name:', firstName);
        console.log('Middle Name:', middleName);
        console.log('Last Name:', lastName);
    } else {
        console.log('Student details not found');
    }
};

  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
  
      // Check if imageUrl is valid
      if (imageUrl) {
        console.log(`User image fetched successfully for email: ${email}`);
        return imageUrl;
      } else {
        console.log(`User image not found for email: ${email}`);
        return null;
      }
    } catch (error) {
      console.error('Error fetching user image:', error.message);
      console.error('Email:', email); // Log the email for debugging purposes
      return null;
    }
  };
  
  const [imageUrls, setImageUrls] = useState({});

  const imageCache = useRef({}); // Create a ref to store cached images

  useEffect(() => {
    // Fetch image URLs for all scanned users
    const fetchImageUrls = async () => {
      const urls = {};
      const newImageCache = {}; // Temporary cache to store fetched images
      const fetchPromises = []; // Array to hold all fetch promises
  
      for (const scannedData of scannedDataArray) {
        if (studentUIDs[scannedData.studentNumber] && userDetails[studentUIDs[scannedData.studentNumber]]) {
          const email = userDetails[studentUIDs[scannedData.studentNumber]].email;
  
          // Check if image is already cached
          if (imageCache.current[email]) {
            urls[email] = imageCache.current[email]; // Retrieve from cache
          } else {
            // Fetch image and add promise to array
            fetchPromises.push(
              fetchUserImage(email)
                .then(imageUrl => {
                  urls[email] = imageUrl; // Store image URL
                  newImageCache[email] = imageUrl; // Add to temporary cache
                })
                .catch(error => {
                  console.error('Error fetching user image:', error.message);
                  console.error('Email:', email); // Log the email for debugging purposes
                })
            );
          }
        }
      }
  
      // Wait for all fetch promises to resolve
      await Promise.all(fetchPromises);
  
      // Update image cache with newly fetched images
      imageCache.current = { ...imageCache.current, ...newImageCache };
  
      // Update state with fetched image URLs
      setImageUrls(urls);
    };
  
    fetchImageUrls();
  }, [scannedDataArray, studentUIDs, userDetails]);
  const checkEnrollment = (studentNumber) => {
    const isEnrolled = enrolledStudents.some((student) => student.studentNumber === studentNumber);
    if (!isEnrolled) {
    }
  };

  useEffect(() => {
  }, [scannedDataArray]);
  const [validScannedDataArray, setValidScannedDataArray] = useState([]);

  useEffect(() => {
    filterValidScannedData();
  }, [scannedDataArray]);
  
  // Define a function to filter and set valid scanned data
  const filterValidScannedData = () => {
    const validData = scannedDataArray.filter((data) => isValidScannedData(data));
    // Add the timestamp to each valid data
    const dataWithTimestamp = validData.map(data => ({ ...data, timestamp: new Date() }));
    setValidScannedDataArray(dataWithTimestamp);
  };
const [currentTime, setCurrentTime] = useState(new Date());

useEffect(() => {
  const interval = setInterval(() => {
    setCurrentTime(new Date());
  }, 1000);

  return () => clearInterval(interval);
}, []);
const getRemarks = () => {
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  if ((currentHour === 4 && currentMinute >= 30) || (currentHour > 4 && currentHour < 7)) {
    return 'Time-In - Present';
  } else if ((currentHour === 7 && currentMinute >= 1) || (currentHour > 7 && currentHour < 9)) {
    return 'Time-In - Late';
  } else if ((currentHour === 15 && currentMinute >= 30) || (currentHour > 15 && currentHour < 18)) {
    return 'Time-Out - Present';
  } else {
    return 'Time-Out';
  }
};
const getUniqueStudentNumbers = () => {
  const uniqueStudentNumbers = new Set(); // Using Set to automatically filter duplicates
  const uniqueStudents = scannedDataArray.filter((data) => {
    if (isValidScannedData(data)) {
      if (!uniqueStudentNumbers.has(data.studentNumber)) {
        uniqueStudentNumbers.add(data.studentNumber);
        return true; // Return true if the student number is unique
      }
    }
    return false; // Return false if the student number is not valid or is a duplicate
  });
  return uniqueStudents;
};
 const countUniqueStudentNumbers = () => {
  return getUniqueStudentNumbers().length;
};
const countStudentNumbers = () => {
  const studentNumbersSet = new Set(); // Using a Set to automatically filter duplicate student numbers
  filteredStudents.forEach((student) => {
    studentNumbersSet.add(student.studentNumber);
  });
  return studentNumbersSet.size; // Return the size of the Set, which represents the count of unique student numbers
};
const handleItemClick = (itemName) => {
  setSelectedItem(itemName);
};
const [selectedItem, setSelectedItem] = useState(null);
const [trainingDayKey, setTrainingDayKey] = useState('');
const [storedEmail, setStoredEmail] = useState('');

// Retrieving the student number from local storage on component mount
useEffect(() => {
  
  const storedSchoolYear = localStorage.getItem('selectedSchoolYear');
  if (storedSchoolYear) {
    setSelectedSchoolYear(storedSchoolYear);
  }

  const storedSemester = localStorage.getItem('selectedSemester');
  if (storedSemester) {
    setSelectedSemester(storedSemester);
  }

  const storedTrainingDayKey = localStorage.getItem('trainingDayKey');
  if (storedTrainingDayKey) {
    setTrainingDayKey(storedTrainingDayKey);
  }
  const storedEmail = localStorage.getItem('email'); // Retrieve the email from local storage
setStoredEmail(storedEmail); // Set the email in the component state

}, []);
useEffect(() => {
  const storedStudentNumber = localStorage.getItem('studentNumber');
  if (storedStudentNumber) {
    setSidebarStudentNumber(storedStudentNumber);
  }
}, []);

const matchingTrainingDay = existingTrainingDays.find(item => `Training Day ${item.day}: ${item.date}` === trainingDayKey);
const filteredTrainingDays = existingTrainingDays.filter(item => `Training Day ${item.day}: ${item.date}` === trainingDayKey);

const enableTrainingDays = (existingTrainingDays) => {
  const currentDate = new Date();
  return existingTrainingDays.map((item, index) => ({
    ...item,
    enabled: isTrainingDateEnabled(item.date),
  }));
};

return (
  <div className='qr-scanner-2'>
    <ToastContainer />
    <QRScannerSideBar studentNumber={sidebarStudentNumber} />
    <div className='qr-scanner-2-full'>
      <div className="Header-list-Scanner-2">
        <h1>QR Scanner</h1>  
      </div>
    <div className="container-qr-scanner-2">
      <div className='box-qr-scanner-container'>
      {!isMinimized ? (
  <div className="select-date-training-qr-scanner">
    <div className="header-select-date-training-qr-scanner">
      
      <h2>
        Set-up
        
        <span className="minimize-button-container">
          <button className="minimize-button-change" onClick={toggleMinimize}>
            {isMinimized ? 'Maximize' : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
        </span>
      </h2>
    </div>
    
    <div className="admin-dropdown-attendance-qr-scanner-2">
      <select
        id="semester"
        className="filter-select"
        onChange={(e) => setSelectedSemester(e.target.value)}
        value={selectedSemester}
      >
        <option value="" disabled hidden>
          Select Semester
        </option>
        <option value="First Semester">First Semester</option>
        <option value="Second Semester">Second Semester</option>
      </select>
    </div>
    <div className="admin-dropdown-attendance-qr-scanner-2">
      <select
        id="schoolYear"
        className="filter-select"
        onChange={(e) => setSelectedSchoolYear(e.target.value)}
        value={selectedSchoolYear}
      >
        <option value="" hidden>Select School Year</option>
        {schoolYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
<div className="admin-dropdown-attendance-qr-scanner-2">
  <select
    id="trainingDay"
    className="filter-select"
    onChange={(e) => setSelectedTrainingDay(parseInt(e.target.value))}
    value={selectedTrainingDay || ''}
  >
    <option value="">Select Training Day</option>
    {enableTrainingDays(existingTrainingDays).map((item, index) => (
      <option
        key={index}
        value={index + 1}
        disabled={!item.enabled}
      >
        {`Training Day ${item.day}: ${item.date}`}
      </option>
    ))}
  </select>
</div>

  </div>

        ) : (
          <button className="toggle-button-max" onClick={toggleMinimize}>
            {isMinimized ? 'Maximize' : 'Minimize'}
          </button>
        )}

<div className="scanned-student-numbers-box">
  <div className="scanned-student-numbers-container">
    <h2 className='scanned-student-numbers-container-header'>Scanned Student Numbers</h2>
    <ul className="scanned-student-numbers-list">
      <p className="student-count-scanner">Student Numbers: {uniqueStudentCount}/{countStudentNumbers()}</p>
      {getUniqueStudentNumbers().map((scannedData, index) => (
        <li key={index}>
          <span className="student-number">{index + 1}.</span> {scannedData.studentNumber}
        </li>
      ))}
    </ul>
  </div>
</div>
</div>
      <div className='camera-n-details'>
    <div className="scanner-frame12">
        <div className="QR-Header">
          <h3>QR Scanner</h3>
        </div>
        <div class="video-container">
        <video id="video" class="video-qr-2" autoPlay playsInline></video>
        <div class="qr-code-overlay">
  <div class="overlay-background"></div>
  <div class="overlay-background1"></div>
  <div class="overlay-background2"></div>
  <div class="overlay-background3"></div>
</div>

        <div class="button-container-unique">
          <button onClick={turnOnScanner}>Turn On Scanner</button>
        </div>
      </div>
      </div>
      </div>

      {validScannedDataArray.length > 0 && (
        <div className="qr-content-container">
          {validScannedDataArray.slice(-1).map((validScannedData, index) => (
            <div key={index}>
              {studentUIDs[validScannedData.studentNumber] &&
                userDetails[studentUIDs[validScannedData.studentNumber]] && (
                  <div className='student-info-container-qr-scanner'>
                      <div className="user-image-container-qr-scanner">
                      {imageUrls[userDetails[studentUIDs[validScannedData.studentNumber]].email] ? (
                        <img className="user-image-qr" src={imageUrls[userDetails[studentUIDs[validScannedData.studentNumber]].email]} alt="User Image" />
                      ) : (
                        <p className="loading-message">Loading user image...</p>
                      )}
                    </div>
                    <div className='qr-scanner-details'>
                    <div className='qr-scanner-details-s1'>
                    <h2>Student Details</h2>
                    {/* Display the selected training day details */}
                    <p>Full name: {userDetails[studentUIDs[validScannedData.studentNumber]].Lastname}, {userDetails[studentUIDs[validScannedData.studentNumber]].Firstname}  {userDetails[studentUIDs[validScannedData.studentNumber]].Middlename}</p>
                    <p>Course: {userDetails[studentUIDs[validScannedData.studentNumber]]?.Course}</p>
                    <p>Gender: {userDetails[studentUIDs[validScannedData.studentNumber]].Gender}</p>
                    </div>
                    <div className='time-in-out'>
                      {getRemarks().startsWith("Time-In") && (
                        <div>
                          <h3>Time-In</h3>
                          <p>Appearance: {getRemarks().endsWith("Late") ? "Late" : "Present"}</p>
                          <p>Time: {validScannedData.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                        </div>
                      )}
                      {getRemarks().startsWith("Time-Out") && (
                        <div>
                          <h3>Time-Out</h3>
                          <p>Appearance: Present</p>
                          <p>Time:  {validScannedData.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </div>
      )}

{validScannedDataArray.length > 0 && (
  <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Student Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div>
        {validScannedDataArray.slice(-1).map((validScannedData, index) => (
          <div key={index}>
            {studentUIDs[validScannedData.studentNumber] &&
              userDetails[studentUIDs[validScannedData.studentNumber]] && (
                <div className='student-info-container-qr-scanner'>
                    <div className="user-image-container-qr-scanner">
                    {imageUrls[userDetails[studentUIDs[validScannedData.studentNumber]].email] ? (
                      <img className="user-image-qr11" src={imageUrls[userDetails[studentUIDs[validScannedData.studentNumber]].email]} alt="User Image" />
                    ) : (
                      <p className="loading-message">Loading user image...</p>
                    )}
                  </div>
                  <div className='qr-scanner-details'>
                  <div className='qr-scanner-details-s1'>
                  <h2>Student Details</h2>
                  {/* Display the selected training day details */}
                  <p>Full name: {userDetails[studentUIDs[validScannedData.studentNumber]].Lastname}, {userDetails[studentUIDs[validScannedData.studentNumber]].Firstname}  {userDetails[studentUIDs[validScannedData.studentNumber]].Middlename}</p>
                  <p>Course: {userDetails[studentUIDs[validScannedData.studentNumber]]?.Course}</p>
                  <p>Gender: {userDetails[studentUIDs[validScannedData.studentNumber]].Gender}</p>
                  </div>
                  <div className='time-in-out'>
                    {getRemarks().startsWith("Time-In") && (
                      <div>
                        <h3>Time-In</h3>
                        <p>Appearance: {getRemarks().endsWith("Late") ? "Late" : "Present"}</p>
                        <p>Time: {validScannedData.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                      </div>
                    )}
                    {getRemarks().startsWith("Time-Out") && (
                      <div>
                        <h3>Time-Out</h3>
                        <p>Appearance: Present</p>
                        <p>Time:  {validScannedData.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                      </div>
                    )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
    </Modal.Footer>
  </Modal>
)}

      </div>
    </div>
  </div>
);
}

export default QRCodeScanner2;