import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { getDatabase, ref, get, set } from "firebase/database";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { app } from "../Firebase";
import "./QRScannerLogin.css"
import { faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; 
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Dropdown, Offcanvas } from 'react-bootstrap';

function QRScannerLogin() {
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedTrainingDay, setSelectedTrainingDay] = useState('');
  const [filteredTrainingDates, setFilteredTrainingDates] = useState([]);
  const [trainingData, setTrainingData] = useState(null);
  const [studentNumber, setStudentNumber] = useState('');
  const [email, setEmail] = useState(''); // New state for email input
  const [sidebarStudentNumber, setSidebarStudentNumber] = useState('');
  const [trainingDayKey, setTrainingDayKey] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(db, 'enrolled');
        const schoolYearsSnapshot = await get(schoolYearsRef);
        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);

  useEffect(() => {
    if (selectedSemester && selectedSchoolYear) {
      fetchExistingData(selectedSchoolYear, selectedSemester);
    }
  }, [selectedSemester, selectedSchoolYear]);

  const fetchExistingData = async (selectedSchoolYear, selectedSemester) => {
    try {
      const db = getDatabase(app);

      const trainingDatesRef = ref(
        db,
        `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
      );

      const snapshot = await get(trainingDatesRef);

      if (snapshot.exists()) {
        const trainingDatesData = snapshot.val();
        console.log('Existing Training Dates:', trainingDatesData);
        const trainingDays = Object.keys(trainingDatesData).map(day => ({
          day,
          date: trainingDatesData[day],
        }));
        const filteredDates = filterTrainingDatesWithinWeek(trainingDays);
        setFilteredTrainingDates(filteredDates);
      } else {
        console.log('No existing training dates found.');
        setFilteredTrainingDates([]);
      }
    } catch (error) {
      console.error('Error fetching existing data:', error.message);
    }
  };

  const filterTrainingDatesWithinWeek = (trainingDates) => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  
    // Calculate the difference in days to get to Monday
    const daysUntilMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - daysUntilMonday); // Get the Monday of the current week
  
    // Calculate the date for next Monday
    const nextMondayDate = new Date(mondayDate);
    nextMondayDate.setDate(mondayDate.getDate() + 7);
  
    // Filter training dates within the week (from Monday to next Monday)
    const filteredDates = trainingDates.filter(date => {
      const trainingDate = new Date(date.date);
      return trainingDate >= mondayDate && trainingDate < nextMondayDate;
    });
  
    return filteredDates;
  };

  const displayTrainingDatesFromFirebase = async () => {
    try {
      const scannerRef = ref(db, 'Scanner');
      const snapshot = await get(scannerRef);
      const data = snapshot.val();
      setTrainingData(data);
    } catch (error) {
      console.error('Error displaying data from Firebase:', error);
    }
  };

  useEffect(() => {
    displayTrainingDatesFromFirebase();
  }, []);

  useEffect(() => {
    const storedStudentNumber = localStorage.getItem('studentNumber');
    if (storedStudentNumber) {
      setSidebarStudentNumber(storedStudentNumber);
    }
  }, []);

  useEffect(() => {
    const storedStudentNumber = localStorage.getItem('studentNumber');
    if (storedStudentNumber) {
      setSidebarStudentNumber(storedStudentNumber);
    }
    
    const storedSchoolYear = localStorage.getItem('selectedSchoolYear');
    if (storedSchoolYear) {
      setSelectedSchoolYear(storedSchoolYear);
    }
  
    const storedSemester = localStorage.getItem('selectedSemester');
    if (storedSemester) {
      setSelectedSemester(storedSemester);
    }
  
    const storedTrainingDay = localStorage.getItem('selectedTrainingDay');
    if (storedTrainingDay) {
      setSelectedTrainingDay(JSON.parse(storedTrainingDay));
    }
  }, []);

  useEffect(() => {
    const storedTrainingDayKey = localStorage.getItem('trainingDayKey');
    if (storedTrainingDayKey) {
      setTrainingDayKey(storedTrainingDayKey);
    }
  }, []);

  const login = async () => {
    if (selectedSchoolYear && selectedSemester && selectedTrainingDay && studentNumber && trainingData && email) {
      localStorage.setItem('selectedSchoolYear', selectedSchoolYear);
      localStorage.setItem('selectedSemester', selectedSemester);
      localStorage.setItem('selectedTrainingDay', JSON.stringify(selectedTrainingDay));

      const selectedItem = filteredTrainingDates[selectedTrainingDay - 1];
      const trainingDayKey = `Training Day ${selectedItem.day}: ${selectedItem.date}`;
      setTrainingDayKey(trainingDayKey);
      localStorage.setItem('trainingDayKey', trainingDayKey);

      try {
        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          for (const key in usersData) {
            if (usersData[key].studentNumber === studentNumber && usersData[key].email === email) {
              const trainingDayData = trainingData[selectedSchoolYear] &&
                                      trainingData[selectedSchoolYear][selectedSemester] &&
                                      trainingData[selectedSchoolYear][selectedSemester]['TrainingDates'] &&
                                      trainingData[selectedSchoolYear][selectedSemester]['TrainingDates'][trainingDayKey];
              if (trainingDayData && trainingDayData[studentNumber]) {
                const loginRef = ref(db, `Scanner/${selectedSchoolYear}/${selectedSemester}/TrainingDates/${trainingDayKey}/${studentNumber}`);
                await set(loginRef, { status: "Online" });
                console.log('Login status updated successfully');

                setSidebarStudentNumber(studentNumber);

                localStorage.setItem('studentNumber', studentNumber);
                localStorage.setItem('email', email);

                navigate('/Admin/QRScanner2.0');
                
                return;
              } else {
                console.log("No matching record found.");
              }
            }
          }
          console.log("Invalid student number or email.");
        } else {
          console.log('No user data found.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    } else {
      console.log("Please fill in all required fields.");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          for (const key in usersData) {
            if (usersData[key].studentNumber === studentNumber) {
              setUserData(usersData[key]);
              break;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, [studentNumber]);

  return (
    <div className="Admin-authority1">
      <div className="Admin-authority-container1">

        <Link to="/Admin/LoginAdmin">
          <Button
            variant="link"
            size="lg"
            className="button-homepage"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="xl"
              style={{ color: "#000000" }}
            />
          </Button>
        </Link>
        <h1 className="Admin-authority-heading1">Authorized Personnel</h1>
        <div className="offcanvas-container">
          <Offcanvas show={showMenu} onHide={() => setShowMenu(false)} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filter Options</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className='authority-filter1'>
                <div className="admin-dropdown-authority1">
                  <select
                    id="semester"
                    className="filter-select1"
                    onChange={(e) => setSelectedSemester(e.target.value)}
                    value={selectedSemester}
                  >
                    <option value="" disabled hidden>
                      Select Semester
                    </option>
                    <option value="First Semester">First Semester</option>
                    <option value="Second Semester">Second Semester</option>
                  </select>
                </div>
                <div className="admin-dropdown-authority1">
                  <select
                    id="schoolYear"
                    className="filter-select"
                    onChange={(e) => setSelectedSchoolYear(e.target.value)}
                    value={selectedSchoolYear}
                  >
                    <option value="" hidden>Select School Year</option>
                    {schoolYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="admin-dropdown131">
                <label className="checkbox-label1">Select Training Days: </label>
                <div className="checkbox-group">
                  {filteredTrainingDates.map((item, index) => (
                    <div className="checkbox-item1" key={`trainingDay${index}`}>
                      <input
                        type="radio"
                        id={`trainingDay${index}`}
                        value={index + 1}
                        checked={selectedTrainingDay === (index + 1)}
                        onChange={(e) => {
                          setSelectedTrainingDay(parseInt(e.target.value));
                        }}
                        className="checkbox-input1"
                      />
                      <label htmlFor={`trainingDay${index}`} className="checkbox-text">
                        {`Training Day ${item.day}: ${item.date}`}
                        {selectedTrainingDay === (index + 1) && (
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>

        <Button variant="primary" onClick={() => setShowMenu(true)} className="QRScannerLogin-button">
          Configuration
        </Button>

        <div className="authority-container11">
          <input
            type="text"
            placeholder="Enter Student Number"
            value={studentNumber}
            onChange={(e) => setStudentNumber(e.target.value)}
          />
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="QRLogin" onClick={login}>Login</button>
        </div>
      
      </div>
    </div>
  );
}

export default QRScannerLogin;
