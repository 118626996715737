import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, orderByChild} from 'firebase/database';
import './Announcement.css';
import LandingPage from '../LANDINGPAGES/LandingPage';
import Footer from '../LANDINGPAGES/Footer';

const Announcement = () => {
  const [announcements, setAnnouncements] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase();
      const announcementsRef = ref(db, "announcements");

      // No need for orderByChild here, since we are fetching the entire 'announcements' node
      onValue(announcementsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Extracting the latest announcement from the snapshot
          const latestAnnouncementKey =
            Object.keys(data)[Object.keys(data).length - 1];
          const latestAnnouncement = data[latestAnnouncementKey];
          setAnnouncements({ [latestAnnouncementKey]: latestAnnouncement });
        }
      });
    };

    fetchData();

    // Clean up function to remove event listener
    return () => {
      // Code to remove event listener if necessary
    };
  }, []);

  return (
    <div>
      <LandingPage />
      <h1 className="announcement-heading">Announcements</h1>
      <div className="announcement-container">
        {announcements &&
          Object.keys(announcements).map(
            (key) =>
              announcements[key].WarningDroplist && (
                <div key={key} className="droplist-warning-1">
                  <strong>Droplist Warning:</strong> <br />{" "}
                  {/* Add line break here */}
                  {announcements[key].WarningDroplist}
                </div>
              ),
          )}

        <ul className="announcement-list">
          {announcements &&
            Object.keys(announcements).map((key) => (
              <li key={key} className="announcement-item">
                <strong>DATE:</strong> {key}
                <ul>
                  <li>
                    <strong>What:</strong> {announcements[key].What}
                  </li>
                  <li>
                    <strong>When:</strong> {announcements[key].When}
                  </li>
                  <li>
                    <strong>Where:</strong> {announcements[key].Where}
                  </li>
                  <li>
                    <strong>Who:</strong> {announcements[key].Who}
                  </li>
                  <li>
                    <strong>Reminder:</strong> {announcements[key].Reminder}
                  </li>
                </ul>
              </li>
            ))}
        </ul>

        {announcements &&
          Object.keys(announcements).map(
            (key) =>
              announcements[key].Droplist && (
                <div key={key} className="warning-drop">
                  <strong>Droplist:</strong>
                  <br /> {/* Add line break here */}
                  {announcements[key].Droplist}
                </div>
              ),
          )}
      </div>

      <Footer />
    </div>
  );
};

export default Announcement;