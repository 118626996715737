import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AttendanceSummary = ({ summary, studentNumber }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <FontAwesomeIcon icon={faInfoCircle} onClick={openModal} />

      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>Training Day Summary</h2>
            <p>{summary}</p>
            <p>Selected Student Number: {studentNumber}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendanceSummary;
