import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faHome,
  faChartBar,
  faCalendarCheck,
  faBell,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./AdminSideBar.css";

function SideBarMonitor() {
  const [minimized, setMinimized] = useState(false);
  const [icon, setIcon] = useState(faAngleLeft);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleMinimized = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
  };

  const handleLogoClick = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  return (
    <div className={`sidebarAdmin ${minimized ? "minimized" : ""}`}>
      <div
        className={`logo-image ${minimized ? "minimized" : ""}`}
        onClick={handleLogoClick}
      ></div>
      <hr className="line-separator" />
      <div className="sidebar-content">
        <ul>
          <li className={`sidebar-list-item ${selectedItem === "home" ? "selected" : ""}`}>
            <Link to="/" onClick={() => handleItemClick("home")}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "dashboard" ? "selected" : ""}`}>
            <Link
              to="/Admin/Dashboard"
              onClick={() => handleItemClick("dashboard")}
            >
              <FontAwesomeIcon icon={faChartBar} />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
            <Link to="/Admin/AdminSchedule">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>Schedule</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
            <Link to="/Admin/AdminAnnouncement">
            <FontAwesomeIcon icon={faBell} />
                <span>Announcement</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "attendance" ? "selected" : ""}`}>
            <Link
              to="/Admin/Attendance"
              onClick={() => handleItemClick("attendance")}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />
              <span>Attendance</span>
            </Link>
          </li>

        </ul>
      </div>
      <div className="minimize-btn-container">
        <button className="minimize-btn-admin" onClick={toggleMinimized}>
          <FontAwesomeIcon icon={icon} />
        </button>
      </div>
    </div>
  );
}

export default SideBarMonitor;