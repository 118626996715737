  import React, { useState, useEffect } from 'react';
  import Modal from 'react-modal';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faTimesCircle, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
  import { ref, get, onValue } from "firebase/database";
  import { db } from "../Firebase";
  import "./AdminModalTable.css"
  import * as XLSX from 'xlsx';

  const AdminModalTable = () => {
      
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [enrolledStudents, setEnrolledStudents] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
    const [selectedSemester, setSelectedSemester] = useState('');
    const [schoolYears, setSchoolYears] = useState([]);
    const [studentUIDs, setStudentUIDs] = useState({});
    const [userDetails, setUserDetails] = useState({}); 
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSchoolNumber, setSelectedSchoolNumber] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    
    const openDataModal = () => {
      setIsDataModalOpen(true);
    };

    const closeDataModal = () => {
      setIsDataModalOpen(false);
    };


    
    const downloadTableDataAsXLSX = () => {
      // Collect the data displayed in the table
      const tableData = filteredStudents.map((student, index) => {
        const rowData = displayedColumns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return index + 1;
          } else {
            return renderUserDataColumn(student, column, index);
          }
        });
        return rowData;
      });
    
      // Prepare the worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([displayedColumns, ...tableData]);
    
      // Prepare the workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');
    
      // Save the workbook as an XLSX file
      XLSX.writeFile(workbook, 'table_data.xlsx');
    };
    
    useEffect(() => {
      const fetchEnrolledStudents = async () => {
        if (selectedSchoolYear && selectedSemester) {
          try {
            const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
            const userSemesterSnapshot = await get(userSemesterRef);
            if (userSemesterSnapshot.exists()) {
              const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
              setEnrolledStudents(enrolledStudentsData);
              fetchUserUIDs(enrolledStudentsData);
            } else {
              setEnrolledStudents([]);
              setStudentUIDs({});
            }
          } catch (error) {
            console.error('Error fetching enrolled students:', error.message);
          }
        }
      };

      fetchEnrolledStudents();
    }, [selectedSchoolYear, selectedSemester]);

    const transformEnrolledData = (data) => {
      if (!data) return [];
      return Object.entries(data).map(([id, student]) => ({
        id: id,
        year: selectedSchoolYear,
        semester: selectedSemester,
        studentNumber: student.studentNumber
      }));
    };

    useEffect(() => {
      const fetchSchoolYears = async () => {
        try {
          const schoolYearsRef = ref(db, 'enrolled');
          const schoolYearsSnapshot = await get(schoolYearsRef);
          if (schoolYearsSnapshot.exists()) {
            const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
            setSchoolYears(schoolYearsList);
          } else {
            setSchoolYears([]);
          }
        } catch (error) {
          console.error('Error fetching school years:', error.message);
        }
      };

      fetchSchoolYears();
    }, []);

    const fetchUserUIDs = async (students) => {
      const studentNumbers = students.map(student => student.studentNumber);
      const usersRef = ref(db, 'users');
      const userSnapshot = await get(usersRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const studentUIDs = {};
        const userDetails = {};
        studentNumbers.forEach(studentNumber => {
          for (const uid in userData) {
            if (userData[uid].studentNumber === studentNumber) {
              studentUIDs[studentNumber] = uid;
              userDetails[uid] = userData[uid]; // Storing user details by UID
              break;
            }
          }
        });
        setStudentUIDs(studentUIDs);
        setUserDetails(userDetails);
      } else {
        console.log('No user data found in the database.');
      }
    };

    useEffect(() => {
      // Apply filters based on selected filter values and search query
      let filtered = enrolledStudents.filter(student =>
        student.studentNumber.includes(searchQuery) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Firstname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Lastname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.studentNumber || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Middlename || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Course || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.DateOfBirth || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.ContactNo || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.region || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.province || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.city || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.barangay || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Height || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Weight || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.BloodType || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.Position || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.BeneficiaryName || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.BeneficiaryContact || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.BeneficiaryAddress || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (userDetails[studentUIDs[student.studentNumber]]?.BeneficiaryRelationship || '').toLowerCase().includes(searchQuery.toLowerCase())
        
      );
    
      // Apply additional filters based on selected filter values
      if (selectedSchoolNumber) {
        filtered = filtered.filter(student => student.studentNumber.includes(selectedSchoolNumber));
      }
      if (selectedCourse) {
        filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Course.includes(selectedCourse));
      }
      if (selectedGender) {
        filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Gender.includes(selectedGender));
      }
    
      setFilteredStudents(filtered);
    }, [searchQuery, enrolledStudents, userDetails, studentUIDs, selectedSchoolNumber, selectedCourse, selectedGender]);
    const handleSearch = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
      
      // Check if userDetails is initialized before accessing it
      if (Object.keys(userDetails).length === 0) {
        // Return an empty array if userDetails is not initialized yet
        setFilteredStudents([]);
        return;
      }
    
      // Filter the enrolled students based on the search query
      setFilteredStudents(enrolledStudents.filter(student => {
        const userDetail = userDetails[studentUIDs[student.studentNumber]];
        if (!userDetail) return false;
        return Object.values(userDetail).some(value => 
          value && value.toString().toLowerCase().includes(query)
        );
      }));
    };
    
    
    const extractSchoolNumber = (studentNumber) => {
      return studentNumber.substring(0, 4);
    };
    
    // Logic to collect unique school numbers from enrolled students
    const getUniqueSchoolNumbers = () => {
      const uniqueSchoolNumbers = new Set();
      enrolledStudents.forEach((student) => {
        const schoolNumber = extractSchoolNumber(student.studentNumber);
        uniqueSchoolNumbers.add(schoolNumber);
      });
      return Array.from(uniqueSchoolNumbers);
    };
    const getUniqueCourses = () => {
      const uniqueCourses = new Set();
      enrolledStudents.forEach((student) => {
          const userDetail = userDetails[studentUIDs[student.studentNumber]];
          if (userDetail && userDetail.Course) { // Check if userDetail is defined and Course property exists
              uniqueCourses.add(userDetail.Course);
          }
      });
      return Array.from(uniqueCourses);
  };
 
  const initialColumns = [
    'No.',
    'Student Number',
    'Last Name',
    'First Name',
    'Middle Name',
    'Course',
    'Gender',
    'Email',
    'Date of Birth',
    'Contact Number',
    'Region',
    'Province',
    'City',
    'Barangay',
    'Height',
    'Weight',
    'Blood Type',
    'Position',
    'Beneficiary Name',
    'Beneficiary Contact',
    'Beneficiary Address',
    'Beneficiary Relationship',
  ];

  const [displayedColumns, setDisplayedColumns] = useState([...initialColumns]);
  const moveColumnLeft = (index) => {
    if (index > 1) {
      setDisplayedColumns((prevColumns) => {
        const newDisplayedColumns = [...prevColumns];
        if (newDisplayedColumns[index] !== 'No.') { // Check if the column is not 'No.'
          const movedColumn = newDisplayedColumns.splice(index, 1)[0];
          newDisplayedColumns.splice(index - 1, 0, movedColumn);
        }
        return newDisplayedColumns;
      });
    }
  };
  
  const moveColumnRight = (index) => {
    if (index < displayedColumns.length - 1) {
      setDisplayedColumns((prevColumns) => {
        const newDisplayedColumns = [...prevColumns];
        if (newDisplayedColumns[index] !== 'No.') { // Check if the column is not 'No.'
          const movedColumn = newDisplayedColumns.splice(index, 1)[0];
          newDisplayedColumns.splice(index + 1, 0, movedColumn);
        }
        return newDisplayedColumns;
      });
    }
  };
  
// Function to reset columns to their original state
const resetColumns = () => {
    setDisplayedColumns([...initialColumns]);
};


const renderUserDataColumn = (student, column, index) => {
  const userData = userDetails[studentUIDs[student.studentNumber]];

  if (!userData) {
      return 'N/A';
  }

  switch (column) {
      case 'No.':
          return index + 1; // Add 1 to start from 1 instead of 0
      case 'Student Number':
          return userData.studentNumber;
      case 'First Name':
          return userData.Firstname;
      case 'Last Name':
          return userData.Lastname;
      case 'Middle Name':
          return userData.Middlename;
      case 'Course':
          return userData.Course;
      case 'Gender':
          return userData.Gender;
      case 'Email':
          return userData.email;
      case 'Date of Birth':
          return userData.DateOfBirth;
      case 'Contact Number':
          return userData.ContactNo;
      case 'Region':
          return userData.region;
      case 'Province':
          return userData.province;
      case 'City':
          return userData.city;
      case 'Barangay':
          return userData.barangay;
      case 'Height':
          return userData.Height;
      case 'Weight':
          return userData.Weight;
      case 'Blood Type':
          return userData.BloodType;
      case 'Position':
          return userData.Position;
      case 'Beneficiary Name':
          return userData.BeneficiaryName;
      case 'Beneficiary Contact':
          return userData.BeneficiaryContact;
      case 'Beneficiary Address':
          return userData.BeneficiaryAddress;
      case 'Beneficiary Relationship':
          return userData.BeneficiaryRelationship;
      default:
          return '';
  }
};

const renderDataFilterCheckboxes = () => {
  const categories = {
    'A-Summary of Enrolled': ['Last Name', 'First Name', 'Middle Name', 'Course', 'Date of Birth'],
    'B-Roster of Enrolled': [
      'Last Name', 
      'First Name',
      'Middle Name',
      'Course',
      'Date of Birth',
      'Region',
      'Province',
      'City',
      'Barangay',
      'Beneficiary Name',
      'Beneficiary Relationship',
    ],
    'C-List of Beneficiary': [
      'Last Name', 
      'First Name',
      'Middle Name',
      'Course',
      'Date of Birth',
      'Beneficiary Name',
      'Beneficiary Address',
    ],
    'D-Cadets Profile': [
      'Last Name', 
      'First Name',
      'Middle Name',
      'Course',
      'Date of Birth',
      'Blood Type',
      'Region',
      'Province',
      'Height',
    ],
  };

  const handleSelectAllToggle = () => {
    const allColumnsChecked = displayedColumns.length === initialColumns.length;

    if (allColumnsChecked) {
      // If all columns are checked, uncheck all except "No."
      setDisplayedColumns(['No.']);
    } else {
      // Otherwise, check all columns
      setDisplayedColumns([...initialColumns]);
    }
  };

  const handleCategoryToggle = (category) => {
    setDisplayedColumns(prevColumns => {
      const columnsToToggle = categories[category];
      const allColumnsChecked = columnsToToggle.every(col => prevColumns.includes(col));
  
      if (allColumnsChecked) {
        // If all columns in the category are checked, uncheck them
        const updatedColumns = prevColumns.filter(col => !columnsToToggle.includes(col) || col === 'No.');
        return updatedColumns;
      } else {
        // Otherwise, check all columns in the category
        return [...prevColumns, ...columnsToToggle];
      }
    });
  };
  
  

  return (
    <div className="data-filter-checkboxes1">
      <div className="filter-checkbox">
        <button onClick={handleSelectAllToggle}>
          {displayedColumns.length === initialColumns.length
            ? 'Deselect All'
            : 'Select All'}
        </button>
      </div>
      <div className="data-filter-checkboxes">
      <div className='Categories'>
  {Object.keys(categories).map((category, index) => (
    <div key={index} className={`filter-checkbox ${category}`}>
      <button
        onClick={() => handleCategoryToggle(category)}
        className={`category-button ${displayedColumns.includes(category) ? 'active' : ''}`}
      >
        {category}
      </button>
    </div>
  ))}
</div>

      <div className='Categories'>
        {initialColumns.map((column, index) => (
          <div key={index} className="filter-checkbox">
            {/* Make the "No." checkbox always checked and disabled */}
            <input
              type="checkbox"
              id={column}
              checked={column === 'No.' || displayedColumns.includes(column)}
              disabled={column === 'No.'} // Disable the checkbox for "No."
              onChange={() => {
                setDisplayedColumns(prevColumns => {
                  if (prevColumns.includes(column)) {
                    // Remove column from displayedColumns
                    return prevColumns.filter(col => col !== column);
                  } else {
                    // Add column to displayedColumns
                    return [...prevColumns, column];
                  }
                });
              }}
            />
            <label htmlFor={column}>{column}</label>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};
const [isHovered, setIsHovered] = useState(null);

// Inside your component function
const [sortedColumn, setSortedColumn] = useState(null);
const [sortOrder, setSortOrder] = useState('asc');

// Function to handle sorting when a column header is clicked
const handleSort = (column) => {
  if (sortedColumn === column) {
      // Toggle sort order if the same column is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
      // Set the new column for sorting and default to ascending order
      setSortedColumn(column);
      setSortOrder('asc');
  }
};
useEffect(() => {
        setFilteredStudents(sortData(filteredStudents, sortedColumn));
    }, [sortOrder, sortedColumn]);


// Function to determine if a column is currently sorted
const isSorted = (column) => sortedColumn === column;

// Function to sort the data based on the selected column and sort order
// Function to sort the data based on the selected column and sort order
const sortData = (data, column) => {
  return data.slice().sort((a, b) => {
    let comparison = 0;

    switch (column) {
      case 'No.':
        comparison = a.id - b.id;
        break;
      case 'Student Number':
        comparison = (a.studentNumber || '').localeCompare(b.studentNumber || '');
        break;
      case 'First Name':
        comparison = (a.Firstname || '').localeCompare(b.Firstname || '');
        break;
      case 'Last Name':
        comparison = (a.Lastname || '').localeCompare(b.Lastname || '');
        break;
      case 'Middle Name':
        comparison = (a.Middlename || '').localeCompare(b.Middlename || '');
        break;
      case 'Course':
        comparison = (a.Course || '').localeCompare(b.Course || '');
        break;
      case 'Gender':
        comparison = (a.Gender || '').localeCompare(b.Gender || '');
        break;
      case 'Email':
        comparison = (a.email || '').localeCompare(b.email || '');
        break;
      case 'Date of Birth':
        comparison = (a.DateOfBirth || '').localeCompare(b.DateOfBirth || '');
        break;
      case 'Contact Number':
        comparison = (a.ContactNo || '').localeCompare(b.ContactNo || '');
        break;
      case 'Region':
        comparison = (a.region || '').localeCompare(b.region || '');
        break;
      case 'Province':
        comparison = (a.province || '').localeCompare(b.province || '');
        break;
      case 'City':
        comparison = (a.city || '').localeCompare(b.city || '');
        break;
      case 'Barangay':
        comparison = (a.barangay || '').localeCompare(b.barangay || '');
        break;
      case 'Height':
        comparison = (a.Height || 0) - (b.Height || 0);
        break;
      case 'Weight':
        comparison = (a.Weight || 0) - (b.Weight || 0);
        break;
      case 'Blood Type':
        comparison = (a.BloodType || '').localeCompare(b.BloodType || '');
        break;
      case 'Position':
        comparison = (a.Position || '').localeCompare(b.Position || '');
        break;
      case 'Beneficiary Name':
        comparison = (a.BeneficiaryName || '').localeCompare(b.BeneficiaryName || '');
        break;
      case 'Beneficiary Contact':
        comparison = (a.BeneficiaryContact || '').localeCompare(b.BeneficiaryContact || '');
        break;
      case 'Beneficiary Address':
        comparison = (a.BeneficiaryAddress || '').localeCompare(b.BeneficiaryAddress || '');
        break;
      case 'Beneficiary Relationship':
        comparison = (a.BeneficiaryRelationship || '').localeCompare(b.BeneficiaryRelationship || '');
        break;
      default:
        comparison = 0;
        break;
    }

    return sortOrder === 'asc' ? comparison : -comparison;
  });
};
const renderDataTable = () => (
  <div className="admin-table1">
    <button className="resetcolumn" onClick={resetColumns}>Reset Columns</button>
    <div className="table-header"></div>
    <table className="enrolled-1">
      <thead>
        <tr>
          {displayedColumns.map((column, index) => (
            <th
              key={index}
              style={{ display: displayedColumns.includes(column) ? 'table-cell' : 'none' }}
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(null)}
            >
              {column}
              <div>
                {index > 0 && isHovered === index && (
                  <button onClick={() => moveColumnLeft(index)}>⬅️</button>
                )}
                {index < displayedColumns.length - 1 && isHovered === index && (
                  <button onClick={() => moveColumnRight(index)}>➡️</button>
                )}
                {column !== 'No.' && ( // Ensure 'No.' column is not sortable
                  <button onClick={() => handleSort(column)}>
                    {isSorted(column) ? (sortOrder === 'asc' ? '⬆️' : '⬇️') : '⬇️⬆️'}
                  </button>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Sorting and rendering the data */}
        {sortData(filteredStudents, sortedColumn).map((student, index) => (
          <tr key={student.id}>
            {displayedColumns.map((column, columnIndex) => {
              const isVisible = displayedColumns.includes(column);
              return (
                <td key={columnIndex} style={{ display: isVisible ? 'table-cell' : 'none' }}>
                  {/* Check if the column is included in displayedColumns before rendering */}
                  {columnIndex === 0 ? (index + 1) : (isVisible ? renderUserDataColumn(student, column, index) : null)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);


const renderDataModal = () => {
  return (
      <>

          {isDataModalOpen && (
            <div>
                <div className="modal-background"></div>
       
              <div className="Attendance1">
                
                
                      <button type="button" className="close-modal" onClick={closeDataModal}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                      </button>
                      <div className="SelectData">REPORTS CREATION</div>

        <div className="SemSchool-container">
            <div className="admin-dropdown-attendance">
              
                <select
                    id="semester"
                    className="filter-select"
                    onChange={(e) => setSelectedSemester(e.target.value)}
                    value={selectedSemester}
                >
                    <option value="" disabled hidden>
                        Select Semester
                    </option>
                    <option value="First Semester">First Semester</option>
                    <option value="Second Semester">Second Semester</option>
                </select>
            </div>

            {/* School Year Dropdown */}
            <div className="admin-dropdown-attendance">
                <select
                    id="schoolYear"
                    className="filter-select"
                    onChange={(e) => setSelectedSchoolYear(e.target.value)}
                    value={selectedSchoolYear}
                >
                    <option value="" hidden>Select School Year</option>
                    {schoolYears.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        <div className="SemSchool-container1">
            {/* Render data filter checkboxes */}
     

            <div className="filter-dropdown">
                <label htmlFor="studentNumberFilter">Student Number:</label>
                <select
                    className="filter-select"
                    value={selectedSchoolNumber}
                    onChange={(e) => setSelectedSchoolNumber(e.target.value)}
                >
                    <option value="">Select School Number</option>
                    {getUniqueSchoolNumbers().map((schoolNumber) => (
                        <option key={schoolNumber} value={schoolNumber}>
                            {schoolNumber}
                        </option>
                    ))}
                </select>
            </div>

            <div className="filter-dropdown">
                <label htmlFor="courseFilter">Course:</label>
                <select
                    className="filter-select"
                    value={selectedCourse}
                    onChange={(e) => setSelectedCourse(e.target.value)}
                >
                    <option value="">Select Course</option>
                    {getUniqueCourses().map((course) => (
                        <option key={course} value={course}>
                            {course}
                        </option>
                    ))}
                </select>
            </div>

            <div className="filter-dropdown">
                <label htmlFor="genderFilter">Gender:</label>
                <select
                    className="filter-select"
                    value={selectedGender}
                    onChange={(e) => setSelectedGender(e.target.value)}
                >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>
        </div>  
        {renderDataFilterCheckboxes()}
        <button type="button" className="download-csv" onClick={downloadTableDataAsXLSX}>
            <FontAwesomeIcon icon={faDownload} /> Download XLSX
        </button>

        <div className="admin-table21">
       
            <div className="Displayed">Displayed Data</div>

         
            <div className="search">
                <div className="search-input-userlist1">
                    <input
                        type="text"
                        id="courseFilter1"
                        placeholder="Search by student, name, email, or course"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <FontAwesomeIcon icon={faSearch} className="search-icon-userlist1" />
                </div>
            </div>
            {renderDataTable()}
        </div>
        </div>
        </div>
      )}
        </>
    );
};
    
    return (
      // Your component JSX goes here, you can use renderDataModal() or directly include its content here
      <div>
         {renderDataModal()}
      <button className="button-modal-admin" onClick={openDataModal}>
      <FontAwesomeIcon icon={faDownload} className="download-icon-modal-admin" />
          Open Report
        </button>
       
      </div>
      
    );
  };

  export default AdminModalTable;