import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import './LateTotal.css'; // Update the CSS file name

const LateTotal = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [lateCount, setLateCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          let totalLateCount = 0;

          // Iterate through each student entry and count late appearances
          Object.values(attendanceData).forEach(studentData => {
            if (studentData['Time In']?.appearance === 'Late' || studentData['Time Out']?.appearance === 'Late') {
              totalLateCount++;
            }
          });

          // Update state with the total late count
          setLateCount(totalLateCount);
        } else {
          // If attendance data doesn't exist, set late count to 0
          setLateCount(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate]);

  return (
    <div className="late-total-display"> {/* Update class name */}
      <h2>Total Late: </h2> {/* Update text content */}
      <div className='late-count-ttl'> {/* Update class name */}
        {lateCount}
      </div>
    </div>
  );
};

export default LateTotal;
