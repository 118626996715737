import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function AboutUsModal() {
  const [showAboutUs, setShowAboutUs] = useState(false);

  const handleCloseAboutUs = () => setShowAboutUs(false);
  const handleShowAboutUs = () => setShowAboutUs(true);

  return (
    <div>
      <Button
        onClick={handleShowAboutUs}
        style={{
          background: "none" /* Remove background color */,
          border: "none" /* Remove the border */,
          padding: "0" /* Remove padding */,
          cursor: "pointer" /* Change cursor to pointer on hover */,
          color: "#007bff" /* Set the text color */,
          fontSize: "24px" /* Set the font size */,
        }}
      >
        About Us
      </Button>

      <Modal
        size="xl"
        show={showAboutUs}
        animation={true}
        onHide={handleCloseAboutUs}
      >
        <Modal.Header closeButton>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Welcome to the ROTC Enrollment and Attendance Monitoring System, a
            technological innovation designed to enhance the Reserve Officers'
            Training Corps (ROTC) program at the Polytechnic University of the
            Philippines (PUP). Our mission is to modernize and optimize the ROTC
            experience for students and streamline administrative processes for
            program officers and administrators.
          </p>
          <p>Our Commitment:</p>
          At the core of our commitment is the pursuit of excellence within the
          ROTC program. We are dedicated to fostering a more interactive and
          engaging environment for students while promoting efficiency and
          transparency in program management. Our use of QR code technology
          ensures accurate attendance tracking and allows students to actively
          participate in their ROTC journey.
          <p>Who We Serve:</p>
          Our system serves a diverse range of stakeholders, including students,
          ROTC officers, administrators, PUP Lopez, and the local community in
          Lopez, Quezon Province. We believe in the positive impact of
          well-trained and responsible ROTC students who actively contribute to
          local initiatives, disaster preparedness, and community service
          activities.
          <p>Technological Advancement:</p>
          We recognize the importance of technological advancement in education.
          By leveraging QR code technology and innovative management practices,
          we equip students with valuable digital literacy skills, preparing
          them for an evolving technological landscape. Our commitment to
          innovation extends to PUP Lopez, positioning it as a forward-thinking
          educational institution.
          <p>Data Privacy:</p>
          We prioritize data privacy and adhere to strict confidentiality
          standards in handling personal information collected by the system.
          Your privacy and the security of your data are paramount to us.
          <p>Continuous Improvement:</p>
          We are dedicated to continuous improvement. Our system administrators
          actively monitor and update the system to ensure it meets the evolving
          needs of the ROTC program and its stakeholders.
          <br />
          <br />
          We invite you to explore our system and experience the benefits it
          offers to the PUP ROTC program, PUP Lopez, and the wider community.
          Together, we are shaping a brighter future through technology and
          education. Thank you for being a part of our journey.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAboutUs}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AboutUsModal;
