import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { getDatabase, ref, get, set, remove, onValue } from "firebase/database";
import "./Authority.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import bcrypt from 'bcryptjs'; // Import bcryptjs
import './AdminSignUpOfficer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Form, Button, Row , Col, InputGroup  } from 'react-bootstrap';


function AdminSignUpOfficer() {
    const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
    const [selectedSemester, setSelectedSemester] = useState('');
    const [schoolYears, setSchoolYears] = useState([]);
    const [databaseOptions, setDatabaseOptions] = useState({});
    const [studentNumber, setStudentNumber] = useState('');
    const [studentData, setStudentData] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]); // Define state for selected options
    const [selectedOption, setSelectedOption] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [course, setCourse] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showSignUpForm, setShowSignUpForm] = useState(false); // State to control form visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [studentEmail, setStudentEmail] = useState(''); // State variable for student email
    const [uid, setUid] = useState(''); // State variable for UID

    const toggleShowPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(prevState => !prevState);
    };

    useEffect(() => {
        const fetchSchoolYears = async () => {
            try {
                const schoolYearsRef = ref(db, 'Officer');
                const schoolYearsSnapshot = await get(schoolYearsRef);
                if (schoolYearsSnapshot.exists()) {
                    const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
                    setSchoolYears(schoolYearsList);
                } else {
                    setSchoolYears([]);
                }
            } catch (error) {
                console.error('Error fetching school years:', error.message);
            }
        };

        fetchSchoolYears();
    }, []);

    const fetchDatabaseOptions = async () => {
        if (selectedSchoolYear && selectedSemester) {
            try {
                const semesterRef = ref(db, `Officer/${selectedSchoolYear}/${selectedSemester}`);
                const semesterSnapshot = await get(semesterRef);
                if (semesterSnapshot.exists()) {
                    setDatabaseOptions(semesterSnapshot.val());
                } else {
                    setDatabaseOptions({});
                }
            } catch (error) {
                console.error('Error fetching database options:', error);
            }
        }
    };

    useEffect(() => {
        fetchDatabaseOptions();
    }, [selectedSchoolYear, selectedSemester]);

    const handleDelete = async (option, studentNumber) => {
        if (selectedSchoolYear && selectedSemester) {
            try {
                const semesterRef = ref(db, `Officer/${selectedSchoolYear}/${selectedSemester}/${option}/${studentNumber}`);
                await remove(semesterRef);
                console.log('Student number deleted successfully:', studentNumber);
                fetchDatabaseOptions(); // Refresh databaseOptions after deletion
            } catch (error) {
                console.error('Error deleting student number:', error);
            }
        }
    };

    const handleStudentNumberChange = (e) => {
        setStudentNumber(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if all necessary data is selected
        if (!selectedSchoolYear || !selectedSemester || !selectedOption || !studentNumber) {
            toast.error('Please select school year, semester, option, and enter your student number.', { position: 'top-center' });
            return;
        }
    
        try {
            // Fetch UID for the student number
            const usersRef = ref(db, 'users');
            const userSnapshot = await get(usersRef);
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                for (const uid in userData) {
                    if (userData[uid].studentNumber === studentNumber) {
                        // If the student number is found, set the UID
                        setUid(uid);
                        break;
                    }
                }
            }
    
            // Check if the student number exists in the databaseOptions
            if (databaseOptions && databaseOptions[selectedOption] && databaseOptions[selectedOption][studentNumber]) {
                setStudentData(databaseOptions[selectedOption][studentNumber]);
    
                if (databaseOptions[selectedOption][studentNumber].authorized === 'Authorized') {
                    // If student number is authorized, hide the sign-up form
                    setShowSignUpForm(false);
                    toast.info('Your student number is already authorized.', { position: 'top-center' }); // Show toast
                } else {
                    // If not authorized, show the sign-up form
                    setShowSignUpForm(true);
                }
            } else {
                setStudentData(null);
                toast.error('Your student number is not authorized.', { position: 'top-center' });
                setShowSignUpForm(true); // Show the sign-up form if student number not found
            }
        } catch (error) {
            console.error('Error checking student number:', error);
            toast.error('Failed to check student number. Please try again later.', { position: 'top-center' });
        }
    };
    
    

      const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            // Check if all fields are filled
            if (!studentEmail || !password || !confirmPassword) {
                toast.error('Please fill in all fields.', { position: 'top-center' });
                return;
            }

            // Ensure passwords match
            if (password !== confirmPassword) {
                toast.error('Passwords do not match.', { position: 'top-center' });
                return;
            }

            // Reference the appropriate location in the database to check authorization
            const signUpRef = ref(db, `Officer/${selectedSchoolYear}/${selectedSemester}/${selectedOption}/${studentNumber}`);
            const signUpSnapshot = await get(signUpRef);

            // Check if the student number already has authorized data
            if (signUpSnapshot.exists() && signUpSnapshot.val().authorized === 'Authorized') {
                // Display error message if the student number is already authorized
                toast.error('Student number is already authorized.', { position: 'top-center' });
                return;
            }

            // Encrypt the password using bcrypt
            const hashedPassword = await bcrypt.hash(password, 10); // 10 is the number of salt rounds

            // Construct the data object to be saved
            const userData = {
                email: studentEmail,
                password: hashedPassword,
                authorized: 'Authorized'
            };

            // Save the data to the database
            await set(signUpRef, userData);

            // Display success message
            toast.success('Sign-up successful!', { position: 'top-center' });

            // Clear form fields after successful submission
            setEmail('');
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Error signing up:', error);
            toast.error('Failed to sign up. Please try again later.', { position: 'top-center' });
        }
    };
    
    // Define the function to fetch UID for the student number
    const fetchUidForStudentNumber = async () => {
        // Use the student number to fetch the UID from the database
        const usersRef = ref(db, 'users');
        const userSnapshot = await get(usersRef);
        if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            for (const uid in userData) {
                if (userData[uid].studentNumber === studentNumber) {
                    // Found the UID for the student number
                    setUid(uid);
                    return;
                }
            }
        }
        // If UID not found or no user data exists
        setUid('');
    };

    // Define the function to fetch email using UID
    const fetchEmailForUid = async () => {
        if (uid) {
            const usersRef = ref(db, `users/${uid}/email`);
            const emailSnapshot = await get(usersRef);
            if (emailSnapshot.exists()) {
                setStudentEmail(emailSnapshot.val());
            } else {
                setStudentEmail('');
            }
        }
    };

    useEffect(() => {
        // Fetch email whenever UID changes
        fetchEmailForUid();
    }, [uid]);

    useEffect(() => {
        // Fetch UID whenever student number changes
        fetchUidForStudentNumber();
    }, [studentNumber]);


    return (
        <div className="Admin-authority-off-su">
            <div className="Admin-authority-container-off-su">
                <h1 className="Admin-authority-heading-off-su">Sign Up Officer</h1>
                <form className="admin-signup-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Enter Student Number"
                        value={studentNumber}
                        onChange={handleStudentNumberChange}
                    />
                    <div className="option-selection-off-su">
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="EXO"
                                checked={selectedOption === 'EXO'}
                                onChange={() => setSelectedOption('EXO')}
                            />
                            EXO {selectedOption === 'EXO' && <FontAwesomeIcon icon={faCheck} />}
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="S1"
                                checked={selectedOption === 'S1'}
                                onChange={() => setSelectedOption('S1')}
                            />
                            S1 {selectedOption === 'S1' && <FontAwesomeIcon icon={faCheck} />}
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="S2"
                                checked={selectedOption === 'S2'}
                                onChange={() => setSelectedOption('S2')}
                            />
                            S2 {selectedOption === 'S2' && <FontAwesomeIcon icon={faCheck} />}
                        </label>
                    </div>
                    <Button type="submit">Submit</Button>
                </form>
                {studentData && studentData.authorized === 'Authorized' && (
    <div>
        <div className="student-data-off-su">
            <p>Student Number: {studentNumber}</p>
            <p>School Year: {selectedSchoolYear}</p>
            <p>Semester: {selectedSemester}</p>
            <p>Option: {selectedOption}</p>
            <p>Authorized</p>
            <p>Email: {studentData.email}</p>
        </div>
    </div>
)}

                {showSignUpForm && studentData && (
                    <div>
                        <div className="student-data-off-su">
                            <p>Student Number: {studentData.studentNumber}</p>
                            <p>School Year: {selectedSchoolYear}</p>
                            <p>Semester: {selectedSemester}</p>
                            <p>Option: {selectedOption}</p>
                        </div>
                        <Form className="sign-up-form-su" onSubmit={handleSignUp}>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                <Form.Label column sm="3">Email</Form.Label>
                                <Col sm="9">
                                    <InputGroup>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={studentEmail} 
                                            readOnly /* Add the readOnly attribute */
                                            style={{ backgroundColor: '#f5f5f5', cursor: 'not-allowed' }} // Add custom styles for indication
                                        />
                                    </InputGroup>
                                    <small className="text-muted">This field is not editable</small> {/* Add a small note */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="password-input">
                                <Form.Label column sm="3">Password</Form.Label>
                                <Col sm="9">
                                    <InputGroup>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            style={{
                                                border: "1px solid #343a40",
                                                height: "40px",
                                                width: "200px",
                                                fontSize: "18px",
                                            }}
                                        />
                                        <Button onClick={toggleShowPassword}
                                            variant="outline-dark"
                                            aria-label={showPassword ? "Hide Password" : "Show Password"}
                                            className="show-eye-button">
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="confirm-password-input">
                                <Form.Label column sm="3">Confirm Password</Form.Label>
                                <Col sm="9">
                                    <InputGroup>
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            style={{
                                                border: "1px solid #343a40",
                                                height: "40px",
                                                width: "200px",
                                                fontSize: "18px",
                                            }}
                                        />
                                        <Button onClick={toggleShowConfirmPassword}
                                            variant="outline-dark"
                                            aria-label={showPassword ? "Hide Password" : "Show Password"}
                                            className="show-eye-button"
                                        >
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Sign Up
                            </Button>
                        </Form>
                    </div>
                )}
                {!showSignUpForm && (!studentData || (studentData && studentData.authorized !== 'Authorized')) && (
                <div className='admin-dropdown-authority-off-su-container'>
                    <div className="admin-dropdown-authority-off-su">
                        <select
                            id="semester"
                            className="filter-select"
                            onChange={(e) => setSelectedSemester(e.target.value)}
                            value={selectedSemester}
                        >
                            <option value="" disabled hidden>Select Semester</option>
                            <option value="First Semester">First Semester</option>
                            <option value="Second Semester">Second Semester</option>
                        </select>
                    </div>
                    <div className="admin-dropdown-authority-off-su">
                        <select
                            id="schoolYear"
                            className="filter-select"
                            onChange={(e) => setSelectedSchoolYear(e.target.value)}
                            value={selectedSchoolYear}
                        >
                            <option value="" hidden>Select School Year</option>
                            {schoolYears.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}  
                <ToastContainer />
            </div>
        </div>
    );
}

export default AdminSignUpOfficer;
