import React, { useEffect, useState} from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ref, onValue, get } from 'firebase/database';
import { db } from '../Firebase';
import * as XLSX from 'xlsx';
import './AttendanceModal.css';


Modal.setAppElement('#root'); // Set the root element for accessibility

const AttendanceModal = ({ isOpen, closeModal, userData, recordsForStudent, displayedRowNumber }) => {
  const [selectedSemester, setSelectedSemester] = useState('First Semester');
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('2023-2024');
  const [userDataArray, setUserDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingTrainingDates, setExistingTrainingDates] = useState([]);
  const [filterPrefix, setFilterPrefix] = useState('');
  const [filterCourse, setFilterCourse] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedFilterTrainingDate, setSelectedFilterTrainingDate] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedTrainingDate, setSelectedTrainingDate] = useState(null);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const handleFilterStatusChange = (newFilterStatus) => {
    setFilterStatus(newFilterStatus);
  };

  const handleFilterPrefixChange = (newFilterPrefix) => {
    setFilterPrefix(newFilterPrefix);
  };

  const handleFilterCourseChange = (newFilterCourse) => {
    setFilterCourse(newFilterCourse);
  };

  const handleFilterGenderChange = (newFilterGender) => {
    setFilterGender(newFilterGender);
  };

  useEffect(() => {
    const dbRef = ref(db, 'users');
    const callback = (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const userList = Object.values(userData);
        setUserDataArray(userList);
      } else {
        console.log('No user data found in the database.');
      }
    };

    const unsubscribe = onValue(dbRef, callback);

    return () => {
      unsubscribe();
    };
  }, []);

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map(student => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach(studentNumber => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid]; // Storing user details by UID
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

  // Fetch student UIDs when userDataArray changes
  useEffect(() => {
    if (userDataArray.length > 0) {
      fetchUserUIDs(userDataArray);
    }
  }, [userDataArray]);
  const fetchAttendanceData = async () => {
    setLoading(true);
  
    try {
      const userRef = ref(db, 'users');
      const userSnapshot = await get(userRef);
  
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const userList = Object.values(userData);
        setUsers(userList); // Update user state
      } else {
        console.log('No user data found in the database.');
      }
  
      if (selectedSemester !== '' && selectedSchoolYear !== '') {
        const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const userSemesterSnapshot = await get(userSemesterRef);
        if (userSemesterSnapshot.exists()) {
          const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
          setEnrolledStudents(enrolledStudentsData);
          fetchUserUIDs(enrolledStudentsData);
        } else {
          setEnrolledStudents([]);
          setStudentUIDs({});
        }
      
        
  
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates`;
        const trainingDatesRef = ref(db, sessionPath);
        const trainingDatesSnapshot = await get(trainingDatesRef);
  
        if (trainingDatesSnapshot.exists()) {
          const trainingDatesData = trainingDatesSnapshot.val();
          setExistingTrainingDates(trainingDatesData);
  
          const initialTrainingDate = Object.keys(trainingDatesData)[0];
          setSelectedTrainingDate(initialTrainingDate);
        } else {
          setExistingTrainingDates([]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };
  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber
    }));
  };
  useEffect(() => {
    fetchAttendanceData();
  }, [selectedSemester, selectedSchoolYear]);

  useEffect(() => {
    async function fetchSchoolYears() {
      try {
        const schoolYearsRef = ref(db, 'SchoolYear');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      } 
    }

    fetchSchoolYears();
  }, []);


  const [tableRef, setTableRef] = useState(null);

  const filterEnrolledStudents = (allRecords, enrolledStudents) => {
    return allRecords.filter((record) => {
      return enrolledStudents.some((student) => student.studentNumber === record.studentNumber);
    });
  };
  

  const renderAttendanceData = (trainingDates) => {
    const allRecords = Object.entries(trainingDates || {}).flatMap(([trainingDate, attendanceRecords]) => {
        return Object.entries(attendanceRecords || {}).map(([studentNumber, record]) => ({
            studentNumber,
            trainingDate,
            ...record,
        }));
    });
    const enrolledRecords = filterEnrolledStudents(allRecords, enrolledStudents);

    const filteredTrainingDates = selectedFilterTrainingDate
        ? { [selectedFilterTrainingDate]: trainingDates[selectedFilterTrainingDate] }
        : trainingDates;

    // Utility function to convert time format
    const convertTimeFormat = (time) => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const suffix = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${suffix}`;
    };

    const studentNumbers = enrolledStudents.map((student) => student.studentNumber);

    let displayedRowNumber = 0;

    const getLatestRecordTime = (allRecords, studentNumber) => {
        const recordsForStudent = allRecords.filter((r) => r.studentNumber === studentNumber);
        const latestTime = recordsForStudent.reduce((latest, record) => {
            const recordTime = record['Time In']?.time || record['Time Out']?.time || '12:00:00 AM';
            return latest.localeCompare(recordTime) >= 0 ? latest : recordTime;
        }, '12:00:00 AM');

        return latestTime;
    };

    const handleExcelDownload = () => {
      if (tableRef) {
        const table = tableRef.cloneNode(true);
        const ws = XLSX.utils.table_to_sheet(table); // Use XLSX from the imported library
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Attendance Data');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const fileName = 'attendance_data.xlsx';
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      }
    };
  
    return (
      <div className='Attendance'>
        <div className='attendance-container'>
          <div className="Header-list-Attendance1">
            <h1>Attendance</h1>
          </div>

<div className='attendance-time-filters'>
          <div className='filter-attendance'>
          <div class="admin-dropdown-attendance">
    <select
      id="semester"
      class="user-list-label1"
      onChange={(e) => setSelectedSemester(e.target.value)}
      value={selectedSemester}
    >
      <option value="" disabled hidden>
        Select Semester
      </option>
      <option value="First Semester">First Semester</option>
      <option value="Second Semester">Second Semester</option>
    </select>
  </div>

  <div class="admin-dropdown-attendance">
    <select
      id="schoolYear"
      class="user-list-label1"
      onChange={(e) => setSelectedSchoolYear(e.target.value)}
      value={selectedSchoolYear}
    >
      <option value="" hidden>Select School Year</option>
      {schoolYears.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </div>

            <div className="admin-dropdown-attendance">
              <select
                id="filterCourse"
                className="user-list-label1"
                onChange={(e) => handleFilterCourseChange(e.target.value)}
                value={filterCourse}
              >
                <option value="" disabled hidden>
                  Course
                </option>
                <option value="">All</option>
                {[...new Set(userDataArray.map((user) => user.Course))].map((course) => (
                  <option key={course} value={course}>
                    {course}
                  </option>
                ))}
              </select>
            </div>
            <div className="admin-dropdown-attendance">
              <select
                id="filterGender"
                className="user-list-label1"
                onChange={(e) => handleFilterGenderChange(e.target.value)}
                value={filterGender}
              >
                <option value="" disabled hidden>
                  Gender
                </option>
                <option value="">All</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
            </div>

            <div className="admin-dropdown-attendance">
            <select
              id="filterTrainingDate"
              className="user-list-label1"
              onChange={(e) => setSelectedFilterTrainingDate(e.target.value)}
              value={selectedFilterTrainingDate}
            >
              <option value="">All Training Dates</option>
              {existingTrainingDates &&
                Object.keys(existingTrainingDates).map((date) => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
            </select>
          </div>

            <div className="admin-dropdown-attendance-search">
              <label htmlFor="search"></label>
              <input
                type="text"
                id="search"
                className="user-list-label1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Enter text to search"
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon-attendance" />
            </div>
          </div>

          <button className="download-button-attendance-csv" onClick={handleExcelDownload}>
            Download xlsx
          </button>
          </div>

          {loading && <p>Loading data...</p>}

{studentNumbers.length > 0 ? (
    <div className="table-container-attendance">
        <table className="table-attendance" ref={(ref) => setTableRef(ref)}>
            <thead>
                <tr>
                <th style={{ whiteSpace: 'nowrap' }}>No.</th>
        <th style={{ whiteSpace: 'nowrap' }}>Student Number</th>
        <th style={{ whiteSpace: 'nowrap' }}>Lastname</th>
        <th style={{ whiteSpace: 'nowrap' }}>Firstname</th>
        <th style={{ whiteSpace: 'nowrap' }}>Middlename</th>
        <th style={{ whiteSpace: 'nowrap' }}>Course</th>
        <th style={{ whiteSpace: 'nowrap' }}>Gender</th>
        {Object.keys(filteredTrainingDates)
            .sort((a, b) => new Date(a) - new Date(b))
            .map((trainingDate) => (
                <th key={trainingDate.split(': ')[0]} style={{ whiteSpace: 'nowrap' }}>{trainingDate.split(': ')[0]}</th>
            ))}
                </tr>
            </thead>
            <tbody>
                {studentNumbers.map((studentNumber, index) => {
                    const userData = userDetails[studentUIDs[studentNumber]];
                    const recordsForStudent = allRecords.filter((r) => r.studentNumber === studentNumber);

                    displayedRowNumber++;
                    // Inside the rendering logic
                    const firstName = userData && userData.Firstname ? capitalizeFirstLetter(userData.Firstname) : '-';
                    const lastName = userData && userData.Lastname ? capitalizeFirstLetter(userData.Lastname) : '-';
                    const middleName = userData && userData.Middlename ? capitalizeFirstLetter(userData.Middlename) : '-';
                    return (
                        <tr key={index}>
                            <td>{displayedRowNumber}</td>
                            <td>{studentNumber}</td>
                            <td>{firstName}</td>
                            <td>{lastName}</td>
                            <td>{middleName}</td>
                            <td>{userData ? userData.Course : '-'}</td>
                            <td>{userData ? userData.Gender : '-'}</td>
                            {Object.keys(filteredTrainingDates)
                                .sort((a, b) => parseInt(a.match(/\d+/)[0]) - parseInt(b.match(/\d+/)[0]))
                                .map((trainingDate) => {
                                    const record = recordsForStudent.find((r) => r.trainingDate === trainingDate);
                                    const statusClass = record ? (record['Time In']?.time || record['Time Out']?.time || '-') : '-';
                                    const statusValue = record ? (record['Time In']?.time || record['Time Out']?.time || '-') : '-';

                                    return (
                                        <td key={`${trainingDate}-${studentNumber}`} className={`status-${statusClass.toLowerCase()}`}>
                                            {statusValue}
                                        </td>
                                    );
                                })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
) : (
    <p>No matching students found.</p>
)}
</div>
</div>
);
};
  
  return (
    <div className={isOpen ? "modaltime open" : "modal-attendancetime"}>
    <div className="modal-attendancetime-content">
      <button className="close-modal-time-button" onClick={closeModal}>
        Close
      </button>
      {renderAttendanceData(existingTrainingDates)}
    </div>
  </div>
  
  );
};

export default AttendanceModal;