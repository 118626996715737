import React, { useState, useEffect } from "react";
import { auth, db } from "../Firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { ref, get } from "firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth"; // Import missing function
import Alert from "react-bootstrap/Alert";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const isValidEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const checkIfEmailExists = async () => {
    try {
      const userRef = ref(db, "users"); // Assuming you have a "users" node in your database
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const emailExists = Object.values(snapshot.val()).some((user) => user.email === email);
        return emailExists;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error.message);
      return false;
    }
  };

  const sendForgotPasswordLink = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset email sent. Check your inbox.");
      setErrorMessage(""); // Clear any existing error message
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      setErrorMessage("Password reset failed. Please check the email address.");
      setSuccessMessage(""); // Clear any existing success message
    }
  };

  const handlePasswordReset = async () => {
    try {
      setIsProcessing(true);
  
      if (!isValidEmail(email)) {
        setErrorMessage("Please enter a valid email address.");
        setSuccessMessage("");
        return;
      }
  
      // Check if email exists in Officer database
      const officerRef = ref(db, `Officer`);
      const officerSnapshot = await get(officerRef);
  
      let emailExistsInOfficerDB = false;
  
      if (officerSnapshot.exists()) {
        Object.keys(officerSnapshot.val()).forEach(async (designation) => {
          const userRef = ref(db, `Officer/${designation}`);
          const userSnapshot = await get(userRef);
  
          if (userSnapshot.exists()) {
            Object.keys(userSnapshot.val()).forEach((studentNumber) => {
              const userData = userSnapshot.val()[studentNumber];
              if (userData.email === email) {
                emailExistsInOfficerDB = true;
                // Send password reset email
                sendForgotPasswordLink(email);
              }
            });
          }
        });
      }
  
      // Check if email exists in users database
      const userRef = ref(db, "users");
      const userSnapshot = await get(userRef);
  
      let emailExistsInUserDB = false;
  
      if (userSnapshot.exists()) {
        Object.values(userSnapshot.val()).forEach((user) => {
          if (user.email === email) {
            emailExistsInUserDB = true;
            // Send password reset email
            sendForgotPasswordLink(email);
          }
        });
      }
  
      if (!emailExistsInOfficerDB && !emailExistsInUserDB) {
        setErrorMessage("No user found with this email address. Please register an account.");
        setSuccessMessage("");
        return;
      }
  
    } catch (error) {
      console.error("Error:", error.message);
  
      if (error.code === "auth/too-many-requests") {
        setErrorMessage("Too many requests. Please try again later.");
      } else if (error.code === "auth/invalid-email") {
        setErrorMessage("Invalid email address. Please enter a valid email.");
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage("No user found with this email address. Please register an account.");
      } else {
        setErrorMessage("Password reset failed. Please check the email address.");
      }
  
      setSuccessMessage(""); // Clear any existing success message
    } finally {
      setIsProcessing(false);
    }
  };
  
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000);

    return () => clearTimeout(timer);
  }, [successMessage, errorMessage]);

  return (
    <div className="forgot-password-container">
      <h2>User Account Verification and Password Reset</h2>
      <div className="error-container">
        {errorMessage && (
          <Alert className="error-alert" variant="danger">
            <Alert.Heading>Error</Alert.Heading>
            {errorMessage}
          </Alert>
        )}
      </div>
      <div className="success-container">
        {successMessage && (
          <Alert className="success-alert" variant="success">
            <Alert.Heading>Success</Alert.Heading>
            {successMessage}
          </Alert>
        )}
      </div>
      <form>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="button" onClick={handlePasswordReset} disabled={isProcessing}>
          {isProcessing ? "Processing..." : "Forgot Password"}
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
