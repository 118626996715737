import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function SOP() {
  const [showSOP, setShowSOP] = useState(false);

  const handleCloseSOP = () => setShowSOP(false);
  const handleShowSOP = () => setShowSOP(true);

  return (
    <div>
      <Button
        onClick={handleShowSOP}
        style={{
          background: "none" /* Remove background color */,
          border: "none" /* Remove the border */,
          padding: "0" /* Remove padding */,
          cursor: "pointer" /* Change cursor to pointer on hover */,
          color: "#007bff" /* Set the text color */,
          fontSize: "24px" /* Set the font size */,
        }}
      >
        SOP
      </Button>

      <Modal size="lg" show={showSOP} animation={true} onHide={handleCloseSOP}>
        <Modal.Header closeButton>
          <Modal.Title>SOP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <br />
            1. Saluting business
            <br />
            2. Greeting business
            <br />
            3. Always begin with Mam/Sir and end with Mam/Sir
            <br />
            4. Side stepping
            <br />
            5. Always stand at attention when the officers are passing or
            talking
            <br />
            6. Always ask permission to talk
            <br />
            7. Do not make any promises if you cannot do it
            <br />
            8. Always move in the double
            <br />
            9. Always consult the bulletin board 3x a day
            <br />
            10. Always attend your own duty
            <br />
            11. Do not squeal
            <br />
            12. Avoid malingering
            <br />
            13. Always say mate/buddy to your classmate
            <br />
            14. Sit erect, stomach in, chest out.
            <br />
            15. Always log in and log out in the logbook.
            <br />
            16. Avoid over familiarization
            <br />
            17. Do not sit if the officers say so.
            <br />
            18. Make your excuses reasonable.
            <br />
            19. Tap 3x before entering and leaving the room
            <br />
            20. Caution your mate regarding their posture
            <br />
            21. Always occupy ¼ of your seat.
            <br />
            22. Obey first before you complain
            <br />
            23. No smoking
            <br />
            24. Try to speak in English
            <br />
            25. Do all SOPs
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSOP}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SOP;
