import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import { Pie } from 'react-chartjs-2';
import './AttendanceSummary.css';
import Spinner from "react-bootstrap/Spinner";
const AttendanceSummary = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [absentStudents, setAbsentStudents] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);
  const [lateStudents, setLateStudents] = useState([]);
  const [excusedStudents, setExcusedStudents] = useState([]);
  const [genderData, setGenderData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          const studentEntries = Object.entries(attendanceData);

          const filterStudentsByAppearance = (appearance) => {
            return studentEntries
              .filter(([_, studentData]) => {
                const timeInAppearance = studentData['Time In']?.appearance;
                const timeOutAppearance = studentData['Time Out']?.appearance;
                return timeInAppearance === appearance || timeOutAppearance === appearance;
              })
              .map(([studentNumber, _]) => studentNumber);
          };
          
          const absentStudentsData = filterStudentsByAppearance('Absent');
          const presentStudentsData = filterStudentsByAppearance('Present');
          const lateStudentsData = filterStudentsByAppearance('Late');
          const excusedStudentsData = filterStudentsByAppearance('Excused');

          setAbsentStudents(absentStudentsData);
          setPresentStudents([...presentStudentsData, ...lateStudentsData]); // Combine Present and Late
          setLateStudents(lateStudentsData);
          setExcusedStudents(excusedStudentsData);
        } else {
          setAbsentStudents([]);
          setPresentStudents([]);
          setLateStudents([]);
          setExcusedStudents([]);
        }

        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const enrolledSnapshot = await get(enrolledRef);

        if (enrolledSnapshot.exists()) {
          const enrolledData = enrolledSnapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);

          const usersRef = ref(db, 'users');
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const genderCounts = {};

            const countStudentsByAppearance = (appearance, studentsData) => {
              Object.values(users).forEach(user => {
                if (studentNumbers.includes(user.studentNumber)) {
                  const { Gender } = user;
                  if (!genderCounts[Gender]) {
                    genderCounts[Gender] = {
                      Absent: 0,
                      Present: 0,
                      Late: 0,
                      Excused: 0
                    };
                  }
                  if (studentsData.includes(user.studentNumber)) {
                    genderCounts[Gender][appearance]++;
                  }
                }
              });
            };
            
            countStudentsByAppearance('Absent', absentStudents);
            countStudentsByAppearance('Present', presentStudents);
            countStudentsByAppearance('Late', lateStudents);
            countStudentsByAppearance('Excused', excusedStudents);

            setGenderData(genderCounts);
          } else {
            setGenderData({});
          }
        } else {
          setGenderData({});
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate, absentStudents, presentStudents, lateStudents, excusedStudents]);

  const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
  ];

  const prepareChartData = () => {
    if (!genderData || Object.keys(genderData).length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }
  
    const genders = Object.keys(genderData);
    const data = genders.map(gender => genderData[gender]?.Present || 0); // Use Present instead of Absent
    const backgroundColor = colors.slice(0, data.length);
  
    return {
      labels: genders.map(gender => `${gender} (Present)`), // Update labels to include Present
      datasets: [{
        label: 'Present Per Gender', // Update label
        data: data,
        backgroundColor: backgroundColor,
      }],
    };
  };
  
  return (
    <div className="attendance-summary"> {/* Add class name */}
      <h2>Present: Male & Female</h2> {/* Update title */}
      <Pie data={prepareChartData()} />
    </div>
  );
};

export default AttendanceSummary;
