import React, { useEffect, useState } from "react";
import { getAuth, signOut  } from "firebase/auth";
import {
  getDatabase,
  ref as dbRef, ref,
  get, update
} from "firebase/database";
import { app } from "../Firebase";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import "./CadetAttend.css";
import Spinner from "react-bootstrap/Spinner";
import { MdKeyboardArrowDown } from "react-icons/md";
import SideBarCadet from "./CadetSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function CadetAttend() {
  const [userData, setUserData] = useState(() => {
    // Try to retrieve user data from localStorage
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          Firstname: "",
          Lastname: "",
          Middlename: "",
          Course: "",
          Position: "",
          email: "",
          studentNumber: "",
        };
  });

  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");

  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedSchoolYear, setSelectedSchoolYear] = useState("");
  const [schoolYears, setSchoolYears] = useState([]);
  const [trainingData, setTrainingData] = useState({});
  const [loadingTrainingData, setLoadingTrainingData] = useState(false);

 
  useEffect(() => {
    const fetchSchoolYears = async () => {
      const db = getDatabase();
      const schoolYearsRef = dbRef(db, 'SchoolYear');

      try {
        const snapshot = await get(schoolYearsRef);
        if (snapshot.exists()) {
          const schoolYearsList = Object.keys(snapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);

  useEffect(() => {
    const fetchTrainingData = async () => {
      setLoadingTrainingData(true);
  
      const db = getDatabase();
      const trainingDataRef = dbRef(db, `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates`);
  
      try {
        const snapshot = await get(trainingDataRef);
        if (snapshot.exists()) {
          setTrainingData(snapshot.val());
        } else {
          setTrainingData({});
        }
      } catch (error) {
        console.error("Error fetching training data:", error.message);
      } finally {
        setLoadingTrainingData(false);
      }
    };
  
    if (selectedSemester && selectedSchoolYear) {
      fetchTrainingData();
    }
  }, [selectedSemester, selectedSchoolYear]);
  

  const [userImageUrl, setUserImageUrl] = useState(() => {
    // Try to retrieve the user image URL from local storage
    const storedUserImageUrl = localStorage.getItem("userImageUrl");
    return storedUserImageUrl || ""; // Initialize with an empty string if not found
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error("No user is logged in.");
        return;
      }

      const userId = user.uid;
      const database = getDatabase();
      const userRef = dbRef(database, `users/${userId}`);

      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserData({
            ...userData,
            email: user.email,
          });

          const imageRef = storageRef(
            getStorage(),
            `user_images/${user.email}`,
          );
          const qrCodeImageRef = storageRef(
            getStorage(),
            `qr_code_downloads/${user.email}.png`,
          );

          try {
            const imageUrl = await getDownloadURL(qrCodeImageRef);
            setQrCodeImageUrl(imageUrl);
            setQrCodeGenerated(true);
          } catch (error) {
            console.error("Error fetching QR code image URL:", error);
          }

          try {
            const userImageUrl = await getDownloadURL(imageRef);
            setUserImageUrl(userImageUrl);

            // Store the user image URL in local storage
            localStorage.setItem("userImageUrl", userImageUrl);
          } catch (error) {
            console.error("Error fetching user image URL:", error);
          }
        } else {
          console.error("User data not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);



 

  // Store user data in localStorage
  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  document.addEventListener("DOMContentLoaded", function () {
    const offcanvas = document.getElementById("offcanvas");

    window.addEventListener("resize", function () {
      if (window.innerWidth <= 768) {
        offcanvas.style.right = "0"; // Show the off-canvas when the window is smaller
      } else {
        offcanvas.style.right = "-250px"; // Hide the off-canvas when the window is larger
      }
    });
  });
  document.addEventListener("DOMContentLoaded", function () {
    const offcanvas = document.getElementById("offcanvas");
  
    if (offcanvas) {
      window.addEventListener("resize", function () {
        if (window.innerWidth <= 768) {
          offcanvas.style.right = "0"; // Show the off-canvas when the window is smaller
        } else {
          offcanvas.style.right = "-250px"; // Hide the off-canvas when the window is larger
        }
      });
    } else {
      console.error("Offcanvas element not found.");
    }
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const [sessionData, setSessionData] = useState([]);
  const renderSessionData = () => (
    <div className="session-data-container">
      <div className="session-header-container"></div>
  
      <div className="session-filter-attend1">
        <div className="admin-dropdown-session-attend">
          <label htmlFor="schoolYear"></label>
          <select
            id="schoolYear"
            className="filter-select"
            onChange={(e) => setSelectedSchoolYear(e.target.value)}
            value={selectedSchoolYear}
          >
            <option value="" disabled>
              School Year
            </option>
            {schoolYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="admin-dropdown-session-attend">
          <label htmlFor="semester"></label>
          <select
            id="semester"
            className="filter-select"
            onChange={(e) => setSelectedSemester(e.target.value)}
            value={selectedSemester}
          >
            <option value="" disabled>
              Select Semester
            </option>
            <option value="First Semester">First Semester</option>
            <option value="Second Semester">Second Semester</option>
          </select>
        </div>
      </div>
      <div className="user-info-box-snum1">
        <div className="user-info-box-snum">
          <div className="attend-student-number">
            {userData.Lastname}, {userData.Firstname} {userData.Middlename} (
            {userData.studentNumber}) {userData.Course}
          </div>
        </div>
  
      
 <div className="custom-table-attend">
      {!selectedSchoolYear || !selectedSemester ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
          Please select a school year and semester.
        </div>
      ) : (
        <table className="table-attendance">
          <thead>
            <tr>
              <th>Training day</th>
              <th>Attendance</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(sessionData).length === 0 ||
              !sessionData[selectedSchoolYear] ||
              !sessionData[selectedSchoolYear][selectedSemester] ||
              !sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"] ||
              Object.keys(sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"]).length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
                    No record for this year and semester
                  </div>
                </td>
              </tr>
            ) : isEnrolled ? (
              Object.keys(sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"])
                .sort((a, b) => new Date(a) - new Date(b)) // Sort the training dates by date
                .map((trainingDateKey, index) => (
                  <tr key={`${selectedSchoolYear}-${selectedSemester}-${trainingDateKey}`}>
                    <td>{trainingDateKey}</td>
                    <td>
                      {sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber] &&
                        sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber][Object.keys(sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber])[0]].appearance}
                    </td>
                    <td>
                      {sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber] &&
                        sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber][Object.keys(sessionData[selectedSchoolYear][selectedSemester]["Trainingdates"][trainingDateKey][userData.studentNumber])[0]].time}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="3" style={{ whiteSpace: 'wrap' }}>
                  You are not enrolled for this School Year {selectedSchoolYear} {selectedSemester}.
                  <Link to="/Cadet/AdminEnrollment">Enroll now.</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
      </div>
    </div>
  );
  
 
const [isEnrolled, setIsEnrolled] = useState(false);

useEffect(() => {
  const checkEnrollmentStatus = async () => {
    if (userData.studentNumber && selectedSchoolYear && selectedSemester) {
      const database = getDatabase();
      const enrollmentRef = dbRef(database, `enrolled/${selectedSchoolYear}/${selectedSemester}`);

      try {
        const snapshot = await get(enrollmentRef);
        if (snapshot.exists()) {
          const enrollmentData = snapshot.val();
          const enrolledStudents = Object.values(enrollmentData); // Assuming student data is stored as an array

          // Check if the provided student number exists in the enrolled students array
          const isStudentEnrolled = enrolledStudents.some(student => student.studentNumber === userData.studentNumber);
          setIsEnrolled(isStudentEnrolled);
        } else {
          console.log("No enrollment data found for the selected year and semester.");
          setIsEnrolled(false); // Reset enrollment status if no data found
        }
      } catch (error) {
        console.error("Error fetching enrollment data:", error.message);
        setIsEnrolled(false); // Reset enrollment status on error
      }
    }
  };

  // Check enrollment status only when userData.studentNumber, selectedSchoolYear, or selectedSemester changes
  checkEnrollmentStatus();
}, [userData.studentNumber, selectedSchoolYear, selectedSemester]);
  
  const fetchSessionDataBySemesterYear = async (semester, schoolYear) => {
    try {
      const db = getDatabase(app);
      const sessionRef = ref(db, `Session/${schoolYear}/${semester}`); // Adjust the path based on your database structure

      const snapshot = await get(sessionRef);

      if (snapshot.exists()) {
        const sessionData = snapshot.val();
        setSessionData(sessionData);
      } else {
        console.log("No session data found.");
      }
    } catch (error) {
      console.error("Error fetching session data:", error.message);
    }
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      if (selectedSemester && selectedSchoolYear) {
        await fetchSessionDataBySemesterYear(selectedSemester, selectedSchoolYear);
      }
    };

    fetchSessionData();
  }, [selectedSemester, selectedSchoolYear]);
  
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const db = getDatabase(app);
        const sessionRef = ref(db, "Session"); // Adjust the path based on your database structure

        const snapshot = await get(sessionRef);

        if (snapshot.exists()) {
          const sessionData = snapshot.val();
          setSessionData(sessionData);
        } else {
          console.log("No session data found.");
        }
      } catch (error) {
        console.error("Error fetching session data:", error.message);
      }
    };

    if (selectedSemester && selectedSchoolYear) {
      fetchSessionData();
    }
  }, [selectedSemester, selectedSchoolYear]);

  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Add any additional logic you want to execute when the modal is closed
  };

  
  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut(auth);
        
    const database = getDatabase();
    // Update user's status to "Offline" in the database
    await update(ref(database, `users/${userId}`), { status: "Offline" });
    
    window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
  };
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  return (
    <div className="cadet-info-container">
      <SideBarCadet />
      <div className="cadet-info-container-cadet">
      <div className="Header-list-cadet321">
          <h1>Attendance</h1>
          <div className="user-image-cadet1"> 
          <p>{localStorage.getItem("fullName")}</p>
      {userImageUrl ? (
        <img
          className="user-image enlarged-image" onClick={handleModalOpen}
          src={userImageUrl}
          alt="User"
        />
      ) : (
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

    </div>
        </div>
        {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <Link to="/Cadet/CadetUpdate">
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
 <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
</Link>
    <div className="info-box">
  <p className="Personnel">Cadet</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
      <tr>
      <td><label htmlFor="studentNumber">Student Number:</label></td>
      <td><p>{userData.studentNumber}</p></td>
    </tr>
    <tr>
      <td><label>Name:</label></td>
      <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
    </tr>
    <tr>
      <td><label htmlFor="email">Email:</label></td>
      <td><p>{userData.email}</p></td>
    </tr>
    <tr>
      <td><label>Course:</label></td>
      <td><p>{userData.Course}</p></td>
    </tr>
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}

 
{isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
      

      {renderSessionData()}

      </div>
      </div>
    );
    }
    
    export default CadetAttend;