import React, { useState, useEffect } from 'react';
import { db } from '../Firebase'; // Import the Firebase database object
import { ref, get, onValue, set} from 'firebase/database'; // Import the necessary Firebase database functions
import SideBar from "./AdminSideBar";
    import Modal from 'react-modal';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faInfoCircle, faExclamationCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
    import UserInfoModal from "./UserInfoModal";
import {faSortUp, faSortDown ,faSort  } from '@fortawesome/free-solid-svg-icons';
    import { faDownload, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
    import AdminModalTable from "./AdminModalTable";
    import EditProfileModal from "./EditProfileModal";
import "./AdminEnrolled.css"
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Spinner from "react-bootstrap/Spinner";
function EnrolledStudents() {
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
const [users, setUsers] = useState([]);
const [filteredStudents, setFilteredStudents] = useState([]);
const [searchQuery, setSearchQuery] = useState('');

  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [selectedSchoolNumber, setSelectedSchoolNumber] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(true); // Add loading state


  const openDataModal = () => {
    setIsDataModalOpen(true);
  };

  const closeDataModal = () => {
    setIsDataModalOpen(false);
  };
  const openModal = (userData) => {
    setSelectedUserData(userData);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setSelectedUserData(null);
    setIsModalOpen(false);

  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);

  const openEditModal = (user) => {
    setSelectedUserForEdit(user);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedUserForEdit(null);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    const fetchEnrolledStudents = () => {
        if (selectedSchoolYear && selectedSemester) {
            const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
            // Subscribe to real-time updates
            onValue(userSemesterRef, (snapshot) => {
                if (snapshot.exists()) {
                    const enrolledStudentsData = transformEnrolledData(snapshot.val());
                    setEnrolledStudents(enrolledStudentsData);
                    fetchUserUIDs(enrolledStudentsData);
                } else {
                    setEnrolledStudents([]);
                    setStudentUIDs({});
                }
                setLoading(false); // Set loading to false when data is received
            });
        }
    };

    fetchEnrolledStudents();

    // Cleanup function to unsubscribe from real-time updates
    return () => {
        // Detach listeners if necessary
    };
}, [selectedSchoolYear, selectedSemester]);


  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber
    }));
  };

  useEffect(() => {
    const fetchSchoolYears = async () => {
        try {
            const schoolYearsRef = ref(db, 'enrolled');
            // Subscribe to real-time updates
            onValue(schoolYearsRef, (snapshot) => {
                if (snapshot.exists()) {
                    const schoolYearsList = Object.keys(snapshot.val());
                    setSchoolYears(schoolYearsList);
                } else {
                    setSchoolYears([]);
                }
            });
        } catch (error) {
            console.error('Error fetching school years:', error.message);
        }
    };

    fetchSchoolYears();
}, []);

useEffect(() => {
  const fetchData = () => {
      const usersRef = ref(db, 'users');
      // Subscribe to real-time updates
      onValue(usersRef, (snapshot) => {
          if (snapshot.exists()) {
              const userData = snapshot.val();
              setUserDetails(userData);
              setLoading(false); // Set loading to false when data is received
          } else {
              console.log('No user data found in the database.');
          }
      });
  };

  fetchData();

  // Cleanup function to unsubscribe from real-time updates
  return () => {
      // Detach listeners if necessary
  };
}, []);

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map(student => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach(studentNumber => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid];
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };

  useEffect(() => {
    // Apply filters based on selected filter values and search query
    let filtered = enrolledStudents.filter(student =>
      student.studentNumber.includes(searchQuery) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Firstname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Lastname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.studentNumber || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Middlename || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Course || '').toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    // Apply additional filters based on selected filter values
    if (selectedSchoolNumber) {
      filtered = filtered.filter(student => student.studentNumber.includes(selectedSchoolNumber));
    }
    if (selectedCourse) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Course.includes(selectedCourse));
    }
    if (selectedGender) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Gender.includes(selectedGender));
    }
  
    setFilteredStudents(filtered);
  }, [searchQuery, enrolledStudents, userDetails, studentUIDs, selectedSchoolNumber, selectedCourse, selectedGender]);
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const extractSchoolNumber = (studentNumber) => {
    return studentNumber.substring(0, 4);
  };
  
  // Logic to collect unique school numbers from enrolled students
  const getUniqueSchoolNumbers = () => {
    const uniqueSchoolNumbers = new Set();
    enrolledStudents.forEach((student) => {
      const schoolNumber = extractSchoolNumber(student.studentNumber);
      uniqueSchoolNumbers.add(schoolNumber);
    });
    return Array.from(uniqueSchoolNumbers);
  };
  const getUniqueCourses = () => {
    const uniqueCourses = new Set();
    enrolledStudents.forEach((student) => {
        const userDetail = userDetails[studentUIDs[student.studentNumber]];
        if (userDetail && userDetail.Course) { // Check if userDetail is defined and Course property exists
            uniqueCourses.add(userDetail.Course);
        }
    });
    return Array.from(uniqueCourses);
};


// Function to handle sorting
const sortData = (field) => {
  if (sortBy === field) {
    // If already sorted by the same field, toggle the sort order
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    // If sorting by a different field, update the sort field and set the order to ascending
    setSortBy(field);
    setSortOrder('asc');
  }
};

const sortedStudents = [...filteredStudents].sort((a, b) => {
  // Determine the comparison based on the sorting field
  let comparison = 0;
  if (sortBy === 'studentNumber') {
    comparison = a.studentNumber.localeCompare(b.studentNumber);
  } else {
    const userA = userDetails[studentUIDs[a.studentNumber]];
    const userB = userDetails[studentUIDs[b.studentNumber]];
    if (userA && userB) {
      if (sortBy === 'firstName') {
        comparison = (userA.Firstname || '').localeCompare(userB.Firstname || '');
      } else if (sortBy === 'lastName') {
        comparison = (userA.Lastname || '').localeCompare(userB.Lastname || '');
      } else if (sortBy === 'course') {
        comparison = (userA.Course || '').localeCompare(userB.Course || '');
      } else if (sortBy === 'gender') {
        comparison = (userA.Gender || '').localeCompare(userB.Gender || '');
      } else if (sortBy === 'email') {
        comparison = (userA.email || '').localeCompare(userB.email || '');
      }
    }
  }

  // Apply the sort order
  return sortOrder === 'asc' ? comparison : -comparison;
});


const [userType, setUserType] = useState("");
const [storedStudentNumber, setStoredStudentNumber] = useState("");
const [storedEmail, setStoredEmail] = useState('');
const [storedDesignation, setStoredDesignation] = useState('');
const [designation, setDesignation] = useState('');
const [studentNumber, setStudentNumber] = useState('');
useEffect(() => {
    // Set user type based on credentials
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
        setUserType("admin");
    }
}, []);

useEffect(() => {
    const userType = localStorage.getItem("userType");
    setUserType(userType);

    const storedStudentNumber = localStorage.getItem('studentNumber');
    setStoredStudentNumber(storedStudentNumber);


    const storedEmail = localStorage.getItem('email');
    setStoredEmail(storedEmail);

    const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
    setStoredDesignation(storedDesignation); // Set the designation in the component state
}, []);

useEffect(() => {
    const storedDesignation = localStorage.getItem('designation');
    const storedStudentNumber = localStorage.getItem('studentNumber');
    const storedEmail = localStorage.getItem('email');
    if (storedDesignation && storedStudentNumber) {
        setDesignation(storedDesignation);
        setStudentNumber(storedStudentNumber);
        setEmail(storedEmail);
    }
}, []);
// Effect to fetch user data when component mounts or when studentNumber or designation changes
useEffect(() => {
  const fetchData = async () => {
    try {
      const userDataFromDb = await getUserData(designation, studentNumber);
      setUserData(userDataFromDb);
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  fetchData();
}, [designation, studentNumber]);
const [email, setEmail] = useState('');

const getUserData = async (designation, studentNumber) => {
  try {
    // Construct the reference path
    const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
    
    // Retrieve the data
    const snapshot = await get(userRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Extract the user data
      const userData = snapshot.val();
      // Return the relevant data such as Lastname and Firstname
      return {
        Course: userData.Course,
        Lastname: userData.Lastname || '',
        Firstname: userData.Firstname || '',
        Middlename: userData.Middlename || '',
        // Add more properties as needed
      };
    } else {
      // Data does not exist
      console.log("No data available for the given student number.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving user data:", error.message);
    throw error;
  }
};


const [userImageUrl, setUserImageUrl] = useState(null);
const fetchUserImage = async (email) => {
  try {
    const storage = getStorage();
    const imageRef = storageRef(storage, `user_images/${email}`);
    const imageUrl = await getDownloadURL(imageRef);
    setUserImageUrl(imageUrl);
  } catch (error) {
    console.error('Error fetching user image:', error);
    setUserImageUrl(null);
  }
};

useEffect(() => {
  // Fetch user image when email changes
  if (email) {
    fetchUserImage(email);
  }
}, [email]);
const [showModal, setShowModal] = useState(false);
const [sortIcon, setSortIcon] = useState(faSortDown);

const handleModalOpen = () => {
  setShowModal(prevState => !prevState); // Toggle modal state
  setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
};

const handleModalClose = () => {
  setShowModal(false);
  setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
};

const handleModalContentClick = (e) => {
  e.stopPropagation(); // Prevent event bubbling
};
const handleLogoutClick = () => {
  setIsModalOpen1(true);
  // Add any additional logic you want to execute when logout is clicked
};

const handleCloseModal = () => {
  setIsModalOpen1(false);
  // Add any additional logic you want to execute when the modal is closed
};

const [isModalOpen1, setIsModalOpen1] = useState(false);

function getStatusClass(status) {
  if (!status || status === "") {
    return "signal-light gray";
  } else if (status === "Offline") {
    return "signal-light offline";
  } else {
    return "signal-light online";
  }
}

function StatusIndicator({ status }) {
  const statusClass = getStatusClass(status);

  return (
    <div className={statusClass}></div>
  );
}
const [studentCount, setStudentCount] = useState(0); // New state variable for student count

useEffect(() => {
  // Function to calculate student count based on filtered and sorted students
  const calculateStudentCount = () => {
    const count = sortedStudents.length;
    setStudentCount(count);
  };

  calculateStudentCount();
}, [sortedStudents]);

const handleResetSearch = () => {
  setSearchQueries({});
};

const [searchQueries, setSearchQueries] = useState({});

const handleSearchPerSemester = (event, year, semester) => {
  const { value } = event.target;
  setSearchQueries(prevState => ({
    ...prevState,
    [`${year}-${semester}`]: value
  }));
};
const renderSortIcon = (column) => {
  if (sortBy === column) {
      return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  }
  return <FontAwesomeIcon icon={faSort} />; // Always render the sort icon
};
const handleLogout = async () => {
  try {
      // Reference the appropriate location in the database using the student number
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);

      // Get the user data from the database
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
          const userData = userSnapshot.val();

          // Update the user's status to "Offline"
          await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Offline');

          toast.success('Logout successful!', { position: 'top-center' });

          // Clear local storage
          localStorage.clear();

          // Redirect to the login page using navigate
          navigate("/Admin/AdminOfficerLogin");
      } else {
          // User does not exist
          toast.error('User does not exist.', { position: 'top-center' });
      }
  } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Failed to log out. Please try again later.', { position: 'top-center' });
  }
};


const getNumberOfRows = () => {
  return sortedStudents.length;
};

return (
  <div className="Admin-authority">
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

<div className='attendance-container'>
  
    <div className="header-container1">
    {isModalOpen1 && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
  <h1 className="Header-container-attendance">CADET ENROLLED</h1>  
  
{!designation   && (
    <>
     
    </>
  )}
     
     { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
<div className="user-details">
<div className="user-details1">
{userData && (
<p className="user-name">
{userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
</p>
)}
</div>
<div className="user-image-container1" onClick={handleModalOpen}>
{userImageUrl ? (
<img className="user-image1" src={userImageUrl} alt="User" />
) : (

<span className="visually-hidden">Loading...</span>

)}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

</div>
{showModal && (
<div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
<div className="modal-content" onClick={handleModalContentClick}>
<span className="close" onClick={handleModalClose}>&times;</span>
<div className="pendingHeader1">Profile</div>
<div className="user-image-container12">
 {userImageUrl ? (
   <img className="user-image12" src={userImageUrl} alt="User" />
 ) : (
   <Spinner animation="border" role="status" variant="success">
     <span className="visually-hidden">Loading...</span>
   </Spinner>
 )}
 

</div>
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
<div className="info-box">
<p className="Personnel">Personnel Officer ({designation})</p>
{userData && (
<>
<div className="custom-table"> 
<table className="custom-table">
<tbody>
<tr>
  <td><label htmlFor="studentNumber">Student Number:</label></td>
  <td><p>{studentNumber}</p></td>
</tr>
<tr>
<td><label>Name:</label></td>
  <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
</tr>
<tr>
  <td><label htmlFor="email">Email:</label></td>
  <td><p>{email}</p></td>
</tr>
<tr>
  <td><label>Course:</label></td>
  <td><p>{userData.Course}</p></td>
</tr>

</tbody>
</table></div>

</>
)}

</div>
<div className="button-container12">
<button className="CustomBtn" onClick={handleLogoutClick}>
  <div className="sign12">
    <svg viewBox="0 0 512 512">
      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
    </svg>
  </div>
  <div className="text12">Logout</div>
</button>
</div>


</div>

</div>

)}

</div>
)}

</div>
<div className="enrollement-yearsem1"></div>
      
      <div className="enrollement-yearsem">
      <h1 className="pendingHeader" >ENROLLED STUDENTS </h1>
      <div className="reminder">
      <FontAwesomeIcon icon={faExclamationCircle} className="reminder-icon" />
      <span>Reminder: Select<span className="school-year">School Year</span> and<span className="semester">Semester</span> to show the data</span>
    </div>
      <div className="authority-filter">
      <div className="Admin-PE-action-buttons1">
   
       
        <select
          id="schoolYear"
          className="custom-dropdown1"
          onChange={(e) => setSelectedSchoolYear(e.target.value)}
          value={selectedSchoolYear}
        >
          <option value="" hidden>Select School Year</option>
          {schoolYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          id="semester"
          className="custom-dropdown1"
          onChange={(e) => setSelectedSemester(e.target.value)}
          value={selectedSemester}
        >
          <option value="" disabled hidden>
            Select Semester
          </option>
          <option value="First Semester">First Semester</option>
          <option value="Second Semester">Second Semester</option>
        </select>
     
        <select
        className="custom-dropdown1"
          value={selectedSchoolNumber}
          onChange={(e) => setSelectedSchoolNumber(e.target.value)}
        >
          <option value="">School Number</option>
          {getUniqueSchoolNumbers().map((schoolNumber) => (
            <option key={schoolNumber} value={schoolNumber}>
              {schoolNumber}
            </option>
          ))}
        </select>
        <select
            className="custom-dropdown1"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="">Course</option>
              {getUniqueCourses().map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>

        <select
         className="custom-dropdown1"
          value={selectedGender}
          onChange={(e) => setSelectedGender(e.target.value)}
        >
          <option value="">Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <form className="form-search">
      <label htmlFor="search" className="label-search">
          <input
           className="input-search"
           type="text1"
           required
           placeholder="Search"
           id="search"
            value={searchQuery}
            onChange={handleSearch}
          />
          <div className="fancy-bg-search"></div>
        <div className="search-search">
          <svg viewBox="0 0 24 24" aria-hidden="true" className="icon-search r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
        </div>
        <button className="close-btn-search" type="reset" onClick={handleResetSearch}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
      </label>
    </form>
</div>
</div>
{ (designation === 'S2' || designation === 'EXO') && (
              <div className="admin-modal-table-container122">
 
</div>
)}
         { (designation === 'S1' || !designation ) && (
              <div className="admin-modal-table-container">
  <AdminModalTable
    isOpen={isDataModalOpen}
    onRequestClose={closeDataModal}
    openDataModal={openDataModal}
    closeDataModal={closeDataModal}
  />
</div>
)}

<div className="status-indicators-container">
  <h1>Status Indicators</h1>
  <p>
    <StatusIndicator status="Online" /> Online 
    <StatusIndicator status="Offline" /> Offline 
    <StatusIndicator status="" /> Not Signed In 
  </p> 
</div>
      {/* Table of Enrolled Students */}
      <p>Numbers of student: {getNumberOfRows()}</p>
      <table className="enrolled-table112">
        <thead>
          <tr>
          <th>No.</th> {/* Added new table header for numbering */}
          <th className={`sortable-header ${sortBy === 'studentNumber' ? sortOrder : ''}`} onClick={() => sortData('studentNumber')}>
  Student Number {renderSortIcon('studentNumber')}
</th>
  <th className={`sortable-header ${sortBy === 'firstName' ? sortOrder : ''}`} onClick={() => sortData('firstName')}>
    First Name {renderSortIcon('firstName')}
  </th>
<th className={`sortable-header ${sortBy === 'lastName' ? sortOrder : ''}`} onClick={() => sortData('lastName')}>
Last Name {renderSortIcon('lastName')}
</th>
<th>Middle Name</th>
<th className={`sortable-header ${sortBy === 'course' ? sortOrder : ''}`} onClick={() => sortData('course')}>
  Course {renderSortIcon('course')}
</th>
<th className={`sortable-header ${sortBy === 'gender' ? sortOrder : ''}`} onClick={() => sortData('gender')}>
  Gender {renderSortIcon('gender')}
</th>
<th className={`sortable-header ${sortBy === 'email' ? sortOrder : ''}`} onClick={() => sortData('email')}>
  Email {renderSortIcon('email')}
</th>


<th>Status</th>
<th>Info</th>
{/*<th>Edit</th>*/}

          </tr>
        </thead>
        <tbody>
        {sortedStudents.map((student, index) => (
          <tr key={student.id}>
            
            {studentUIDs[student.studentNumber] && userDetails[studentUIDs[student.studentNumber]] ? (
  <>
    <td>{index + 1}</td>
    <td>{student.studentNumber}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]].Firstname}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]].Lastname}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]].Middlename}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]]?.Course}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]].Gender}</td>
    <td>{userDetails[studentUIDs[student.studentNumber]].email}</td>
    <td>
      <div className={getStatusClass(userDetails[studentUIDs[student.studentNumber]].status)}></div>
    </td>
    <td>
      <button className="info" onClick={() => openModal(student)}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </td>
  </>
) : (
  <>
    {/* If studentUIDs doesn't contain the student number */}
    <td style={{ backgroundColor: "lightgray" }}>N/A</td>
    <td style={{ backgroundColor: "lightgray" }}>N/A</td>
    <td style={{ backgroundColor: "lightgray" }}>N/A</td>
  </>
)}
          </tr>
        ))}
      </tbody>
      </table>
      </div>
      {/* Modals */}
      <EditProfileModal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        user={selectedUserForEdit}
      />
      <UserInfoModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        user={selectedUserData}
      />
    </div>
  </div>
  
);
}

export default EnrolledStudents;