import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import LandingPage from '../LANDINGPAGES/LandingPage';
import Footer from '../LANDINGPAGES/Footer';
import './Overview.css'; // Import the CSS file
import AbsenceLineChart from './Overview/DisplayAttendance';
import StudentsPerCity from './Overview/CityCountsChart';
import StudentsPerCourse from './Overview/UserCountsChart';
import { getDatabase, ref, get } from "firebase/database";

const Overview = ({ app }) => {
  // Set the school year directly
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [schoolYears, setSchoolYears] = useState([]); // State to store available school years
  const [selectedSemester, setSelectedSemester] = useState();

  useEffect(() => {
    // Fetch school years from Firebase and update the state
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(getDatabase(), 'SchoolYear');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          // Extract school years from the snapshot
          const schoolYearList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearList);
          // Set the default selected school year to the first year in the list
          setSelectedSchoolYear(schoolYearList[0]);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div>
      <LandingPage />
      <div className='overview-page'>
        <div className="header-container">
          <header>
            <h1>Overview</h1>
          </header>
        </div>

        <p className='sy-overview'> School Year: {selectedSchoolYear}</p>
        <div className='overview-selection'>
        <div className="school-year-selection1">
          <select
            id="semester"
            className="filter-select" // Use className instead of class
            onChange={(e) => setSelectedSchoolYear(e.target.value)}
            value={selectedSchoolYear}
          >
            <option value="" hidden>Select School Year</option>
            {schoolYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="semester-selection1">
          <select
            id="semester"
            class="filter-select"
    onChange={(e) => setSelectedSemester(e.target.value)}
    value={selectedSemester}
  >
    <option value="" hidden>
      Select Semester
      </option>
              <option value="First Semester">First Semester</option>
              <option value="Second Semester">Second Semester</option>
            </select>
        </div> 
        </div>
        <div className="charts-container">

            <StudentsPerCity selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>
            <AbsenceLineChart selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>
            <StudentsPerCourse selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Overview;
