import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import { Line } from 'react-chartjs-2';
import './AbsencePerCourse.css';

const AttendanceLineChart = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [absentStudents, setAbsentStudents] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);
  const [lateStudents, setLateStudents] = useState([]);
  const [excusedStudents, setExcusedStudents] = useState([]);
  const [courseData, setCourseData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          const studentEntries = Object.entries(attendanceData);

          const filterStudentsByAppearance = (appearance) => {
            return studentEntries
              .filter(([_, studentData]) => {
                const timeInAppearance = studentData['Time In']?.appearance;
                const timeOutAppearance = studentData['Time Out']?.appearance;
                return timeInAppearance === appearance || timeOutAppearance === appearance;
              })
              .map(([studentNumber, _]) => studentNumber);
          };
          

          const absentStudentsData = filterStudentsByAppearance('Absent');
          const presentStudentsData = filterStudentsByAppearance('Present');
          const lateStudentsData = filterStudentsByAppearance('Late');
          const excusedStudentsData = filterStudentsByAppearance('Excused');

          setAbsentStudents(absentStudentsData);
          setPresentStudents(presentStudentsData);
          setLateStudents(lateStudentsData);
          setExcusedStudents(excusedStudentsData);
        } else {
          setAbsentStudents([]);
          setPresentStudents([]);
          setLateStudents([]);
          setExcusedStudents([]);
        }

        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const enrolledSnapshot = await get(enrolledRef);

        if (enrolledSnapshot.exists()) {
          const enrolledData = enrolledSnapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);

          const usersRef = ref(db, 'users');
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const courseCounts = {};

            const countStudentsByAppearance = (appearance, studentsData) => {
              Object.values(users).forEach(user => {
                if (studentNumbers.includes(user.studentNumber)) {
                  const { Course } = user;
                  if (!courseCounts[Course]) {
                    courseCounts[Course] = {
                      Absent: 0,
                      Present: 0,
                      Late: 0,
                      Excused: 0
                    };
                  }
                  if (studentsData.includes(user.studentNumber)) {
                    courseCounts[Course][appearance]++;
                  }
                }
              });
            };
            
            countStudentsByAppearance('Absent', absentStudents);
            countStudentsByAppearance('Present', presentStudents);
            countStudentsByAppearance('Late', lateStudents);
            countStudentsByAppearance('Excused', excusedStudents);

            setCourseData(courseCounts);
          } else {
            setCourseData({});
          }
        } else {
          setCourseData({});
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate, absentStudents, presentStudents, lateStudents, excusedStudents]);


  const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
  ];

  const prepareChartData = () => {
    if (!courseData || Object.keys(courseData).length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }
  
    const courses = Object.keys(courseData);
    const datasets = Object.keys(courseData[courses[0]]).map((attendanceType, index) => {
      return {
        label: `${attendanceType} Per Course`,
        data: courses.map(course => courseData[course]?.[attendanceType] || 0),
        backgroundColor: colors[index % colors.length],
        borderColor: colors[index % colors.length],
        borderWidth: 3, // Increase this value for a more solid line
      };
    });
  
    return {
      labels: courses,
      datasets: datasets,
    };
  };  
  
  return (
    <div className="attendance-line-chart">
      <h2>Strength: Program</h2>
      <Line data={prepareChartData()} />
    </div>
  );
};

export default AttendanceLineChart;
