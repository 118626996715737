import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Add this line to import the storage SDK

const firebaseConfig = {
  apiKey: "AIzaSyBu46ntJUaK1eLFZjogljJdiR1Hs0jvgrY",
  authDomain: "pupl-rotcpaiskan.firebaseapp.com",
  projectId: "pupl-rotcpaiskan",
  storageBucket: "pupl-rotcpaiskan.appspot.com",
  messagingSenderId: "1053849444547",
  appId: "1:1053849444547:web:2f83f1af72f45cef86c276"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app); // Create a storage object

export { app, auth, db, storage };