import React from "react";
import LandingPage from '../LANDINGPAGES/LandingPage';
import Footer from '../LANDINGPAGES/Footer';
import './ContactUs.css'

function App() {
  return ( 
    <div className="app">
           <LandingPage />
      <header className="header">
        <h1>Contact Us</h1>
      </header>
      <main className="main-content">
        <section className="content-section">
          <h2> ROTC Enrollment and Attendance Monitoring System</h2>
          <div className="rules-container">
            <p>Introduction</p>
            At the ROTC Enrollment and Attendance Monitoring System, we value
            communication and aim to provide the best possible support to our
            users. Please feel free to reach out to us using the contact
            information and methods provided below.
            <p>Contact Information</p>
            Email Address: rotcpaiskan@gmail.com
            <br />
            Phone Number: +1 (123) 456-7890
            <br />
            Physical Address: Yumul Street, Barangay Burgos Lopez Quezon (4316)
            <br />
            For your convenience, we recommend using email as the preferred
            method of contact. Our team is committed to responding promptly to
            your inquiries and concerns.
            <p>Contact Form</p>
            If you prefer to send us a message directly, you can use our contact
            form located on our website. This form allows you to submit your
            questions or messages, and we will get back to you as soon as
            possible.
            <p>Operating Hours</p>
            Our standard operating hours for user support are as follows:
            <br />
            Monday to Friday: 9:00 AM - 5:00 PM
            <br />
            Saturday and Sunday: Closed
            <br />
            Holidays: Closed
            <br />
            <br />
            Please note that response times may vary based on the nature of your
            inquiry.
            <p>Response Time</p>
            We aim to provide timely responses to all inquiries. Typically, you
            can expect a response within 24 to 48 hours. For urgent matters,
            please indicate the urgency in your message or consider using our
            phone number for immediate assistance.
            <p>
          This page content provides users with comprehensive information and
          multiple ways to contact the organization while ensuring their privacy
          and data security.
        </p>
          </div>
        </section>
      </main>
 


      <Footer />
    </div>
  );
}

export default App;
