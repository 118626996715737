import React, { useEffect, useState } from "react";
import { getDatabase, ref as dbRef, get, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadString,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import QRCode from "qrcode.react";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import { useNavigate } from "react-router-dom";
import "../CadetCSS/CadetHomepage.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import qrcode from "qrcode";

function CadetHomepage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [message, setMessage] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [regionAddr, setRegionAddr] = useState("");
  const [provinceAddr, setProvinceAddr] = useState("");
  const [cityAddr, setCityAddr] = useState("");
  const [barangayAddr, setBarangayAddr] = useState("");

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageURL, setUploadedImageURL] = useState(null);

  const [editedUserData, setEditedUserData] = useState({
    Firstname: "",
    Lastname: "",
    Middlename: "",
    email: "",
    studentNumber: "",
    Gender: "",
    Course: "",
    DateOfBirth: "",
    ContactNo: "",
    regionAddr: "",
    province: "",
    city: "",
    barangay: "",
    Height: "",
    Weight: "",
    BloodType: "",
    Position: "",
    BeneficiaryName: "", // Added Beneficiary fields
    BeneficiaryContact: "",
    BeneficiaryAddress: "",
    BeneficiaryRelationship: "",
  });
  const handleInputChange = (e) => {
    const input = e.target.value;
    if (/^\d*'?\d*\.?\d*$/.test(input)) {
      setEditedUserData({ ...editedUserData, Height: input });
    } else {
      // Handle the case where an invalid value is entered
      alert("Please enter a valid format for Height (e.g., 5'5).");
    }
  };
  const [qrCodeData, setQrCodeData] = useState("");

  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const storeDataInStorage = async (qrCodeDataURL) => {
    try {
      const storage = getStorage();
      const qrCodeImageRef = storageRef(
        storage,
        `qr_codes/${userData.studentNumber}.jpeg`
      );
      // Upload QR code to Firebase Storage
      await uploadString(qrCodeImageRef, qrCodeDataURL, "data_url");
      console.log("QR code uploaded to Firebase Storage");
    } catch (error) {
      console.error("Error uploading QR code to Firebase Storage:", error);
    }
  };
  const fetchRegionData = async () => {
    const storedRegionData = localStorage.getItem("regionData");

    if (storedRegionData) {
      setRegionData(JSON.parse(storedRegionData));
    } else {
      try {
        const response = await regions();
        setRegionData(response);

        // Save the region data in local storage
        localStorage.setItem("regionData", JSON.stringify(response));
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    }
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Display the selected image
        const reader = new FileReader();
        reader.onload = async (event) => {
          setSelectedImage(event.target.result);

          // Upload the original image to Firebase Storage
          const storage = getStorage();
          const imageRef = storageRef(storage, `user_images/${email}`);
          const blob = await fetch(event.target.result).then((r) => r.blob());
          await uploadBytes(imageRef, blob);

          // Get the download URL of the uploaded image
          const imageURL = await getDownloadURL(imageRef);
          setUploadedImageURL(imageURL);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  // Function to fetch user data
  const fetchUserData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("No user is logged in.");
      return;
    }

    const userId = user.uid;
    const database = getDatabase();
    const userRef = dbRef(database, `users/${userId}`);

    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserData(userData);

        const userEmail = userData.email;
        setEmail(userEmail);

        // Populate editedUserData when data is available
        setEditedUserData((prevData) => ({
          ...prevData,
          Firstname: userData.Firstname || "",
          Lastname: userData.Lastname || "",
          Middlename: userData.Middlename || "",
          email: userData.email || "",
          studentNumber: userData.studentNumber || "",
          Gender: userData.Gender || "",
          Course: userData.Course || "",
          DateOfBirth: userData.DateOfBirth || "",
          ContactNo: userData.ContactNo || "",
          Position: userData.Position || "",
          Height: userData.Height || "",
          Weight: userData.Weight || "",
          region: userData.selectedregion || "",
          BloodType: userData.BloodType || "",
          BeneficiaryRelationship: userData.BeneficiaryRelationship || "",
          BeneficiaryAddress: userData.BeneficiaryAddress || "",
          BeneficiaryContact: userData.BeneficiaryContact || "",
          BeneficiaryName: userData.BeneficiaryName || "",
          selectedRegion: userData.selectedRegion || "",
        }));

        storeUserDataInLocalStorage(userData);
      } else {
        console.error("User data not found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Function to generate the QR code
  const generateQrCode = () => {
    const studentNumber = editedUserData.studentNumber;

    setQrCodeData(studentNumber);
    setQrCodeGenerated(true);
  };

  // Function to handle downloading the QR code
  const handleDownloadQRCode = async () => {
    const qrCodeElement = document.querySelector(".qrcode canvas");

    if (!qrCodeElement) {
      console.error("QR Code element not found.");
      return;
    }

    // Load the background image
    const backgroundImage = new Image();
    backgroundImage.src = "/template1.png"; // Assuming the image is in the public folder and accessible at the root

    backgroundImage.onload = async () => {
      try {
        // Create a new canvas to combine the QR code, background, and user's full name
        const combinedCanvas = document.createElement("canvas");
        const ctx = combinedCanvas.getContext("2d");

        // Determine the desired dimensions based on screen width
        let screenWidth = window.innerWidth;

        let desiredWidth;
        let desiredHeight;
        let qrCodeScale;
        let fontSize;

        if (screenWidth <= 360) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 393) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 430) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 480) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 768) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 420) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 428) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else if (screenWidth <= 500) {
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.2;
          fontSize = 27;
        } else {
          // For larger screens (laptop), use original dimensions
          desiredWidth = 400;
          desiredHeight = 600;
          qrCodeScale = 1.9;
          fontSize = 27;
        }

        combinedCanvas.width = desiredWidth;
        combinedCanvas.height = desiredHeight;

        // Draw the resized background image first
        ctx.drawImage(backgroundImage, 0, 0, desiredWidth, desiredHeight);

        // Calculate the position to center the QR code on the resized background
        const qrCodeWidth = qrCodeElement.width * qrCodeScale;
        const qrCodeHeight = qrCodeElement.height * qrCodeScale;
        const xPosition = (desiredWidth - qrCodeWidth) / 2;
        const yPosition = (desiredHeight - qrCodeHeight) / 2;

        // Draw the user's full name below the QR code
        ctx.font = `bold ${fontSize}px Arial`; // Adjusted font size for the full name
        ctx.fillStyle = "white"; // Set the text color
        ctx.textAlign = "center"; // Center-align the text
        ctx.fillText(
          `${userData1.Lastname}, ${userData1.Firstname} ${userData1.Middlename}`,
          desiredWidth / 2,
          yPosition + qrCodeHeight + 60
        );

        // Draw the course below the full name
        ctx.font = `bold ${fontSize}px Arial`; // Adjusted font size for the course
        ctx.fillText(
          `${userData1.Course}`,
          desiredWidth / 2,
          yPosition + qrCodeHeight + 100
        );

        // Generate the QR code
        const qrCodeDataURL = await qrcode.toDataURL(userData1.studentNumber, {
          width: 200,
          margin: 0,
        });

        // Create an image element for the QR code
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCodeDataURL;

        // Wait for the QR code image to load
        qrCodeImage.onload = () => {
          // Draw the QR code with higher resolution on the canvas
          ctx.drawImage(
            qrCodeImage,
            xPosition,
            yPosition,
            qrCodeWidth,
            qrCodeHeight
          );

          // Continue with the download and upload logic
          const dataUrl = combinedCanvas.toDataURL("image/png");
          setIsDownloaded(true);

          // Upload to Firebase Storage
          const storage = getStorage();
          const qrCodeImageRef = storageRef(
            storage,
            `qr_code_downloads/${userData.studentNumber}.png`
          );

          uploadString(qrCodeImageRef, dataUrl, "data_url")
            .then(() =>
              console.log("QR code image uploaded to Firebase Storage")
            )
            .catch((error) =>
              console.error(
                "Error uploading QR code image to Firebase Storage:",
                error
              )
            );

          const downloadLink = document.createElement("a");
          downloadLink.href = dataUrl;
          downloadLink.download = "qr_code.png";
          document.body.appendChild(downloadLink); // Append to body to ensure it can be clicked
          downloadLink.click();
          document.body.removeChild(downloadLink); // Remove after click
        };
      } catch (error) {
        console.error("Error handling QR code download:", error);
      }
    };

    backgroundImage.onerror = () => {
      console.error("Failed to load the background image.");
    };
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      "Firstname",
      "Lastname",
      "email",
      "studentNumber",
      "Gender",
      "Course",
      "DateOfBirth",
      "ContactNo",
      "regionAddr",
      "province",
      "city",
      "barangay",
      "Height",
      "Weight",
      "BloodType",
      "Position",
    ];
    const emptyFields = requiredFields.filter(
      (field) => !editedUserData[field]
    );

    if (emptyFields.length > 0) {
      alert(`Please fill in the following fields: ${emptyFields.join(", ")}`);
      return;
    }

    // Check if an image is selected
    if (!selectedImage) {
      alert("Please select an image.");
      return;
    }

    // Rest of your code to save the data
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("No user is logged in.");
      return;
    }

    const userId = user.uid;
    const database = getDatabase();
    const userRef = dbRef(database, `users/${userId}`);

    try {
      // Build the updated user data object
      const updatedUserData = {
        Firstname: editedUserData.Firstname,
        Lastname: editedUserData.Lastname,
        Middlename: editedUserData.Middlename || null, // Set to null if empty
        email: editedUserData.email,
        studentNumber: editedUserData.studentNumber,
        Gender: editedUserData.Gender,
        Course: editedUserData.Course,
        DateOfBirth: editedUserData.DateOfBirth,
        ContactNo: editedUserData.ContactNo,
        region: editedUserData.regionAddr || "", // Use empty string as a default
        province: editedUserData.province || "", // Use empty string as a default
        city: editedUserData.city || "", // Use empty string as a default
        barangay: editedUserData.barangay || "", // Use empty string as a default
        Height: editedUserData.Height,
        Weight: editedUserData.Weight,
        BloodType: editedUserData.BloodType,
        Position: editedUserData.Position,
        BeneficiaryName: editedUserData.BeneficiaryName,
        BeneficiaryContact: editedUserData.BeneficiaryContact,
        BeneficiaryAddress: editedUserData.BeneficiaryAddress,
        BeneficiaryRelationship: editedUserData.BeneficiaryRelationship,
      };

      await set(userRef, updatedUserData); // Save the updated data to the database
      setIsEditing(false); // Disable form fields
      setShowConfirmationPopup(false);

      storeUserDataInLocalStorage(updatedUserData); // Save the updated data in local storage
      saveUserDataToLocalStorage(updatedUserData); // Save specific data to local storage

      navigate("/Cadet/Cadet");
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const saveUserDataToLocalStorage = (userData) => {
    try {
      const { Lastname, Firstname, Middlename, Course, studentNumber } =
        userData;

      // Check if Middlename exists, if not set it to an empty string
      const middleName = Middlename || "";

      const fullName = `${Lastname}, ${Firstname} ${middleName}`;

      localStorage.setItem("studentNumber", studentNumber);
      localStorage.setItem("fullName", fullName);
      localStorage.setItem("Course", Course);
    } catch (error) {
      console.error("Error saving user data to local storage:", error);
    }
  };

  const region = async () => {
    try {
      const response = await regions();
      setRegionData(response);

      // Save the region data in local storage
      localStorage.setItem("regionData", JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const province = async (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegionText = e.target.selectedOptions[0].text;
    setRegionAddr(selectedRegionText);
    setSelectedRegion(selectedRegionCode); // Store selected region

    try {
      const response = await provinces(selectedRegionCode);
      setProvinceData(response);
      setCityData([]);
      setBarangayData([]);

      // Update selected region and province as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        region: selectedRegionText,
        regionAddr: selectedRegionText,
        province: "", // Clear province when region changes
        provinceAddr: "", // Clear provinceAddr when region changes
        city: "", // Clear city when region changes
        barangay: "", // Clear barangay when region changes
      }));
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const city = async (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvinceText = e.target.selectedOptions[0].text;
    setProvinceAddr(selectedProvinceText);
    setSelectedProvince(selectedProvinceCode); // Store selected province

    try {
      const response = await cities(selectedProvinceCode);
      setCityData(response);

      // Update selected province and city as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        province: selectedProvinceText,
        provinceAddr: selectedProvinceText,
        city: "", // Clear city when province changes
        barangay: "", // Clear barangay when province changes
      }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const barangay = async (e) => {
    const selectedCityCode = e.target.value;
    const selectedCityText = e.target.selectedOptions[0].text;
    setCityAddr(selectedCityText);

    try {
      const response = await barangays(selectedCityCode);
      setBarangayData(response);

      // Update selected city and barangay as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        city: selectedCityText,
        barangay: "", // Clear barangay when city changes
      }));
    } catch (error) {
      console.error("Error fetching barangays:", error);
    }
  };

  const storeUserDataInLocalStorage = (key, value) => {
    try {
      // Store the data in local storage
      localStorage.setItem(key, value);

      console.log(`Stored ${key} in local storage: ${value}`);
    } catch (error) {
      console.error(`Error storing ${key} in local storage:`, error);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      fetchUserData();

      // Check if region data is available in local storage
      const storedRegionData = localStorage.getItem("regionData");
      if (storedRegionData) {
        // If available, use it
        setRegionData(JSON.parse(storedRegionData));
      } else {
        // If not, fetch it
        region();
      }
    } else {
      console.error("No user is logged in.");
    }

    // Load selected place data from local storage
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setEditedUserData(JSON.parse(storedUserData));
    }
    fetchRegionData();
  }, []);

  const [smShow, setSmShow] = useState(false);
  const [userData1, setUserData1] = useState(() => {
    // Try to retrieve user data from localStorage
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          Firstname: "",
          Lastname: "",
          Middlename: "",
          Course: "",
          Position: "",
          email: "",
          studentNumber: "",
        };
  });

  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleClose = () => {
    setSmShow(false);
  };

  const [summaryData, setSummaryData] = useState("");

  const generateSummary = () => {
    const summary = {
      "First Name": editedUserData.Firstname,
      "Last Name": editedUserData.Lastname,
      "Middle Name": editedUserData.Middlename || "", // Set an empty string if Middlename is not available
      "Student ID": editedUserData.studentNumber,
      Email: editedUserData.email,
      Gender: editedUserData.Gender,
      "Date of Birth": editedUserData.DateOfBirth,
      "Contact No.": editedUserData.ContactNo,
      Position: editedUserData.Position,
      Course: editedUserData.Course,
      "Height (ft)": editedUserData.Height,
      "Weight (kg)": editedUserData.Weight,
      "Blood Type": editedUserData.BloodType,
      Region: regionAddr,
      Province: provinceAddr,
      City: cityAddr,
      Barangay: barangayAddr,
      "Beneficiary Name": editedUserData.BeneficiaryName,
      "Beneficiary Contact No.": editedUserData.BeneficiaryContact,
      "Beneficiary Address": editedUserData.BeneficiaryAddress,
      "Beneficiary Relationship": editedUserData.BeneficiaryRelationship,
    };

    setSummaryData(summary);
  };

  return (
    <div className="Cadet-enter-creds">
      <Form.Group controlId="formFile" className="mb-3">
        <Row className="row-centered-dls">
          <Col sm="12" className="col-centered-dls">
            <h2>Edit Your Credentials</h2>
          </Col>
        </Row>
        <div className="homepage-credential">
          <div className="image-upload-container">
            <Col sm="15">
              <div className="d-flex justify-content-center">
                <Form.Control
                  type="file"
                  id="ImageUpload"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
              {/* Display the selected image */}
              {selectedImage && (
                <div className="image-container d-flex justify-content-center">
                  <img src={selectedImage} alt="Selected" />
                </div>
              )}
            </Col>
          </div>

          <div class="input-container-group-ch1-9">
            <div className="input-container-ch4">
              <label htmlFor="StudentID">Student ID:</label>
              <input
                type="text"
                id="StudentID"
                placeholder="Enter your Student ID"
                value={editedUserData.studentNumber}
                disabled
              />
            </div>
            <div className="input-container-ch5">
              <label htmlFor="Email">Email:</label>
              <input
                type="email"
                id="Email"
                placeholder="Enter your Email"
                value={editedUserData.email}
                disabled
              />
            </div>
            <div className="input-container-ch1">
              <label htmlFor="Firstname">First Name:</label>
              <input
                type="text"
                id="Firstname"
                placeholder="Enter your First Name"
                value={editedUserData.Firstname}
                disabled
              />
            </div>
            <div className="input-container-ch2">
              <label htmlFor="Lastname">Last Name:</label>
              <input
                type="text"
                id="Lastname"
                placeholder="Enter your Last Name"
                value={editedUserData.Lastname}
                disabled
              />
            </div>
            <div className="input-container-ch3">
              <label htmlFor="Middlename">Middle Name:</label>
              <input
                type="text"
                id="Middlename"
                placeholder="Enter your Middle Name"
                value={editedUserData.Middlename}
                disabled
              />
            </div>

            <div className="input-container-ch6">
              <label htmlFor="Gender">Gender:</label>
              <div className="select-containerch6">
                <select
                  id="Gender"
                  value={editedUserData.Gender}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      Gender: e.target.value,
                    })
                  }
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
            <div className="input-container-ch7">
              <label htmlFor="DateOfBirth">Date of Birth:</label>
              <input
                type="date"
                id="DateOfBirth"
                value={editedUserData.DateOfBirth}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    DateOfBirth: e.target.value,
                  })
                }
                style={{
                  width: "100%",
                  height: "40px", // Increased height
                  padding: "10px", // Increased padding
                  border: "1px solid #000000",
                  borderRadius: "8px",
                  fontSize: "18px", // Increased font size
                  color: "#000",
                }}
              />
            </div>
            <div className="input-container-ch8">
              <label htmlFor="ContactNo">Contact No.:</label>
              <div className="input-container">
                <span className="static-prefix">63+</span>
                <input
                  type="tel" // Use type "tel" for phone numbers
                  id="ContactNo"
                  placeholder="Enter your Contact No"
                  value={editedUserData.ContactNo}
                  onChange={(e) => {
                    let input = e.target.value;
                    const numberPattern = /^[0-9]*$/; // Regular expression to allow only numbers

                    // Remove any non-numeric characters
                    input = input.replace(/\D/g, "");

                    // Ensure the first digit is "9"
                    if (input.length > 0 && input[0] !== "9") {
                      input = "9" + input.substring(1);
                    }

                    // Limit the input to 10 digits
                    if (input.length > 10) {
                      const alertMessage =
                        "Oops! The maximum allowed input is 10 digits. Please try again.";

                      // Create a div element for the alert
                      const alertDiv = document.createElement("div");
                      alertDiv.className = "custom-alert";
                      alertDiv.textContent = alertMessage;

                      // Append the alert to the body
                      document.body.appendChild(alertDiv);

                      // Remove the alert after 3 seconds (adjust as needed)
                      setTimeout(() => {
                        document.body.removeChild(alertDiv);
                      }, 3000);

                      input = input.slice(0, 10);
                    }

                    // Format the input as 3-3-4
                    if (input.length >= 4) {
                      input = input.slice(0, 3) + "-" + input.slice(3);
                    }
                    if (input.length >= 8) {
                      input = input.slice(0, 7) + "-" + input.slice(7);
                    }

                    setEditedUserData({
                      ...editedUserData,
                      ContactNo: input,
                    });
                  }}
                />
              </div>
            </div>
            <div className="input-container-ch9">
              <label htmlFor="Position">Position:</label>
              <div className="select-containerch9">
                <select
                  id="Position"
                  value={editedUserData.Position}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      Position: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Enter Your Position
                  </option>
                  <option value="Cadet">Cadet</option>
                  <option value="Officer">Officer</option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-container-group-ch10-17">
            <div className="input-container-ch10">
              <label htmlFor="Course">Course:</label>
              <div className="select-containerch10">
                <select
                  id="Course"
                  value={editedUserData.Course}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      Course: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Enter Your Course
                  </option>
                  <option value="BEED">BEED</option>
                  <option value="BPA">BPA</option>
                  <option value="BPA-FA">BPA-FA</option>
                  <option value="BPA-FM">BPA-FM</option>
                  <option value="BSA">BSA</option>
                  <option value="BSAM">BSAM</option>
                  <option value="BS ARCHI">BS ARCHI</option>
                  <option value="BSBA-FM">BSBA-FM</option>
                  <option value="BSBSA-MM">BSBSA-MM</option>
                  <option value="BSBIO">BSBIO</option>
                  <option value="BSCE">BSCE</option>
                  <option value="BSEE">BSEE</option>
                  <option value="BSED-MT">BSED-MT</option>
                  <option value="BSHM">BSHM</option>
                  <option value="BSIT">BSIT</option>
                  <option value="BSND">BSND</option>
                  <option value="BSOA">BSOA</option>
                  <option value="DCPET">DCPET</option>
                  <option value="DCVET">DCVET</option>
                  <option value="DIT">DIT</option>
                  <option value="DEET">DEET</option>
                  <option value="DOMT-LOM">DOMT-LOM</option>
                  <option value="DOMT-MOM">DOMT-MOM</option>
                </select>
              </div>
            </div>
            <div className="input-container-ch11">
              <label htmlFor="Height">Height (ft):</label>
              <input
                type="text"
                id="Height"
                className="bigger-input"
                placeholder="Enter your Height"
                value={editedUserData.Height}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-container-ch12">
              <label htmlFor="Weight">Weight (kg):</label>
              <input
                type="number"
                id="Weight"
                className="bigger-input"
                placeholder="Enter your Weight"
                value={editedUserData.Weight}
                onKeyPress={(e) => {
                  const key = String.fromCharCode(e.which);
                  if (!/[\d.]/.test(key)) {
                    e.preventDefault();
                    alert("Please enter a valid number for Weight.");
                  }
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  setEditedUserData({ ...editedUserData, Weight: input });
                }}
              />
            </div>

            <div className="input-container-ch13">
              <label htmlFor="BloodType">Blood Type:</label>
              <div className="select-containerch13">
                <select
                  id="BloodType"
                  placeholder="Enter your Blood Type"
                  value={editedUserData.BloodType}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      BloodType: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Enter Your BloodType
                  </option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>
            </div>

            <div className="input-container-ch14">
              <label htmlFor="regionSelect">Select Region:</label>
              <div className="select-containerch14">
                <select
                  id="regionSelect"
                  onChange={(e) => {
                    const selectedRegionCode = e.target.value;
                    const selectedRegionText = e.target.selectedOptions[0].text;

                    // Update selected region in the state
                    setSelectedRegion(selectedRegionCode);
                    setRegionAddr(selectedRegionText);

                    // Call the province function when region changes
                    province(e);

                    // Update editedUserData with the selected region text
                    setEditedUserData((prevData) => ({
                      ...prevData,
                      regionAddr: selectedRegionText,
                    }));

                    // Store the selected region in local storage
                    localStorage.setItem("selectedRegion", selectedRegionCode);
                  }}
                  value={selectedRegion} // Set the selected value
                >
                  <option value="" disabled>
                    Select Region
                  </option>
                  {regionData &&
                    regionData.length > 0 &&
                    regionData.map((region) => (
                      <option
                        key={region.region_code}
                        value={region.region_code}
                      >
                        {region.region_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* Province Dropdown */}
            <div className="input-container-ch15">
              <label htmlFor="provinceSelect">Select Province:</label>
              <div className="select-containerch15">
                <select
                  id="provinceSelect"
                  onChange={(e) => {
                    setProvinceAddr(e.target.selectedOptions[0].text);
                    city(e); // Call the city function when province changes
                    setSelectedProvince(e.target.value); // Update selected province
                    setEditedUserData({
                      ...editedUserData,
                      province: e.target.value,
                    });
                  }}
                  value={selectedProvince} // Set the selected value
                >
                  <option value="" disabled>
                    Select Province
                  </option>
                  {provinceData &&
                    provinceData.length > 0 &&
                    provinceData.map((province) => (
                      <option
                        key={province.province_code}
                        value={province.province_code}
                      >
                        {province.province_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* City Dropdown */}
            <div className="input-container-ch16">
              <label htmlFor="citySelect">Select City:</label>
              <div className="select-containerch16">
                <select
                  id="citySelect"
                  onChange={(e) => {
                    setCityAddr(e.target.selectedOptions[0].text);
                    barangay(e); // Call the barangay function when city changes
                    setSelectedCity(e.target.value); // Update selected city
                    setEditedUserData({
                      ...editedUserData,
                      city: e.target.value,
                    });
                  }}
                  value={selectedCity} // Set the selected value
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {cityData &&
                    cityData.length > 0 &&
                    cityData.map((city) => (
                      <option key={city.city_code} value={city.city_code}>
                        {city.city_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Always render Barangay */}
            <div className="input-container-ch17">
              <label htmlFor="barangaySelect">Select Barangay:</label>
              <div className="select-containerch17">
                <select
                  id="barangaySelect"
                  onChange={(e) => {
                    setBarangayAddr(e.target.selectedOptions[0].text);

                    // Update selected barangay in the editedUserData state
                    setEditedUserData({
                      ...editedUserData,
                      barangay: e.target.value,
                    });
                  }}
                  value={editedUserData.barangay}
                >
                  <option value="" disabled>
                    Select Barangay
                  </option>
                  {barangayData &&
                    barangayData.length > 0 &&
                    barangayData.map((barangay, index) => (
                      <option key={index} value={barangay.brgy_name}>
                        {barangay.brgy_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-container-ch18">
            <h2>Beneficiaries</h2>
            <div className="beneficiaries">
              <div>
                <label htmlFor="BeneficiaryName">Name:</label>
                <input
                  type="text"
                  id="BeneficiaryName"
                  placeholder="e.g. Dela Cruz, Juan T."
                  value={editedUserData.BeneficiaryName}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      BeneficiaryName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-container-ch8">
                <label htmlFor="BeneficiaryContact">Contact No.:</label>
                <div className="input-container">
                  <span className="static-prefix">63+</span>
                  <input
                    type="tel" // Use type "tel" for phone numbers
                    id="BeneficiaryContact"
                    placeholder="Enter your Contact No"
                    value={editedUserData.BeneficiaryContact}
                    onChange={(e) => {
                      let input = e.target.value;
                      const numberPattern = /^[0-9]*$/; // Regular expression to allow only numbers

                      // Remove any non-numeric characters
                      input = input.replace(/\D/g, "");

                      // Ensure the first digit is "9"
                      if (input.length > 0 && input[0] !== "9") {
                        input = "9" + input.substring(1);
                      }

                      // Limit the input to 10 digits
                      if (input.length > 10) {
                        const alertMessage =
                          "Oops! The maximum allowed input is 10 digits. Please try again.";

                        // Create a div element for the alert
                        const alertDiv = document.createElement("div");
                        alertDiv.className = "custom-alert";
                        alertDiv.textContent = alertMessage;

                        // Append the alert to the body
                        document.body.appendChild(alertDiv);

                        // Remove the alert after 3 seconds (adjust as needed)
                        setTimeout(() => {
                          document.body.removeChild(alertDiv);
                        }, 3000);

                        input = input.slice(0, 10);
                      }

                      // Format the input as 3-3-4
                      if (input.length >= 4) {
                        input = input.slice(0, 3) + "-" + input.slice(3);
                      }
                      if (input.length >= 8) {
                        input = input.slice(0, 7) + "-" + input.slice(7);
                      }

                      setEditedUserData({
                        ...editedUserData,
                        BeneficiaryContact: input,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="BeneficiaryAddress">Address:</label>
                <input
                  type="text"
                  id="BeneficiaryAddress"
                  placeholder="Enter your Address"
                  value={editedUserData.BeneficiaryAddress}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      BeneficiaryAddress: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label htmlFor="BeneficiaryRelationship">Relationship:</label>
                <input
                  type="text"
                  id="BeneficiaryRelationship"
                  placeholder="Enter your Relationship"
                  value={editedUserData.BeneficiaryRelationship}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      BeneficiaryRelationship: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="qr-code-container">
  <div className="submit-container">
    <Button
      type="button-ch1"
      onClick={(e) => {
        e.preventDefault();
        generateSummary();
        setSmShow(true);
      }}
      className="me-2"
    >
      Submit
    </Button>
  </div>
  {smShow && (
    <div className="modal-backdrop">
      <div className="custom-modal">
        <div className="modal-header">
          <h2 className="modal-title">Summary</h2>
          <button onClick={() => setSmShow(false)} className="close-button">&times;</button>
        </div>
        <div className="modal-body-summary">
          {summaryData && (
            <table className="summary-table">
              <tbody>
                {Object.entries(summaryData).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {message && <p>{message}</p>}
        </div>
        <div className="modal-footer">
          <button onClick={handleEditSubmit} type="button" className="save-button">Save</button>
        </div>
      </div>
    </div>
  )}
</div>

        </div>
        <div className="footer1"></div>
      </Form.Group>
    </div>
  );
}

export default CadetHomepage;
