

// Footer.js

import React from 'react';
import '../LANDINGPAGECSS/Footer.css';

const Footer = () => {
  return (
    <footer>
    <div className="logo-container-f">
      <img src='/rotc-logo.png' alt="ROTC Logo" />
    </div>
    
    <div className="footer-container">
      <nav>  
        <ul>
          <li className="nav-links1"><a href="/Officer/ContactUs">Contact us</a></li>
          <span>|</span>
          <li className="nav-links1"><a href="/developer-info">Developer info</a></li>
          <span>|</span>
        </ul>
      </nav>
      
      <div className="copyright">
        {/* Copyright text */}
        <p>© 2023 PA-iSKAN.</p>
      </div>
    </div>
    
    <div className="logo-container-f1">
      <img src='/pup-logo.png' alt="PUP Logo" />
    </div>
  </footer>  
  );
};

export default Footer;
