import React from 'react';
import './Modal1.css'; // Import CSS for styling modal

const Modal1 = ({ isOpen, onRequestClose, selectedFileURL, userData, year, semester }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay2">
      <div className="modal1212">
      <span className="close1" onClick={onRequestClose}>&times;</span>
       
        {userData && (
  <div className="user-data-container">
    <p className="heading">Student Information</p>
    <p className="student-number">Student Number: {userData.studentNumber} </p>
    <p className="email">Email: {userData.email} </p>
    <p className="fullname">Full Name: {userData.Lastname}, {userData.Firstname} {userData.Middlename}</p>
    <p className="course">Course: {userData.Course}</p>
    <div className="separator"></div>
    {/* Add additional details if needed */}
  </div>
)}



        {selectedFileURL && (
          <iframe
            src={selectedFileURL}
            width="100%"
            height="100%"
            title="PDF Viewer"
          />
        )}
      </div>
    </div>
  );
};

export default Modal1;
