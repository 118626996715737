import React, { useState, useEffect } from 'react';
import { db } from '../Firebase'; // Import the Firebase database object
import { getDatabase, ref, get, set, remove, onValue } from "firebase/database";
import SideBar from "./AdminSideBar";
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faInfoCircle, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
    import { app } from "../Firebase"; // Import your Firebase app object
import "./Authority.css"
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal'; // Import your Modal component
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'; // Import Link from React Router

function Authority() {
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
const [filteredStudents, setFilteredStudents] = useState([]);
const [searchQuery, setSearchQuery] = useState('');
  const [selectedSchoolNumber, setSelectedSchoolNumber] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      if (selectedSchoolYear && selectedSemester) {
        try {
          const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
          const userSemesterSnapshot = await get(userSemesterRef);
          if (userSemesterSnapshot.exists()) {
            const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
            setEnrolledStudents(enrolledStudentsData);
            fetchUserUIDs(enrolledStudentsData);
          } else {
            setEnrolledStudents([]);
            setStudentUIDs({});
          }
        } catch (error) {
          console.error('Error fetching enrolled students:', error.message);
        }
      }
    };

    fetchEnrolledStudents();
  }, [selectedSchoolYear, selectedSemester]);

  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber
    }));
  };

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(db, 'enrolled');
        const schoolYearsSnapshot = await get(schoolYearsRef);
        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);
  

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map(student => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach(studentNumber => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid];
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };

  useEffect(() => {
    // Apply filters based on selected filter values and search query
    let filtered = enrolledStudents.filter(student =>
      student.studentNumber.includes(searchQuery) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Firstname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Lastname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.studentNumber || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Middlename || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Course || '').toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    // Apply additional filters based on selected filter values
    if (selectedSchoolNumber) {
      filtered = filtered.filter(student => student.studentNumber.includes(selectedSchoolNumber));
    }
    if (selectedCourse) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Course.includes(selectedCourse));
    }
    if (selectedGender) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Gender.includes(selectedGender));
    }
  
    setFilteredStudents(filtered);
  }, [searchQuery, enrolledStudents, userDetails, studentUIDs, selectedSchoolNumber, selectedCourse, selectedGender]);
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const extractSchoolNumber = (studentNumber) => {
    return studentNumber.substring(0, 4);
  };
  
  // Logic to collect unique school numbers from enrolled students
  const getUniqueSchoolNumbers = () => {
    const uniqueSchoolNumbers = new Set();
    enrolledStudents.forEach((student) => {
      const schoolNumber = extractSchoolNumber(student.studentNumber);
      uniqueSchoolNumbers.add(schoolNumber);
    });
    return Array.from(uniqueSchoolNumbers);
  };
  const getUniqueCourses = () => {
    const uniqueCourses = new Set();
    enrolledStudents.forEach((student) => {
        const userDetail = userDetails[studentUIDs[student.studentNumber]];
        if (userDetail && userDetail.Course) { // Check if userDetail is defined and Course property exists
            uniqueCourses.add(userDetail.Course);
        }
    });
    return Array.from(uniqueCourses);
};


// Function to handle sorting
const sortData = (field) => {
  if (sortBy === field) {
    // If already sorted by the same field, toggle the sort order
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    // If sorting by a different field, update the sort field and set the order to ascending
    setSortBy(field);
    setSortOrder('asc');
  }
};
// Sort the data based on the selected criteria and order
const sortedStudents = [...filteredStudents].sort((a, b) => {
    // Determine the comparison based on the sorting field
    let comparison = 0;
    if (sortBy === 'studentNumber') {
      comparison = a.studentNumber.localeCompare(b.studentNumber);
    } else if (sortBy === 'fullName') {
      const fullNameA = `${userDetails[studentUIDs[a.studentNumber]].Lastname} ${userDetails[studentUIDs[a.studentNumber]].Firstname} ${userDetails[studentUIDs[a.studentNumber]].Middlename}`;
      const fullNameB = `${userDetails[studentUIDs[b.studentNumber]].Lastname} ${userDetails[studentUIDs[b.studentNumber]].Firstname} ${userDetails[studentUIDs[b.studentNumber]].Middlename}`;
      comparison = fullNameA.localeCompare(fullNameB);
    } else if (sortBy === 'course') {
      comparison = userDetails[studentUIDs[a.studentNumber]].Course.localeCompare(userDetails[studentUIDs[b.studentNumber]].Course);
    } else if (sortBy === 'gender') {
      comparison = userDetails[studentUIDs[a.studentNumber]].Gender.localeCompare(userDetails[studentUIDs[b.studentNumber]].Gender);
    } else if (sortBy === 'email') {
      comparison = userDetails[studentUIDs[a.studentNumber]].email.localeCompare(userDetails[studentUIDs[b.studentNumber]].email);
    }
  
    // Apply the sort order
    return sortOrder === 'asc' ? comparison : -comparison;
  });
  

  const fetchExistingData = async (selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates) => {
    try {
        const db = getDatabase(app);
        const trainingDatesRef = ref(db, `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`);

        onValue(trainingDatesRef, (snapshot) => {
            if (snapshot.exists()) {
                const trainingDatesData = snapshot.val();
                console.log('Existing Training Dates:', trainingDatesData);
                setExistingTrainingDates(trainingDatesData || Array.from({ length: 15 }, () => ''));
            } else {
                console.log('No existing training dates found.');
                setExistingTrainingDates(Array.from({ length: 15 }, () => ''));
            }
        });
    } catch (error) {
        console.error('Error fetching existing data:', error.message);
    }
};
  const [updatedTrainingDates, setUpdatedTrainingDates] = useState({});
  useEffect(() => {
    if (selectedSemester && selectedSchoolYear) {
      fetchExistingData(selectedSchoolYear, selectedSemester, setExistingTrainingDates, setUpdatedTrainingDates);
      setUpdatedTrainingDates({});
    }
  }, [selectedSemester, selectedSchoolYear]);


const [existingTrainingDates, setExistingTrainingDates] = useState(Array.from({ length: 15 }, () => ''));

const [selectedTrainingDay, setSelectedTrainingDay] = useState([]);



useEffect(() => {
    if (existingTrainingDates) {
      const trainingDays = Object.keys(existingTrainingDates).map((day) => ({
        day,
        date: existingTrainingDates[day],
      }));
      setExistingTrainingDays(trainingDays);
    }
  }, [existingTrainingDates]);


  const [existingTrainingDays, setExistingTrainingDays] = useState([]);

  const getSelectedTrainingDayData = () => {
    if (!selectedTrainingDay || !existingTrainingDays[selectedTrainingDay - 1]) {
      console.error("Invalid selected training day or data not found.");
      return null;
    }
  
    return existingTrainingDays[selectedTrainingDay - 1];
  };
  useEffect(() => {
    if (existingTrainingDays.length > 0) {
      const filteredDates = filterTrainingDatesWithinWeek(existingTrainingDays);
      setFilteredTrainingDates(filteredDates);
    }
  }, [existingTrainingDays]);


  const [filteredTrainingDates, setFilteredTrainingDates] = useState([]);

  // Function to filter training dates within a week
  const filterTrainingDatesWithinWeek = (trainingDates) => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  
    // Calculate the difference in days to get to Monday
    const daysUntilMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - daysUntilMonday); // Get the Monday of the current week
  
    // Calculate the date for next Monday
    const nextMondayDate = new Date(mondayDate);
    nextMondayDate.setDate(mondayDate.getDate() + 7);
  
    // Filter training dates within the week (from Monday to next Monday)
    const filteredDates = trainingDates.filter(date => {
      const trainingDate = new Date(date.date);
      return trainingDate >= mondayDate && trainingDate < nextMondayDate;
    });
  
    return filteredDates;
  };
  
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [selectedStudentNumber, setSelectedStudentNumber] = useState('');

  // Function to handle modal visibility toggle
  const toggleModal = (studentNumber) => {
    setShowModal(!showModal);
    setSelectedStudentNumber(studentNumber);
  };
  const [selectedTrainingDayMap, setSelectedTrainingDayMap] = useState({});


  const handleSave = () => {
  // Check if the selected student number already exists for any selected training day
  const isStudentNumberExists = selectedTrainingDay.some((day) =>
    (selectedTrainingDayMap[day] || []).includes(selectedStudentNumber)
  );

  if (isStudentNumberExists) {
    // Display a toast message
    toast.error(`The student number ${selectedStudentNumber} already exists for one or more selected training days.`);
  } else {
    // Check if adding the selected student number will exceed the limit of 5 student numbers per training day
    const exceedsLimit = selectedTrainingDay.some((day) =>
      (selectedTrainingDayMap[day] || []).length >= 5
    );

    if (exceedsLimit) {
      // Display a toast message
      toast.error('Cannot add more than 5 student numbers to a training day.');
    } else {
      // Logic to save the selected training dates
      setShowModal(false); // Close the modal
      // Update the selectedTrainingDayMap state to associate the selected training days with student numbers
      const updatedMap = { ...selectedTrainingDayMap };
      selectedTrainingDay.forEach((day) => {
        updatedMap[day] = updatedMap[day] ? [...updatedMap[day], selectedStudentNumber] : [selectedStudentNumber];
      });
      setSelectedTrainingDayMap(updatedMap);
    }
  }
};

const handleDeleteStudentNumber = async (day, studentNumber) => {
  try {
    const scannerRef = ref(db, 'Scanner');
    const snapshot = await get(scannerRef);
    const scannerData = snapshot.val();

    if (scannerData && scannerData[selectedSchoolYear] && scannerData[selectedSchoolYear][selectedSemester] && scannerData[selectedSchoolYear][selectedSemester].TrainingDates) {
      const trainingDates = scannerData[selectedSchoolYear][selectedSemester].TrainingDates;

      // Iterate through each training day
      for (const dayKey in trainingDates) {
        if (dayKey.includes(day)) {
          // Found the correct day key
          const dayData = trainingDates[dayKey];
          if (dayData && dayData[studentNumber]) {
            // Remove the student number
            delete dayData[studentNumber];
            // Update the database with the modified data
            await set(scannerRef, scannerData);
            toast.success(`Student number ${studentNumber} deleted successfully from ${day}.`);
            return; // Exit the function after successful deletion
          }
        }
      }
      toast.error(`Student number ${studentNumber} not found in ${day}.`);
    } else {
      toast.error(`No data found for ${selectedSchoolYear} - ${selectedSemester}.`);
    }
  } catch (error) {
    console.error('Error deleting student number:', error);
    toast.error('An error occurred while deleting the student number.');
  }
};


const saveTrainingDatesToFirebase = async () => {
    try {
      // Reference to the Scanner database
      const scannerRef = ref(db, 'Scanner');
  
      // Fetch existing data from Firebase
      const snapshot = await get(scannerRef);
      const existingData = snapshot.val() || {};
  
      // Update existing data with new data
      const newData = {
        [selectedSchoolYear]: {
          [selectedSemester]: {
            TrainingDates: filteredTrainingDates
              .filter((item, index) => selectedTrainingDay.includes(index + 1))
              .reduce((acc, selectedItem) => {
                const trainingDayData = selectedTrainingDayMap[selectedItem.day];
                if (trainingDayData && trainingDayData.length > 0) {
                  const dayKey = `Training Day ${selectedItem.day}: ${selectedItem.date}`;
                  acc[dayKey] = acc[dayKey] || {}; // Initialize if not exist
                  
                  // Check if the number of student numbers in the training day exceeds 5
                  if (Object.keys(acc[dayKey]).length < 5) {
                    // Add student numbers to the training day if it's less than 5
                    trainingDayData.forEach((studentNumber) => {
                      if (!acc[dayKey][studentNumber]) {
                        acc[dayKey][studentNumber] = true;
                      }
                    });
                  } else {
                    // Show a toast notification if the limit is reached
                    toast.error(`Cannot add more than 5 student numbers to ${dayKey}.`);
                    console.log(`Cannot add more than 5 student numbers to ${dayKey}.`);
                  }
                }
                return acc;
              }, {...(existingData[selectedSchoolYear]?.[selectedSemester]?.TrainingDates || {})})
          }
        }
      };
  
      // Merge existing data with new data
      const mergedData = {
        ...existingData,
        ...newData
      };
  
      // Set the merged data to the Scanner database
      await set(scannerRef, mergedData);
  
      console.log('Data saved successfully to Firebase.');
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
    }
};
const [trainingData, setTrainingData] = useState(null);


useEffect(() => {
  displayTrainingDatesFromFirebase();
}, []);

const displayTrainingDatesFromFirebase = async () => {
  try {
      const scannerRef = ref(db, 'Scanner');
      onValue(scannerRef, (snapshot) => {
          const data = snapshot.val();
          setTrainingData(data);
      });
  } catch (error) {
      console.error('Error displaying data from Firebase:', error);
  }
};
const [studentNumber, setStudentNumber] = useState('');
const [trainingDayKey, setTrainingDayKey] = useState('');
const [studentStatus, setStudentStatus] = useState('');

const checkStudentStatus = async () => {
  try {
      const statusRef = ref(
          db,
          `Scanner/${selectedSchoolYear}/${selectedSemester}/TrainingDates/${trainingDayKey}/${studentNumber}`
      );

      onValue(statusRef, (snapshot) => {
          if (snapshot.exists()) {
              const studentStatusData = snapshot.val();
              setStudentStatus(studentStatusData);
              console.log('Student Status:', studentStatusData);
          } else {
              console.log('No status found for the student.');
          }
      });
  } catch (error) {
      console.error('Error checking student status:', error.message);
  }
};

useEffect(() => {
  if (selectedTrainingDay && selectedSchoolYear && selectedSemester) {
      checkStudentStatus();
  }
}, [selectedTrainingDay, selectedSchoolYear, selectedSemester]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const statusRef = ref(
        db,
        `Scanner/${selectedSchoolYear}/${selectedSemester}/TrainingDates/${trainingDayKey}/${studentNumber}`
      );
      onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        setStudentStatus(data);
      });
    } catch (error) {
      console.error('Error fetching student status:', error.message);
    }
  };

  if (selectedTrainingDay && selectedSchoolYear && selectedSemester && studentNumber) {
    fetchData();
  }
}, [selectedTrainingDay, selectedSchoolYear, selectedSemester, studentNumber, trainingDayKey]); // Include trainingDayKey in the dependency array
const [userType, setUserType] = useState("");
const [storedStudentNumber, setStoredStudentNumber] = useState("");
const [storedEmail, setStoredEmail] = useState('');
const [storedDesignation, setStoredDesignation] = useState('');
const [designation, setDesignation] = useState('');
useEffect(() => {
    // Set user type based on credentials
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
        setUserType("admin");
    }
}, []);

useEffect(() => {
    const userType = localStorage.getItem("userType");
    setUserType(userType);

    const storedStudentNumber = localStorage.getItem('studentNumber');
    setStoredStudentNumber(storedStudentNumber);


    const storedEmail = localStorage.getItem('email');
    setStoredEmail(storedEmail);

    const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
    setStoredDesignation(storedDesignation); // Set the designation in the component state
}, []);

useEffect(() => {
    const storedDesignation = localStorage.getItem('designation');
    const storedStudentNumber = localStorage.getItem('studentNumber');

    if (storedDesignation && storedStudentNumber) {
        setDesignation(storedDesignation);
        setStudentNumber(storedStudentNumber);
    }
}, []);

return (
    <div className="Admin-authority">
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

    <div className="Admin-authority-container">
       
      <h1  className="Admin-authority-heading">Authorized Personel</h1>
      <div className="reminder">
      <FontAwesomeIcon icon={faExclamationCircle} className="reminder-icon" />
      <span>Reminder: Select<span className="school-year">School Year</span> and<span className="semester">Semester</span> to show the data</span>
    </div>
        <div className='authority-filter'>
        <div className="admin-dropdown-authority">
          <select
            id="semester"
            className="filter-select"
            onChange={(e) => setSelectedSemester(e.target.value)}
            value={selectedSemester}
          >
            <option value="" disabled hidden>
              Select Semester
            </option>
            <option value="First Semester">First Semester</option>
            <option value="Second Semester">Second Semester</option>
          </select>
        </div>

        <div className="admin-dropdown-authority">
          <select
            id="schoolYear"
            className="filter-select"
            onChange={(e) => setSelectedSchoolYear(e.target.value)}
            value={selectedSchoolYear}
          >
            <option value="" hidden>Select School Year</option>
            {schoolYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

          <select
            className="filter-select"
            value={selectedSchoolNumber}
            onChange={(e) => setSelectedSchoolNumber(e.target.value)}
          >
            <option value="">School Number</option>
            {getUniqueSchoolNumbers().map((schoolNumber) => (
              <option key={schoolNumber} value={schoolNumber}>
                {schoolNumber}
              </option>
            ))}
          </select>
          <select
                className="filter-select"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
              >
                <option value="">Course</option>
                {getUniqueCourses().map((course) => (
                  <option key={course} value={course}>
                    {course}
                  </option>
                ))}
              </select>
              <select
                className="filter-select"
                value={selectedGender}
                onChange={(e) => setSelectedGender(e.target.value)}
              >
                <option value="">Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>

        <div className="search-authority">
          <div className="search-input-userlist">
            <input
              type="text"
              id="courseFilter1"
              placeholder="Search by student, name, email, or course"
              value={searchQuery}
              onChange={handleSearch}
            />
            <FontAwesomeIcon icon={faSearch} className="search-icon-userlist" />
          </div>
        </div>
        </div>
        <Link to="/Admin/AdminOfficerAuthorization" className="admin-officer-auth-link">
            <button class="animated-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="arr-2">
            <path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"/>
          </svg>
              <span class="text">OFFICER</span>
              <span class="circle"></span>
              <svg xmlns="http://www.w3.org/2000/svg" class="arr-1" viewBox="0 0 24 24">
                <path
                  d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                ></path>
              </svg>
            </button>
          </Link>

        <div className="authority-container1">

        <div className="authority-container">
        <div className="admin-dropdown13">
        <label className="checkbox-label">Select Training Days: </label>
        <div className="checkbox-group">
        {filteredTrainingDates.map((item, index) => (
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id={`trainingDay${index}`}
                    value={index + 1}
                    checked={selectedTrainingDay.includes(index + 1)}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (e.target.checked) {
                        // Checkbox is checked
                        setSelectedTrainingDay((prevSelected) => [...prevSelected, value]);
                      } else {
                        // Checkbox is unchecked
                        setSelectedTrainingDay((prevSelected) =>
                          prevSelected.filter((day) => day !== value)
                        );
                      }
                    }}
                    className="checkbox-input"
                  />
                  <label htmlFor={`trainingDay${index}`} className="checkbox-text">
                    {`Training Day ${item.day}: ${item.date}`}
                    {selectedTrainingDay.includes(index + 1) && (
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    )}
                  </label>
                </div>
              ))}
        </div>
        </div>


              {/* Table of Enrolled Students */}
              <table className="enrolled-table1">
        <thead>
          <tr>
            <th className="sortable-header" onClick={() => sortData('studentNumber')}>
              Student Number
              {sortBy === 'studentNumber' && (
                <span className={`sort-indicator ${sortOrder === 'asc' ? 'asc' : 'desc'}`}>&#9660;</span>
              )}
            </th>
            <th className="sortable-header" onClick={() => sortData('fullName')}>
              Full Name
              {sortBy === 'fullName' && (
                <span className={`sort-indicator ${sortOrder === 'asc' ? 'asc' : 'desc'}`}>&#9660;</span>
              )}
            </th>
            <th className="sortable-header" onClick={() => sortData('course')}>
              Course
              {sortBy === 'course' && (
                <span className={`sort-indicator ${sortOrder === 'asc' ? 'asc' : 'desc'}`}>&#9660;</span>
              )}
            </th>
            <th className="sortable-header" onClick={() => sortData('gender')}>
              Gender
              {sortBy === 'gender' && (
                <span className={`sort-indicator ${sortOrder === 'asc' ? 'asc' : 'desc'}`}>&#9660;</span>
              )}
            </th>
            <th className="sortable-header" onClick={() => sortData('email')}>
              Email
              {sortBy === 'email' && (
                <span className={`sort-indicator ${sortOrder === 'asc' ? 'asc' : 'desc'}`}>&#9660;</span>
              )}
            </th> 
            <th className="sortable-header" onClick={() => sortData('add')}>
                    Add
                  </th>

          </tr>
        </thead>
        <tbody>
          {sortedStudents.map((student) => (
            <tr key={student.id}>
              {studentUIDs[student.studentNumber] ? (
                <>
                  <td>{student.studentNumber}</td>
                  <td>
                    {userDetails[studentUIDs[student.studentNumber]].Lastname},{' '}
                    {userDetails[studentUIDs[student.studentNumber]].Firstname}{' '}
                    {userDetails[studentUIDs[student.studentNumber]].Middlename}
                  </td>
                  <td>{userDetails[studentUIDs[student.studentNumber]]?.Course}</td>
                  <td>{userDetails[studentUIDs[student.studentNumber]].Gender}</td>
                  <td>{userDetails[studentUIDs[student.studentNumber]].email}</td>
                  {/* Icon button for 'Add' column */}
                  <td>
                      {/* Add icon */}
                      <div
                    className="add-icon"
                    onClick={() => toggleModal(student.studentNumber)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </div>
                    </td>

                </>
              ) : (
                <>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </>
              )}
            </tr>
          ))}
        </tbody>

        </table>
{/* Modal Container */}
<div className="custom-modal-container-scanner1">
        {/* Modal content */}
        <Modal className="custom-modal-container-scanner" show={showModal} onClose={() => setShowModal(false)}>
          <p>Student Number: {selectedStudentNumber}</p>
          <h3>Selected Training Dates</h3>
          <div className='train-scanner'>
            {filteredTrainingDates
              .filter((item, index) => selectedTrainingDay.includes(index + 1))
              .map((selectedItem) => (
                <div key={selectedItem.day}>
                  <input
                    type="checkbox"
                    id={`trainingDay${selectedItem.day}`}
                    checked={selectedTrainingDay.includes(selectedItem.day)}
                    onChange={(e) => {
                      const day = selectedItem.day;
                      if (e.target.checked) {
                        setSelectedTrainingDay((prevSelected) => [...prevSelected, day]);
                      } else {
                        setSelectedTrainingDay((prevSelected) =>
                          prevSelected.filter((selectedDay) => selectedDay !== day)
                        );
                      }
                    }}
                  />
                  <label htmlFor={`trainingDay${selectedItem.day}`}>
                    Training Day {selectedItem.day}: {selectedItem.date}
                  </label>
                </div>
              ))}
          </div>
          {/* Save Button */}
          <button className='save-button-auth noselect' onClick={handleSave}>
  <span className="text">Save</span>
  <span className="icon1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
    </svg>
  </span>
</button>
        </Modal>
      </div>
    <ToastContainer /> 
      </div>
      <div className="selected-training-dates-container">
      <h2>Selected Training Dates</h2>
      <ul className="selected-training-dates-list">
        {filteredTrainingDates
          .filter((item, index) => selectedTrainingDay.includes(index + 1))
          .map((selectedItem) => {
            const trainingDayKey = `Training Day ${selectedItem.day}: ${selectedItem.date}`;
            const trainingDayData =
              trainingData &&
              trainingData[selectedSchoolYear] &&
              trainingData[selectedSchoolYear][selectedSemester] &&
              trainingData[selectedSchoolYear][selectedSemester]['TrainingDates'] &&
              trainingData[selectedSchoolYear][selectedSemester]['TrainingDates'][trainingDayKey];

            return (
              <li key={selectedItem.day}>
                <div>
                  Training Day {selectedItem.day}: {selectedItem.date}
                  <ul>
                    
                    {trainingDayData &&
                      Object.keys(trainingDayData).map((studentNumber) => (
                        <div key={studentNumber} className="student-number-container">
                          <li>
                            Student Number: {studentNumber}
                            {trainingDayData[studentNumber] && (
                              <FontAwesomeIcon icon={faCheck} className="check-icon" />
                            )}
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="delete-icon"
                              onClick={() => handleDeleteStudentNumber(selectedItem.day, studentNumber)}
                            />
                          </li>
                        </div>
                      ))}
 {
  selectedTrainingDayMap[selectedItem.day] &&
  selectedTrainingDayMap[selectedItem.day].slice(0, 5).map((studentNumber) => {
    // Check if the studentNumber already exists in trainingDayData
    const isStudentNumberExist = Object.keys(trainingDayData || {}).includes(studentNumber);

    // Render the student number only if it doesn't exist in trainingDayData
    if (!isStudentNumberExist) {
      return (
        <div key={studentNumber} className="student-number-container">
          <li>
            Student Number: {studentNumber}
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
            <FontAwesomeIcon
              icon={faTimes}
              className="delete-icon"
              onClick={() => handleDeleteStudentNumber(selectedItem.day, studentNumber)}
            />
          </li>
        </div>
      );
    }
    return null; // Render nothing if the student number already exists
  })
}


                  </ul>
                </div>
              </li>
            );
          })}
<button className='save-button-auth noselect' onClick={saveTrainingDatesToFirebase}>
  <span className="text">Save</span>
  <span className="icon1">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
 <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
  </span>
</button>
      </ul>
    </div>
 

      </div>
      {studentStatus && (
  <div className="student-status">
    <div className="student-status1">
      <h3>Student Status:</h3>
      {studentStatus &&
        Object.entries(studentStatus).map(([trainingDay, studentStatusData], index) => (
          <div key={`trainingDay-${index}`} className="student-status2">
            <h4>{trainingDay}</h4>
            <table>
              <thead>
                <tr>
                  <th>Student Number</th>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Status</th>
                  <th>Signal</th> {/* New column for Signal */}
                </tr>
              </thead>
              <tbody>
                {Object.entries(studentStatusData).map(([studentNumber, status], index) => (
                  <tr key={`studentStatus-${index}`}>
                    <td>{studentNumber}</td>
                    <td>
                      {userDetails[studentUIDs[studentNumber]] && (
                        <>
                          {userDetails[studentUIDs[studentNumber]].Lastname},{' '}
                          {userDetails[studentUIDs[studentNumber]].Firstname}{' '}
                          {userDetails[studentUIDs[studentNumber]].Middlename}
                        </>
                      )}
                    </td>
                    <td>
                      {userDetails[studentUIDs[studentNumber]] && (
                        userDetails[studentUIDs[studentNumber]].Course
                      )}
                    </td>
                    <td>{typeof status === 'boolean' ? '-' : status.status}</td> {/* Updated condition */}
                    <td>
                      {typeof status === 'boolean' ? (
                        // Offline status
                        <div className="gray-light" style={{ backgroundColor: 'gray' }}></div>
                      ) : status.status === 'Online' ? (
                        // Online status
                        <div className="green-light" style={{ backgroundColor: 'green' }}></div>
                      ) : (
                        // Other status
                        <div className="red-light" style={{ backgroundColor: 'red' }}></div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  </div>
)}


   </div>
   </div>
 );
}

export default Authority;