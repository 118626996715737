import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./AdminSideBar";
import { getDatabase, ref, get, set } from "firebase/database";
import { db } from '../Firebase';
import "./Dashboard.css";
import EnrolledTotal from "./DASHBOARD/TotalEnrolled.js";
import TotalProgram from "./DASHBOARD/TotalProgram.js";
import ExcuseTotal from './DASHBOARD/TotalExcused.js';
import LateTotal from './DASHBOARD/TotalLate.js';
import PresentTotal from './DASHBOARD/PresentTotal.js';
import AttendancePerTrainingDay from './DASHBOARD/AbsentTotal.js';
import AbsenceLineChart from './DASHBOARD/AbsenceLineChart.js';
import StudentsPerCourse from './DASHBOARD/StudentsPerCourse.js'
import AttendanceLineChart from './DASHBOARD/AbsencePerCourse.js'
import AttendancePerGender from './DASHBOARD/AttendancePerGender.js'
import AttendanceSummary from './DASHBOARD/AttendanceSummary.js'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Spinner from "react-bootstrap/Spinner";


function Dashboard({ app, attendanceData }) {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [currentDay, setCurrentDay] = useState("");
  const [isSunday, setIsSunday] = useState(false);
  const [selectedTrainingDate, setSelectedTrainingDate] = useState();
  const [trainingDates, setTrainingDates] = useState([]); // State to store available training dates
  const [selectedSchoolYear, setSelectedSchoolYear] = useState();
  const [schoolYears, setSchoolYears] = useState([]); // State to store available school years
  const [selectedSemester, setSelectedSemester] = useState();
  const navigate = useNavigate();
  // Declare db here
  const db = getDatabase();

  useEffect(() => {
    const updateDateTime = () => {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      };
      const now = new Date();
      const formattedDate = now.toLocaleDateString(undefined, options);
      setCurrentDate(formattedDate);

      const formattedTime = now.toLocaleTimeString();
      setCurrentTime(formattedTime);

      const formattedDay = now.toLocaleDateString(undefined, {
        weekday: "long",
      });
      setCurrentDay(formattedDay);

      setIsSunday(formattedDay === "Sunday");
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Fetch semesters from Firebase and update the state
    const fetchSemesters = async () => {
      try {
        const schoolYearsRef = ref(db, 'SchoolYear');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          // Extract school years from the snapshot
          const schoolYearList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearList);
          // Set the default selected school year to the first year in the list
          setSelectedSchoolYear(schoolYearList[0]);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSemesters();
  }, [db]);  

  useEffect(() => {
    // Fetch semesters from Firebase and update the state
    const fetchSemesters = async () => {
      try {
        const schoolYearsRef = ref(db, 'Session');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          // Extract school years from the snapshot
          const schoolYearList = Object.keys(schoolYearsSnapshot.val());
          const trainingDates = schoolYearList.reduce((acc, year) => {
            const semesters = Object.keys(schoolYearsSnapshot.val()[year]);
            semesters.forEach(semester => {
              const dates = Object.keys(schoolYearsSnapshot.val()[year][semester].Trainingdates || {});
              acc.push(...dates);
            });
            return acc;
          }, []);
          setTrainingDates(trainingDates);
          // Set the default selected training date to the first date in the list
          setSelectedTrainingDate(trainingDates[0]);
        } else {
          setTrainingDates([]);
        }
      } catch (error) {
        console.error('Error fetching training dates:', error.message);
      }
    };

    fetchSemesters();
  }, [db]);

  const [userType, setUserType] = useState("");
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedEmail, setStoredEmail] = useState('');
  const [storedDesignation, setStoredDesignation] = useState('');
  const [designation, setDesignation] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  useEffect(() => {
      // Set user type based on credentials
      const userEmail = localStorage.getItem('userEmail');
      if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
          setUserType("admin");
      }
  }, []);

  useEffect(() => {
      const userType = localStorage.getItem("userType");
      setUserType(userType);

      const storedStudentNumber = localStorage.getItem('studentNumber');
      setStoredStudentNumber(storedStudentNumber);


      const storedEmail = localStorage.getItem('email');
      setStoredEmail(storedEmail);

      const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
      setStoredDesignation(storedDesignation); // Set the designation in the component state
  }, []);

  useEffect(() => {
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');
      const storedEmail = localStorage.getItem('email');
      if (storedDesignation && storedStudentNumber) {
          setDesignation(storedDesignation);
          setStudentNumber(storedStudentNumber);
          setEmail(storedEmail);
      }
  }, []);
  // Effect to fetch user data when component mounts or when studentNumber or designation changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromDb = await getUserData(designation, studentNumber);
        setUserData(userDataFromDb);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchData();
  }, [designation, studentNumber]);
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState(null);



  const getUserData = async (designation, studentNumber) => {
    try {
      // Construct the reference path
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
      
      // Retrieve the data
      const snapshot = await get(userRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Extract the user data
        const userData = snapshot.val();
        // Return the relevant data such as Lastname and Firstname
        return {
          Course: userData.Course,
          Lastname: userData.Lastname || '',
          Firstname: userData.Firstname || '',
          Middlename: userData.Middlename || '',
          // Add more properties as needed
        };
      } else {
        // Data does not exist
        console.log("No data available for the given student number.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user data:", error.message);
      throw error;
    }
  };


  const [userImageUrl, setUserImageUrl] = useState(null);
  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
      setUserImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching user image:', error);
      setUserImageUrl(null);
    }
  };
  
  useEffect(() => {
    // Fetch user image when email changes
    if (email) {
      fetchUserImage(email);
    }
  }, [email]);
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Add any additional logic you want to execute when the modal is closed
  };
  const handleLogout = async () => {
    try {
        // Reference the appropriate location in the database using the student number
        const userRef = ref(db, `Officer/${designation}/${studentNumber}`);

        // Get the user data from the database
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
            const userData = userSnapshot.val();

            // Update the user's status to "Offline"
            await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Offline');

            toast.success('Logout successful!', { position: 'top-center' });

            // Clear local storage
            localStorage.clear();

            // Redirect to the login page using navigate
            navigate("/Admin/AdminOfficerLogin");
        } else {
            // User does not exist
            toast.error('User does not exist.', { position: 'top-center' });
        }
    } catch (error) {
        console.error('Error logging out:', error);
        toast.error('Failed to log out. Please try again later.', { position: 'top-center' });
    }
};

  return (
    <div className="dashboard-container"> 
    
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

      <div className="Dashboard-full">
        <div className="header-container1">

        {isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
          <h1 className="Header-container-attendance">Dashboard</h1>  
          
        {!designation   && (
            <>
             
            </>
          )}
             
             { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
 <div className="user-details">
 <div className="user-details1">
   {userData && (
     <p className="user-name">
       {userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
     </p>
   )}
 </div>
 <div className="user-image-container1" onClick={handleModalOpen}>
   {userImageUrl ? (
     <img className="user-image1" src={userImageUrl} alt="User" />
   ) : (
       <span className="visually-hidden">Loading...</span>
   )}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

 </div>
 {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <button className="edit-button">
      <svg className="edit-svgIcon" viewBox="0 0 512 512">
        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
      </svg>
    </button>
    <div className="info-box">
  <p className="Personnel">Personnel Officer ({designation})</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
        <tr>
          <td><label htmlFor="studentNumber">Student Number:</label></td>
          <td><p>{studentNumber}</p></td>
        </tr>
        <tr>
        <td><label>Name:</label></td>
          <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
        </tr>
        <tr>
          <td><label htmlFor="email">Email:</label></td>
          <td><p>{email}</p></td>
        </tr>
        <tr>
          <td><label>Course:</label></td>
          <td><p>{userData.Course}</p></td>
        </tr>
       
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}
 
</div>
)}
   
        </div> 
    
        <div className="attendance-table-container1">
        <div className="date-container">
          <div className="combined-container">
            <div className="day-status">
              <div className="current-day">
                {currentDay}
              </div>
            </div>
            <div
              className={`class-status ${isSunday ? "have-class" : "no-class"}`}
            >
              {isSunday ? <p>📚 Have Class</p> : <p>🚫 No Class</p>}
            </div>
          </div>
        </div>
        <div className="dash-time-comb">
        <div className="date-content">
            <div className="date-top">
              <span className="label-text">Date</span>
              <div className="date-top1">
              {currentDate}
              <FontAwesomeIcon icon={faCalendar} className="faCalendar" />
              </div>
            </div>
            <div className="current-date">
              <div className="time-top">
                <span className="label-text">Time</span>
              </div>
              <div className="current-time">
                {currentTime}
                <FontAwesomeIcon icon={faClock} className="faClock" />
              </div>
            </div>
          </div>
        <div className="dash-filter">
          <div className="school-year-selection">
            <select
              id="semester"
              className="Filter-select"
              onChange={(e) => setSelectedSchoolYear(e.target.value)}
              value={selectedSchoolYear}
            >
              <option value="" hidden>Select School Year</option>
              {schoolYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="semester-selection">
            <select
              id="semester"
              className="Filter-select"
              onChange={(e) => setSelectedSemester(e.target.value)}
              value={selectedSemester}
            >
              <option value="" hidden>
                Select Semester
              </option>
              <option value="First Semester">First Semester</option>
              <option value="Second Semester">Second Semester</option>
            </select>
          </div>
          <div className="date-selection">
            <select
              id="semester"
              className="Filter-select"
              value={selectedTrainingDate || ""}
              onChange={(e) => setSelectedTrainingDate(e.target.value)}
            >
              {trainingDates
                .sort((a, b) => {
                  const trainingNumberA = parseInt(a.split(' ')[1]);
                  const trainingNumberB = parseInt(b.split(' ')[1]);
                  return trainingNumberA - trainingNumberB;
                })
                .map((date) => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
            </select>
          </div>
        </div>
        </div>        
        
        <div className="attendance-comb">     
        <AttendancePerTrainingDay selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
        <PresentTotal selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
        <LateTotal selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
        <ExcuseTotal selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
        <EnrolledTotal selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>
        </div>    
        <div className="chart-program-comb">
        <div className="charts-container">        
        <AbsenceLineChart selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
          <AttendanceLineChart selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate} />
          <StudentsPerCourse selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>
          <AttendancePerGender selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate}/>
          <AttendanceSummary selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester} selectedTrainingDate={selectedTrainingDate}/>
        </div>      
        <div className="total-program">
          <TotalProgram selectedSchoolYear={selectedSchoolYear} selectedSemester={selectedSemester}/>   
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;