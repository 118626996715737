import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Row, Col, InputGroup, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./LoginAdmin.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from "react-bootstrap/Spinner"; // Import Spinner from react-bootstrap
function AdminLoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading spinner
  const handleLogin = (e) => {
    let userType = "";
    if (email === "rotcpaiskan@gmail.com" && password === "Gentech1234") {
      navigate("/Admin/Dashboard");
      userType = "admin";
    } else if (email === "rotcpuppaiskan@gmail.com" && password === "rotcpuppaiskan") {
      navigate("/Admin/QRScanner");
    }  else if (email === "monitor@gmail.com" && password === "admin1234") {
      navigate("/Admin/Dashboard");
      userType = "monitor";
    } else if (email === "userapproval@gmail.com" && password === "admin1234") {
      navigate("/Admin/Dashboard");
      userType = "approval";
    } else if (email === "report@gmail.com" && password === "admin1234") {
      navigate("/Admin/Dashboard");
      userType = "report";
    } else {
      toast.error("Invalid email or password");
      return;
    }
    localStorage.setItem("userType", userType);
    localStorage.setItem("email", email); // Store the email in local storage
  };
  
  
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
         <ToastContainer /> 
      <div className="login-form-container">

        <Link to="/">
          <Button variant="link" size="lg" className="button-homepage">
            <FontAwesomeIcon icon={faArrowLeft} size="xl" style={{ color: "#000000" }} />
          </Button>
        </Link>

        <div className="login-heading-container">
          <h2>Welcome!</h2>
        </div>

        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              Email
            </Form.Label>
            <Col sm="15">
            <Form.Control
  id="email-input"
  type="email"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  placeholder="Enter your email"
  aria-describedby="passwordHelpBlock"
/>

            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column sm="3">
              Password
            </Form.Label>
            <Col sm="15">
              <InputGroup>
              <Form.Control
  id="password-input"
  type={showPassword ? "text" : "password"}
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  onKeyPress={handleKeyPress}
  placeholder="Enter your password"
  style={{
    border: "1px solid #343a40",
    borderRadius: "1",
    height: "40px",
    width: "200px",
    fontSize: "18px",
  }}
/>

                <Button
                  variant="outline-dark"
                  onClick={toggleShowPassword}
                  aria-label={showPassword ? "Hide Password" : "Show Password"}
                  className="show-eye-button"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
              </InputGroup>
            </Col>
          </Form.Group> 

          <div className="login-container-button-cd">
            <button
              variant="success"
              size="lg"
              type="button"
              className="button-login-cd"
              onClick={handleLogin}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-2"
                    style={{ marginRight: "8px" }} // Adjust spacing
                  />
                  <span className="spinner-text">Logging in...</span>
                </>
              ) : (
                <p>Login</p>
              )}
            </button>
          </div>
          <div className="scanner-link-container">
            <Link to="/Admin/QRScannerLogin" className="scanner-link">
              Scanner
            </Link>
          </div>
          <div className="scanner-link-container">
            <Link to="/Admin/AdminOfficerLogin " className="scanner-link">
              LOGIN OFFICER
            </Link>
          </div>
        </Form>
      </div>
      
      {/* Error Modal */}
      <Modal className="forgot-modal" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminLoginForm;
