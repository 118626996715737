import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { ref, set, get } from 'firebase/database';
import SideBar from './AdminSideBar';
import ListGroup from 'react-bootstrap/ListGroup';
import { ToastContainer, toast } from 'react-toastify'; // Import toast container and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import "./AdminCreateAccount.css";

function Admin() {
  // State variables
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemesterData, setSelectedSemesterData] = useState(null);

  // Function to generate the range of school years
  function generateSchoolYearRange() {
    const currentYear = new Date().getFullYear();
    const academicYearStartMonth = 9; // September
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so add 1

    // If the current month is before the start of the academic year, use the previous year
    const startYear = currentMonth < academicYearStartMonth ? currentYear - 1 : currentYear;

    const years = [];
    for (let i = startYear; i <= startYear + 3; i++) {
      years.push(`${i}-${i + 1}`);
    }

    return years;
  }

  // Function to save data for the selected semester and school year
  async function saveSchoolYearWithSemesterData(selectedSemester) {
    if (selectedSemester && selectedSchoolYear) {
      try {
        const semesterRef = ref(db, `SchoolYear/${selectedSchoolYear}/${selectedSemester}`);
        const snapshot = await get(semesterRef);
  
        if (snapshot.exists()) {
          // If data already exists, show toast notification and return
          toast.warn(`Semester ${selectedSemester} for School Year ${selectedSchoolYear} already exists.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
  
        // Data doesn't exist, proceed to save
        let semesterData = {};
  

        // Check the selected semester and define the data accordingly
        if (selectedSemester === 'First Semester') {
          semesterData = {
            subjects: [
              "PHYSICAL ORGANIZATION OF THE CADET CORPS AND COURSE AND COURSE ORIENTATION",
              "MILITARY ORIENTATION",
              {
                title: "COMBAT TRAINING OF INDIVIDUAL SOLDIER",
                subtopics: [
                  "Basic Intelligence",
                  "Basic Map Reading And Land Navigation",
                  "Basic Signal Communication",
                  "Individual Movement Technique",
                  "Basic Unit Formation",
                  "Basic Weapon Training (M16 5.56mm, M14 6.72mm Granade)",
                  "Pre Marksmanship Training"
                ]
              },
              {
                title: "PUBLIC INFORMATION",
                subtopics: [
                  "Threat To National Security",
                  "Peace Process"
                ]
              },
              {
                title: "CIVIL AFFAIRS",
                subtopics: [
                  "Army Social Responsibility (ASR) Program",
                  "Disaster Preparedness",
                  "Community Service"
                ]
              },
              {
                title: "DRILLLS & CEREMONIES",
                subtopics: [
                  "Dismounted Drill",
                  "Ceremonies"
                ]
              }
            ]
          };
        } else if (selectedSemester === 'Second Semester') {
          semesterData = {
            subjects: [
              {
                title: "GENERAL MILITARY SUBJECTS",
                subtopics: [
                  "PA ORGANIZATION",
                  "TROOP LEADING PROCEDURE",
                  "PROBLEM SOLVING PROCESS",
                  "RESERVIST & RESERVE FORCE DEVELOPMENT",
                  "INTRODUCTION TO LOGISTICS"
                ]
              },
              {
                title: "SMALL UNIT TACTICS",
                subtopics: [
                  "PATROLLING",
                  "RAIDS",
                  "AMBUSCADES",
                  "RIFLE SQUAD TACTICS"
                ]
              },
              {
                title: "INDIVIDUAL-UNIT TRAINING",
                subtopics: [
                  "VISUAL TRACKING",
                  "MILITARY MOUNTAINEERING",
                  "JUNGLE BASE OPERATION",
                  "MARCHES & BIVOUACS & LAND NAVIGATION",
                  "MILITARY STAKES"
                ]
              },
              {
                title: "CREW SERVE WEAPONS TRAINING",
                subtopics: [
                  "60MM MORTAR",
                  "80MM MORTAR",
                  "CAL 50 MG"
                ]
              },
              {
                title: "MARKSMANSHIP TRAINING",
                subtopics: [
                  "RIFLE MARKMANSHIP TRAINING"
                ]
              },
              {
                title: "INDIVIDUAL PROTECTIVE MEASURES",
                subtopics: [
                  "PERSONAL HYGIENE & SANITATION",
                  "BASIC LIFE SUPPORT",
                  "FIRST AID"
                ]
              },
              {
                title: "MANDATORY READINGS",
                subtopics: [
                  "FIELD ARTILLERY OPERATIONS",
                  "FORWARD-OBSERVER",
                  "INTRODUCTION TO ARMOR OPERATIONS",
                  "INTRODUCTION TO UNCONVENTIONAL WARFARE",
                  "PL"
                ]
              }
            ]
          };
        }

        // Set the data for the selected semester and school year
        await set(semesterRef, semesterData);
        console.log('Data saved successfully!');
        setSelectedSemesterData(semesterData);
  
        // Show success toast notification
        toast.success(`Semester ${selectedSemester} for School Year ${selectedSchoolYear} data saved successfully!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
      } catch (error) {
        console.error('Error saving data:', error.message);
        // Show error toast notification
        toast.error('Error saving semester data. Please try again later.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      console.log('Please select semester and school year.');
    }
  }

  // Function to retrieve data for the selected semester and school year
  async function retrieveSemesterData() {
    if (selectedSemester && selectedSchoolYear) {
      try {
        const semesterRef = ref(db, `SchoolYear/${selectedSchoolYear}/${selectedSemester}`);
        const snapshot = await get(semesterRef);
        if (snapshot.exists()) {
          setSelectedSemesterData(snapshot.val());
        } else {
          console.log("No data available for the selected semester and school year.");
        }
      } catch (error) {
        console.error('Error retrieving data:', error.message);
      }
    } else {
      console.log('Please select semester and school year.');
    }
  }

  // Function to render the data for the selected semester
  function renderSemesterData() {
    if (selectedSemester === 'First Semester' || selectedSemester === 'Second Semester') {
      return (
        <div className="semester-container">
          <h2 className="semester-heading">{selectedSemester} Data</h2>
          <ListGroup>
            {selectedSemesterData &&
              selectedSemesterData.subjects.map((subject, index) => (
                <ListGroup.Item key={index}>
                  {typeof subject === 'string' ? subject : subject.title}
                  {/* If subject is an object with subtopics, render them */}
                  {typeof subject !== 'string' && subject.subtopics && (
                    <ol>
                      {subject.subtopics.map((subtopic, idx) => (
                        <li key={idx}>{subtopic}</li>
                      ))}
                    </ol>
                  )}
                </ListGroup.Item>
              ))}
          </ListGroup>
        </div>
      );
    } else {
      return null; // Return null if no semester is selected or if it's not the First or Second Semester
    }
  }

  useEffect(() => {
    retrieveSemesterData();
  }, [selectedSemester, selectedSchoolYear]);

  const [userType, setUserType] = useState("");
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedEmail, setStoredEmail] = useState('');
  const [storedDesignation, setStoredDesignation] = useState('');
  const [designation, setDesignation] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  useEffect(() => {
      // Set user type based on credentials
      const userEmail = localStorage.getItem('userEmail');
      if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
          setUserType("admin");
      }
  }, []);

  useEffect(() => {
      const userType = localStorage.getItem("userType");
      setUserType(userType);

      const storedStudentNumber = localStorage.getItem('studentNumber');
      setStoredStudentNumber(storedStudentNumber);


      const storedEmail = localStorage.getItem('email');
      setStoredEmail(storedEmail);

      const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
      setStoredDesignation(storedDesignation); // Set the designation in the component state
  }, []);

  useEffect(() => {
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');

      if (storedDesignation && storedStudentNumber) {
          setDesignation(storedDesignation);
          setStudentNumber(storedStudentNumber);
      }
  }, []);

  return (
    <div className="admin-container-1">
      <SideBar
        studentNumber={storedStudentNumber}
        email={storedEmail}
        designation={storedDesignation}
      />
      <div className="admin-container-admin-account">
        <div className="admin-container3">
          <div className="Header-list-admin">
            <h1>Enrollment</h1>
          </div>
          <div className='add-schoolyear-ac'>
          <div className='add-schoolyear-filter'>
            <div className="admin-dropdown-container-sed">
            <div className="admin-dropdown-sed">
                <select
                  id="schoolYear"
                  className="filter-select"
                  onChange={(e) => setSelectedSchoolYear(e.target.value)}
                  value={selectedSchoolYear}
                >
                  <option value="">Select School Year</option>
                  {generateSchoolYearRange().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="admin-dropdown-sed">
                <select
                  id="semester"
                  className="filter-select"
                  onChange={(e) => setSelectedSemester(e.target.value)}
                  value={selectedSemester}
                >
                  <option value="">Select Semester</option>
                  <option value="First Semester">First Semester</option>
                  <option value="Second Semester">Second Semester</option>
                </select>
              </div>
            </div>
          </div>
          </div>
        </div>
        <button className='noselect00w' onClick={() => saveSchoolYearWithSemesterData(selectedSemester)}>
      <span className="text00w">Save</span>
      <span className="icon00w">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
        </svg>
      </span>
    </button>
        {/* Save button */}
        {renderSemesterData()}
        {/* Render the data for the selected semester */}
      </div>
      <ToastContainer /> {/* Toast container */}
    </div>
  );
}

export default Admin;