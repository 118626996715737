import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Bar } from 'react-chartjs-2';
import './CityCountsChart.css'; // Import the CSS file

function StudentsPerCity({ selectedSchoolYear, selectedSemester }) {
  const [cityData, setCityData] = useState({});
  const db = getDatabase();

  useEffect(() => {
    const fetchUserCities = async () => {
      try {
        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const snapshot = await get(enrolledRef);

        if (snapshot.exists()) {
          const enrolledData = snapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);

          const usersRef = ref(db, 'users');
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const cityCounts = {};

            // Count the number of users per city
            Object.values(users).forEach(user => {
              if (studentNumbers.includes(user.studentNumber)) {
                const { city } = user; // Assuming city is the property name in the user object
                if (city in cityCounts) {
                  cityCounts[city]++;
                } else {
                  cityCounts[city] = 1;
                }
              }
            });

            setCityData(cityCounts);
          } else {
            setCityData({});
          }
        } else {
          setCityData({});
        }
      } catch (error) {
        console.error('Error fetching user cities:', error.message);
      }
    };

    fetchUserCities();
  }, [db, selectedSchoolYear, selectedSemester]);

  const prepareChartData = () => {
    return {
      labels: Object.keys(cityData),
      datasets: [
        {
          label: 'Students Per City',
          data: Object.values(cityData),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
          ],
        },
      ],
    };
  };

  return (
    <div className="students-per-city-container">
      <div className="students-per-city-chart">
        <h2 className="students-per-city-header">Students Per City</h2>
        <Bar data={prepareChartData()} />
      </div>
    </div>
  );
}

export default StudentsPerCity;
