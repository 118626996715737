// SideBar.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faHome,
  faChartBar,
  faUserPlus,
  faCalendarAlt,
  faQrcode,
  faCalendarCheck,
  faUsers,
  faBell,
  faPersonCirclePlus,
  faFileArrowUp,
  faCircleCheck,
  faIdCard,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import "./AdminSideBar.css";
import { useNavigate } from "react-router-dom";
import { ref, get, set} from "firebase/database";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../Firebase';
import Spinner from "react-bootstrap/Spinner";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";

function SideBar({ selectedOption, studentNumber, selectedSchoolYear, selectedSemester, email, designation }) {
  const [minimized, setMinimized] = useState(false);
  const [icon, setIcon] = useState(faAngleLeft);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const toggleMinimized = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
  
    // Check if the selected item is "home"
    if (itemName === "home") {
      // Clear local storage
      localStorage.clear();
    }
  };
  const handleLogoClick = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  const getUserData = async (designation, studentNumber) => {
    try {
      // Construct the reference path
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
      
      // Retrieve the data
      const snapshot = await get(userRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Extract the user data
        const userData = snapshot.val();
        // Return the relevant data such as Lastname and Firstname
        return {
          Lastname: userData.Lastname || '',
          Firstname: userData.Firstname || '',
          Middlename: userData.Middlename || '',
          // Add more properties as needed
        };
      } else {
        // Data does not exist
        console.log("No data available for the given student number.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user data:", error.message);
      throw error;
    }
  };
  const handleLogout = async () => {
    // Ask for confirmation before logging out
    const confirmLogout = window.confirm('Are you sure you want to log out?');

    if (confirmLogout) {
        try {
            // Reference the appropriate location in the database using the student number
            const userRef = ref(db, `Officer/${designation}/${studentNumber}`);

            // Get the user data from the database
            const userSnapshot = await get(userRef);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();

                // Update the user's status to "Offline"
                await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Offline');

                toast.success('Logout successful!', { position: 'top-center' });

                // Clear local storage
                localStorage.clear();

                // Redirect to the login page using navigate
                navigate("/Admin/AdminOfficerLogin");
            } else {
                // User does not exist
                toast.error('User does not exist.', { position: 'top-center' });
            }
        } catch (error) {
            console.error('Error logging out:', error);
            toast.error('Failed to log out. Please try again later.', { position: 'top-center' });
        }
    }
};


  const [userImageUrl, setUserImageUrl] = useState(null);
  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
      setUserImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching user image:', error);
      setUserImageUrl(null);
    }
  };
  
  useEffect(() => {
    // Fetch user image when email changes
    if (email) {
      fetchUserImage(email);
    }
  }, [email]);
  const [userData, setUserData] = useState(null);

  // Effect to fetch user data when component mounts or when studentNumber or designation changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromDb = await getUserData(designation, studentNumber);
        setUserData(userDataFromDb);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchData();
  }, [designation, studentNumber]);

  return (
    <div className={`sidebarAdmin ${minimized ? "minimized" : ""}`}>
      <div
        className={`logo-image ${minimized ? "minimized" : ""}`}
        onClick={handleLogoClick}
      ></div>
      <hr className="line-separator" />
      <div className="sidebar-content"> 
        <ul>
        {!designation   && (
            <>
            
                  <ul>
          <li className={`sidebar-list-item ${selectedItem === "home" ? "selected" : ""}`}>
            <Link to="/" onClick={() => handleItemClick("home")}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "dashboard" ? "selected" : ""}`}>
            <Link
              to="/Admin/Dashboard"
              onClick={() => handleItemClick("dashboard")}
            >
              <FontAwesomeIcon icon={faChartBar} />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "addUser" ? "selected" : ""}`}>
            <Link
              to="/Admin/AdminCreateAccount"
              onClick={() => handleItemClick("addUser")}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              <span>AddUser</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
            <Link to="/Admin/AdminSchedule">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>Schedule</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
    <Link to="/Admin/AdminAnnouncement">
    <FontAwesomeIcon icon={faBell} />
        <span>Announcement</span>
      
    </Link>
</li>
          <li className={`sidebar-list-item ${selectedItem === "attendance" ? "selected" : ""}`}>
            <Link
              to="/Admin/Attendance"
              onClick={() => handleItemClick("attendance")}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />
              <span>Attendance</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "AdminEnrolled" ? "selected" : ""}`}>
            <Link to="/Admin/AdminEnrolled">
              <FontAwesomeIcon icon={faUsers} />
              <span>Cadet</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "AdminVerified" ? "selected" : ""}`}>
            <Link to="/Admin/AdminVerified">
            <FontAwesomeIcon icon={faCircleCheck} />
              <span>Approval</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "PendingEnrollments" ? "selected" : ""}`}>
            <Link to="/Admin/AdminPendingEnrollees">
            <FontAwesomeIcon icon={faPersonCirclePlus} />
              <span>Enrollees</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "FileUploader" ? "selected" : ""}`}>
            <Link to="/Admin/AdminAddSubject">
            <FontAwesomeIcon icon={faFileArrowUp} />
              <span>Add Subject</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "AdminAuthority" ? "selected" : ""}`}>
            <Link to="/Admin/AdminAuthority">
            <FontAwesomeIcon icon={faIdCard} />
              <span>Authority</span>
            </Link>
          </li>
        </ul>
            </>
          )}
          {/* Render sidebar items based on the selected option */}
          {designation   === 'EXO' && (
            <>
  <div className="selected-info">
  <p>{designation  } - {studentNumber}</p>
</div>
<ul>
          <li className={`sidebar-list-item ${selectedItem === "home" ? "selected" : ""}`}>
            <Link to="/" onClick={() => handleItemClick("home")}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "dashboard" ? "selected" : ""}`}>
            <Link
              to="/Admin/Dashboard"
              onClick={() => handleItemClick("dashboard")}
            >
              <FontAwesomeIcon icon={faChartBar} />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
            <Link to="/Admin/AdminSchedule">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>Schedule</span>
            </Link>
          </li>
          <li className="sidebar-list-item">
    <Link to="/Admin/AdminAnnouncement">
    <FontAwesomeIcon icon={faBell} />
        <span>Announcement</span>
      
    </Link>
</li>
          <li className={`sidebar-list-item ${selectedItem === "attendance" ? "selected" : ""}`}>
            <Link
              to="/Admin/Attendance"
              onClick={() => handleItemClick("attendance")}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />
              <span>Attendance</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "AdminEnrolled" ? "selected" : ""}`}>
            <Link to="/Admin/AdminEnrolled">
              <FontAwesomeIcon icon={faUsers} />
              <span>Cadet</span>
            </Link>
          </li>
        </ul>
              <li className="sidebar-list-item">
  <button onClick={handleLogout}>
    <FontAwesomeIcon icon={faSignOutAlt} />
    <span>Logout</span>
  </button>
</li>

            </>
          )}
          {designation   === 'S1' && (
            <>
            <div className="selected-info">
  <p>{designation  }</p>
    <p>{studentNumber}</p>
    <p>{email}</p>
    {userData && <p>First Name: {userData.Firstname}</p>}
        </div>
        <li className={`sidebar-list-item ${selectedItem === "home" ? "selected" : ""}`}>
            <Link to="/" onClick={() => handleItemClick("home")}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "dashboard" ? "selected" : ""}`}>
            <Link to="/Admin/Dashboard" onClick={() => handleItemClick("dashboard")}>
              <FontAwesomeIcon icon={faChartBar} />
              <span>Dashboard</span>
            </Link>
            
          </li>
              <li className={`sidebar-list-item ${selectedItem === "attendance" ? "selected" : ""}`}>
                <Link to="/Admin/Attendance" onClick={() => handleItemClick("attendance")}>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                  <span>Attendance</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
            <Link to="/Admin/AdminSchedule">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>Schedule</span>
            </Link>
          </li>
              <li className={`sidebar-list-item ${selectedItem === "cadet" ? "selected" : ""}`}>
                <Link to="/Admin/AdminEnrolled" onClick={() => handleItemClick("cadet")}>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span>Cadet</span>
                </Link>
              </li>
              <li className="sidebar-list-item">
  <button onClick={handleLogout}>
    <FontAwesomeIcon icon={faSignOutAlt} />
    <span>Logout</span>
  </button>
</li>
<div className="user-image-cadet1">
        {userImageUrl ? (
          <img className="user-image enlarged-image" src={userImageUrl} alt="User" />
        ) : (
          <Spinner animation="border" role="status" variant="success">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <p>{localStorage.getItem("fullName")}</p>
      </div>
{/* Display Designation and Student Number */}
<p>{designation} - {studentNumber}</p>
            </>
          )}
          {designation   === 'S2' && (
            <>
            <div className="selected-info">
  <p>{designation  }</p>
    <p>{studentNumber}</p>
    <p>{email}</p>
        </div>
        <li className={`sidebar-list-item ${selectedItem === "home" ? "selected" : ""}`}>
            <Link to="/" onClick={() => handleItemClick("home")}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={`sidebar-list-item ${selectedItem === "dashboard" ? "selected" : ""}`}>
            <Link to="/Admin/Dashboard" onClick={() => handleItemClick("dashboard")}>
              <FontAwesomeIcon icon={faChartBar} />
              <span>Dashboard</span>
            </Link>
          </li>
              <li className={`sidebar-list-item ${selectedItem === "schedule" ? "selected" : ""}`}>
                <Link to="/Admin/AdminSchedule" onClick={() => handleItemClick("schedule")}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <span>Schedule</span>
                </Link>
              </li>
              <li className={`sidebar-list-item ${selectedItem === "attendance" ? "selected" : ""}`}>
                <Link to="/Admin/Attendance" onClick={() => handleItemClick("attendance")}>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                  <span>Attendance</span>
                </Link>
              </li>
              <li className={`sidebar-list-item ${selectedItem === "cadet" ? "selected" : ""}`}>
                <Link to="/Admin/AdminEnrolled" onClick={() => handleItemClick("cadet")}>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span>Cadet</span>
                </Link>
              </li>
              <li className={`sidebar-list-item ${selectedItem === "approval" ? "selected" : ""}`}>
                <Link to="/Admin/AdminVerified" onClick={() => handleItemClick("approval")}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span>Approval</span>
                </Link>
              </li>
              <li className={`sidebar-list-item ${selectedItem === "enrollees" ? "selected" : ""}`}>
                <Link to="/Admin/AdminPendingEnrollees" onClick={() => handleItemClick("enrollees")}>
                  <FontAwesomeIcon icon={faUsers} />
                  <span>Enrollees</span>
                </Link>
              </li>


{/* Display Designation and Student Number */}
            </>
          )}
          
        </ul>
      </div>
      <div className="minimize-btn-container">
        <button className="minimize-btn-admin" onClick={toggleMinimized}>
          <FontAwesomeIcon icon={icon} />
        </button>
      </div>
      
    </div>
  );
}

export default SideBar;