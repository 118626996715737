import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const SessionDataViewer = ({ sessionData, userData, selectedSemester, selectedSchoolYear, schoolYears }) => (
  <div className="session-data-container">
    <div className="session-header-container">
      <h1>Session Data Viewer</h1>

      <div className="session-filter">
        <div className="admin-dropdown-session">
          <label htmlFor="semester"></label>
          <select
            id="semester"
            className="user-list-label1"
            onChange={(e) => selectedSemester(e.target.value)}
            value={selectedSemester}
          >
            <option value="" disabled>Select Semester</option>
            <option value="First Semester">First Semester</option>
            <option value="Second Semester">Second Semester</option>
          </select>
          <MdKeyboardArrowDown className="dropdown-icon-session" />
        </div>

        <div className="admin-dropdown-session">
          <label htmlFor="schoolYear"></label>
          <select
            id="schoolYear"
            className="user-list-label1"
            onChange={(e) => selectedSchoolYear(e.target.value)}
            value={selectedSchoolYear}
          >
            <option value="" disabled>Select School Year</option>
            {schoolYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <MdKeyboardArrowDown className="dropdown-icon-session" />
        </div>
      </div>
    </div>

    <div className="custom-table table-container">
      <table>
        <thead>
          <tr>
            <th>Student Number</th>
            {sessionData &&
              Object.keys(sessionData).map((schoolYear) =>
                Object.keys(sessionData[schoolYear]).map((semester) =>
                  sessionData[schoolYear][semester]["Trainingdates"] ? (
                    Object.keys(sessionData[schoolYear][semester]["Trainingdates"])
                      .sort((a, b) => parseInt(a.match(/\d+/)[0]) - parseInt(b.match(/\d+/)[0]))
                      .map((trainingDateKey) => (
                        <th key={`header-${trainingDateKey}`}>{trainingDateKey}</th>
                      ))
                  ) : null
                )
              )}
          </tr>
        </thead>
        <tbody>
          {sessionData &&
            Object.keys(sessionData).map((schoolYear) =>
              Object.keys(sessionData[schoolYear]).map((semester) =>
                sessionData[schoolYear][semester]["Trainingdates"] ? (
                  Object.keys(sessionData[schoolYear][semester]["Trainingdates"][Object.keys(sessionData[schoolYear][semester]["Trainingdates"])[0]])
                    .map((studentNumber) =>
                      studentNumber === userData.studentNumber && (
                        <tr key={`${schoolYear}-${semester}-${studentNumber}`}>
                          <td>{studentNumber}</td>
                          {Object.keys(sessionData[schoolYear][semester]["Trainingdates"])
                            .sort((a, b) => parseInt(a.match(/\d+/)[0]) - parseInt(b.match(/\d+/)[0]))
                            .map((trainingDateKey) => (
                              <td key={`${trainingDateKey}-${studentNumber}`}>
                                {sessionData[schoolYear][semester]["Trainingdates"][trainingDateKey][studentNumber] ?
                                  sessionData[schoolYear][semester]["Trainingdates"][trainingDateKey][studentNumber][Object.keys(sessionData[schoolYear][semester]["Trainingdates"][trainingDateKey][studentNumber])[0]].appearance
                                  : null}
                              </td>
                            ))}
                        </tr>
                      )
                    )
                ) : null
              )
            )}
        </tbody>
      </table>
    </div>
  </div>
);

export default SessionDataViewer;