import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../LANDINGPAGECSS/Landingpage.css";
import { Link } from "react-router-dom";

const Home = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
<div id="container">
{/* Button Container */}
{!isDropdownOpen && windowWidth > 768 && (
          <div className="button-container">
            {/* Button Inner */}
            <div className="button-inner">
              {/* Link to Status.js */}
              <Link to="/Status" className="button">
                {/* Button Text */}
                <div className="button-text">LOG IN</div>
              </Link>
            </div>
          </div>
        )}

        {/* Menu Items */}
        <Link to="/" className="menu-item1-home" onClick={toggleDropdown}>
          <div className="menu-item home">HOME</div>
        </Link>

        <Link to="/Officer/Overview" onClick={toggleDropdown}>
          <div className="menu-item overview">OVERVIEW</div>
        </Link>

        <Link to="/Officer/Announcement">
          <div className="menu-item announcements">ANNOUNCEMENTS</div>
        </Link>

        <Link to="/LANDINGPAGES/Samplee" onClick={toggleDropdown}>
          <div className="menu-item faqs">FAQS</div>
        </Link>
        
        {/* Manual Readings link now conditional */}
        {windowWidth > 868 && (
          <Link to="/LANDINGPAGES/ManualReadings" onClick={toggleDropdown}>
            <div className="menu-item manual1">MANUAL READINGS</div>
          </Link>
        )}

        {/* Logo */}
        <div className="logo" />

        {/* Dropdown */}
        <div className="dropdown">
          <FontAwesomeIcon
            icon={isDropdownOpen ? faTimes : faBars}
            onClick={toggleDropdown}
            className="dropdown-icon"
          />
          {isDropdownOpen && (
            <div className="dropdown-content">
              <Link to="/" className="menu-item1-home" onClick={toggleDropdown}>
                <a>HOME</a>
              </Link>
              <Link to="/Officer/Overview" onClick={toggleDropdown}>
                <a>OVERVIEW</a>
              </Link>
              <Link
                to="/Officer/Announcement"
                className="menu-item1-announcement"
                onClick={toggleDropdown}
              >
                <a>ANNOUNCEMENTS</a>
              </Link>
              <Link to="/LANDINGPAGES/Samplee" className="menu-item1-faqs" onClick={toggleDropdown}>
                <a>FAQS</a>
              </Link>
              {/* Manual readings link doesn't need to be conditional */}
              <Link to="/LANDINGPAGES/ManualReadings" className="menu-item1-manual1" onClick={toggleDropdown}>
                <a>MANUAL READINGS</a>
              </Link>
              {/* Button for LOG IN */}
              <Link to="/Status" className="button">
                <div className="button-text">LOG IN</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
