import React, { useEffect, useState } from "react";
import { getDatabase, ref, get } from "firebase/database";
import "./TotalProgram.css"; // Update the CSS file name

function TotalProgram({ selectedSchoolYear, selectedSemester }) {
  const [courseData, setCourseData] = useState({});
  const db = getDatabase();

  useEffect(() => {
    const fetchUserCourses = async () => {
      try {
        const enrolledRef = ref(
          db,
          `enrolled/${selectedSchoolYear}/${selectedSemester}`
        );
        const snapshot = await get(enrolledRef);

        if (snapshot.exists()) {
          const enrolledData = snapshot.val();
          const studentNumbers = Object.values(enrolledData).map(
            (student) => student.studentNumber
          );

          const usersRef = ref(db, "users");
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const courseCounts = {};

            // Count the number of users per course
            Object.values(users).forEach((user) => {
              if (studentNumbers.includes(user.studentNumber)) {
                const { Course } = user;
                if (Course in courseCounts) {
                  courseCounts[Course]++;
                } else {
                  courseCounts[Course] = 1;
                }
              }
            });

            setCourseData(courseCounts);
          } else {
            setCourseData({});
          }
        } else {
          setCourseData({});
        }
      } catch (error) {
        console.error("Error fetching user courses:", error.message);
      }
    };

    fetchUserCourses();
  }, [db, selectedSchoolYear, selectedSemester]);

  return (
    <div>
      <div className="total-program-container">
        <h2>Students Per Course</h2>
        <div className="course-box-container">
          {Object.entries(courseData).map(([course, count]) => (
            <div key={course} className="course-box">
              <span className="course-name">{course}</span>:{" "}
              <span className="course-count">{count}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TotalProgram;
