import React, { useEffect, useState, useRef } from 'react';
import { getStorage, ref as storageRef, uploadString } from 'firebase/storage';
import { getAuth, signOut  } from "firebase/auth";
import { getDatabase, ref as dbRef, get, child, ref, update } from 'firebase/database';
import SideBarCadet from './CadetSidebar';
import qrcode from 'qrcode';
import QRCode from 'qrcode.react';
import '../CadetCSS/QrCode.css';
import { db } from '../Firebase'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";

function QRCodeDownloader() {
  const [qrCodeSize, setQrCodeSize] = useState(300); // Initialize with a default size
  const [userData, setUserData] = useState({
    Firstname: '',
    Lastname: '',
    Middlename: '',
    Course: '',
    Position: '',
    email: '',
    studentNumber: '',
    enrolled: false, // Add enrolled state
  });

  const qrCodeCanvasRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      const userId = user.uid;
      const database = getDatabase();
      const userRef = dbRef(database, `users/${userId}`);

      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserData({
            ...userData,
            email: user.email,
          });

          // Check if the student number is enrolled
          const enrolledRef = dbRef(database, 'enrolled');
          const enrolledSnapshot = await get(child(enrolledRef, userData.studentNumber));
          if (enrolledSnapshot.exists()) {
            setUserData(prevState => ({
              ...prevState,
              enrolled: true,
            }));
          }
        } else {
          console.error('User data not found.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const fullName = `${userData.Lastname}, ${userData.Firstname} ${userData.Middlename}`;
  const Course = `${userData.Course}`;

  
  const handleDownloadQRCode = async () => {
    try {
      const fullName = `${userData.Lastname}, ${userData.Firstname} ${userData.Middlename}`;
      const course = `${userData.Course}`;
      const schoolYear = selectedSchoolYear;
      const semester = selectedSemester;
  
      const backgroundImage = new Image();
      backgroundImage.src = '/template1.png';
  
      backgroundImage.onload = async () => {
        const combinedCanvas = document.createElement('canvas');
        const ctx = combinedCanvas.getContext('2d');
  
        const desiredWidth = 450;
        const desiredHeight = 650;
  
        combinedCanvas.width = desiredWidth;
        combinedCanvas.height = desiredHeight;
  
        // Draw the background template
        ctx.drawImage(backgroundImage, 0, 0, desiredWidth, desiredHeight);
  
        const qrData = ` Student Number: ${userData.studentNumber}\nSchool Year: ${selectedSchoolYear}\nSemester: ${selectedSemester}`;
        const qrCodeDataURL = await qrcode.toDataURL(qrData, { width: 150, margin: 0 });
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCodeDataURL;
  
        qrCodeImage.onload = () => {
          const qrCodeScale = 1.9;
          const qrCodeWidth = qrCodeImage.width * qrCodeScale;
          const qrCodeHeight = qrCodeImage.height * qrCodeScale;
  
          // Center the QR code above the template
          const xPosition = (desiredWidth - qrCodeWidth) / 2;
          const yPosition = (desiredHeight - qrCodeHeight) / 1.9;
  
          // Draw the QR code on the canvas
          ctx.drawImage(qrCodeImage, xPosition, yPosition, qrCodeWidth, qrCodeHeight);
  
          // Draw the name on the canvas
          ctx.fillStyle = 'white'; // Set font color to white
          ctx.font = 'bold 27px Arial';
  
          // Measure the width of the text
          const textWidth = ctx.measureText(fullName).width;
  
          // Calculate x-position to center the text
          const textXPosition = (desiredWidth - textWidth) / 2;
  
          // Use the calculated x-position to draw the text
          ctx.fillText(fullName, textXPosition, desiredHeight - 120); // Adjusted y-position
  
          // Draw the course below the name
          ctx.fillStyle = 'white'; // Set font color to white
          ctx.font = 'bold 27px Arial'; // Adjusted font size for the course
  
          // Measure the width of the course text
          const courseWidth = ctx.measureText(course).width;
  
          // Calculate x-position to center the course text
          const courseXPosition = (desiredWidth - courseWidth) / 2;
  
          // Use the calculated x-position to draw the course text
          ctx.fillText(course, courseXPosition, desiredHeight - 70);
  
          // Convert the canvas to data URL
          const dataUrl = combinedCanvas.toDataURL('image/png');
  
          // Check if userData.studentNumber is in the enrolledStudents list
          const isEnrolled = enrolledStudents.some(student => student.studentNumber === userData.studentNumber);
  
          if (isEnrolled) {
            // Trigger the download and upload to Firebase Storage
            downloadImage(dataUrl);
            toast.success('QR code downloaded successfully!');
          } else {
            toast.error('User with student number is not enrolled.');
          }
        };
      };
  
      backgroundImage.onerror = (error) => {
        console.error('Failed to load the background image:', error);
      };
    } catch (error) {
      console.error('Error handling QR code download:', error);
    }
  };
  
  
  
  

  const downloadImage = async (dataUrl) => {
    try {
      // Convert data URL to Blob
      const blob = await (await fetch(dataUrl)).blob();

      // Create a download link and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'qr_code.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Upload the image to Firebase Storage
      const storage = getStorage();
      const storageRef = storage.ref();
      const qrCodeImageRef = storageRef.child(`qr_code_downloads/${userData.email}.png`);

      await uploadString(qrCodeImageRef, dataUrl, 'data_url');

      console.log('QR code image uploaded to Firebase Storage');
    } catch (error) {
      console.error('Error downloading or uploading image:', error);
    }
  };

  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [schoolYears, setSchoolYears] = useState([]);
  const [studentUIDs, setStudentUIDs] = useState({});
  const [userDetails, setUserDetails] = useState({});
    
const [filteredStudents, setFilteredStudents] = useState([]);
const [searchQuery, setSearchQuery] = useState('');

  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [selectedSchoolNumber, setSelectedSchoolNumber] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      if (selectedSchoolYear && selectedSemester) {
        try {
          const userSemesterRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
          const userSemesterSnapshot = await get(userSemesterRef);
          if (userSemesterSnapshot.exists()) {
            const enrolledStudentsData = transformEnrolledData(userSemesterSnapshot.val());
            setEnrolledStudents(enrolledStudentsData);
            fetchUserUIDs(enrolledStudentsData);
          } else {
            setEnrolledStudents([]);
            setStudentUIDs({});
          }
        } catch (error) {
          console.error('Error fetching enrolled students:', error.message);
        }
      }
    };

    fetchEnrolledStudents();
  }, [selectedSchoolYear, selectedSemester]);

  const transformEnrolledData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([id, student]) => ({
      id: id,
      year: selectedSchoolYear,
      semester: selectedSemester,
      studentNumber: student.studentNumber
    }));
  };

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const schoolYearsRef = ref(db, 'enrolled');
        const schoolYearsSnapshot = await get(schoolYearsRef);
        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        console.error('Error fetching school years:', error.message);
      }
    };

    fetchSchoolYears();
  }, []);

  const fetchUserUIDs = async (students) => {
    const studentNumbers = students.map(student => student.studentNumber);
    const usersRef = ref(db, 'users');
    const userSnapshot = await get(usersRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.val();
      const studentUIDs = {};
      const userDetails = {};
      studentNumbers.forEach(studentNumber => {
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            studentUIDs[studentNumber] = uid;
            userDetails[uid] = userData[uid];
            break;
          }
        }
      });
      setStudentUIDs(studentUIDs);
      setUserDetails(userDetails);
    } else {
      console.log('No user data found in the database.');
    }
  };

  useEffect(() => {
    // Apply filters based on selected filter values and search query
    let filtered = enrolledStudents.filter(student =>
      student.studentNumber.includes(searchQuery) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Firstname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Lastname || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.studentNumber || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Middlename || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (userDetails[studentUIDs[student.studentNumber]]?.Course || '').toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    // Apply additional filters based on selected filter values
    if (selectedSchoolNumber) {
      filtered = filtered.filter(student => student.studentNumber.includes(selectedSchoolNumber));
    }
    if (selectedCourse) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Course.includes(selectedCourse));
    }
    if (selectedGender) {
      filtered = filtered.filter(student => userDetails[studentUIDs[student.studentNumber]].Gender.includes(selectedGender));
    }
  
    setFilteredStudents(filtered);
  }, [searchQuery, enrolledStudents, userDetails, studentUIDs, selectedSchoolNumber, selectedCourse, selectedGender]);

// Function to handle sorting
const sortData = (field) => {
  if (sortBy === field) {
    // If already sorted by the same field, toggle the sort order
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    // If sorting by a different field, update the sort field and set the order to ascending
    setSortBy(field);
    setSortOrder('asc');
  }
};

// Sort the data based on the selected criteria and order
const sortedStudents = [...filteredStudents].sort((a, b) => {
  // Determine the comparison based on the sorting field
  let comparison = 0;
  if (sortBy === 'studentNumber') {
    comparison = a.studentNumber.localeCompare(b.studentNumber);
  } else if (sortBy === 'firstName') {
    comparison = userDetails[studentUIDs[a.studentNumber]].Firstname.localeCompare(userDetails[studentUIDs[b.studentNumber]].Firstname);
  } else if (sortBy === 'lastName') {
    comparison = userDetails[studentUIDs[a.studentNumber]].Lastname.localeCompare(userDetails[studentUIDs[b.studentNumber]].Lastname);
  } else if (sortBy === 'course') {
    comparison = userDetails[studentUIDs[a.studentNumber]].Course.localeCompare(userDetails[studentUIDs[b.studentNumber]].Course);
  } else if (sortBy === 'gender') {
    comparison = userDetails[studentUIDs[a.studentNumber]].Gender.localeCompare(userDetails[studentUIDs[b.studentNumber]].Gender);
  } else if (sortBy === 'email') {
    comparison = userDetails[studentUIDs[a.studentNumber]].email.localeCompare(userDetails[studentUIDs[b.studentNumber]].email);
  }

  // Apply the sort order
  return sortOrder === 'asc' ? comparison : -comparison;
});

const [isEnrolled, setIsEnrolled] = useState(false);

useEffect(() => {
  const checkEnrollmentStatus = () => {
    // Check if userData.studentNumber is in the enrolledStudents list
    const isEnrolled = enrolledStudents.some(student => student.studentNumber === userData.studentNumber);
    setIsEnrolled(isEnrolled);
  };

  checkEnrollmentStatus();
}, [userData.studentNumber, enrolledStudents]);
const [userImageUrl, setUserImageUrl] = useState(() => {
  // Try to retrieve the user image URL from local storage
  const storedUserImageUrl = localStorage.getItem("userImageUrl");
  return storedUserImageUrl || ""; // Initialize with an empty string if not found
});

const [showModal, setShowModal] = useState(false);
const [sortIcon, setSortIcon] = useState(faSortDown);

const handleModalOpen = () => {
  setShowModal(prevState => !prevState); // Toggle modal state
  setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
};

const handleModalClose = () => {
  setShowModal(false);
  setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
};

const handleModalContentClick = (e) => {
  e.stopPropagation(); // Prevent event bubbling
};
const [isModalOpen, setIsModalOpen] = useState(false);

const handleLogoutClick = () => {
  setIsModalOpen(true);
  // Add any additional logic you want to execute when logout is clicked
};

const handleCloseModal = () => {
  setIsModalOpen(false);
  // Add any additional logic you want to execute when the modal is closed
};


const handleLogout = async (e) => {
  e.preventDefault();
  await signOut(auth);
      
  const database = getDatabase();
  // Update user's status to "Offline" in the database
  await update(ref(database, `users/${userId}`), { status: "Offline" });
  
  window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
};
const auth = getAuth();
const user = auth.currentUser;
const userId = user ? user.uid : null;

    return (
      <div className="qrcode-container">
        <SideBarCadet />
        <div className="qrcode-container-all1">
          <div className="Header-list-qrcode1">
          <div className="Header-list-cadet321">
          <h1>QR Code</h1>
          <div className="user-image-cadet1"> 
          <p>{localStorage.getItem("fullName")}</p>
      {userImageUrl ? (
        <img
          className="user-image enlarged-image" onClick={handleModalOpen}
          src={userImageUrl}
          alt="User"
        />
      ) : (
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

    </div>
        </div>
        {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <Link to="/Cadet/CadetUpdate">
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
 <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
</Link>
    <div className="info-box">
  <p className="Personnel">Cadet</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
      <tr>
      <td><label htmlFor="studentNumber">Student Number:</label></td>
      <td><p>{userData.studentNumber}</p></td>
    </tr>
    <tr>
      <td><label>Name:</label></td>
      <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
    </tr>
    <tr>
      <td><label htmlFor="email">Email:</label></td>
      <td><p>{userData.email}</p></td>
    </tr>
    <tr>
      <td><label>Course:</label></td>
      <td><p>{userData.Course}</p></td>
    </tr>
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}
 {isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
            <div className="filter-n-qr-code">
              <div className="sn-qr-code-filter">
                <div className="qr-filter">
                  {/* School Year Dropdown */}
                  <div className="admin-dropdown-attendance">
                    <select
                      id="schoolYear"
                      className="filter-select"
                      onChange={(e) => setSelectedSchoolYear(e.target.value)}
                      value={selectedSchoolYear}
                    >
                      <option value="" hidden>
                        Select School Year
                      </option>
                      {schoolYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="admin-dropdown-attendance">
                    <select
                      id="semester"
                      className="filter-select"
                      onChange={(e) => setSelectedSemester(e.target.value)}
                      value={selectedSemester}
                    >
                      <option value="" disabled hidden>
                        Select Semester
                      </option>
                      <option value="First Semester">First Semester</option>
                      <option value="Second Semester">Second Semester</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="qrcode-container-pre">
              <div className="qr-code-holder">
                {userData.email && (
                  <div className="qrcode-content">
                    {/* Display the template */}
                    <img
                      src="/template1.png"
                      alt="Template"
                      className="qrcode-template"
                    />

                    {/* Use the QRCode component to display the QR code */}
                    {/* Use the QRCode component to display the QR code */}
                    {/* Use the QRCode component to display the QR code */}
                    <div className="qr-code-display">
                      {userData.email &&
                        selectedSemester &&
                        selectedSchoolYear && (
                          <div>
                            {isEnrolled ? (
                              <QRCode
                                value={`Student Number: ${userData.studentNumber}\nSchool Year: ${selectedSchoolYear}\nSemester: ${selectedSemester}`}
                                size={250}
                              />
                            ) : (
                              <div>
                                <p>
                                  This student is not enrolled for the selected
                                  semester and school year.
                                </p>
                                <p>
                                  <Link to="/Cadet/AdminEnrollment">
                                    Enroll Now
                                  </Link>
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    {/* Display the full name in the middle */}
                    <div className="qr-code-fullname">
                      <p>{fullName}</p>
                      <p>{Course}</p>
                    </div>
                    <button
                      onClick={handleDownloadQRCode}
                      className="qr-download-btn"
                    >
                      Download QR Code
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} />

        {/* Semester Dropdown */}
      </div>
    );
}

export default QRCodeDownloader;