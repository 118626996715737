import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import { Pie } from 'react-chartjs-2'; // Import Pie component
import './AttendancePerGender.css'; // Update the CSS file name

const AttendancePerGender = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => { // Update component name
  const [absentStudents, setAbsentStudents] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);
  const [lateStudents, setLateStudents] = useState([]);
  const [excusedStudents, setExcusedStudents] = useState([]);
  const [genderData, setGenderData] = useState({}); // Update state name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          const studentEntries = Object.entries(attendanceData);

          const filterStudentsByAppearance = (appearance) => {
            return studentEntries
              .filter(([_, studentData]) => {
                const timeInAppearance = studentData['Time In']?.appearance;
                const timeOutAppearance = studentData['Time Out']?.appearance;
                return timeInAppearance === appearance || timeOutAppearance === appearance;
              })
              .map(([studentNumber, _]) => studentNumber);
          };
          

          const absentStudentsData = filterStudentsByAppearance('Absent');
          const presentStudentsData = filterStudentsByAppearance('Present');
          const lateStudentsData = filterStudentsByAppearance('Late');
          const excusedStudentsData = filterStudentsByAppearance('Excused');

          setAbsentStudents(absentStudentsData);
          setPresentStudents(presentStudentsData);
          setLateStudents(lateStudentsData);
          setExcusedStudents(excusedStudentsData);
        } else {
          setAbsentStudents([]);
          setPresentStudents([]);
          setLateStudents([]);
          setExcusedStudents([]);
        }

        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);
        const enrolledSnapshot = await get(enrolledRef);

        if (enrolledSnapshot.exists()) {
          const enrolledData = enrolledSnapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);

          const usersRef = ref(db, 'users');
          const usersSnapshot = await get(usersRef);

          if (usersSnapshot.exists()) {
            const users = usersSnapshot.val();
            const genderCounts = {}; // Update variable name

            const countStudentsByAppearance = (appearance, studentsData) => {
              Object.values(users).forEach(user => {
                if (studentNumbers.includes(user.studentNumber)) {
                  const { Gender } = user; // Update property name
                  if (!genderCounts[Gender]) {
                    genderCounts[Gender] = { // Update property name
                      Absent: 0,
                      Present: 0,
                      Late: 0,
                      Excused: 0
                    };
                  }
                  if (studentsData.includes(user.studentNumber)) {
                    genderCounts[Gender][appearance]++; // Update property name
                  }
                }
              });
            };
            
            countStudentsByAppearance('Absent', absentStudents);
            countStudentsByAppearance('Present', presentStudents);
            countStudentsByAppearance('Late', lateStudents);
            countStudentsByAppearance('Excused', excusedStudents);

            setGenderData(genderCounts); // Update state name
          } else {
            setGenderData({});
          }
        } else {
          setGenderData({});
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate, absentStudents, presentStudents, lateStudents, excusedStudents]);

  const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
  ];

  const prepareChartData = () => {
    if (!genderData || Object.keys(genderData).length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }
  
    const genders = Object.keys(genderData);
    const data = genders.map(gender => genderData[gender]?.Absent || 0);
    const backgroundColor = [
      'rgba(255, 99, 132, 0.6)',
      'rgba(54, 162, 235, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(75, 192, 192, 0.6)',
      'rgba(153, 102, 255, 0.6)',
      'rgba(255, 159, 64, 0.6)',
      'rgba(255, 99, 132, 0.6)',
      'rgba(54, 162, 235, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(75, 192, 192, 0.6)',
      'rgba(153, 102, 255, 0.6)',
      'rgba(255, 159, 64, 0.6)',
    ];
  
    return {
      labels: genders.map(gender => `${gender} (Absent)`),
      datasets: [{
        label: 'Absent Per Gender',
        data: data,
        backgroundColor: backgroundColor.slice(0, data.length),
      }],
    };
  };
  
  
  return (
        <div className="attendance-pie-chart"> {/* Add class name */}
        <h2>Absent: Male & Female</h2>
        <Pie data={prepareChartData()} />
        </div>
  );
};

export default AttendancePerGender;
