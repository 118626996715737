import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set, update, onValue } from 'firebase/database';
import { db } from '../Firebase';
import SideBar from "./AdminSideBar";
import "./AdminSchedule.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap'; // Add this import

import { useNavigate } from "react-router-dom";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Spinner from "react-bootstrap/Spinner";
const AdminSchedule = () => {
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTrainingDay, setSelectedTrainingDay] = useState(null);
  const [selectedTrainingDate, setSelectedTrainingDate] = useState({});
  const [existingTrainingDates, setExistingTrainingDates] = useState(Array.from({ length: 15 }, () => ''));
  const [trainingDayExists, setTrainingDayExists] = useState(false);
  const [trainingDayEmpty, setTrainingDayEmpty] = useState(false);
  const [updatedTrainingDates, setUpdatedTrainingDates] = useState({});
  const [showModal1, setShowModal1] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [announcementsByMonth, setAnnouncementsByMonth] = useState({});
  const navigate = useNavigate();
  const handleOpenModal = (month) => {
    setSelectedMonth(month);
    setShowModal1(true);
  };
  
  const handleCloseModal = () => {
    setSelectedMonth('');
    setShowModal1(false);
    setIsModalOpen(false);
  };
  
  const [editMode, setEditMode] = useState(false);
  // Function to generate school year options dynamically
  const [schoolYears, setSchoolYears] = useState([]);

  useEffect(() => {
    async function fetchSchoolYears() {
      try {
        const schoolYearsRef = ref(db, 'SchoolYear');
        const schoolYearsSnapshot = await get(schoolYearsRef);

        if (schoolYearsSnapshot.exists()) {
          const schoolYearsList = Object.keys(schoolYearsSnapshot.val());
          setSchoolYears(schoolYearsList);
        } else {
          setSchoolYears([]);
        }
      } catch (error) {
        toast.error('Error fetching school years:', error.message);
      }
    }

    fetchSchoolYears();
  }, []);
  
  useEffect(() => {
    async function fetchExistingData() {
      try {
        const trainingDatesRef = ref(
          db,
          `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
        );
  
        const snapshot = await get(trainingDatesRef);
  
        if (snapshot.exists()) {
          const trainingDatesData = snapshot.val();
          toast.error('Existing Training Dates:', trainingDatesData);
          setExistingTrainingDates(trainingDatesData || Array.from({ length: 15 }, () => ''));
        } else {
          toast.error('No existing training dates found.');
          
          // If no existing data, set all training days to an initial state
          const initialTrainingDates = Array.from({ length: 15 }, () => '');
          setExistingTrainingDates(initialTrainingDates);
          setUpdatedTrainingDates({}); // Reset any updated dates
        }
      } catch (error) {
        toast.error('Error fetching existing data:', error.message);
      }
    }

    // Fetch existing data and updated training dates when selectedSemester or selectedSchoolYear changes
    if (selectedSemester && selectedSchoolYear) {
      fetchExistingData();
      // Assume updatedTrainingDates is stored somewhere (e.g., fetched from another source)
      // Update this logic based on where you get the updated training dates from
      setUpdatedTrainingDates({});
    }
  }, [selectedSemester, selectedSchoolYear]);


  const [modifiedDates, setModifiedDates] = useState({});

  // Function to handle changes in training dates
  const handleTrainingDateChange = (trainingDay, date) => {
    // Ensure that the selected date is a Sunday and not a previous Sunday
    const selectedDate = new Date(date);
    const dayOfWeek = selectedDate.getDay();
    const currentDate = new Date();
  
    // Check if the training day already exists
    if (existingTrainingDates && existingTrainingDates[trainingDay - 1]) {
      setTrainingDayExists(true);
    } else {
      setTrainingDayExists(false);
    }
  
    // Check if the training day is empty
    if (!date) {
      setTrainingDayEmpty(true);
    } else {
      setTrainingDayEmpty(false);
    }
  
    if (dayOfWeek === 0 && selectedDate >= currentDate) {
      // If selected date is a Sunday and not before the current date, update the state
      setSelectedTrainingDate((prevDates) => ({
        ...prevDates,
        [trainingDay]: date,
      }));
      setEditMode(true);
      setSelectedTrainingDaysForEdit((prevSelectedDays) => {
        if (!prevSelectedDays.includes(trainingDay)) {
          return [...prevSelectedDays, trainingDay];
        }
        return prevSelectedDays;
      });
  
      // Update modifiedDates state to indicate changes
      setModifiedDates((prevDates) => ({
        ...prevDates,
        [trainingDay]: true,
      }));
    } else {
      // If selected date is not a Sunday or before the current date,
      // display an error or handle it accordingly
      toast.error(`Training Day ${trainingDay}: Please select a future Sunday.`);
      // Optionally, you can reset the state or show an error message to the user
    }
  };
  
  // Function to handle saving individual training dates
  const handleSaveTrainingDate = async (trainingDay) => {
    try {
      const trainingDatesRef = ref(
        db,
        `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
      );
  
      // Check if the date has been modified
      if (modifiedDates[trainingDay]) {
        const trainingDateData = {
          [`Training ${trainingDay}`]: selectedTrainingDate[trainingDay],
        };
  
        await update(trainingDatesRef, trainingDateData);
  
        toast.success(`Training Day ${trainingDay} saved successfully!`);
  
        // After saving, reset modifiedDates for the specific training day
        setModifiedDates((prevDates) => ({
          ...prevDates,
          [trainingDay]: false,
        }));
      } else {
        toast.error(`No changes detected for Training Day ${trainingDay}.`);
      }
    } catch (error) {
      toast.error(`Error saving Training Day ${trainingDay}: ${error.message}`);
    }
  };

    const handleSaveTrainingDates = async () => {
  try {
    const trainingDatesRef = ref(
      db,
      `Attendance/${selectedSchoolYear}/${selectedSemester}/TrainingDates`
    );

    // Check if all training dates are selected
    const allDatesSelected = Array.from({ length: 15 }).every(index => selectedTrainingDate[index + 1] !== null);

    if (allDatesSelected) {
      // Assuming trainingDatesData is an object with Training 1 to Training 15 and their corresponding dates
      const trainingDatesData = {};
      for (let index = 1; index <= 15; index++) {
        if (!selectedTrainingDate[index]) {
          toast.error(`Training Day ${index} is not set. Please select a date for all training days.`);
          return; // Stop the function if any date is missing
        }
        trainingDatesData[`Training ${index}`] = selectedTrainingDate[index];
      }

      await set(trainingDatesRef, trainingDatesData);

      toast.success('Training dates saved successfully!');
    } else {
      toast.error('Please select dates for all 15 training days.');
    }
  } catch (error) {
    toast.error('Error saving training dates. Please try again later.');
  }
};

  
    const renderTrainingDayExistsMessage = () => {
      if (trainingDayExists) {
        return <p style={{ color: 'red' }}>Training day already exists. Please select a different day.</p>;
      }
      return null;
    };

    useEffect(() => {
      if (existingTrainingDates) {
        const trainingDays = Object.keys(existingTrainingDates).map((day) => ({
          day,
          date: existingTrainingDates[day],
        }));
        setExistingTrainingDays(trainingDays);
      }
    }, [existingTrainingDates]);
  
    const [selectedTrainingDaysForEdit, setSelectedTrainingDaysForEdit] = useState([]);
  
    const [existingTrainingDays, setExistingTrainingDays] = useState([]);
    
    const [totalTrainingDays, setTotalTrainingDays] = useState(15);

    const handleAddTrainingDay = () => {
      setTotalTrainingDays((prevTotal) => prevTotal + 1);
    };

    const handleDeleteTrainingDay = (dayToDelete) => {
      if (totalTrainingDays > 1 && totalTrainingDays !== 15) {
        // Create a copy of selectedTrainingDate and remove the selected day
        const updatedTrainingDate = { ...selectedTrainingDate };
        delete updatedTrainingDate[dayToDelete];
    
        // Create a copy of existingTrainingDates and remove the selected day
        const updatedExistingTrainingDates = { ...existingTrainingDates };
        delete updatedExistingTrainingDates[`Training ${dayToDelete}`];
    
        // Update the state
        setSelectedTrainingDate(updatedTrainingDate);
        setExistingTrainingDates(updatedExistingTrainingDates);
    
        // Update totalTrainingDays
        setTotalTrainingDays((prevTotal) => prevTotal - 1);
      } else {
        toast.error('Cannot delete the last training day or when the count is 15.');
      }
    };


    const [userType, setUserType] = useState("");
    const [storedStudentNumber, setStoredStudentNumber] = useState("");
    const [storedEmail, setStoredEmail] = useState('');
    const [storedDesignation, setStoredDesignation] = useState('');
    const [designation, setDesignation] = useState('');
    const [studentNumber, setStudentNumber] = useState('');
    useEffect(() => {
        // Set user type based on credentials
        const userEmail = localStorage.getItem('userEmail');
        if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
            setUserType("admin");
        }
    }, []);
  
    useEffect(() => {
        const userType = localStorage.getItem("userType");
        setUserType(userType);
  
        const storedStudentNumber = localStorage.getItem('studentNumber');
        setStoredStudentNumber(storedStudentNumber);
  
  
        const storedEmail = localStorage.getItem('email');
        setStoredEmail(storedEmail);
  
        const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
        setStoredDesignation(storedDesignation); // Set the designation in the component state
    }, []);
  
    useEffect(() => {
        const storedDesignation = localStorage.getItem('designation');
        const storedStudentNumber = localStorage.getItem('studentNumber');
        const storedEmail = localStorage.getItem('email');
        if (storedDesignation && storedStudentNumber) {
            setDesignation(storedDesignation);
            setStudentNumber(storedStudentNumber);
            setEmail(storedEmail);
        }
    }, []);
    // Effect to fetch user data when component mounts or when studentNumber or designation changes
    useEffect(() => {
      const fetchData = async () => {
        try {
          const userDataFromDb = await getUserData(designation, studentNumber);
          setUserData(userDataFromDb);
        } catch (error) {
          console.error('Error fetching user data:', error.message);
        }
      };
  
      fetchData();
    }, [designation, studentNumber]);
    const [email, setEmail] = useState('');
  const [announcements, setAnnouncements] = useState(null);

  const fetchData = async () => {
    try {
      const db = getDatabase();
      const announcementsRef = ref(db, 'announcements');
  
      onValue(announcementsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setAnnouncements(data);
  
          // Process data by month
          const byMonth = {};
          Object.values(data).forEach((announcement) => {
            const date = new Date(announcement.When);
            const month = date.toLocaleString('default', { month: 'long' });
            if (!byMonth[month]) {
              byMonth[month] = [announcement];
            } else {
              byMonth[month].push(announcement);
            }
          });
          setAnnouncementsByMonth(byMonth);
        }
      });
    } catch (error) {
      console.error('Error fetching announcements:', error.message);
      // Handle error
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  

  const getUserData = async (designation, studentNumber) => {
    try {
      // Construct the reference path
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
      
      // Retrieve the data
      const snapshot = await get(userRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Extract the user data
        const userData = snapshot.val();
        // Return the relevant data such as Lastname and Firstname
        return {
          Course: userData.Course,
          Lastname: userData.Lastname || '',
          Firstname: userData.Firstname || '',
          Middlename: userData.Middlename || '',
          // Add more properties as needed
        };
      } else {
        // Data does not exist
        console.log("No data available for the given student number.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user data:", error.message);
      throw error;
    }
  };


  const [userImageUrl, setUserImageUrl] = useState(null);
  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
      setUserImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching user image:', error);
      setUserImageUrl(null);
    }
  };
  
  useEffect(() => {
    // Fetch user image when email changes
    if (email) {
      fetchUserImage(email);
    }
  }, [email]);
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };
  const handleLogout = async () => {
    try {
        // Reference the appropriate location in the database using the student number
        const userRef = ref(db, `Officer/${designation}/${studentNumber}`);

        // Get the user data from the database
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
            const userData = userSnapshot.val();

            // Update the user's status to "Offline"
            await set(ref(db, `Officer/${designation}/${studentNumber}/status`), 'Offline');

            toast.success('Logout successful!', { position: 'top-center' });

            // Clear local storage
            localStorage.clear();

            // Redirect to the login page using navigate
            navigate("/Admin/AdminOfficerLogin");
        } else {
            // User does not exist
            toast.error('User does not exist.', { position: 'top-center' });
        }
    } catch (error) {
        console.error('Error logging out:', error);
        toast.error('Failed to log out. Please try again later.', { position: 'top-center' });
    }
};

    return (
      <div className='admin-schedule-all'>
       <ToastContainer className="toast-container" />
         <SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>
        
  <div className="admin-schedule-full">
             <div className="Header-list-schedule">
             <div className="header-container1">

             {isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
  <h1 className="Header-container-schedule">Schedule</h1>  
  
{!designation   && (
    <>
     
    </>
  )}
     
     { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
<div className="user-details">
<div className="user-details1">
{userData && (
<p className="user-name">
{userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
</p>
)}
</div>
<div className="user-image-container1" onClick={handleModalOpen}>
{userImageUrl ? (
<img className="user-image1" src={userImageUrl} alt="User" />
) : (
<span className="visually-hidden">Loading...</span>
)}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

</div>
{showModal && (
<div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
<div className="modal-content" onClick={handleModalContentClick}>
<span className="close" onClick={handleModalClose}>&times;</span>
<div className="pendingHeader1">Profile</div>
<div className="user-image-container12">
 {userImageUrl ? (
   <img className="user-image12" src={userImageUrl} alt="User" />
 ) : (
   <Spinner animation="border" role="status" variant="success">
     <span className="visually-hidden">Loading...</span>
   </Spinner>
 )}
 

</div>
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
<div className="info-box">
<p className="Personnel">Personnel Officer ({designation})</p>
{userData && (
<>
<div className="custom-table"> 
<table className="custom-table">
<tbody>
<tr>
  <td><label htmlFor="studentNumber">Student Number:</label></td>
  <td><p>{studentNumber}</p></td>
</tr>
<tr>
<td><label>Name:</label></td>
  <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
</tr>
<tr>
  <td><label htmlFor="email">Email:</label></td>
  <td><p>{email}</p></td>
</tr>
<tr>
  <td><label>Course:</label></td>
  <td><p>{userData.Course}</p></td>
</tr>

</tbody>
</table></div>

</>
)}

</div>
<div className="button-container12">
<button className="CustomBtn" onClick={handleLogoutClick}>
  <div className="sign12">
    <svg viewBox="0 0 512 512">
      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
    </svg>
  </div>
  <div className="text12">Logout</div>
</button>
</div>


</div>

</div>

)}

</div>
)}

</div> 
      </div>

<div className='slect-year-sem-dropdown'>

  <div className='select-dropdown-23'>

  <div className="admin-dropdown-sed1">
  <div className="select-container">
    <select
      id="schoolYear"
      className="filter-select"
      onChange={(e) => setSelectedSchoolYear(e.target.value)}
      value={selectedSchoolYear}
    >
      <option value="" disabled selected>Select School Year</option>
      {schoolYears.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </div>
  </div>

        <div className="admin-dropdown-sed1">
  <div className="select-container">
    <select
      id="semester"
      className="filter-select"
      onChange={(e) => setSelectedSemester(e.target.value)}
      value={selectedSemester}
    >
      <option value="" disabled selected>Select Semester</option>
      <option value="First Semester">First Semester</option>
      <option value="Second Semester">Second Semester</option>
    </select>
  </div>
</div>

</div>

</div>

<div className='existing-update-day1'>

{!designation   && (
            <>
             <div className='existing-update-day'>
  {renderTrainingDayExistsMessage()}
  {loading && <p>Loading...</p>}

  <div className="admin-schedule-container">
  <div className='training-h3-day'>
    <h3>Training Days</h3>
  </div>
  <p>School Year: {selectedSchoolYear}</p>
          <p>Semester: {selectedSemester}</p>
  <table className="calendar-table">
    <thead>
      <tr>
        <th>Training Day</th>
        <th>Date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
    {Array.from({ length: totalTrainingDays }, (_, index) => (
  <tr key={index}>
    <td>Training Day {index + 1}</td>
    <td>
      <input
        type="date"
        value={(selectedTrainingDate[index + 1] && selectedTrainingDate[index + 1]) || (existingTrainingDates[`Training ${index + 1}`] || '')}
        onChange={(e) => handleTrainingDateChange(index + 1, e.target.value)}
      />
    </td>
    <td>
    {totalTrainingDays !== 15 && (
        <button className="delete-button" onClick={() => handleDeleteTrainingDay(index + 1)}>Delete</button>
      )}
      {modifiedDates[index + 1] && ( // Render save button only if the date has been modified
        <button className="save-button" onClick={() => handleSaveTrainingDate(index + 1)}>Save</button>
      )}
     
    </td>
  </tr>
))}
    </tbody>
  </table>

  <div className='sched-buttons'>
    <button className='noselect00w1' onClick={handleAddTrainingDay}>
      <span className="text00w1">Add Day</span>
      <span className="icon00w1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
        <path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192zM224 248c13.3 0 24 10.7 24 24v56h56c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V376H144c-13.3 0-24-10.7-24-24s10.7-24 24-24h56V272c0-13.3 10.7-24 24-24z"/></svg>
      </span>
    </button>
    <button className='noselect00w1' onClick={handleSaveTrainingDates}>
      <span className="text00w1">Save Dates</span>
      <span className="icon00w1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
          <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"/>        </svg>
      </span>
    </button>
  </div>
</div>

</div>

            </>
          )}
              {designation   === 'EXO'  && (
            <>
            </>
          )}
              {designation   === 'S1'  && (
            <>
            </>
          )}
              {designation   === 'S2'  && (
            <>
            </>
          )}
{!designation   && (
            <>
              <div className='sched-container12'>
  <div className='sched-header-calendar'>
    <h2>Calendar</h2>
  </div>
  <div className='calendar-container-sched'>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['January'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('January')}>January</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['February'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('February')}>February</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['March'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('March')}>March</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['April'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('April')}>April</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['May'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('May')}>May</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['June'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('June')}>June</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['July'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('July')}>July</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['August'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('August')}>August</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['September'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('September')}>September</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['October'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('October')}>October</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['November'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('November')}>November</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['December'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('December')}>December</Button>
</div>

<div className={`1custom-modal-sched121 ${showModal1 ? 'show' : ''}`}>
  <div className="custom-modal-content-sched-month">
    <div className="custom-modal-header-sched-month">
      <h2 className='sched-month21'>{selectedMonth}</h2>
    </div>
    <div className="custom-modal-body-sched12">
    {announcementsByMonth[selectedMonth] && (
  <ul className="announcement-list12-sched">
    {announcementsByMonth[selectedMonth].map((announcement) => {
      const announcementDate = new Date(announcement.When);
      const day = announcementDate.getDate();
      return (
        <li key={announcement.id} className="announcement-item12-sched">
          <div className='sched-grid-container'>
          <div className='sched-container1'>
            <div className="what-sched">
          <strong></strong> {announcement.What}<br />
          </div>
          <div className='announcement-history'>
          <strong></strong> {announcement.Where}<br />
          <strong></strong> {announcement.Who}<br />
          </div>
          </div>
          <strong className="day-sched">{day}</strong><br /> {/* Display only the day */}
          </div>
        </li>
      );
    })}
  </ul>
)}

    </div>
    <div className="custom-modal-footer">
    </div>
  </div>
</div>
</div>
            </>
          )}
              {designation   === 'EXO'  && (
            <>
              <div className='sched-container12'>
  <div className='sched-header-calendar'>
    <h2>Calendar</h2>
  </div>
  <div className='calendar-container-sched'>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['January'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('January')}>January</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['February'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('February')}>February</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['March'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('March')}>March</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['April'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('April')}>April</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['May'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('May')}>May</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['June'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('June')}>June</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['July'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('July')}>July</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['August'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('August')}>August</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['September'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('September')}>September</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['October'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('October')}>October</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['November'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('November')}>November</Button>
  <Button className={`calendar-month-button-sched ${announcementsByMonth['December'] ? 'has-data' : 'no-data'}`} onClick={() => handleOpenModal('December')}>December</Button>
</div>

<div className={`1custom-modal-sched121 ${showModal1 ? 'show' : ''}`}>
  <div className="custom-modal-content-sched-month">
    <div className="custom-modal-header-sched-month">
      <h2 className='sched-month21'>{selectedMonth}</h2>
    </div>
    <div className="custom-modal-body-sched12">
    {announcementsByMonth[selectedMonth] && (
  <ul className="announcement-list12-sched">
    {announcementsByMonth[selectedMonth].map((announcement) => {
      const announcementDate = new Date(announcement.When);
      const day = announcementDate.getDate();
      return (
        <li key={announcement.id} className="announcement-item12-sched">
          <div className='sched-grid-container'>
          <div className='sched-container1'>
            <div className="what-sched">
          <strong></strong> {announcement.What}<br />
          </div>
          <div className='announcement-history'>
          <strong></strong> {announcement.Where}<br />
          <strong></strong> {announcement.Who}<br />
          </div>
          </div>
          <strong className="day-sched">{day}</strong><br /> {/* Display only the day */}
          </div>
        </li>
      );
    })}
  </ul>
)}

    </div>
    <div className="custom-modal-footer">
    </div>
  </div>
</div>
</div>
            </>
          )}
              {designation   === 'S1'  && (
            <>
           
            </>
          )}
              {designation   === 'S2'  && (
            <>
                     <div className='existing-update-day'>
  {renderTrainingDayExistsMessage()}
  {loading && <p>Loading...</p>}

  <div className="admin-schedule-container">
  <div className='training-h3-day'>
    <h3>Training Days</h3>
  </div>
  <p>School Year: {selectedSchoolYear}</p>
          <p>Semester: {selectedSemester}</p>
  <table className="calendar-table">
    <thead>
      <tr>
        <th>Training Day</th>
        <th>Date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
    {Array.from({ length: totalTrainingDays }, (_, index) => (
  <tr key={index}>
    <td>Training Day {index + 1}</td>
    <td>
      <input
        type="date"
        value={(selectedTrainingDate[index + 1] && selectedTrainingDate[index + 1]) || (existingTrainingDates[`Training ${index + 1}`] || '')}
        onChange={(e) => handleTrainingDateChange(index + 1, e.target.value)}
      />
    </td>
    <td>
    {totalTrainingDays !== 15 && (
        <button className="delete-button" onClick={() => handleDeleteTrainingDay(index + 1)}>Delete</button>
      )}
      {modifiedDates[index + 1] && ( // Render save button only if the date has been modified
        <button className="save-button" onClick={() => handleSaveTrainingDate(index + 1)}>Save</button>
      )}
     
    </td>
  </tr>
))}
    </tbody>
  </table>

  <div className='sched-buttons'>
    <button className='noselect00w1' onClick={handleAddTrainingDay}>
      <span className="text00w1">Add Day</span>
      <span className="icon00w1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
        <path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192zM224 248c13.3 0 24 10.7 24 24v56h56c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V376H144c-13.3 0-24-10.7-24-24s10.7-24 24-24h56V272c0-13.3 10.7-24 24-24z"/></svg>
      </span>
    </button>
    <button className='noselect00w1' onClick={handleSaveTrainingDates}>
      <span className="text00w1">Save Dates</span>
      <span className="icon00w1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
          <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"/>        </svg>
      </span>
    </button>
  </div>
</div>

</div>
            </>
          )}


      </div>
      </div>
      
      </div>
    );
  };

  export default AdminSchedule;

  