import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import './ExcuseTotal.css'; // Update the CSS file name

const ExcuseTotal = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [excuseCount, setExcuseCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          let totalExcuseCount = 0;

          // Iterate through each student entry and count excuse appearances
          Object.values(attendanceData).forEach(studentData => {
            if (studentData['Time In']?.appearance === 'Excused' || studentData['Time Out']?.appearance === 'Excused') {
              totalExcuseCount++;
            }
          });

          // Update state with the total excuse count
          setExcuseCount(totalExcuseCount);
        } else {
          // If attendance data doesn't exist, set excuse count to 0
          setExcuseCount(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate]);

  return (
    <div className="excuse-total-display"> {/* Update class name */}
      <h2>Total Excuse: </h2> {/* Update text content */}
      <div className='excuse-count-ttl'> {/* Update class name */}
        {excuseCount}
      </div>
    </div>
  );
};

export default ExcuseTotal;
