import { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tabs, Tab } from 'react-bootstrap';
import SideBarCadet from './CadetSidebar';
import '../CadetCSS/CadetDisplayFile.css';
import {
  getDatabase,
  ref as dbRef, ref,
  get, update
} from "firebase/database";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFilePowerpoint, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import Spinner from "react-bootstrap/Spinner";
import { getAuth, signOut  } from "firebase/auth";
import { Link } from "react-router-dom"; // Import Link from react-router-dom



const DisplayFile = () => {
  const [activeTab, setActiveTab] = useState('MS1');
  const [ms1Files, setMS1Files] = useState([]);
  const [ms2Files, setMS2Files] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(() => {
    // Try to retrieve user data from localStorage
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          Firstname: "",
          Lastname: "",
          Middlename: "",
          Course: "",
          Position: "",
          email: "",
          studentNumber: "",
        };
  });
  useEffect(() => {
    const fetchFiles = async () => {
      const storage = getStorage();

      try {
        // Fetch file names from MS1 folder
        const ms1Ref = storageRef(storage, 'MS1');
        const ms1FileList = await listAll(ms1Ref);
        const ms1FileNames = ms1FileList.items.map(item => item.name);
        setMS1Files(ms1FileNames);

        // Fetch file names from MS2 folder
        const ms2Ref = storageRef(storage, 'MS2');
        const ms2FileList = await listAll(ms2Ref);
        const ms2FileNames = ms2FileList.items.map(item => item.name);
        setMS2Files(ms2FileNames);

        setLoading(false);
      } catch (error) {
        console.error('Error occurred while fetching file list: ', error);
        toast.error('Error fetching file list. Please try again.');
      }
    };

    fetchFiles();
  }, []);

  const openFile = async (fileName) => {
    const storage = getStorage();

    try {
      const downloadURL = await getDownloadURL(storageRef(storage, fileName));
      window.open(downloadURL, '_blank');
    } catch (error) {
      console.error('Error occurred while opening file: ', error);
      toast.error('Error opening file. Please try again.');
    }
  };

  const [userImageUrl, setUserImageUrl] = useState(() => {
    // Try to retrieve the user image URL from local storage
    const storedUserImageUrl = localStorage.getItem("userImageUrl");
    return storedUserImageUrl || ""; // Initialize with an empty string if not found
  });
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Add any additional logic you want to execute when the modal is closed
  };

  
  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut(auth);
        
    const database = getDatabase();
    // Update user's status to "Offline" in the database
    await update(ref(database, `users/${userId}`), { status: "Offline" });
    
    window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
  };
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;

  return (
    <div className="subject-container">      
      <SideBarCadet />
      <div className="subject-full">
      <div className="Header-list-cadet321">
          <h1>Subject</h1>
          <div className="user-image-cadet1"> 
          <p>{localStorage.getItem("fullName")}</p>
      {userImageUrl ? (
        <img
          className="user-image enlarged-image" onClick={handleModalOpen}
          src={userImageUrl}
          alt="User"
        />
      ) : (
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

    </div>
        </div>
        {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <Link to="/Cadet/CadetUpdate">
       <button className="edit-button">
      <svg className="edit-svgIcon" viewBox="0 0 512 512">
        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
      </svg>
    </button>
      </Link>
    <div className="info-box">
  <p className="Personnel">Cadet</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
      <tr>
      <td><label htmlFor="studentNumber">Student Number:</label></td>
      <td><p>{userData.studentNumber}</p></td>
    </tr>
    <tr>
      <td><label>Name:</label></td>
      <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
    </tr>
    <tr>
      <td><label htmlFor="email">Email:</label></td>
      <td><p>{userData.email}</p></td>
    </tr>
    <tr>
      <td><label>Course:</label></td>
      <td><p>{userData.Course}</p></td>
    </tr>
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}

 
{isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
        <ToastContainer />
        {loading ? (
          <div>Loading...</div>
        ) : (
        <div className="tabs-container-subject">
      <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
      >
        <Tab eventKey="MS1" title={<div style={{ textAlign: 'center' }}>MS1 Files</div>}>
          <ul className="file-list">
            {ms1Files.map((name, index) => (
              <li key={index} onClick={() => openFile(`MS1/${name}`)} className="file-item" style={{ cursor: 'pointer' }}>
                {name}
                {name.endsWith('.pdf') && <FontAwesomeIcon icon={faFilePdf} style={{ marginLeft: '5px', color: 'red' }} />} {/* Change the color here */}
              </li>
            ))}
          </ul>
        </Tab>
        <Tab eventKey="MS2" title={<div style={{ textAlign: 'center' }}>MS2 Files</div>}>
          <ul className="file-list">
            {ms2Files.map((name, index) => (
              <li key={index} onClick={() => openFile(`MS2/${name}`)} className="file-item" style={{ cursor: 'pointer' }}>
                {name}
                {name.endsWith('.pdf') && <FontAwesomeIcon icon={faFilePdf} style={{ marginLeft: '5px', color: 'red' }} />} {/* Change the color here */}
              </li>
            ))}
          </ul>
        </Tab>
      </Tabs>
      </div>
        )}
      </div>
    </div>
  );
};

export default DisplayFile;