import React, { useState, useEffect } from "react";
import { auth, db } from "../Firebase"; // Import Firebase auth
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { get, ref, onValue, update } from "firebase/database";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import "../CadetCSS/CadetLogin.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner from react-bootstrap
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import Modal from "react-bootstrap/Modal"; // Import the Modal component
import ForgotPassword from "./ForgotPassword"; // Import the ForgotPassword component
import "./ForgotPassword.css"; // Import the CSS file
import Signup from './CadetSignUp'; // Adjust the path as necessary
import { Offcanvas } from 'react-bootstrap';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPendingApprovalModal, setShowPendingApprovalModal] = useState(false);
  const [showSignupOffcanvas, setShowSignupOffcanvas] = useState(false);
  

  const toggleSignupOffcanvas = () => {
    setShowSignupOffcanvas(!showSignupOffcanvas);
  };

  useEffect(() => {
    const cleanupListener = auth.onAuthStateChanged(user => {
      if (user) {
        // Update user's status to "Online" in the database
        update(ref(db, `users/${user.uid}`), { status: "Online" })
          .then(() => console.log("User status updated to Online"))
          .catch(error => console.error("Error updating user status:", error));
      }
    });

    return () => cleanupListener();
  }, []);

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      toast.success("Verification email sent successfully.");
      setShowVerificationModal(false); // Close the modal after sending email
    } catch (error) {
      console.error("Error sending verification email:", error);
      toast.error("Failed to send verification email. Please try again.");
    }
  };

  const handleLogin = async (email, password) => {
    try {
      setLoading(true); // Show spinner when logging in
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
      // Check if the user's email is verified
      if (!userCredential.user.emailVerified) {
        // If the email is not verified, display a modal or message
        setShowVerificationModal(true);
        return;
      }
  
      setErrorMessage("");
  
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      const userDataSnapshot = await get(userRef);
  
      if (userDataSnapshot.exists()) {
        const userData = userDataSnapshot.val();
  
        if (userData.DateOfBirth) {
          const { Lastname, Firstname, Middlename, Course, studentNumber } = userData;
          let fullName = "";
  
          if (Lastname) {
            fullName += Lastname;
          }
          if (Firstname) {
            fullName += `, ${Firstname}`;
          }
          if (Middlename) {
            fullName += ` ${Middlename}`;
          }
  
          localStorage.setItem('studentNumber', studentNumber);
          localStorage.setItem('fullName', fullName);
          localStorage.setItem('Course', Course);
  
          navigate("/Cadet/Cadet");
        } else {
          // If DateOfBirth does not exist, check for other required fields
          const requiredFields = ['studentNumber', 'Lastname', 'Middlename', 'Firstname', 'email', 'Course'];
          const missingFields = requiredFields.filter(field => !userData[field]);
  
          if (missingFields.length === 0) {
            // If all required fields are present, navigate to CadetHomepage
            navigate("/Cadet/CadetHomepage");
          } else {
            // If any required field is missing, display an error message
            toast.error("Your email account is not registered in our database.");
          }
        }
      } else {
        throw new Error("User data not found");
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Login failed. Please check your email and password.");
      showAlert("Login failed. Please check your email and password.");
    } finally {
      setLoading(false); // Hide spinner after login attempt
    }
  };
  
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  const handleLoginClick = async () => {
    if (!email || !password) {
      setErrorMessage("Please enter both email and password.");
      showAlert("Please enter both email and password.");
    } else {
      try {
        const pendingUsersRef = ref(db, "pendingUsers");
        const snapshot = await get(pendingUsersRef);
        if (snapshot.exists()) {
          const pendingUsersData = snapshot.val();
          const usersArray = Object.entries(pendingUsersData).map(([uid, userData]) => ({
            uid: uid,
            ...userData
          }));
          // Check if the user's email is in the pending users list
          const isEmailPending = usersArray.some(user => user.email === email);
          if (isEmailPending) {
            // If email is in pendingUsers list, show a toast message
            toast.info("Your account is still pending verification. Please try again later.");
            return; // Exit the function without further action
          }
        }
        // If email is not in pendingUsers list or the list doesn't exist, proceed with login
        handleLogin(email, password);
      } catch (error) {
        console.error("Error checking pending status:", error);
      }
    }
  };

  const showAlert = (message) => {
    toast.error(message);
  };
  return (
    <div className="cadet-login-container">
      <div className="login-form-container">

        <Form>
          <div className="form-container-cd">
            <div></div>
            <Link to="/">
              <Button
                variant="link"
                size="lg"
                className="button-homepage"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="xl"
                  style={{ color: "#000000" }}
                />
              </Button>
            </Link>
            <div className="login-heading-container">
              <h2>Welcome Cadets!</h2>
            </div>
            <Form.Group
  as={Row}
  className="mb-3"
  controlId="formPlaintextEmail"
>
  <Form.Label column sm="3">
    Email
  </Form.Label>
  <Col sm="15">
    <Form.Control
      id="email-input"
      type="email"
      placeholder="Enter your email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      aria-describedby="passwordHelpBlock"
    />
  </Col>
</Form.Group>

<Form.Group
  as={Row}
  className="mb-3"
  controlId="formPlaintextPassword"
>
  <Form.Label column sm="3">
    Password
  </Form.Label>
  <Col sm="15">
    <InputGroup>
      <Form.Control
        id="password-input"
        type={showPassword ? "text" : "password"}
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{
          border: "1px solid #343a40",
          borderRadius: "1",
          height: "40px",
          width: "200px",
          fontSize: "18px",
        }}
      />
      <Button
        variant="outline-dark"
        onClick={togglePasswordVisibility}
        aria-label={
          showPassword ? "Hide Password" : "Show Password"
        }
        className="input-group-btn-small1"
      >
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
        />
      </Button>
    </InputGroup>
  </Col>
</Form.Group>

          </div>

          <div className="login-container-button-cd">
            <button
              variant="success"
              size="lg"
              type="button"
              className="button-login-cd"
              onClick={handleLoginClick}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-2"
                    style={{ marginRight: "8px" }} // Adjust spacing
                  />
                  <span className="spinner-text">Logging in...</span>
                </>
              ) : (
                <p>Login</p>
              )}
            </button>
          </div>

          </Form>
             <div className="cadet-login">
      <p>
        <a href="#" className="forgotpass" onClick={() => setShowForgotPasswordModal(true)}>
          Forgot Password
        </a>
      </p>

      
    </div>

        <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Verification Email</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
         
          <Button variant="primary" onClick={sendVerificationEmail}>
            Send Verification Email
          </Button>
        </Modal.Footer>
      </Modal>


        {/* Toast Container */}
        <ToastContainer />

        <div>
        <div>
      <div className="signup-container">
        <p className="signup-prompt">You don't have an account?</p>
        <Link to="#" onClick={toggleSignupOffcanvas} className="signup-link">
          Sign Up
        </Link>
      </div>

      <Offcanvas
        show={showSignupOffcanvas}
        onHide={toggleSignupOffcanvas}
        placement="end"
        className="offcanvas-container1"
        style={{ width: '450px' }} // Adjust width as needed
      >
        <Offcanvas.Header closeButton className="offcanvas-header">
          <Offcanvas.Title className="offcanvas-title">Signup</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body">
          <Signup />
        </Offcanvas.Body>
      </Offcanvas>

    </div>
    </div>
    </div>
    {showForgotPasswordModal && (
        <div className="forgot-modal">
          <div className="custom-modal-content">
            <div className="custom-modal-header">
              <h5 className="modal-title">Forgot Password</h5>
              <button  onClick={() => setShowForgotPasswordModal(false)}>
                <span>&times;</span>
              </button>
            </div>
            <div className="custom-modal-body">
              <ForgotPassword />
            </div>
            <div className="custom-modal-footer">
              <button type="button" className="btn btn-primary" onClick={toggleForgotPasswordModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;