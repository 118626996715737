import React from 'react';

const DownloadCSV = ({ successfulEmails, existingEmails, emailsNotAdded, courseCounts }) => {
  // Function to generate the summary text
  const generateSummaryText = () => {
    const totalAccounts = successfulEmails.length + existingEmails.length + emailsNotAdded.length;
    const summaryText = `Total Accounts: ${totalAccounts},\n` +
                        `Successful Registrations: ${successfulEmails.length},\n` +
                        `Existing Emails: ${existingEmails.length},\n` +
                        `Unsuccessful Emails: ${emailsNotAdded.length},\n` +
                        `Accounts per Course:,\n` +
                        Object.keys(courseCounts).map(course => `${course}: ${courseCounts[course]}`).join(',\n');
    return summaryText;
  };
  
  // Function to convert data to CSV format
  const convertToCSV = () => {
    const summaryText = generateSummaryText();
    const csv = [
      'Successful Registrations,Existing Emails,Unsuccessful Emails,Summary', // Header row
      // Iterate over the maximum length of all lists to ensure all rows have data
      ...Array.from(
        { length: Math.max(successfulEmails.length, existingEmails.length, emailsNotAdded.length) },
        (_, index) => {
          const successful = successfulEmails[index] || ''; // Get successful email at index or empty string if not available
          const existing = existingEmails[index] || ''; // Get existing email at index or empty string if not available
          const notAdded = emailsNotAdded[index] || ''; // Get email not added at index or empty string if not available
          return `"${successful}","${existing}","${notAdded}","${index === 0 ? summaryText : ''}"`; // Concatenate data for each row
        }
      )
    ].join('\n');
  
    return csv;
  };

  // Function to trigger the CSV download
  const downloadCSV = () => {
    const csv = convertToCSV();
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'student_numbers.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <button onClick={downloadCSV}>Download CSV</button>
  );
};

export default DownloadCSV;
