import React from 'react';
import LandingPage from '../LANDINGPAGES/LandingPage';
import Footer from '../LANDINGPAGES/Footer';
import './Manual.css';


const ManualReadings = () => {
  return (
    <div>
        <LandingPage />
      <div className="heading-container">
      <h1>Manual Readings</h1>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Mission"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
          To train and develop college students for leadership position in the reserve components and in the defense 
          establishement in case of national emergency.
        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Vision"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
         A premier ROTC unit in the country which can produce quality and dedicated officers and enlisted reservist imbued with the attitude 
         of serving the people and providing support to the AFP regular counterpart.
        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Pledge of Loyalty"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
         If you work for a man in heaven's name, work for him, speak well of him. Stand by the institutions he represents. Remember an ounce of loyalty is worth pound of cleverness.
         If you must growl, condemn, and eternally find fault, why resign your position. And when you are on the outside, damn to your heart's content. But as long as you are part of this institution, do not condemn it.
         And if you do, the first high wind that comes along will blow you away and probably you will never no why.
        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Military Professionalism"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
        Men who adopt the profession of arms submit their own free will to a law of perpetual constraints. At their own accord, they reject their right to live where they choose, to say what they think, to dress as they like.<br />
         From the moment they become soldiers, it needs but an order to settle them in this place to move them to that, to separate from their families, and dislocate their normal lives.
        In the world of command they must rise, march, run, endure bad weather, go out without sleep or food and be isolated in some distant post. Work until they drop. <br />
        They have ceased to become master of their own fate. If they drop on their tracks and their ashes shall be scattered, in the four winds that is all part and parcel of their job.

        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"11 General Orders"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
        1. To take charge of this post and all government properties in view.<br />
        2. To walk my post in a military manner. Keeping always on the alert and observing everything that takes place within sight or hearing.<br />
        3. To report all violations of orders I am instructed to enforce.<br />
        4. To repeat all calls from my post more distant from the guard house than my own.<br />
        5. To quit my post only when properly relieved.<br />
        6. To receive, obey and pass on to the sentinel who relieves me all orders from the commanding officer, officer of the day, commissioned and non-commissioned officer of the guard only.<br />
        7. To talk to no one except in the line of duty.<br />
        8. To give the alarm in case of fire or disorder.<br />
        9. To call the commander officer of the guard in any case not covered by instructions.<br />
        10. To salute all officers, all colors and standard not cased.<br />
        11. To be especially watchful at night and during the time for challenging, to challenge all persons on or near my post. And to allow no one to pass without proper authority.<br />

        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Desiderata"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
        Go placidly amid the noise and the haste, and remember what peace there may be in silence,
As far as possible without surrenders, be on good terms with all persons.
Speak your truth quietly and clearly, and listen to others even to the dull and ignorant, they too have their stories.<br />

Avoid loud and aggresive persons, they are vexations to the spirit.
 If you compare yourself to others, you may become vain or bitter, 
for always there will be greater or lesser persons than yourself.<br />

Enjoy your achievement as well as your plans.
Keep interested in your own career, however humble;
it is a real possession in the changing fortunes of time.<br />

Exercise caution in your business affairs, for the world is full of trickery.
But let this not blind you to what virtue there is;
many persons strive for high ideals, and everywhere life is full of heroism.<br />

Be yourself, especially don mot feign affection.
Neither be cynical about love,
for in the face of all aridity and disenchantment, it is as perenial as the grass.<br />

Take kindly the cousel of the years,
gracefully surrendering the things of youth,
Nurture strength of spirit to shield you in a sudden misfortune,
But do not distress yourself with dark imaginings.
Many fears are born of fatigue and loneliness.<br />

Beyond a wholesome discipline,
be gentle with yourself.
You are a child of the Universe no less than the trees and stars,
you have a right to be here.<br />

And whether or not it is clear to you,
no doubt the Universe is unfolding as it should.
Therefore, be at peace with God, whatever you conceive him to be,
And whatever your labors and aspirations,
in the noisy confusion of life,
keep peace in your soul, with all its sham, drudgery, and broken dreams, it is still a beautiful world.
Be cheerful. Strive to be happy.<br />
        </p>
      </div>
      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Military Courtesy"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
          It is the accepted form of politeness in the military service. The foremost of this special form of courtesy is the salute.
          It is expression or manifestation of one's regards for others.
        </p>
      </div>

      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"11 Principles of Leadership"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
         1. Know yourself and seek self- improvement. <br />
         2. Be technically and tactically proficient. <br />
         3. Seek responsibility and take responsibility for your actions. <br />
         4. Make timely and sound decision. <br />
         5. Set the example. <br />
         6. Know your soldier and look out for their welfare. <br />
         7. Keep your subordinate informed. <br />
         8.Develop a sense of responsibility in your subordinates. <br />
         9. Ensure that the task is understood, supervised, and accimplished. <br />
         10. Train your men as a team. <br />
         11. Employ your unit in accordance with its capabilities. <br />
        </p>
      </div>

      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Military Secrecy"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
         What you see, What you hear <br />
         What you know, And even what you feel, <br />
         When you go, Leave it here.
        </p>
      </div>

      <div className="sub-heading1-container">
        <h2 className="sub-heading1">"Honor Code"</h2>
      </div>
      <div className="container1">
        {/* Your content goes here */}
        <p>
         We the cadets does not lie, cheat, steal, squeal nor tolerate among us those who do.
        </p>
      </div>
      <Footer />
    </div>

    
  );
}

export default ManualReadings;