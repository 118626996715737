import React, { useEffect, useState } from "react";
import { getDatabase, ref as dbRef, get, set, update, ref } from "firebase/database";
import { getAuth, signOut } from "firebase/auth";
import {getStorage,ref as storageRef, uploadString, uploadBytes, getDownloadURL,} from "firebase/storage";
import {regions,provinces,cities,barangays,} from "select-philippines-address";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import "../CadetCSS/CadetUpdate.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import SideBarCadet from "./CadetSidebar";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";



function CadetUpdate() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(null);

  const [isEditing, setIsEditing] = useState(true);
  const [message, setMessage] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [regionAddr, setRegionAddr] = useState("");
  const [provinceAddr, setProvinceAddr] = useState("");
  const [cityAddr, setCityAddr] = useState("");
  const [barangayAddr, setBarangayAddr] = useState("");

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBarangay, setSelectedBarangay] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageURL, setUploadedImageURL] = useState(null);

  const [editedUserData, setEditedUserData] = useState({
    Firstname: "",
    Lastname: "",
    Middlename: "",
    email: "",
    studentNumber: "",
    Gender: "",
    Course: "",
    DateOfBirth: "",
    ContactNo: "",
    regionAddr: "",
    province: "",
    city: "",
    barangay: "",
    Height: "",
    Weight: "",
    BloodType: "",
    Position: "",
    BeneficiaryName: "", // Added Beneficiary fields
    BeneficiaryContact: "",
    BeneficiaryAddress: "",
    BeneficiaryRelationship: "",
  });

  const fetchRegionData = async () => {
    const storedRegionData = localStorage.getItem("regionData");

    if (storedRegionData) {
      setRegionData(JSON.parse(storedRegionData));
    } else {
      try {
        const response = await regions();
        setRegionData(response);

        // Save the region data in local storage
        localStorage.setItem("regionData", JSON.stringify(response));
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    }
  };

  const [resizedImageURL, setResizedImageURL] = useState(null); // Define setResizedImageURL

  const resizeImage = (dataURL, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      resolve(dataURL); // Return the original dataURL without resizing
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Display the selected image
        const reader = new FileReader();
        reader.onload = async (event) => {
          setSelectedImage(event.target.result);

          // Upload the original image to Firebase Storage
          const storage = getStorage();
          const imageRef = storageRef(storage, `user_images/${email}`);
          const blob = await fetch(event.target.result).then((r) => r.blob());
          await uploadBytes(imageRef, blob);

          // Get the download URL of the uploaded image
          const imageURL = await getDownloadURL(imageRef);
          setUploadedImageURL(imageURL);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  // Function to fetch user data
  const fetchUserData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("No user is logged in.");
      return;
    }

    const userId = user.uid;
    const database = getDatabase();
    const userRef = dbRef(database, `users/${userId}`);

    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserData(userData);

        const userEmail = userData.email;
        setEmail(userEmail);

        // Populate editedUserData when data is available
        setEditedUserData((prevData) => ({
          ...prevData,
          Firstname: userData.Firstname || "",
          Lastname: userData.Lastname || "",
          Middlename: userData.Middlename || "",
          email: userData.email || "",
          studentNumber: userData.studentNumber || "",
          Gender: userData.Gender || "",
          Course: userData.Course || "",
          DateOfBirth: userData.DateOfBirth || "",
          ContactNo: userData.ContactNo || "",
          Position: userData.Position || "",
          Height: userData.Height || "",
          Weight: userData.Weight || "",
          region: userData.selectedregion || "",
          BloodType: userData.BloodType || "",
          BeneficiaryRelationship: userData.BeneficiaryRelationship || "",
          BeneficiaryAddress: userData.BeneficiaryAddress || "",
          BeneficiaryContact: userData.BeneficiaryContact || "",
          BeneficiaryName: userData.BeneficiaryName || "",
          selectedRegion: userData.selectedRegion || "",
        }));

        storeUserDataInLocalStorage(userData);
      } else {
        console.error("User data not found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Function to handle the form submission for editing user data
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      "Firstname",
      "Lastname",
      "email",
      "studentNumber",
      "Gender",
      "Course",
      "DateOfBirth",
      "ContactNo",
      "regionAddr",
      "province",
      "city",
      "barangay",
      "Height",
      "Weight",
      "BloodType",
      "Position",
    ];
    const emptyFields = requiredFields.filter(
      (field) => !editedUserData[field],
    );

    if (emptyFields.length > 0) {
      alert(`Please fill in the following fields: ${emptyFields.join(", ")}`);
      return;
    }

    // Rest of your code to save the data
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("No user is logged in.");
      return;
    }

    const userId = user.uid;
    const database = getDatabase();
    const userRef = dbRef(database, `users/${userId}`);

    try {
      // Build the updated user data object
      const updatedUserData = {
        Firstname: editedUserData.Firstname,
        Lastname: editedUserData.Lastname,
        Middlename: editedUserData.Middlename,
        email: editedUserData.email,
        studentNumber: editedUserData.studentNumber,
        Gender: editedUserData.Gender,
        Course: editedUserData.Course,
        DateOfBirth: editedUserData.DateOfBirth,
        ContactNo: editedUserData.ContactNo,
        region: editedUserData.regionAddr || "", // Use empty string as a default
        province: editedUserData.province || "", // Use empty string as a default
        city: editedUserData.city || "", // Use empty string as a default
        barangay: editedUserData.barangay || "", // Use empty string as a default
        Height: editedUserData.Height,
        Weight: editedUserData.Weight,
        BloodType: editedUserData.BloodType,
        Position: editedUserData.Position,
        BeneficiaryName: editedUserData.BeneficiaryName,
        BeneficiaryContact: editedUserData.BeneficiaryContact,
        BeneficiaryAddress: editedUserData.BeneficiaryAddress,
        BeneficiaryRelationship: editedUserData.BeneficiaryRelationship,
      };

      await set(userRef, updatedUserData); // Save the updated data to the database
      setIsEditing(false); // Disable form fields
      setShowConfirmationPopup(false);

      storeUserDataInLocalStorage(updatedUserData); // Save the updated data in local storage

      navigate("/Cadet/Cadet");
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const region = async () => {
    try {
      const response = await regions();
      setRegionData(response);

      // Save the region data in local storage
      localStorage.setItem("regionData", JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const province = async (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegionText = e.target.selectedOptions[0].text;
    setRegionAddr(selectedRegionText);
    setSelectedRegion(selectedRegionCode); // Store selected region

    try {
      const response = await provinces(selectedRegionCode);
      setProvinceData(response);
      setCityData([]);
      setBarangayData([]);

      // Update selected region and province as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        region: selectedRegionText,
        regionAddr: selectedRegionText,
        province: "", // Clear province when region changes
        provinceAddr: "", // Clear provinceAddr when region changes
        city: "", // Clear city when region changes
        barangay: "", // Clear barangay when region changes
      }));
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const city = async (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvinceText = e.target.selectedOptions[0].text;
    setProvinceAddr(selectedProvinceText);
    setSelectedProvince(selectedProvinceCode); // Store selected province

    try {
      const response = await cities(selectedProvinceCode);
      setCityData(response);

      // Update selected province and city as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        province: selectedProvinceText,
        provinceAddr: selectedProvinceText,
        city: "", // Clear city when province changes
        barangay: "", // Clear barangay when province changes
      }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const barangay = async (e) => {
    const selectedCityCode = e.target.value;
    const selectedCityText = e.target.selectedOptions[0].text;
    setCityAddr(selectedCityText);

    try {
      const response = await barangays(selectedCityCode);
      setBarangayData(response);

      // Update selected city and barangay as text in editedUserData
      setEditedUserData((prevData) => ({
        ...prevData,
        city: selectedCityText,
        barangay: "", // Clear barangay when city changes
      }));
    } catch (error) {
      console.error("Error fetching barangays:", error);
    }
  };

  const storeUserDataInLocalStorage = (data) => {
    localStorage.setItem("userData", JSON.stringify(data));
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      fetchUserData();

      // Check if region data is available in local storage
      const storedRegionData = localStorage.getItem("regionData");
      if (storedRegionData) {
        // If available, use it
        setRegionData(JSON.parse(storedRegionData));
      } else {
        // If not, fetch it
        region();
      }
    } else {
      console.error("No user is logged in.");
    }

    // Load selected place data from local storage
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setEditedUserData(JSON.parse(storedUserData));
    }
    fetchRegionData();
  }, []);

  const [smShow, setSmShow] = useState(false);

  const [userImageUrl, setUserImageUrl] = useState(() => {
    // Try to retrieve the user image URL from local storage
    const storedUserImageUrl = localStorage.getItem("userImageUrl");
    return storedUserImageUrl || ""; // Initialize with an empty string if not found
  });
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Add any additional logic you want to execute when the modal is closed
  };

  
  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut(auth);
        
    const database = getDatabase();
    // Update user's status to "Offline" in the database
    await update(ref(database, `users/${userId}`), { status: "Offline" });
    
    window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
  };
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  return (


    <div className="cadet-homepage-container-edit">
    <SideBarCadet/>
      <div className="cadet-homepage-edit1">    
      <div className="Header-list-cadet321">
          <h1>Update</h1>
          <div className="user-image-cadet1"> 
          <p>{localStorage.getItem("fullName")}</p>
      {userImageUrl ? (
        <img
          className="user-image enlarged-image" onClick={handleModalOpen}
          src={userImageUrl}
          alt="User"
        />
      ) : (
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

    </div>
        </div>
        {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <Link to="/Cadet/CadetUpdate">
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
 <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
</Link>
    <div className="info-box">
  <p className="Personnel">Cadet</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
      <tr>
      <td><label htmlFor="studentNumber">Student Number:</label></td>
      <td><p>{userData.studentNumber}</p></td>
    </tr>
    <tr>
      <td><label>Name:</label></td>
      <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
    </tr>
    <tr>
      <td><label htmlFor="email">Email:</label></td>
      <td><p>{userData.email}</p></td>
    </tr>
    <tr>
      <td><label>Course:</label></td>
      <td><p>{userData.Course}</p></td>
    </tr>
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}
{isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}

          <Link to="/Cadet/CadetProfile">
        <button className="btn-btn-primary1">Profile</button>
      </Link>
        <div className="profileEdit"> 
        <div>
          
     
          <Form.Group controlId="formFile" className="mb-3">
        
            <div className="image-container">
            {userImageUrl ? (
              <img className="user-image1" src={userImageUrl} alt="User" />
            ) : (
              <Spinner animation="border" role="status" variant="success">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          <div className="update-container">

          <Row className="justify-content-center">
              <Col sm="15">
                <h2>Edit Your Credentials</h2>
              </Col>
            </Row>
            <div className="image-upload-container">
              <Col sm="15">
                <div className="d-flex justify-content-center">
                  <Form.Control
                    type="file"
                    id="ImageUpload"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
                {/* Display the selected image */}
                {selectedImage && (
                  <div className="image-container d-flex justify-content-center">
                    <img src={selectedImage} alt="Selected" />
                  </div>
                )}
              </Col>
            </div>

              <div class="input-container-group-ch1-9">
                <div class="mother-container">
                <div className="input-container-ch4">
        <label htmlFor="StudentID">Student Number:</label>
        <input
          type="text"
          id="StudentID"
          placeholder="Enter your Student ID"
          value={editedUserData.studentNumber}
          disabled
          style={{ backgroundColor: '#f0f0f0', /* Set background color to light grey */ }}
        />
      </div>
      <div className="input-container-ch5">
        <label htmlFor="Email">Email:</label>
        <input
          type="email"
          id="Email"
          placeholder="Enter your Email"
          value={editedUserData.email}
          disabled
          style={{ backgroundColor: '#f0f0f0', /* Set background color to light grey */ }}
        />
        <p className="not-editable-text">These credentials cannot be edited.</p>
      </div>
                
                <div className="input-container-ch2">
                    <label htmlFor="Lastname">Last Name:</label>
                    <input
                      type="text"
                      id="Lastname"
                      placeholder="Enter your Last Name"
                      value={editedUserData.Lastname}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Lastname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-container-ch1">
                    <label htmlFor="Firstname">First Name:</label>
                    <input
                      type="text"
                      id="Firstname"
                      placeholder="Enter your First Name"
                      value={editedUserData.Firstname}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Firstname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-container-ch3">
                    <label htmlFor="Middlename">Middle Name:</label>
                    <input
                      type="text"
                      id="Middlename"
                      placeholder="Enter your Middle Name"
                      value={editedUserData.Middlename}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Middlename: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="input-container-ch6">
                  <label htmlFor="Gender">Gender:</label>
                  <div className="select-containerch6">
                    <select
                      id="Gender"
                      value={editedUserData.Gender}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Gender: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Enter Your Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="input-container-ch7">
                  <label htmlFor="DateOfBirth">Date of Birth:</label>
                  <input
                    type="date"
                    id="DateOfBirth"
                    value={editedUserData.DateOfBirth}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setEditedUserData({
                        ...editedUserData,
                        DateOfBirth: e.target.value,
                      })
                    }
                    style={{
                      width: "100%",
                      height: "40px", // Increased height
                      padding: "10px", // Increased padding
                      border: "1px solid #000000",
                      borderRadius: "8px",
                      fontSize: "18px", // Increased font size
                      color: "#000",
                    }}
                  />
                </div>
                <div className="input-container-ch8">
                <label htmlFor="ContactNo">Contact No.:</label>
                <div className="input-container">
                  <span className="static-prefix">63+</span>
                  <input
                    type="tel" // Use type "tel" for phone numbers
                    id="ContactNo"
                    placeholder="Enter your Contact No"
                    value={editedUserData.ContactNo}
                    onChange={(e) => {
                      let input = e.target.value;
                      const numberPattern = /^[0-9]*$/; // Regular expression to allow only numbers

                      // Remove any non-numeric characters
                      input = input.replace(/\D/g, "");

                      // Ensure the first digit is "9"
                      if (input.length > 0 && input[0] !== "9") {
                        input = "9" + input.substring(1);
                      }

                     // Limit the input to 10 digits
                      if (input.length > 10) {
                        const alertMessage = "Oops! The maximum allowed input is 10 digits. Please try again.";

                        // Create a div element for the alert
                        const alertDiv = document.createElement('div');
                        alertDiv.className = 'custom-alert';
                        alertDiv.textContent = alertMessage;

                        // Append the alert to the body
                        document.body.appendChild(alertDiv);

                        // Remove the alert after 3 seconds (adjust as needed)
                        setTimeout(() => {
                          document.body.removeChild(alertDiv);
                        }, 3000);
                        
                        input = input.slice(0, 10);
                      }


                      // Format the input as 3-3-4
                      if (input.length >= 4) {
                        input = input.slice(0, 3) + "-" + input.slice(3);
                      }
                      if (input.length >= 8) {
                        input = input.slice(0, 7) + "-" + input.slice(7);
                      }

                      setEditedUserData({
                        ...editedUserData,
                        ContactNo: input,
                      });
                    }}
                  />
                </div>
              </div>
                <div className="input-container-ch9">
                  <label htmlFor="Position">Position:</label>
                  <div className="select-containerch9">
                    <select
                      id="Position"
                      value={editedUserData.Position}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Position: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Enter Your Position
                      </option>
                      <option value="Cadet">Cadet</option>
                      <option value="Officer">Officer</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="input-container-group-ch10-17">
                <div className="input-container-ch10">
                  <label htmlFor="Course">Course:</label>
                  <div className="select-containerch10">
                    <select
                      id="Course"
                      value={editedUserData.Course}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          Course: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Enter Your Course
                      </option>
                      <option value="BEED">BEED</option>
                      <option value="BPA">BPA</option>
                      <option value="BPA">BPA-FA</option>
                      <option value="BPA-FM">BPA-FM</option>
                      <option value="BSA">BSA</option>
                      <option value="BSAM">BSAM</option>
                      <option value="BS ARCHI">BS ARCHI</option>
                      <option value="BSBA-FM">BSBA-FM</option>
                      <option value="BSBSA-MM">BSBSA-MM</option>
                      <option value="BSBIO">BSBIO</option>
                      <option value="BSCE">BSCE</option>
                      <option value="BSEE">BSEE</option>
                      <option value="BSED-MT">BSED-MT</option>
                      <option value="BSHM">BSHM</option>
                      <option value="BSIT">BSIT</option>
                      <option value="BSND">BSND</option>
                      <option value="BSOA">BSOA</option>
                      <option value="DCPET">DCPET</option>
                      <option value="DCVET">DCVET</option>
                      <option value="DIT">DIT</option>
                      <option value="DEET">DEET</option>
                      <option value="DOMT-LOM">DOMT-LOM</option>
                      <option value="DOMT-MOM">DOMT-MOM</option>
                    </select>
                  </div>
                </div>

                <div className="input-container-ch11">
                  <label htmlFor="Height">Height (cm):</label>
                  <input
                    type="text"
                    id="Height"
                    placeholder="Enter your Height"
                    value={editedUserData.Height}
                    onChange={(e) =>
                      setEditedUserData({
                        ...editedUserData,
                        Height: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input-container-ch12">
                  <label htmlFor="Weight">Weight (kg):</label>
                  <input
                    type="text"
                    id="Weight"
                    placeholder="Enter your Weight"
                    value={editedUserData.Weight}
                    onChange={(e) =>
                      setEditedUserData({
                        ...editedUserData,
                        Weight: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input-container-ch13">
                  <label htmlFor="BloodType">Blood Type:</label>
                  <div className="select-containerch13">
                    <select
                      id="BloodType"
                      placeholder="Enter your Blood Type"
                      value={editedUserData.BloodType}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          BloodType: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Enter Your BloodType
                      </option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                  </div>
                </div>

                <div className="input-container-ch14">
                  <label htmlFor="regionSelect">Select Region:</label>
                  <div className="select-containerch14">
                    <select
                      id="regionSelect"
                      onChange={(e) => {
                        const selectedRegionCode = e.target.value;
                        const selectedRegionText =
                          e.target.selectedOptions[0].text;

                        // Update selected region in the state
                        setSelectedRegion(selectedRegionCode);
                        setRegionAddr(selectedRegionText);

                        // Call the province function when region changes
                        province(e);

                        // Update editedUserData with the selected region text
                        setEditedUserData((prevData) => ({
                          ...prevData,
                          regionAddr: selectedRegionText,
                        }));

                        // Store the selected region in local storage
                        localStorage.setItem(
                          "selectedRegion",
                          selectedRegionCode,
                        );
                      }}
                      value={selectedRegion} // Set the selected value
                    >
                      <option value="" disabled>
                        Select Region
                      </option>
                      {regionData &&
                        regionData.length > 0 &&
                        regionData.map((region) => (
                          <option
                            key={region.region_code}
                            value={region.region_code}
                          >
                            {region.region_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {/* Province Dropdown */}
                <div className="input-container-ch15">
                  <label htmlFor="provinceSelect">Select Province:</label>
                  <div className="select-containerch15">
                    <select
                      id="provinceSelect"
                      onChange={(e) => {
                        setProvinceAddr(e.target.selectedOptions[0].text);
                        city(e); // Call the city function when province changes
                        setSelectedProvince(e.target.value); // Update selected province
                        setEditedUserData({
                          ...editedUserData,
                          province: e.target.value,
                        });
                      }}
                      value={selectedProvince} // Set the selected value
                    >
                      <option value="" disabled>
                        Select Province
                      </option>
                      {provinceData &&
                        provinceData.length > 0 &&
                        provinceData.map((province) => (
                          <option
                            key={province.province_code}
                            value={province.province_code}
                          >
                            {province.province_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {/* City Dropdown */}
                <div className="input-container-ch16">
                  <label htmlFor="citySelect">Select City:</label>
                  <div className="select-containerch16">
                    <select
                      id="citySelect"
                      onChange={(e) => {
                        setCityAddr(e.target.selectedOptions[0].text);
                        barangay(e); // Call the barangay function when city changes
                        setSelectedCity(e.target.value); // Update selected city
                        setEditedUserData({
                          ...editedUserData,
                          city: e.target.value,
                        });
                      }}
                      value={selectedCity} // Set the selected value
                    >
                      <option value="" disabled>
                        Select City
                      </option>
                      {cityData &&
                        cityData.length > 0 &&
                        cityData.map((city) => (
                          <option key={city.city_code} value={city.city_code}>
                            {city.city_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {/* Always render Barangay */}
                <div className="input-container-ch17">
                  <label htmlFor="barangaySelect">Select Barangay:</label>
                  <div className="select-containerch17">
                    <select
                      id="barangaySelect"
                      onChange={(e) => {
                        setBarangayAddr(e.target.selectedOptions[0].text);

                        // Update selected barangay in the editedUserData state
                        setEditedUserData({
                          ...editedUserData,
                          barangay: e.target.value,
                        });
                      }}
                      value={editedUserData.barangay}
                    >
                      <option value="" disabled>
                        Select Barangay
                      </option>
                      {barangayData &&
                        barangayData.length > 0 &&
                        barangayData.map((barangay, index) => (
                          <option key={index} value={barangay.brgy_name}>
                            {barangay.brgy_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-container-ch18">
                <h2>Beneficiaries</h2>
                <div className="beneficiaries">
                  {/* You can map through an array of beneficiaries if needed */}
                  {/* For simplicity, we'll add one set of beneficiary fields */}
                  <div>
                    <label htmlFor="BeneficiaryName">Name:</label>
                    <input
                      type="text"
                      id="BeneficiaryName"
                      placeholder="e.g. Dela Cruz, Juan T."
                      value={editedUserData.BeneficiaryName}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          BeneficiaryName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-container-ch8">
  <label htmlFor="BeneficiaryContact">Contact No.:</label>
  <div className="input-container">
    <span className="static-prefix">63+</span>
    <input
      type="tel" // Use type "tel" for phone numbers
      id="BeneficiaryContact"
      placeholder="Enter your Contact No"
      value={editedUserData.BeneficiaryContact}
      onChange={(e) => {
        let input = e.target.value;
        const numberPattern = /^[0-9]*$/; // Regular expression to allow only numbers

        // Remove any non-numeric characters
        input = input.replace(/\D/g, "");

        // Ensure the first digit is "9"
        if (input.length > 0 && input[0] !== "9") {
          input = "9" + input.substring(1);
        }

        // Limit the input to 10 digits
        if (input.length > 10) {
          const alertMessage =
            "Oops! The maximum allowed input is 10 digits. Please try again.";

          // Create a div element for the alert
          const alertDiv = document.createElement("div");
          alertDiv.className = "custom-alert";
          alertDiv.textContent = alertMessage;

          // Append the alert to the body
          document.body.appendChild(alertDiv);

          // Remove the alert after 3 seconds (adjust as needed)
          setTimeout(() => {
            document.body.removeChild(alertDiv);
          }, 3000);

          input = input.slice(0, 10);
        }

        // Format the input as 3-3-4
        if (input.length >= 4) {
          input = input.slice(0, 3) + "-" + input.slice(3);
        }
        if (input.length >= 8) {
          input = input.slice(0, 7) + "-" + input.slice(7);
        }

        setEditedUserData({
          ...editedUserData,
          BeneficiaryContact: input,
        });
      }}
    />
  </div>
</div>
                  <div>
                    <label htmlFor="BeneficiaryAddress">Address:</label>
                    <input
                      type="text"
                      id="BeneficiaryAddress"
                      placeholder="Enter your Address"
                      value={editedUserData.BeneficiaryAddress}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          BeneficiaryAddress: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="BeneficiaryRelationship">Relationship:</label>
                    <input
                      type="text"
                      id="BeneficiaryRelationship"
                      placeholder="Enter your Relationship"
                      value={editedUserData.BeneficiaryRelationship}
                      onChange={(e) =>
                        setEditedUserData({
                          ...editedUserData,
                          BeneficiaryRelationship: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="qr-code-container">
                <Button onClick={handleEditSubmit} type="button">
                  Save
                </Button>
              </div>
              <div className="footer1"></div>
              </div>
            </Form.Group>
            </div>
          </div>
        </div>
    </div>
     
       
  );
}

export default CadetUpdate;