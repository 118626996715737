import React, { useState, useEffect } from 'react';
import { db, storage } from '../Firebase'; // Ensure correct import path
import { ref, onValue, push, get, set, update, getDatabase } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { Form } from 'react-bootstrap';
import SideBarCadet from './CadetSidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./CadetEnrollment.css";
import Spinner from 'react-bootstrap/Spinner';
import { getAuth, signOut,  } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCheckCircle, faSortDown, faSortUp   } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
function CadetEnrollment() {
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [userData, setUserData] = useState(() => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          Firstname: "",
          Lastname: "",
          Middlename: "",
          Course: "",
          Position: "",
          email: "",
          studentNumber: "",
        };
  });
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [enrollmentButtonStatusMap, setEnrollmentButtonStatusMap] = useState({});
  const currentYear = new Date().getFullYear();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null); // Track selected semester
  const storage = getStorage();
  const [loading, setLoading] = useState(false); // Declare loading state variable

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolYearRef = ref(db, 'SchoolYear');
        const snapshot = await get(schoolYearRef);
        const data = snapshot.val();
  
        if (data) {
          const years = Object.keys(data);
          if (!years.includes(currentYear.toString())) {
            const latestYear = years[years.length - 1];
            const latestSemester = Object.keys(data[latestYear]).pop(); // Get the latest semester
            setEnrollmentData({ [latestYear]: { [latestSemester]: data[latestYear][latestSemester] } }); // Set only the latest school year and semester
          } else {
            const latestSemester = Object.keys(data[currentYear]).pop(); // Get the latest semester
            setEnrollmentData({ [currentYear]: { [latestSemester]: data[currentYear][latestSemester] } }); // Set only the latest school year and semester
          }
  
          const initialButtonStatusMap = {};
          Object.keys(data).forEach((year) => {
            Object.keys(data[year]).forEach((semester) => {
              const identifier = `${year}-${semester}`;
              initialButtonStatusMap[identifier] = 'Submit Enrollment Request';
            });
          });
          setEnrollmentButtonStatusMap(initialButtonStatusMap);
        } else {
          toast.error('No enrollment data available.');
        }
      } catch (error) {
        console.error('Error fetching enrollment data:', error);
        toast.error('Error fetching enrollment data. Please try again.');
      }
    };
  
    const enrollmentRef = ref(db, `enrolled/${currentYear}`);
    onValue(enrollmentRef, (snapshot) => {
      const enrollmentStatus = snapshot.exists();
      setIsEnrolled(enrollmentStatus);
    });
  
    fetchData();
    return () => {
      // Detach the event listener
    };
  }, []);
  

  const checkEnrollmentStatus = async (year, semester) => {
    try {
      const studentNumber = localStorage.getItem("studentNumber");

      if (!studentNumber) {
        toast.error('Student number not found. Please log in again.');
        return false;
      }

      const semesterRef = ref(db, `enrolled/${year}/${semester}`);
      const snapshot = await get(semesterRef);

      if (snapshot.exists()) {
        const enrolledStudents = snapshot.val();
        return Object.values(enrolledStudents).some(student => student.studentNumber === studentNumber);
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking enrollment status:', error);
      toast.error('Error checking enrollment status. Please try again.');
      return false;
    }
  };
  const handleTableEnrollmentRequest = async (year, semester) => {
    try {
      setLoading(true); // Set loading to true when the button is clicked
  
      const isAlreadyEnrolled = await checkEnrollmentStatus(year, semester);
    
      if (isAlreadyEnrolled) {
        setIsEnrolled(true);
        setEnrollmentButtonStatusMap((prevStatusMap) => ({
          ...prevStatusMap,
          [`${year}-${semester}`]: 'You are already enrolled',
        }));
        toast.info(`You are already enrolled for the current year ${year} and ${semester}.`);
      } else {
        const studentNumber = localStorage.getItem('studentNumber');
    
        if (!studentNumber) {
          toast.error('Student number not found. Please log in again.');
          return;
        }
        if (!selectedFile) {
          toast.error('You must upload your Certificate of Registration (COR) before submitting the enrollment request.');
          return;
        }
    
        if (semester !== selectedSemester) {
          toast.error('The uploaded file does not match the selected semester.');
          return;
        }
  
        const fileName = `${userData.studentNumber}-${year}-${semester}`;
        const fileRef = storageRef(storage, `pdf_files/${fileName}`);
        
        await uploadBytes(fileRef, selectedFile);
    
        const enrollmentRef = ref(db, `pendingEnrollees/${year}/${semester}`);
        const snapshot = await get(enrollmentRef);
    
        if (snapshot.exists()) {
          const enrollmentData = snapshot.val();
          const isAlreadyRequested = Object.values(enrollmentData).some(student => student.studentNumber === userData.studentNumber);
    
          if (isAlreadyRequested) {
            toast.info(`You already have a pending enrollment request for ${year} ${semester}.`);
            setEnrollmentButtonStatusMap(prevStatusMap => ({
              ...prevStatusMap,
              [`${year}-${semester}`]: 'You already sent a request'
            }));
            return;
          }
        }
    
        const newEnrollmentRef = push(enrollmentRef);
        await set(newEnrollmentRef, { studentNumber: userData.studentNumber });
        setEnrollmentButtonStatusMap(prevStatusMap => ({
          ...prevStatusMap,
          [`${year}-${semester}`]: 'Enrollment request sent'
        }));
        toast.success('Enrollment request sent successfully.');
      }
    } catch (error) {
      console.error('Error handling enrollment request:', error);
      toast.error('Error handling enrollment request. Please try again.');
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // Update selected semester when a file is uploaded
    const semester = event.target.dataset.semester;
    setSelectedSemester(semester);
  };


  const [userImageUrl, setUserImageUrl] = useState(() => {
    // Try to retrieve the user image URL from local storage
    const storedUserImageUrl = localStorage.getItem("userImageUrl");
    return storedUserImageUrl || ""; // Initialize with an empty string if not found
  });
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);

  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
    // Add any additional logic you want to execute when logout is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Add any additional logic you want to execute when the modal is closed
  };

  
  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut(auth);
        
    const database = getDatabase();
    // Update user's status to "Offline" in the database
    await update(ref(database, `users/${userId}`), { status: "Offline" });
    
    window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
  };
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  return (
    <div className="Enrollment-container">
      <SideBarCadet />
      <div className='enrollmenet-page-cadet'>
        <div className="Enrollment-table">
        <div className="Header-list-cadet321">
          <h1>Enrollment</h1>
          <div className="user-image-cadet1"> 
          <p>{localStorage.getItem("fullName")}</p>
      {userImageUrl ? (
        <img
          className="user-image enlarged-image" onClick={handleModalOpen}
          src={userImageUrl}
          alt="User"
        />
      ) : (
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

    </div>
        </div>
        {showModal && (
   <div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
     <div className="modal-content" onClick={handleModalContentClick}>
       <span className="close" onClick={handleModalClose}>&times;</span>
       <div className="pendingHeader1">Profile</div>
       <div className="user-image-container12">
         {userImageUrl ? (
           <img className="user-image12" src={userImageUrl} alt="User" />
         ) : (
             <span className="visually-hidden">Loading...</span>
         )}
         
      
       </div>
       <Link to="/Cadet/CadetUpdate">
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
 <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
</Link>
    <div className="info-box">
  <p className="Personnel">Cadet</p>
  {userData && (
    <>
    <div className="custom-table"> 
    <table className="custom-table1">
      <tbody>
      <tr>
      <td><label htmlFor="studentNumber">Student Number:</label></td>
      <td><p>{userData.studentNumber}</p></td>
    </tr>
    <tr>
      <td><label>Name:</label></td>
      <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
    </tr>
    <tr>
      <td><label htmlFor="email">Email:</label></td>
      <td><p>{userData.email}</p></td>
    </tr>
    <tr>
      <td><label>Course:</label></td>
      <td><p>{userData.Course}</p></td>
    </tr>
      </tbody>
</table></div>

    </>
  )}
  
</div>
<div className="button-container12">
        <button className="CustomBtn" onClick={handleLogoutClick}>
          <div className="sign12">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text12">Logout</div>
        </button>
      </div>

   
     </div>
   
   </div>

 )}

 
{isModalOpen && (
        <div className="CustomCard1">
          <div className="CustomCard">
            <div className="card-content">
              <p className="card-heading">Log Out</p>
              <p className="card-description">Are you sure you want to Log out?</p>
            </div>
            <div className="card-button-wrapper">
              <button className="card-button secondary" onClick={handleCloseModal}>No</button>
              <button className="card-button primary" onClick={handleLogout} >Yes</button>
            </div>
            <button className="exit-button" onClick={handleCloseModal}>
              <svg height="20px" viewBox="0 0 384 512">
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
          <div className="Enrollment-table">
            <>
              {enrollmentData &&
                Object.keys(enrollmentData).map((year) => (
                  <div key={year} className="year-container">
                    {enrollmentData[year] &&
                      Object.keys(enrollmentData[year]).map((semester) => (
                        <div key={semester} className="semester-container">
                          <div className='box-ce'>
                            <h2 className="center-header-ce">School Year {year} - {semester}</h2>
                            <div className="user-info-container-ec">
                              <div>
                                <p>{`${userData.Lastname}, ${userData.Firstname} ${userData.Middlename} `} ({userData.studentNumber}) {userData.Course}</p>
                              </div>
                            </div>
                            <table>
                              <tbody>
                                <th>Subject Title</th>
                                <th>Sub Topics</th>
                                {enrollmentData[year][semester].subjects && enrollmentData[year][semester].subjects.map((subject, index) => (
                                  <tr key={index}>
                                    <td className="subject-title"><strong>{subject.title}</strong></td>
                                    <td className="sub-topics">
                                      <ul>
                                        {subject.subtopics && subject.subtopics.map((subtopic, subIndex) => (
                                          <li key={subIndex}>{subtopic}</li>
                                        ))}
                                      </ul>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className='pdf-save-button-container'>
                            <div className="upload-pdf-container">
                              
                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label className="file-input-label">Upload Certificate of Registration</Form.Label>
                                <Form.Control className="file-input" type="file" onChange={handleFileChange} accept="application/pdf" data-semester={semester} />
                              </Form.Group>
                            </div>
                            <div className="enrollment-button-container">
                            <button
  onClick={() => handleTableEnrollmentRequest(year, semester)}
  className={`enrollment-button ${enrollmentButtonStatusMap[`${year}-${semester}`] === 'You are already enrolled' ? 'already-enrolled-button' : 'blue-button'}`}
  disabled={loading} // Disable the button while loading
>
  {loading && (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      className="me-2"
      style={{ marginRight: "8px" }} // Adjust spacing
    />
  )}
  {enrollmentButtonStatusMap[`${year}-${semester}`] === 'You are already enrolled' ? (
    <span className='alreadyenrolled'>
      You are already enrolled <FontAwesomeIcon icon={faCheckCircle} className="green-check-icon" />
    </span>
  ) : enrollmentButtonStatusMap[`${year}-${semester}`]}
</button>
      </div>
         </div>

                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </>
          </div>
        </div>
      </div>
      
      <ToastContainer />
    </div>
  );
}

export default CadetEnrollment;