import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../Firebase'; // Assuming you have the Firebase configuration set up
import { Line, Pie } from 'react-chartjs-2'; // Import the Line and Pie components from the chart library you're using
import './DisplayAttendance.css'; // Import the CSS file for styling

function AbsenceLineChart({ selectedSchoolYear, selectedSemester }) {
  const [absencesData, setAbsencesData] = useState(null);
  const [enrolledStudentNumbers, setEnrolledStudentNumbers] = useState([]);

  useEffect(() => {
    const fetchEnrolledStudentNumbers = async () => {
      try {
        // Construct the database reference path for enrolled directory
        const enrolledRef = ref(db, `enrolled/${selectedSchoolYear}/${selectedSemester}`);

        // Fetch enrolled directory data from Firebase
        const snapshot = await get(enrolledRef);

        if (snapshot.exists()) {
          // If data exists, extract the student numbers
          const enrolledData = snapshot.val();
          const studentNumbers = Object.values(enrolledData).map(student => student.studentNumber);
          setEnrolledStudentNumbers(studentNumbers);
        } else {
          // If no data exists, set enrolledStudentNumbers to an empty array
          setEnrolledStudentNumbers([]);
        }
      } catch (error) {
        console.error('Error fetching enrolled student numbers:', error.message);
      }
    };

    fetchEnrolledStudentNumbers();
  }, [selectedSchoolYear, selectedSemester]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construct the database reference path for absences data
        const absencesRef = ref(db, `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates`);

        // Fetch data from Firebase
        const snapshot = await get(absencesRef);

        if (snapshot.exists()) {
          // If data exists, extract the absences data
          const absencesData = snapshot.val();

          // Filter the absent data based on the enrolled student numbers
          const filteredAbsencesData = {};

          Object.keys(absencesData).forEach(trainingDate => {
            filteredAbsencesData[trainingDate] = {};

            Object.keys(absencesData[trainingDate]).forEach(studentId => {
              const studentNumber = studentId;
              if (enrolledStudentNumbers.includes(studentNumber)) {
                filteredAbsencesData[trainingDate][studentId] = absencesData[trainingDate][studentId];
              }
            });
          });

          // Set the filtered absences data to state
          setAbsencesData(filteredAbsencesData);
        } else {
          // If no data exists, set absencesData to an empty object
          setAbsencesData({});
        }
      } catch (error) {
        console.error('Error fetching absences data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, enrolledStudentNumbers]);

  const prepareLineChartData = () => {
    if (!absencesData || Object.keys(absencesData).length === 0) {
      // If absencesData is empty or undefined, return an empty dataset
      return {
        labels: [],
        datasets: [],
      };
    }
  
    // Extract training days
    const trainingDays = Object.keys(absencesData);
  
    // Sort the training days by extracting the numeric part of the "Training X" label
    trainingDays.sort((a, b) => {
      const trainingNumberA = parseInt(a.split(' ')[1]);
      const trainingNumberB = parseInt(b.split(' ')[1]);
      return trainingNumberA - trainingNumberB;
    });
  
    // Initialize objects to store the absence, present, and excused counts for each training day
    const absenceCount = {};
    const presentCount = {};
    const excusedCount = {};
  
    // Loop through each training day
    trainingDays.forEach((date, index) => {
      // Get the attendance data for the current training day
      const attendanceData = absencesData[date];
      
      // Initialize the absence, present, and excused counts for the current training day
      let dayAbsenceCount = 0;
      let dayPresentCount = 0;
      let dayExcusedCount = 0;
  
      // Loop through each student's attendance data
      Object.values(attendanceData).forEach(studentAttendance => {
        // Check if the student is absent, present, or excused
        if (studentAttendance['Time In']?.appearance === 'Absent' || studentAttendance['Time Out']?.appearance === 'Absent') {
          // Increment the absence count for the current training day
          dayAbsenceCount++;
        } else if (studentAttendance['Time In']?.appearance === 'Late' || studentAttendance['Time Out']?.appearance === 'Late' || studentAttendance['Time In']?.appearance === 'Present' || studentAttendance['Time Out']?.appearance === 'Present') {
          // Increment the present count for the current training day
          dayPresentCount++;
        } else if (studentAttendance['Time In']?.appearance === 'Excused' || studentAttendance['Time Out']?.appearance === 'Excused') {
          // Increment the excused count for the current training day
          dayExcusedCount++;
        }
      });
  
      // Store the absence, present, and excused counts for the current training day
      absenceCount[date] = dayAbsenceCount;
      presentCount[date] = dayPresentCount;
      excusedCount[date] = dayExcusedCount;
    });
  
    // Extract absence, present, and excused counts and labels
    const absences = Object.values(absenceCount);
    const presents = Object.values(presentCount);
    const excused = Object.values(excusedCount);
    const labels = trainingDays.map(date => `Training ${date.split(' ')[1]}`);
  
    // Prepare datasets for absences, presents, and excused
    const absenceDataset = {
      label: 'Absences',
      data: absences,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    };
  
    const presentDataset = {
      label: 'Presents',
      data: presents,
      fill: false,
      borderColor: 'rgb(255, 99, 132)', // Change the color as needed
      tension: 0.1,
    };
  
    const excusedDataset = {
      label: 'Excused',
      data: excused,
      fill: false,
      borderColor: 'rgb(255, 205, 86)', // Change the color as needed
      tension: 0.1,
    };
  
    // Add tooltips configuration
    const tooltips = {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataIndex = tooltipItem.dataIndex;
          const absencesCount = data.datasets[0].data[dataIndex];
          const presentsCount = data.datasets[1].data[dataIndex];
          const excusedCount = data.datasets[2].data[dataIndex];
          return `Absences: ${absencesCount}, Presents: ${presentsCount}, Excused: ${excusedCount}`;
        },
        title: (tooltipItems) => {
          const dataIndex = tooltipItems[0].dataIndex;
          const date = trainingDays[dataIndex];
          return `Training ${date.split(' ')[1]}`;
        }
      }
    };
  
    return {
      labels: labels,
      datasets: [absenceDataset, presentDataset, excusedDataset],
      options: {
        plugins: {
          tooltip: tooltips
        }
      }
    };
  };
   
  return (
    <div className="absence-line-chart-container1">
      <div className="line-chart-wrapper1">
        <h2 className='absent-header1'>Strength: Training Day</h2>
        <Line data={prepareLineChartData()} />
      </div>
    </div>
  );
}

export default AbsenceLineChart;
