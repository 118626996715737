import React from 'react';
import LandingPage from '../LANDINGPAGES/LandingPage';
import Footer from '../LANDINGPAGES/Footer';
import './Sample.css';


const Sample = () => {
  return (
    <div>
         <LandingPage />
         <div>
         <div class="container-sample">
    <div className="section-">
      
    <div className="faq-container-header">
  <h2 style={{ fontSize: '50px', marginTop: '50px'}}>Frequently Asked Questions</h2>
</div>

   
      <div class="accordion">
        <div class="accordion-item" id="question1">
          <a class="accordion-link" href="#question1">
          
            <div class="flex">
              <h3 style={{ fontSize: '25px', margin: '10px' }}>What is the purpose and significance of the Citizen Armed Forces of the Philippines Reservist Act (RA 7077)?</h3>
             
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>RA 7077, also known as the Citizen Armed Forces of the Philippines (CAF) Reservist Act, is a law enacted in 1991 that establishes the CAF, a reserve force of the Armed Forces of the Philippines (AFP). The main purpose of the CAF is to augment the AFP in times of war, invasion, rebellion, or national emergency. The CAF is also tasked with providing assistance in relief and rescue work, as well as other civil assistance activities.
The CAF is composed of two categories of reservists: the Ready Reserve and the Standby Reserve. The Ready Reserve is composed of reservists who are subject to call at any time to augment the AFP. The Standby Reserve is composed of reservists who are not subject to call at any time but may be called to active duty in times of national emergency. All male Filipino citizens who are at least 18 years of age are required to register for military instruction under the CAF Reservist Act. However, there are a number of exemptions, such as those who are physically or mentally unfit for military service, those who are ordained ministers of religion, and those who are already members of the AFP or the Philippine National Police.
Reservists are required to undergo a minimum of 12 days of training per year. The training may include basic military training, marksmanship training, and first aid training. Reservists may also be required to participate in additional training exercises as needed. Reservists are utilized in a variety of ways, including providing security for vital installations, conducting search and rescue operations, and providing humanitarian assistance. Reservists may also be deployed overseas to support peacekeeping missions. 
<a href="https://www.studocu.com/ph/document/university-of-perpetual-help-system-dalta/national-service-training-program-1/citizen-armed-forces-of-the-philippines-reservist-act-republic-act-ra-no-7077/18467029" target="_blank"> Source</a>
</p>
          </div> 
          <hr/>
      </div>
        <div class="accordion-item" id="question2">
          <a class="accordion-link" href="#question2">
            <div class="flex">
              <h3 style={{ fontSize: '25px', margin: '10px' }}>What was the main purpose of the National Defense Act of 1935?</h3>
            
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>Commonwealth Act No. 1 (1935), also known as the National Defense Act of 1935, was to establish an independent Philippine military force. This was a significant step towards the country's eventual independence from the United States. The act also outlined the organization, training, and equipment of the armed forces, as well as the powers of the President as commander-in-chief.
The act was passed by the Philippine National Assembly on December 21, 1935, and took effect on January 2, 1936. However, the development of the Philippine military was interrupted by the outbreak of World War II in 1941.
Despite the setbacks caused by the war, the National Defense Act of 1935 laid the foundation for the modern Philippine military. The act's provisions were incorporated into the 1946 Constitution, and the armed forces have played a key role in the country's defense and security ever since.
<a href="https://lawphil.net/statutes/comacts/ca_1_1935.html" target="_blank"> Source</a>

</p>
          </div>
          <hr/>
      </div>
        <div class="accordion-item" id="question3">
          <a class="accordion-link" href="#question3">
            <div class="flex">
              <h3 style={{ fontSize: '25px', margin: '10px' }}>What is the main purpose of Republic Act of 10742?</h3>
            
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>Republic Act No. 10742, also known as the "Sangguniang Kabataan (SK) Reform Act of 2015", was enacted to strengthen the role of the SK in the country's political system and to empower young Filipinos to participate actively in nation-building. The law grants the SK more powers and responsibilities in local governance, lowers the minimum age for SK members, and implements anti-corruption measures. It also mandates the establishment of youth councils at national, provincial, and city levels, and emphasizes leadership training for SK members and youth leaders. These reforms aim to make the SK a more effective platform for youth participation in local governance and national issues, and to empower young Filipinos to take on leadership roles in their communities.
            <a href="https://www.officialgazette.gov.ph/2016/01/15/republic-act-no-10742/ " target="_blank"> Source</a>
</p>
          </div>
          <hr/>
      </div>
        <div class="accordion-item" id="question4">
          <a class="accordion-link" href="#question4">
            <div>
              <h3 style={{ fontSize: '25px', margin: '10px' }} >What is Republic Act of 9163?</h3>
              
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>Republic Act No. 9163, also known as the "National Service Training Program (NSTP) Act of 2001", is a Philippine law that aims to enhance civic consciousness and defense preparedness among Filipino youth. It is a mandatory program for all male and female Filipino college students, requiring them to undergo 120 hours of training in one or more of its three components: Literacy Training Service (LTS), Community Service Training (CST), and Military Training Service (MTS).
The LTS component focuses on developing the youth's literacy skills, critical thinking, problem-solving, and communication abilities, as well as their understanding of Philippine history and culture. The CST component focuses on developing the youth's sense of civic responsibility and their ability to contribute to their communities through volunteer work and leadership development. The MTS component focuses on developing the youth's physical fitness, discipline, and patriotism, preparing them for the possibility of defending their country.
The NSTP program aims to instill in Filipino youth values such as patriotism, nationalism, and civic consciousness, making them well-rounded and responsible citizens prepared to contribute to the nation's development.
<a href="https://www.officialgazette.gov.ph/2002/01/23/republic-act-no-9163/" target="_blank"> Source</a>
</p>
          </div>
          <hr/>
      </div>
     </div>
  
    </div>
   
    </div>
    </div>
    <Footer /> 
    </div>
   
 
  )
          
}

export default Sample;