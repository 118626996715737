import React, { useEffect, useState } from "react";
import { ref, get } from "firebase/database";
import { db } from "../../Firebase";
import './TotalEnrolled.css'

const EnrolledTotal = ({ selectedSchoolYear, selectedSemester }) => {
  const [enrolledCount, setEnrolledCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const enrolledRef = ref(
          db,
          `enrolled/${selectedSchoolYear}/${selectedSemester}`
        );
        const enrolledSnapshot = await get(enrolledRef);

        if (enrolledSnapshot.exists()) {
          const enrolledData = enrolledSnapshot.val();
          const totalEnrolledCount = Object.keys(enrolledData).length;
          setEnrolledCount(totalEnrolledCount);
        } else {
          setEnrolledCount(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester]);

  return (
    <div className="enrolled-total-display">
      <h2>Total Enrolled: </h2>
      <div className="enrolled-count-ttl">
        <div>{enrolledCount}</div>
      </div>
    </div>
  );
};

export default EnrolledTotal;
