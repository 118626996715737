import React, { useState } from 'react';
import { auth, db } from '../Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set, child, get} from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import '../CadetCSS/CadetSignUp.css';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Row , Col, InputGroup  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEye, faEyeSlash, faXmark  } from '@fortawesome/free-solid-svg-icons'

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  const [Lastname, setLastname] = useState('');
  const [Firstname, setFirstname] = useState('');
  const [Middlename, setMiddlename] = useState(''); // Initialize middle name state with an empty string
  const [Course, setCourse] = useState('');
  const [Gender, setGender] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [customCourse, setCustomCourse] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(prevState => !prevState);
  };

  const handleSignup = async () => {
    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setErrorMessage('');

      const userData = {
        email: email,
        studentNumber: studentNumber,
        Lastname: Lastname,
        Firstname: Firstname,
        Middlename: Middlename,
        Course: Course,
        Gender: Gender
      };
      
      await set(ref(db, `pendingUsers/${userCredential.user.uid}`), userData);

      showSignupSuccessToast(); // Show success toast
      clearFields(); // Clear all fields

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Email is already in use. Please use a different email.');
      } else {
        toast.error('Signup failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const showSignupSuccessToast = () => {
    toast.success('Successfully signed up!');
  };

  const clearFields = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setStudentNumber('');
    setLastname('');
    setFirstname('');
    setMiddlename('');
    setCourse('');
    setGender('');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

// Function to get data from Firebase Realtime Database with error handling
const getDataFromDatabase = async (path) => {
  try {
    const dataSnapshot = await get(child(db, path));
    return dataSnapshot.val();
  } catch (error) {
    console.error('Error getting data from database:', error);
    return null; // or handle the error according to your application's logic
  }
};

// Usage example:
const isStudentNumberExists = async (studentNumber) => {
  try {
    const users = await getDataFromDatabase('users');
    if (users) {
      const userUids = Object.keys(users);
      for (const uid of userUids) {
        if (users[uid].studentNumber === studentNumber) {
          return true; // Student number already exists
        }
      }
    }
    return false; // Student number doesn't exist
  } catch (error) {
    console.error('Error checking if student number exists:', error);
    return false; // or handle the error according to your application's logic
  }
};

const handleSignupClick = async () => {
    const fields = [
        { value: email, label: 'Email' },
        { value: password, label: 'Password' },
        { value: confirmPassword, label: 'Confirm Password' },
        { value: studentNumber, label: 'Student Number' },
        { value: Lastname, label: 'Lastname' },
        { value: Firstname, label: 'Firstname' },
        { value: Course, label: 'Course' },
        { value: Gender, label: 'Gender' },
    ];

    const emptyFields = fields.filter(field => !field.value.trim());

    if (emptyFields.length > 0) {
        emptyFields.forEach(field => {
            toast.error(`Please enter ${field.label}.`);
        });
    } else if (password !== confirmPassword) {
        toast.error('Passwords do not match.');
    } else if (!isValidEmail(email)) {
        toast.error('Please enter a valid email address.');
    } else if (!isValidStudentNumber(studentNumber)) {
        toast.error('Please enter a valid student number in the format: 0000-00000-LQ-0');
    } else if (!selectedCourse || (selectedCourse === 'Other' && !customCourse.trim())) {
        toast.error('Please select a valid course.');
    } else if (!isGmail(email)) {
        toast.error('Please enter a Gmail account.');
    } else {
        const studentNumberExists = await isStudentNumberExists(studentNumber);
        if (studentNumberExists) {
            toast.error('Student number is already in use. Please use a different student number.');
        } else {
            if (passwordStrength === 'Weak') {
                toast.error('Password is weak. Please ensure it meets the strength requirements.');
            } else if (passwordStrength === 'Medium') {
                toast.warn('Password is medium. Consider making it stronger.');
            } else {
                handleSignup();
            }
        }
    }
};

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isGmail = (email) => {
    const gmailRegex = /@gmail.com$/;
    return gmailRegex.test(email);
};

  const isValidStudentNumber = (studentNumber) => {
    const regex = /^\d{4}-\d{5}-LQ-\d$/;
    return regex.test(studentNumber);
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCourse(selectedValue);

    if (selectedValue === 'Other') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }

    // Set the selected course to Course directly
    setCourse(selectedValue);
  };

  const handleInputChange = (e) => {
    // Set the custom course to customCourse
    setCustomCourse(e.target.value.toUpperCase());

    // Set the custom course to Course directly
    setCourse(e.target.value.toUpperCase());
  };

  const toggleCustomInput = () => {
    setShowCustomInput(!showCustomInput);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const checkPasswordStrength = (password) => {
    // Password strength criteria: minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
    
    if (strongRegex.test(password)) {
      return 'Strong';
    } else if (mediumRegex.test(password)) {
      return 'Medium';
    } else {
      return 'Weak';
    }
  };
  return (
    <div className="cadet-login-container-su">
      <div className="login-form-container-su">
        {/* Email */}
        <div className="mb-3" controlId="formPlaintextEmail">
          <div className='sign-up-email'>
            <div sm="3">
              <label>Email</label>
            </div>
            <div sm="9">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e.x. rotcpaiskan@gmail.com"
              />
            </div>
          </div>
        </div>
        {/* Password */}
        <div className="password-container">
      <Form.Group as={Row} controlId="password-input">
        <Form.Label column sm="3">Password</Form.Label>
        <Col sm="15">
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              style={{
                border: "1px solid #343a40",
                height: "40px",
                width: "200px",
                fontSize: "18px",
              }}
            />
            <Button 
              onClick={() => setShowPassword(prevState => !prevState)}
              variant="outline-dark"
              aria-label={showPassword ? "Hide Password" : "Show Password"}
              className="show-eye-button-su"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </Button>
          </InputGroup>
          {/* Conditional rendering for password strength indicator and requirements */}
      {password && (
        <>
          <div className="password-strength-indicator">
            <span className="password-strength-label">Strength:</span>
            {passwordStrength === 'Strong' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon strong" />}
            {passwordStrength === 'Medium' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon medium" />}
            {passwordStrength === 'Weak' && <FontAwesomeIcon icon={faCheck} className="password-strength-icon weak" />}
            <span className={`password-strength-text ${passwordStrength.toLowerCase()}`}>{passwordStrength}</span>
          </div>
          <div className="password-requirements">
            <p>Password must contain:</p>
            <ul>
              <li style={{ color: password.length >= 8 ? 'green' : 'red' }}>At least 8 characters</li>
              <li style={{ color: /[A-Z]/.test(password) ? 'green' : 'red' }}>At least one uppercase letter</li>
              <li style={{ color: /[a-z]/.test(password) ? 'green' : 'red' }}>At least one lowercase letter</li>
              <li style={{ color: /\d/.test(password) ? 'green' : 'red' }}>At least one number</li>
              <li style={{ color: /[!@#$%^&*]/.test(password) ? 'green' : 'red' }}>At least one special character (!@#$%^&*)</li>
            </ul>
          </div>
        </>
      )}
        </Col>
      </Form.Group>
      
    </div>

        {/* Confirm Password */}
        <Form.Group as={Row} controlId="password-input">
          <Form.Label  column sm="3">Confirm Password</Form.Label>
          <Col sm="15">
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{
                  border: "1px solid #343a40",
                  height: "40px",
                  width: "200px",
                  fontSize: "18px",
                }}
              />
              <Button 
                      onClick={toggleShowConfirmPassword}
                      variant="outline-dark"
                      aria-label={showConfirmPassword ? "Hide Password" : "Show Password"}
                      className="show-eye-button-su"
                    >
                      <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                    </Button>
                  </InputGroup>
                  {/* Message for password mismatch */}
                  {confirmPassword && password !== confirmPassword && (
                    <p style={{ color: "red" }}>Passwords do not match <FontAwesomeIcon icon={faXmark} style={{ color: "red" }}/></p>
                  )}
                  {confirmPassword && password == confirmPassword && (
                    <p style={{ color: "green" }}>Password Match <FontAwesomeIcon icon={faCheck} style={{ color: "green" }}></FontAwesomeIcon></p>
                  )}
          </Col>
        </Form.Group>

        {/* Student Number */}
        <div className="signup-details-container">
          <div className="mb-3" controlId="formStudentNumber">
            <div sm="3">
              <label>Student Number</label>
            </div>
            <div sm="9">
              <input
                type="text"
                value={studentNumber}
                onChange={(e) => setStudentNumber(e.target.value)}
                placeholder="e.x. 2020-12345-LQ-0"
              />
            </div>
          </div>
          {/* Lastname, Firstname, Middlename */}
          <div className="mb-3" controlId="formName">
            <div sm="3">
              <label>Lastname</label>
            </div>
            <div sm="3">
              <input
                type="text"
                value={Lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="e.x. Dalisay"
              />
            </div>
            <div sm="2">
              <label>Firstname</label>
            </div>
            <div sm="3">
              <input
                type="text"
                value={Firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="e.x. Ricardo"
              />
            </div>
            <div sm="1">
              <label>Middlename (Leave it blank if not applicable)</label>
            </div>
            <div sm="2">
              <input
                type="text"
                value={Middlename}
                onChange={(e) => setMiddlename(e.target.value)}
                placeholder="e.x. Tanggol"
              />
            </div>
          </div>
          {/* Course, Gender */}
          {showCustomInput ? (
            <div className="mb-3" controlId="formCourseCustom">
              <div sm="3">
                <label>Custom Course</label>
              </div>
              <div sm="9">
                <input
                  type="text"
                  value={Course}
                  placeholder="Enter Course"
                  onChange={handleInputChange}
                />
                <Button onClick={toggleCustomInput}>Back to Select Course</Button>
              </div>
            </div>
          ) : (
            <div className="mb-3" controlId="formCourse">
              <div sm="3">
                <label>Course</label>
              </div>
              <div sm="9">
                <select
                  value={selectedCourse}
                  onChange={handleSelectChange}
                >
                  <option value="">Select Course</option>
                  <option value="BEED">BEED</option>
                  <option value="BPA">BPA</option>
                  <option value="BPA-FA">BPA-FA</option>
                  <option value="BPA-FM">BPA-FM</option>
                  <option value="BSA">BSA</option>
                  <option value="BSAM">BSAM</option>
                  <option value="BSBA-FM">BSBA-FM</option>
                  <option value="BSBIO">BSBIO</option>
                  <option value="BSBA-MM">BSBA-MM</option>
                  <option value="BSCE">BSCE</option>
                  <option value="BSED-MT">BSED-MT</option>
                  <option value="BSEE">BSEE</option>
                  <option value="BSHM">BSHM</option>
                  <option value="BSIT">BSIT</option>
                  <option value="BSND">BSND</option>
                  <option value="BSOA">BSOA</option>
                  <option value="DCPET">DCPET</option>
                  <option value="DCVET">DCVET</option>
                  <option value="DEET">DEET</option>
                  <option value="DIT">DIT</option>
                  <option value="DOMT-LOM">DOMT-LOM</option>
                  <option value="DOMT-MOM">DOMT-MOM</option>
                  <option value="BSARCHI">BSARCHI</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          )}
          {/* Gender */}
          <div className="mb-3" controlId="formCourseGender">
            <div sm="3">
              <label>Sex</label>
            </div>
            <div sm="9">
              <select
                value={Gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select Sex</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
          {/* Signup Button */}
          <div className="login-container-button-cd-su">
            <Button
              variant="success"
              size="lg"
              type="button"
              className="button-login-cd"
              onClick={handleSignupClick}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-2"
                    style={{ marginRight: '8px' }}
                  />
                  <span className="spinner-text">Signing up...</span>
                </>
              ) : (
                'Sign Up'
              )}
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Signup;
