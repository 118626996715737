import React from "react";
import { Link } from "react-router-dom";

import "./Status.css";

const Status = () => {
  return (
    <div class="Content-container">
      <div className="Soldier"></div>
      <div id="container"> 
        {/* Button Container */}
        <div className="button-container">
          {/* Button Inner */}
          <div className="button-inner">
            {/* Link to Status.js */}
            <Link to="/" className="button">
              {/* Button Text */}
              <div className="button-text">HOME</div>
            </Link>
          </div>
        </div>
        <div className="logo" />
        </div>
        <div class="wrapper">
          
        <div className="ContainerStatus">

          <div className="cadet-admin-login" >
            
             <div class='admin-button1'>
              <div className="cadet-photo1"></div>
              <div className="cadet-qoute1">Marching with Resolve, Striving to Excel
</div>
    <Link to="/../Cadet/CadetLogin">
        <button class='Admin'>
            <center>Cadet</center>
        </button>
    </Link>
</div>

<div class='admin-button2'> 
<div className="cadet-photo2"></div>
<div className="cadet-qoute2">Leading with Honor, Serving with Courage</div>
    <Link to="/../Admin/LoginAdmin">
        <button class='Admin'>

            <center>Admin</center>
        </button>
    </Link>
</div>
          </div>
        </div>
        </div>

    </div>
  );
};

export default Status;
