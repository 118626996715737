import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faHome,
  faChartBar,
  faUserPlus,
  faCalendarCheck,
  faQrcode,
  faUsers,
  faSignOutAlt,
  faUser,
  faBook,
  faIdCard,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import { getDatabase, ref, onValue, off, update } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";
import "./CadetSidebar.css";

function SideBarCadet() {
  const [minimized, setMinimized] = useState(() => {
    // Get the minimized state from local storage or default to false
    const storedMinimized = localStorage.getItem("sidebarMinimized");
    return storedMinimized ? JSON.parse(storedMinimized) : false;
  });

  const [icon, setIcon] = useState(faAngleLeft);
  const [loading, setLoading] = useState(true);
  const [userImageUrl, setUserImageUrl] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    // Store the minimized state in local storage whenever it changes
    localStorage.setItem("sidebarMinimized", JSON.stringify(minimized));
  }, [minimized]);

  useEffect(() => {
    let isMounted = true;

    const database = getDatabase();
    const storage = getStorage();

    if (!userId) {
      console.error("No user is logged in.");
      return;
    }

    const databaseRef = ref(database, `users/${userId}`);
    const imageRef = storageRef(storage, `user_images/${user?.email}`);

    const userDataCallback = (snapshot) => {
      if (isMounted) {
        if (snapshot && snapshot.exists()) {
          const imageUrl = snapshot.val().userImageUrl || "";
          setUserImageUrl(imageUrl);
        } else {
          console.error("User data not found.");
        }
        setLoading(false);
      }
    };

    const imageCallback = async () => {
      try {
        const imageUrl = await getDownloadURL(imageRef);
        if (isMounted) {
          setUserImageUrl(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching user image URL:", error);
      }
    };

    onValue(databaseRef, userDataCallback);
    imageCallback();

    return () => {
      isMounted = false;
      if (databaseRef) {
        off(databaseRef, "value", userDataCallback);
      }
    };
  }, [userId, user?.email, location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMinimized(true);
        setIcon(faAngleRight);
      }
    };

    if (window.innerWidth <= 768) {
      // Adjust the screen width threshold as needed
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (window.innerWidth <= 768) {
        // Adjust the screen width threshold as needed
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      // Adjust the screen width threshold as needed
      setMinimized(true);
      setIcon(faAngleRight);
    }
  }, [location.pathname]); // This will trigger when location changes

  const toggleMinimized = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  const handleLogoClick = () => {
    setMinimized(!minimized);
    setIcon(minimized ? faAngleLeft : faAngleRight);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut(auth);
        
    const database = getDatabase();
    // Update user's status to "Offline" in the database
    await update(ref(database, `users/${userId}`), { status: "Offline" });
    
    window.location.href = "/Cadet/CadetLogin"; // Redirect to the login page after logout
  };
  const handleLogoutClick = () => {
    setIsModalOpen1(true);
    // Add any additional logic you want to execute when logout is clicked
  };
  
  const handleCloseModal = () => {
    setIsModalOpen1(false);
    // Add any additional logic you want to execute when the modal is closed
  };
  
  const [isModalOpen1, setIsModalOpen1] = useState(false);
return (
  <div
    ref={sidebarRef}
    className={`sidebarAdmin sidebarCadet ${minimized ? "minimized" : ""}`}
  >
    <div
      className={`logo-image ${minimized ? "minimized" : ""}`}
      onClick={handleLogoClick}
    ></div>
    <hr className="line-separator" />
    <div className="user-info11">
    <div className="user-info-container">
      <FontAwesomeIcon icon={faUser} size="lg" className="user-icon" />
      <div className="2">
        <p> - {localStorage.getItem("studentNumber")}</p>
      </div>
    </div>
    <div className="second-div">
      <FontAwesomeIcon icon={faBook} size="lg" className="course-icon" />
      <p> - {localStorage.getItem("Course")}</p>
    </div>
    </div>
    <ul className="cadet-list">
      <Link to="/" style={{ textDecoration: "none"}}>
        <li  className={location.pathname === "/" ? "selected" : "sidebar-content"}>
          <FontAwesomeIcon icon={faHome} />
          <span>Home</span>
        </li>
      </Link>
      <Link to="/Cadet/Cadet" style={{ textDecoration: "none" }}>
        <li className={location.pathname === "/Cadet/Cadet" ? "selected" : "sidebar-content"}>
          <FontAwesomeIcon icon={faChartBar} />
          <span>Dashboard</span>
        </li>
      </Link>
      <Link to="/Cadet/CadetProfile" style={{ textDecoration: "none" }}>
        <li
          className={
            location.pathname === "/Cadet/CadetProfile" ? "selected" : "sidebar-content"
          }
        >
          <FontAwesomeIcon icon={faUserPlus} />
          <span>Profile</span>
        </li>
      </Link>
      <Link to="/Cadet/CadetAttend" style={{ textDecoration: "none" }}>
        <li
          className={
            location.pathname === "/Cadet/CadetAttend" ? "selected" : "sidebar-content"
          }
        >
          <FontAwesomeIcon icon={faCalendarCheck} />
          <span>Attendance</span>
        </li>
      </Link>
      <Link to="/Cadet/AdminEnrollment" style={{ textDecoration: "none" }}>
        <li
          className={
            location.pathname === "/Cadet/AdminEnrollment" ? "selected" : "sidebar-content"
          }
        >
          <FontAwesomeIcon icon={faIdCard} />
          <span>Enrollment</span>
        </li>
      </Link>
      <Link to="/Cadet/QrCode" style={{ textDecoration: "none" }}>
        <li className={location.pathname === "/Cadet/QrCode" ? "selected" : "sidebar-content"}>
          <FontAwesomeIcon icon={faQrcode} />
          <span>QR Code</span>
        </li>
      </Link>
      <Link to="/Cadet/CadetDisplayFile" style={{ textDecoration: "none" }}>
        <li
          className={
            location.pathname === "/Cadet/CadetDisplayFile" ? "selected" : "sidebar-content"
          }
        >
          <FontAwesomeIcon icon={faBookOpen} />
          <span>Subject</span>
        </li>
      </Link>
    </ul>
    <div className="minimize-btn-container">
      <button className="minimize-btn" onClick={toggleMinimized}>
        <FontAwesomeIcon icon={icon} />
      </button>
    </div>
   
  </div>
);
}

export default SideBarCadet;
