import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { getAuth } from "firebase/auth";
import {
  getDatabase,
  ref as dbRef,
  get,
} from "firebase/database";
import './UserInfoModal.css';
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

const UserInfoModal = ({ isOpen, onRequestClose, user, downloadUserCSV }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [userImageUrl, setUserImageUrl] = useState("");
  const [userData, setUserData] = useState({
    Firstname: "",
    Lastname: "",
    Middlename: "",
    Course: "",
    Position: "",
    email: "",
    studentNumber: "",
  });

  useEffect(() => {
    const fetchUserByStudentNumber = async (studentNumber) => {
      const usersRef = dbRef(getDatabase(), 'users');
      const userSnapshot = await get(usersRef);
      
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            setUserData({
              ...userData[uid],
              email: userData[uid].email,
            });
  
            // Fetch user image here using the updated user data
            const storage = getStorage();
            const imageRef = storageRef(storage, `user_images/${userData[uid].email}`);
  
            try {
              const imageUrl = await getDownloadURL(imageRef);
              setUserImageUrl(imageUrl);
              setImageLoading(false);
            } catch (error) {
              console.error("Error fetching user image:", error);
              setImageLoading(false);
            }
  
            break;
          }
        }
      } else {
        console.log('No user data found in the database.');
      }
    };
  
    if (user && user.studentNumber) {
      fetchUserByStudentNumber(user.studentNumber);
    }
  }, [user]);
  
  
 
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Information"
      className="user-info-modal"
      overlayClassName="user-info-overlay"
      size="xl"
    >
      {user && (
        <div>
          <button className="close-button-modal" onClick={onRequestClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '30px' }} />
          </button>
          <div className="container-header-modal">
            <h2>User Information</h2>
          </div>
          <div className="user-image-container-info">
           
              <img className="user-image enlarged-image" src={userImageUrl} alt="User" />

          </div>

          <table className="user-credentials-table">
            <tbody>
              <tr>
                <td>Student Number:</td>
                <td>{userData.studentNumber}</td>
              </tr>
             
              <tr>
                <td>Fullname Name:</td>
                <td>{userData.Lastname}, {userData.Firstname} {userData.Middlename || "N/A"}</td>
              </tr>
            
              <tr>
                <td>Email:</td>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <td>Course:</td>
                <td>{userData.Course}</td>
              </tr>
              {/* Include additional credentials here */}
              <tr>
                <td>Gender:</td>
                <td>{userData.Gender || "N/A"}</td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>{userData.DateOfBirth || "N/A"}</td>
              </tr>
              <tr>
                <td>Contact Number:</td>
                <td>{userData.ContactNo || "N/A"}</td>
              </tr>
              <tr>
                <td>Region:</td>
                <td>{userData.region || "N/A"}</td>
              </tr>
              <tr>
                <td>Province:</td>
                <td>{userData.province || "N/A"}</td>
              </tr>
              <tr>
                <td>City:</td>
                <td>{userData.city || "N/A"}</td>
              </tr>
              <tr>
                <td>Barangay:</td>
                <td>{userData.barangay || "N/A"}</td>
              </tr>
              <tr>
                <td>Height:</td>
                <td>{userData.Height || "N/A"}</td>
              </tr>
              <tr>
                <td>Weight:</td>
                <td>{userData.Weight || "N/A"}</td>
              </tr>
              <tr>
                <td>Blood Type:</td>
                <td>{userData.BloodType || "N/A"}</td>
              </tr>
              <tr>
                <td>Position:</td>
                <td>{userData.Position || "N/A"}</td>
              </tr>
              <tr>
                <td>Beneficiary Name:</td>
                <td>{userData.BeneficiaryName || "N/A"}</td>
              </tr>
              <tr>
                <td>Beneficiary Contact:</td>
                <td>{userData.BeneficiaryContact || "N/A"}</td>
              </tr>
              <tr>
                <td>Beneficiary Address:</td>
                <td>{userData.BeneficiaryAddress || "N/A"}</td>
              </tr>
              <tr>
                <td>Beneficiary Relationship:</td>
                <td>{userData.BeneficiaryRelationship || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
};

export default UserInfoModal;