import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../Firebase';
import { Pie } from 'react-chartjs-2';
import './AbsentTotal.css';

const AttendancePerTrainingDay = ({ selectedSchoolYear, selectedSemester, selectedTrainingDate }) => {
  const [absentCount, setAbsentCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionPath = `Session/${selectedSchoolYear}/${selectedSemester}/Trainingdates/${selectedTrainingDate}`;
        const attendanceRef = ref(db, sessionPath);
        const attendanceSnapshot = await get(attendanceRef);

        if (attendanceSnapshot.exists()) {
          const attendanceData = attendanceSnapshot.val();
          let totalAbsentCount = 0;

          // Iterate through each student entry and count absent appearances
          Object.values(attendanceData).forEach(studentData => {
            if (studentData['Time In']?.appearance === 'Absent' || studentData['Time Out']?.appearance === 'Absent') {
              totalAbsentCount++;
            }
          });

          // Update state with the total absent count
          setAbsentCount(totalAbsentCount);
        } else {
          // If attendance data doesn't exist, set absent count to 0
          setAbsentCount(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedSemester, selectedTrainingDate]);

  return (
    <div className="attendance-absent-display">
      <h2>Total Absents: </h2>
      <div className='absent-count-ttl'>
      {absentCount}
      </div>
    </div>
  );
};

export default AttendancePerTrainingDay;
