import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@material-ui/core'; // Assuming you are using Material-UI for the spinner
import './AdminAddSubject.css'
import SideBar from "./AdminSideBar";
import Form from 'react-bootstrap/Form';
import { Tab, Tabs } from 'react-bootstrap';

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('MS1');
  const [isUploading, setIsUploading] = useState(false); // State variable for tracking upload status

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      const storage = getStorage();
      const folderRef = storageRef(storage, selectedFolder);

      try {
        const fileList = await listAll(folderRef);
        const fileNames = fileList.items.map(item => item.name);
        setUploadedFiles(fileNames);
      } catch (error) {
        console.error('Error occurred while fetching uploaded files: ', error);
        toast.error('Error fetching uploaded files. Please try again.');
      }
    };

    fetchUploadedFiles();
  }, [downloadURL, selectedFolder]); // Add selectedFolder to dependencies to update file list when folder changes

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file) {
      const storage = getStorage();
      const storageReference = storageRef(storage, `${selectedFolder}/${file.name}`);

      try {
        setIsUploading(true); // Set upload status to true when upload begins
        const uploadTaskSnapshot = await uploadBytes(storageReference, file);
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        console.log('File uploaded successfully. Download URL: ', downloadURL);
        setDownloadURL(downloadURL);
        // Show toast notification for successful upload
        toast.success('File uploaded successfully!');
      } catch (error) {
        console.error('Error occurred during upload: ', error);
        // Show toast notification for upload error
        toast.error('Error uploading file. Please try again.');
      } finally {
        setIsUploading(false); // Set upload status to false after upload completes
      }
    } else {
      console.error('No file selected for upload.');
      // Show toast notification if no file is selected
      toast.error('No file selected for upload.');
    }
  };

  const handleFolderChange = (eventKey) => {
    setSelectedFolder(eventKey);
  };  
  const [userType, setUserType] = useState("");
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedEmail, setStoredEmail] = useState('');
  const [storedDesignation, setStoredDesignation] = useState('');
  const [designation, setDesignation] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  useEffect(() => {
      // Set user type based on credentials
      const userEmail = localStorage.getItem('userEmail');
      if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
          setUserType("admin");
      }
  }, []);

  useEffect(() => {
      const userType = localStorage.getItem("userType");
      setUserType(userType);

      const storedStudentNumber = localStorage.getItem('studentNumber');
      setStoredStudentNumber(storedStudentNumber);


      const storedEmail = localStorage.getItem('email');
      setStoredEmail(storedEmail);

      const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
      setStoredDesignation(storedDesignation); // Set the designation in the component state
  }, []);

  useEffect(() => {
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');

      if (storedDesignation && storedStudentNumber) {
          setDesignation(storedDesignation);
          setStudentNumber(storedStudentNumber);
      }
  }, []);

  return (
    <div className="Admin-add-sub">
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>

    <div className="Admin-add-sub-container">
    <ToastContainer className="toast-container" />
      <h1  className="Admin-add-sub-heading">Subjects</h1>
      <ToastContainer />
      <div className="enrollement-yearsem">
      <h1 className="pendingHeader">File Upload</h1>
      <div className="file-uploader">
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Control type="file" onChange={handleFileChange} />
        
      </Form.Group>
      <div className= "Documents-btn" onClick={handleUpload} >  <span className="folderContainer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 146 113"
          height="113"
          width="146"
          className="fileBack"
        >
          <path
            fill="url(#paint0_linear_117_4)"
            d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
          ></path>
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              y2="95.4804"
              x2="72.93"
              y1="0"
              x1="0"
              id="paint0_linear_117_4"
            >
              <stop stopColor="#8F88C2"></stop>
              <stop stopColor="#5C52A2" offset="1"></stop>
            </linearGradient>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 88 99"
          height="99"
          width="88"
          className="filePage"
        >
          <rect fill="url(#paint0_linear_117_6)" height="99" width="88"></rect>
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              y2="160.5"
              x2="81"
              y1="0"
              x1="0"
              id="paint0_linear_117_6"
            >
              <stop stopColor="white"></stop>
              <stop stopColor="#686868" offset="1"></stop>
            </linearGradient>
          </defs>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 160 79"
          height="79"
          width="160"
          className="fileFront"
        >
          <path
            fill="url(#paint0_linear_117_5)"
            d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
          ></path>
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              y2="82.8317"
              x2="66.9106"
              y1="8.71323"
              x1="38.7619"
              id="paint0_linear_117_5"
            >
              <stop stopColor="#C3BBFF"></stop>
              <stop stopColor="#51469A" offset="1"></stop>
            </linearGradient>
          </defs>
        </svg>
      </span><p className="text" >Upload</p></div>
      {isUploading && <CircularProgress />} {/* Display loading spinner if uploading */}
      {uploadProgress > 0 && <div>Upload Progress: {uploadProgress.toFixed(2)}%</div>}
      {downloadURL && <div>Download URL: {downloadURL}</div>}
      {file && <div>File Name: {file.name}</div>}
      {downloadURL && (
        <a href={`/viewFile?downloadURL=${downloadURL}&filename=${file.name}`} target="_blank" rel="noopener noreferrer">
          View Uploaded File
        </a>
      )}
      </div>
      <Tabs
  id="fill-tab-example"
  fill
  className="mb-4"
  activeKey={selectedFolder}
  onSelect={handleFolderChange}
>
  <Tab eventKey="MS1" title="MS1" style={{ backgroundColor: selectedFolder === "MS1" ? "blue" : "" }}>
  </Tab>
  <Tab eventKey="MS2" title="MS2" style={{ backgroundColor: selectedFolder === "MS2" ? "blue" : "" }}>
  </Tab>
</Tabs>
      

      <div className="enrollement-yearsem1"></div>
        <div className="enrollement-yearsem">

      <div className="uploaded-files-container">
      <h1 className="pendingHeader">List of Uploaded Files</h1>
      <ul className="uploaded-files-list">
        {uploadedFiles.map((fileName, index) => (
          <li key={index} className="uploaded-file-item">{fileName}</li>
        ))}
      </ul>
      </div>
    </div>
    </div>
    </div>  
    </div>
  );
};

export default FileUploader;