import React, { useState, useEffect } from 'react';
import { ref, onValue, set, remove, get} from 'firebase/database';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from './AdminSideBar';
import { db, auth} from '../Firebase';
import { deleteUser as deleteAuthUser, getAuth } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortUp, faSortDown ,faSort  } from '@fortawesome/free-solid-svg-icons';
import "./AdminVerified.css"
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Spinner from "react-bootstrap/Spinner";
const AdminVerified = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGender, setSelectedGender] = useState('');

  useEffect(() => {
    const pendingUsersRef = ref(db, 'pendingUsers');
    
    const unsubscribe = onValue(pendingUsersRef, (snapshot) => {
      if (snapshot.exists()) {
        const pendingUsersData = snapshot.val();
        const usersArray = Object.entries(pendingUsersData).map(([uid, userData]) => ({
          uid: uid,
          ...userData
        }));
        setPendingUsers(usersArray);
      } else {
        setPendingUsers([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const sendApprovalEmail = async (user) => {
    try {
      const templateParams = {
        to_email: user.email,
        lastname: user.Lastname,
        firstname: user.Firstname,
        middlename: user.Middlename,
        status: 'approve' // or 'decline'
      };

      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          service_id: 'service_7h2vn4d',
          template_id: 'template_rz9nuhn',
          user_id: 'xK7xVRrD_6slyuYZ-',
          template_params: templateParams
        })
      });

      const responseData = await response.json();

      if (responseData.status === 'success') {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email:', responseData);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleApproveUser = async (user) => {
    try {
      if (!user || !user.uid) {
        console.error('Invalid user object:', user);
        return;
      }
  
      // Confirm the action with the user
      const confirmation = window.confirm(`Are you sure you want to approve the account for ${user.email}?`);
      if (!confirmation) {
        return; // If user cancels, exit the function
      }
  
      // Remove status and uid from user object
      const { status, uid, ...userData } = user;
  
      // Update user data in the 'users' node without status and uid
      await set(ref(db, `users/${user.uid}`), userData);
      await remove(ref(db, `pendingUsers/${user.uid}`));
      await sendApprovalEmail(user);
      toast.success(`Account for ${user.email} is approved.`);
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };
  
  const handleDeclineUser = async (user) => {
    try {
      if (user && user.uid) {
        // Confirm the action with the user
        const confirmation = window.confirm(`Are you sure you want to decline the account request for ${user.email}?`);
        if (!confirmation) {
          return; // If user cancels, exit the function
        }
  
        // Remove user request from pendingUsers node
        await remove(ref(db, `pendingUsers/${user.uid}`));
        // Remove user from the 'users' node
        await remove(ref(db, `users/${user.uid}`));
        toast.success(`Account request for ${user.email} is declined.`);
      }
    } catch (error) {
      console.error('Error declining user:', error);
      toast.error(`Error declining user: ${error.message}`);
    }
  };
  
  const toggleUserSelection = (user) => {
    const selectedIndex = selectedUsers.findIndex(selectedUser => selectedUser.uid === user.uid);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = [...selectedUsers, user];
    } else {
      newSelectedUsers = [...selectedUsers.slice(0, selectedIndex), ...selectedUsers.slice(selectedIndex + 1)];
    }
    setSelectedUsers(newSelectedUsers);
  };

  const selectAllUsers = () => {
    if (selectedUsers.length === pendingUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers([...pendingUsers]);
    }
  };

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedField, setSortedField] = useState(null);
  const [sortedDirection, setSortedDirection] = useState(null);

  const handleFilterByCourse = (event) => {
    const course = event.target.value;
    setSelectedCourse(course);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterByGender = (event) => {
    const gender = event.target.value;
    setSelectedGender(gender);
  };

  const handleResetSearch = () => {
    setSearchQuery('');
  };
  
  const handleSort = (field) => {
    if (sortedField === field) {
      setSortedDirection(sortedDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortedDirection('asc');
    }
  };

  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    return <FontAwesomeIcon icon={faSort} />; // Always render the sort icon
  };

  const filteredUsers = pendingUsers.filter(user => {
    const searchString = `${user.studentNumber} ${user.email} ${user.Lastname} ${user.Firstname} ${user.Middlename} ${user.Gender} ${user.Course}`.toLowerCase();
    const genderFilter = selectedGender === "" || user.Gender.toLowerCase() === selectedGender.toLowerCase();
    return searchString.includes(searchQuery.toLowerCase()) && genderFilter;
  })
  .filter(user => selectedCourse === "" || user.Course === selectedCourse);

  const sortedUsers = filteredUsers.slice().sort((a, b) => {
    if (!sortedField) return 0;
    const aValue = a[sortedField].toLowerCase();
    const bValue = b[sortedField].toLowerCase();
    if (aValue === bValue) return 0;
    return sortedDirection === 'asc' ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
  });

  const handleActionWithoutSelection = (actionName) => {
    toast.error(`Please select users to ${actionName}.`);
  };
  
  const handleApproveSelectedUsers = async () => {
    try {
      // Check if any user is selected
      if (selectedUsers.length === 0) {
        handleActionWithoutSelection('approve');
        return;
      }
  
      const confirmation = window.confirm("Are you sure you want to approve the selected users?");
      if (!confirmation) {
        return;
      }
  
      for (const user of selectedUsers) {
        await handleApproveUser(user);
      }
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error approving selected users:', error);
    }
  };
  
  const handleDeclineSelectedUsers = async () => {
    try {
      // Check if any user is selected
      if (selectedUsers.length === 0) {
        handleActionWithoutSelection('decline');
        return;
      }
  
      const confirmation = window.confirm("Are you sure you want to decline the selected users?");
      if (!confirmation) {
        return;
      }
  
      for (const user of selectedUsers) {
        await handleDeclineUser(user);
      }
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error declining selected users:', error);
    }
  };
  
  const [userType, setUserType] = useState("");
  const [storedStudentNumber, setStoredStudentNumber] = useState("");
  const [storedEmail, setStoredEmail] = useState('');
  const [storedDesignation, setStoredDesignation] = useState('');
  const [designation, setDesignation] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  useEffect(() => {
      // Set user type based on credentials
      const userEmail = localStorage.getItem('userEmail');
      if (userEmail === "rotcpaiskan@gmail.com" || userEmail === "rotcpuppaiskan@gmail.com") {
          setUserType("admin");
      }
  }, []);
  
  useEffect(() => {
      const userType = localStorage.getItem("userType");
      setUserType(userType);
  
      const storedStudentNumber = localStorage.getItem('studentNumber');
      setStoredStudentNumber(storedStudentNumber);
  
  
      const storedEmail = localStorage.getItem('email');
      setStoredEmail(storedEmail);
  
      const storedDesignation = localStorage.getItem('designation'); // Retrieve the designation from local storage
      setStoredDesignation(storedDesignation); // Set the designation in the component state
  }, []);
  
  useEffect(() => {
      const storedDesignation = localStorage.getItem('designation');
      const storedStudentNumber = localStorage.getItem('studentNumber');
      const storedEmail = localStorage.getItem('email');
      if (storedDesignation && storedStudentNumber) {
          setDesignation(storedDesignation);
          setStudentNumber(storedStudentNumber);
          setEmail(storedEmail);
      }
  }, []);
  // Effect to fetch user data when component mounts or when studentNumber or designation changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataFromDb = await getUserData(designation, studentNumber);
        setUserData(userDataFromDb);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };
  
    fetchData();
  }, [designation, studentNumber]);
  const [email, setEmail] = useState('');
  
  const getUserData = async (designation, studentNumber) => {
    try {
      // Construct the reference path
      const userRef = ref(db, `Officer/${designation}/${studentNumber}`);
      
      // Retrieve the data
      const snapshot = await get(userRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Extract the user data
        const userData = snapshot.val();
        // Return the relevant data such as Lastname and Firstname
        return {
          Course: userData.Course,
          Lastname: userData.Lastname || '',
          Firstname: userData.Firstname || '',
          Middlename: userData.Middlename || '',
          // Add more properties as needed
        };
      } else {
        // Data does not exist
        console.log("No data available for the given student number.");
        return null;
      }
    } catch (error) {
      console.error("Error retrieving user data:", error.message);
      throw error;
    }
  };
  const [userData, setUserData] = useState([]);
  
  const [userImageUrl, setUserImageUrl] = useState(null);
  const fetchUserImage = async (email) => {
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, `user_images/${email}`);
      const imageUrl = await getDownloadURL(imageRef);
      setUserImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching user image:', error);
      setUserImageUrl(null);
    }
  };
  
  useEffect(() => {
    // Fetch user image when email changes
    if (email) {
      fetchUserImage(email);
    }
  }, [email]);
  const [showModal, setShowModal] = useState(false);
  const [sortIcon, setSortIcon] = useState(faSortDown);
  
  const handleModalOpen = () => {
    setShowModal(prevState => !prevState); // Toggle modal state
    setSortIcon(prevState => prevState === faSortDown ? faSortUp : faSortDown); // Toggle sort icon
  };
  
  const handleModalClose = () => {
    setShowModal(false);
    setSortIcon(faSortDown); // Change the icon back to faSortDown when modal closes
  };
  
  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
  };
  const handleLogoutClick = () => {
    setIsModalOpen1(true);
    // Add any additional logic you want to execute when logout is clicked
  };
  
  const handleCloseModal = () => {
    setIsModalOpen1(false);
    // Add any additional logic you want to execute when the modal is closed
  };
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const [searchQueries, setSearchQueries] = useState({});


  return (
    <div className="Admin-PE">
              
<SideBar
    studentNumber={storedStudentNumber}
    email={storedEmail}
    designation={storedDesignation} // Add the designation prop here
/>
<div className='attendance-container'>
<ToastContainer className="toast-container" />
    <div className="header-container1">

{isModalOpen1 && (
<div className="CustomCard1">
  <div className="CustomCard">
    <div className="card-content">
      <p className="card-heading">Log Out</p>
      <p className="card-description">Are you sure you want to Log out?</p>
    </div>
    <div className="card-button-wrapper">
      <button className="card-button secondary" onClick={handleCloseModal}>No</button>
      <button className="card-button primary">Yes</button>
    </div>
    <button className="exit-button" onClick={handleCloseModal}>
      <svg height="20px" viewBox="0 0 384 512">
        <path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        ></path>
      </svg>
    </button>
  </div>
</div>
)}
  <h1 className="Header-container-attendance">PENDING CADETS</h1>  
  
{!designation   && (
    <>
     
    </>
  )}
     
     { (designation === 'S1' || designation === 'S2' || designation === 'EXO') && (
<div className="user-details">
<div className="user-details1">
{userData && (
<p className="user-name">
{userData.Lastname}, {userData.Firstname} {userData.Middlename} ({designation})
</p>
)}
</div>
<div className="user-image-container1" onClick={handleModalOpen}>
{userImageUrl ? (
<img className="user-image1" src={userImageUrl} alt="User" />
) : (

<span className="visually-hidden">Loading...</span>

)}
<FontAwesomeIcon icon={sortIcon} style={{ marginLeft: '2px', marginTop: '-10px', color: sortIcon === faSortUp ? 'green' : 'black' }} onClick={handleModalOpen} />

</div>
{showModal && (
<div className={`modal-overlay ${showModal ? 'fillModal' : 'closed'}`} onClick={handleModalClose}>
<div className="modal-content" onClick={handleModalContentClick}>
<span className="close" onClick={handleModalClose}>&times;</span>
<div className="pendingHeader1">Profile</div>
<div className="user-image-container12">
 {userImageUrl ? (
   <img className="user-image12" src={userImageUrl} alt="User" />
 ) : (
   <Spinner animation="border" role="status" variant="success">
     <span className="visually-hidden">Loading...</span>
   </Spinner>
 )}
 

</div>
<button className="edit-button">
<svg className="edit-svgIcon" viewBox="0 0 512 512">
<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
</svg>
</button>
<div className="info-box">
<p className="Personnel">Personnel Officer ({designation})</p>
{userData && (
<>
<div className="custom-table"> 
<table className="custom-table">
<tbody>
<tr>
  <td><label htmlFor="studentNumber">Student Number:</label></td>
  <td><p>{studentNumber}</p></td>
</tr>
<tr>
<td><label>Name:</label></td>
  <td><p>{userData.Lastname}, {userData.Firstname} {userData.Middlename}</p></td>
</tr>
<tr>
  <td><label htmlFor="email">Email:</label></td>
  <td><p>{email}</p></td>
</tr>
<tr>
  <td><label>Course:</label></td>
  <td><p>{userData.Course}</p></td>
</tr>

</tbody>
</table></div>

</>
)}

</div>
<div className="button-container12">
<button className="CustomBtn" onClick={handleLogoutClick}>
  <div className="sign12">
    <svg viewBox="0 0 512 512">
      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
    </svg>
  </div>
  <div className="text12">Logout</div>
</button>
</div>


</div>

</div>

)}

</div>
)}

</div>
        <div className="Admin-approval-container1">

        <div className="enrollement-yearsem1"></div>
        <div className="enrollement-yearsem">
        <h1 className="pendingHeader">Pending Requests ({pendingUsers.length})</h1>
              <div className="Admin-PE-action-buttons">

              <button className="noselect00"  onClick={handleApproveSelectedUsers}><span className="text00">Approve</span>
  <span className="icon00">
    <div className="sign12">  </div>
  </span>
</button>
  <button className="noselect0" onClick={handleDeclineSelectedUsers}><span className="text0">Delete</span>
  <span className="icon0">
    <div className="sign">
      <svg
        viewBox="0 0 16 16"
        className="bi bi-trash3-fill"
        fill="currentColor"
        height="18"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
        ></path>
      </svg>
    </div>
  </span>
</button>
<select className="custom-dropdown" value={selectedCourse} onChange={handleFilterByCourse}>
          <option value="">All Courses</option>
          {[...new Set(pendingUsers.map(user => user.Course))].map(course => (
            <option key={course} value={course}>{course}</option>
          ))}
        </select>
        <select className="custom-dropdown1" value={selectedGender} onChange={handleFilterByGender}>
          <option value="">All</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <form className="form-search">
        <label htmlFor="search" className="label-search">
        <input
             className="input-search"
             type="text1"
             required
             placeholder="Search"
             id="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
              <div className="fancy-bg-search"></div>
        <div className="search-search">
          <svg viewBox="0 0 24 24" aria-hidden="true" className="icon-search r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
        </div>
        <button className="close-btn-search" type="reset" onClick={handleResetSearch}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
              </label>
    </form>


</div>
<div className="verified-container">
      <table className="table-container-verified">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectedUsers.length === pendingUsers.length && pendingUsers.length !== 0}
                onChange={selectAllUsers}
              />
            </th>
            <th>No.</th>
            <th onClick={() => handleSort('studentNumber')}>
              Student Number
              {renderSortIcon('studentNumber')}
            </th>
            <th onClick={() => handleSort('email')}>
              Email
              {renderSortIcon('email')}
            </th>
            <th onClick={() => handleSort('Lastname')}>
              Last Name
              {renderSortIcon('Lastname')}
            </th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Gender</th>
            <th onClick={() => handleSort('Course')}>
              Course
              {renderSortIcon('Course')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
       {sortedUsers.map((user, index) => (
            <tr key={user.uid}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedUsers.some((selectedUser) => selectedUser.uid === user.uid)}
                  onChange={() => toggleUserSelection(user)}
                />
              </td>
              <td>{index + 1}</td>
              <td>{user.studentNumber}</td>
              <td>{user.email}</td>
              <td>{user.Lastname}</td>
              <td>{user.Firstname}</td>
              <td>{user.Middlename}</td>
              <td>{user.Gender}</td>
              <td>{user.Course}</td>
              <td className="action-btn">
                <button className="Btn1" onClick={() => handleApproveUser(user)}>
                  <div className="sign1"></div>
                  <div className="text2">Approve</div>
                </button>
                <button className="Btn" onClick={() => handleDeclineUser(user)}>
                  <div className="sign">
                    <svg
                      viewBox="0 0 16 16"
                      className="bi bi-trash3-fill"
                      fill="currentColor"
                      height="18"
                      width="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
                      ></path>
                    </svg>
                  </div>
                  <div className="text1">Delete</div>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
        {pendingUsers.length === 0 && <p>No pending sign-up requests.</p>}

      </div>
    </div>
    </div>
  );
};

export default AdminVerified;
