import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { getAuth, updateEmail } from "firebase/auth";

import {
  getDatabase,
  ref as dbRef,
  get,
  set as dbSet, // Import the set function for updating data
} from "firebase/database"; // Import the necessary functions for interacting with the database
import './UserInfoModal.css';
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

// Define field order and aliases
const fieldOrder = [
  { key: "studentNumber", alias: "Student Number" },
  { key: "email", alias: "Email" },
  { key: "Firstname", alias: "First Name" },
  { key: "Lastname", alias: "Last Name" },
  { key: "Middlename", alias: "Middle Name" },
  { key: "Gender", alias: "Gender" },
  { key: "Course", alias: "Course" },
  { key: "DateOfBirth", alias: "Date of Birth" },
  { key: "ContactNo", alias: "Contact Number" },
  { key: "region", alias: "Region" },
  { key: "province", alias: "Province" },
  { key: "city", alias: "City" },
  { key: "barangay", alias: "Barangay" },
  { key: "Height", alias: "Height" },
  { key: "Weight", alias: "Weight" },
  { key: "BloodType", alias: "Blood Type" },
  { key: "Position", alias: "Position" },
  { key: "BeneficiaryName", alias: "Beneficiary Name" },
  { key: "BeneficiaryContact", alias: "Beneficiary Contact" },
  { key: "BeneficiaryAddress", alias: "Beneficiary Address" },
  { key: "BeneficiaryRelationship", alias: "Beneficiary Relationship" },
];

// Function to check if a field is editable
const canEdit = (field) => {
  // Define fields that can be edited by the user
  const editableFields = [
    "Firstname",
    "Lastname",
    "Middlename",
    "email",
    "studentNumber",
    "Gender",
    "Course",
    "DateOfBirth",
    "ContactNo",
    "region",
    "province",
    "city",
    "barangay",
    "Height",
    "Weight",
    "BloodType",
    "Position",
    "BeneficiaryName",
    "BeneficiaryContact",
    "BeneficiaryAddress",
    "BeneficiaryRelationship",
  ];

  // Check if the field is in the list of editable fields
  return editableFields.includes(field);
};

const UserInfoModal = ({ isOpen, onRequestClose, user, downloadUserCSV }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [userImageUrl, setUserImageUrl] = useState("");
  const [userData, setUserData] = useState({
    studentNumber: "",
    email: "",
    Firstname: "",
    Lastname: "",
    Middlename: "",
    Course: "",
    Position: "",
    Gender: "",
    DateOfBirth: "",
    ContactNo: "",
    region: "",
    province: "",
    city: "",
    barangay: "",
    Height: "",
    Weight: "",
    BloodType: "",
    BeneficiaryName: "",
    BeneficiaryContact: "",
    BeneficiaryAddress: "",
    BeneficiaryRelationship: "",
  });

  useEffect(() => {
    const fetchUserByStudentNumber = async (studentNumber) => {
      const usersRef = dbRef(getDatabase(), 'users');
      const userSnapshot = await get(usersRef);
      
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        for (const uid in userData) {
          if (userData[uid].studentNumber === studentNumber) {
            setUserData({
              ...userData[uid],
              email: userData[uid].email,
            });
  
            // Fetch user image here using the updated user data
            const storage = getStorage();
            const imageRef = storageRef(storage, `user_images/${userData[uid].email}`);
  
            try {
              const imageUrl = await getDownloadURL(imageRef);
              setUserImageUrl(imageUrl);
              setImageLoading(false);
            } catch (error) {
              console.error("Error fetching user image:", error);
              setImageLoading(false);
            }
  
            break;
          }
        }
      } else {
        console.log('No user data found in the database.');
      }
    };
  
    if (user && user.studentNumber) {
      fetchUserByStudentNumber(user.studentNumber);
    }
  }, [user]);

  // Function to save updated user data to the database
const saveUserData = async () => {
  const auth = getAuth(); // Get authentication instance
  const userAuth = auth.currentUser; // Get current user
  
  if (userAuth) {
    try {
      // Update email in authentication
      await updateEmail(userAuth, userData.email);

      // Update email in database
      const usersRef = dbRef(getDatabase(), 'users');
      const userSnapshot = await get(usersRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        for (const uid in userData) {
          if (userData[uid].studentNumber === user.studentNumber) {
            userData[uid].email = userData.email; // Update email in user data
            dbSet(dbRef(getDatabase(), `users/${uid}`), userData); // Update user data in the database
            break;
          }
        }
      }
      
      console.log("User email updated successfully!");
    } catch (error) {
      console.error("Error updating user email:", error);
    }
  }
};
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Information"
      className="user-info-modal"
      overlayClassName="user-info-overlay"
      size="xl"
    >
      {user && (
        <div>
          <button className="close-button-modal" onClick={onRequestClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '30px' }} />
          </button>
          <div className="container-header-modal">
            <h2>User Information</h2>
          </div>
          <div className="user-image-container-info">
            {imageLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <img className="user-image enlarged-image" src={userImageUrl} alt="User" />
            )}
          </div>

          <table className="user-credentials-table">
            <tbody>
              {fieldOrder.map((field) => (
                <tr key={field.key}>
                  <td>{field.alias}:</td>
                  <td>
                    {canEdit(field.key) ? (
                      <input
                        type="text"
                        value={userData[field.key]}
                        onChange={(e) => {
                          setUserData({ ...userData, [field.key]: e.target.value });
                        }}
                      />
                    ) : (
                      userData[field.key]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Save Button */}
          <button className="save-button" onClick={saveUserData}>Save</button>
        </div>
      )}
    </Modal>
  );
};

export default UserInfoModal;