import React, { useState, useEffect, useRef } from "react";
import "../LANDINGPAGECSS/ImageCarousel.css";
import LandingPage from '../LANDINGPAGES/LandingPage'
import Footer from '../LANDINGPAGES/Footer';

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = 8;
  const intervalRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 3000);
  };

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startInterval();

    return () => {
      stopInterval();
    };
  }, []);

  useEffect(() => {
    stopInterval();
    startInterval();
  }, [currentIndex]); // Restart interval when currentIndex changes

  return (
    <div> <LandingPage/>
    
    <div className="image-carousel">
      <div className={`image image${currentIndex + 1}`} />
      <div className="background-img"></div>
    </div>
    <Footer />
    </div>
  );
};

export default ImageCarousel;